// import logo from "../../assets/images/WorkDetails/SindhiSoulmate/logo.svg"
// import CommonDiv from "./CommonDiv"
import { motion } from "framer-motion";
import ParenticianCommon from "./ParenticianCommon";
// import ShiporaCommon from "./ShiporaCommon";

export default function ParenticianDescription() {
    const projectInfo = {
        deliverables: [" Web app"],
        timeline: "lorem",
        services: ["Research", "UI/UX Design", "Prototype"],
    }

    return (

        <>


            <motion.div className="max-w-5xl mx-auto pt-8" initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true, amount: 0.2 }}
                transition={{ duration: 0.5 }}>



                <div className="grid grid-cols-2 md:grid-cols-3 gap-8 mb-12">

                    <div>
                        <h3 className="font-normal text-black mb-4 text-lg">Deliverables</h3>
                        <ul className="space-y-2">
                            {projectInfo.deliverables.map((item, index) => (
                                <li key={index} className="text-[#4D4D4D] text-lg font-normal">
                                    {item}
                                </li>
                            ))}
                        </ul>
                    </div>

                    {/* Project Timeline */}
                    <div>
                        <h3 className="font-normal text-black mb-4 text-lg">Project timeline</h3>
                        <p className="text-[#4D4D4D] text-lg font-normal">{projectInfo.timeline}</p>
                    </div>

                    {/* What We Do */}
                    <div>
                        <h3 className="font-normal text-black mb-4 text-lg">What we do</h3>
                        <ul className="space-y-2">
                            {projectInfo.services.map((service, index) => (
                                <li key={index} className="text-[#4D4D4D] text-lg font-normal">
                                    {service}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>


                {/* <div className="max-w-6xl mx-auto">
                <div className="mb-12">
                    <p className="text-[#292929] text-2xl md:text-3xl leading-relaxed">
                        Lorem ipsum dolor sit amet consectetur ,adipl ipsum dolor sit ametr sit a Lorem. Lorem ipsum dolor sit amet
                        consectetur ,adipl ipsum dolor sit ametr sit a Lorem. Lorem ipsum dolor sit amet consectetur ,adipl ipsum
                        dolor Lorem.
                    </p>
                </div>

         
                <div>
                    <h2 className="text-xl font-semibold text-gray-900 mb-4">The Challenge</h2>
                    <div className="space-y-4">
                        <p className="text-gray-600">
                            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Provident in doloremque officia dignissimos
                            deleniti inventore porro possimus, suscipit natus. Commodi iure voluptate voluptatum quo ducimus eaque error
                            numquam facilis itaque?
                        </p>
                        <p className="text-gray-600">
                            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Provident in doloremque officia dignissimos
                            deleniti inventore porro possimus, suscipit natus. Commodi iure voluptate voluptatum quo ducimus eaque error
                            adipisicing elit. Provident in doloremque i iure voluptate voluptatum quo ducimus eaque error numquam
                            facilis itaque?
                        </p>
                    </div>
                </div>
            </div> */}
            </motion.div>
            <div className="max-w-6xl mx-auto">
                <motion.div className="mb-12"   initial={{ opacity: 0, y: 50 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true, amount: 0.2 }}
                        transition={{ duration: 0.5 }}>
                    <p className="text-[#292929] text-xl md:text-2xl leading-relaxed font-medium">
                    {/* SHIPORA is a powerful web and mobile eCommerce solution developed by Tech Rabbit. Designed to provide seamless shopping experiences, it combines cutting-edge technology with a user-friendly interface. */}

                    Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quam facilis dignissimos animi atque aperiam consectetur eius eligendi veniam. Fugiat, consequatur. Lorem ipsum dolor sit amet consectetur adipisicing.
                    </p>
                </motion.div>

                <div className="space-y-10">
                    <motion.div className="grid grid-cols-1 md:grid-cols-[0.5fr_2.5fr] gap-4 items-start"   initial={{ opacity: 0, y: 50 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true, amount: 0.2 }}
                        transition={{ duration: 0.5 }}>
                        {/* Left Side - Heading */}
                        <h2 className="text-2xl  text-[#000000] font-normal">The Challenge</h2>

                        {/* Right Side - Description */}
                        <div className="space-y-4">
                            <p className="text-[#676767] text-lg">
                            {/* Developing an efficient and scalable eCommerce platform required overcoming multiple challenges. One of the primary concerns was ensuring a seamless shopping experience across both web and mobile platforms. */}

                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolores, ducimus doloribus? Esse voluptatum placeat necessitatibus natus tempora quas consectetur laudantium distinctio illum, fugiat cupiditate tenetur, vero eos ratione nobis est.
                            </p>
                            <p className="text-[#676767] text-lg">
                            {/* The interface needed to be visually appealing, intuitive, and highly responsive to keep users engaged. Additionally, integrating secure payment gateways and a reliable authentication system was essential to ensure user trust and data protection. */}

                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolores, ducimus doloribus? Esse voluptatum placeat necessitatibus natus tempora quas consectetur laudantium distinctio illum, fugiat cupiditate tenetur, vero eos ratione nobis est.
                            </p>
                        </div>
                    </motion.div>


                    <motion.div className="grid grid-cols-1 md:grid-cols-[0.5fr_2.5fr] gap-4 items-start"   initial={{ opacity: 0, y: 50 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true, amount: 0.2 }}
                        transition={{ duration: 0.5 }}>

                        <h2 className="text-2xl  text-[#000000] font-normal">The Solution</h2>

                        {/* Right Side - Description */}
                        <div className="space-y-4">
                            <p className="text-[#676767] text-lg">
                            {/* To address these challenges, we carefully selected a modern tech stack that ensures both performance and scalability. The frontend was built using React.js with Material UI, offering a sleek and responsive interface for both web and mobile users.  */}

                            
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolores, ducimus doloribus? Esse voluptatum placeat necessitatibus natus tempora quas consectetur laudantium distinctio illum, fugiat cupiditate tenetur, vero eos ratione nobis est.
                            </p>
                            <p className="text-[#676767] text-lg">
                            {/* On the backend, we used Node.js and Express.js to create a fast and scalable API, while MongoDB handled the database efficiently. For security, Firebase and JWT authentication were implemented, ensuring that user data and transactions remained protected. */}

                            
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolores, ducimus doloribus? Esse voluptatum placeat necessitatibus natus tempora quas consectetur laudantium distinctio illum, fugiat cupiditate tenetur, vero eos ratione nobis est.
                            </p>
                        </div>
                    </motion.div>
                </div>




            </div>

   
            {/* <ShiporaCommon/> */}
            <ParenticianCommon/>


        </>




    )
}

