import "./About.css"
import { useState, useEffect } from "react";
import banner_img from "../../assets/images/about/banner_img.webp"
import { motion } from "framer-motion";
import OurMissionAim from "./OurMissionAim";
import banner_img_mobile from "../../assets/images/about/banner_img_mobile.webp";
export default function AboutComp() {
    const metrics = [
        {
            number: "15+",
            label: "Years of Experience",
        },
        {
            number: "50+",
            label: "Client Projects",
        },
        {
            number: "98+",
            label: "Happy Customer",
        },


    ]

    const [counters, setCounters] = useState(
        metrics.map((metric) => ({
            ...metric,
            count: 0,
        }))
    );

    useEffect(() => {
        const interval = setInterval(() => {
            setCounters((prevCounters) =>
                prevCounters.map((metric) => {
                    if (metric.count < parseInt(metric.number.replace('+', ''))) {
                        return { ...metric, count: metric.count + 1 };
                    }
                    return metric;
                })
            );
        }, 100);

        return () => clearInterval(interval);
    }, []);

    return (
        <section className="relative py-10 sm:py-16 px-4 about-comp -z-10">


            <div className="max-w-7xl mx-auto">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center mb-10 sm:mb-16 px-4" initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true, amount: 0.2 }}
                    transition={{ duration: 0.5 }} >

                    <div>
                        <h1 className="text-3xl md:text-5xl font-medium text-black lg-text fightree">News, insights and more</h1>
                    </div>


                    <div>
                        <p className="text-black  manrope text-lg font-normal">
                            Lorem ipsum dolor sit amet consectetur, adipisicing elit. A, ab nemo! Expedita! dolor sit amet
                            consectetur, adipisicing elit.
                        </p>
                    </div>
                </div>


                <div className="mb-10 sm:mb-16 px-4 " initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true, amount: 0.2 }}
                    transition={{ duration: 0.5 }} >
                    <img
                        src={banner_img}
                        alt="Featured story"
                        className="w-full hidden md:block "
                    />

                    <img
                        src={banner_img_mobile}
                        alt="Featured story"
                        className="w-full block md:hidden "
                    />
                </div>

                <div className="px-4">
                    <motion.div className="inline-block px-4 py-1 bg-white rounded-full mb-6 border border-[#C5D1E7] " initial={{ opacity: 0, y: 50 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true, amount: 0.2 }}
                        transition={{ duration: 0.5 }} >
                        <span className="text-[#4D4D4D] font-medium text-lg">Our Story</span>
                    </motion.div>

                    <div className="space-y-6 text-[#4D4D4D] font-medium text-xl" >
                        <motion.p initial={{ opacity: 0, y: 50 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            viewport={{ once: true, amount: 0.2 }}
                            transition={{ duration: 0.5 }} >
                            Lorem ipsum dolor sit amet consectetur, adipl consectetur, adipl Lorem ipsum Lorem ipsum Lorem ipsum dolor
                            sit ametr sit amet consectetur, adipl consectetur, adipl Lorem ipsum Lorem.Lorem ipsum
                        </motion.p>

                        <motion.p initial={{ opacity: 0, y: 50 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            viewport={{ once: true, amount: 0.2 }}
                            transition={{ duration: 0.5 }} >
                            Dolor sit amet consectetur, adipl consectetur, adipl Lorem ipsum Lorem ipsum Lorem ipsum dolor sit ametr
                            sit amet consectetur, adipl consectetur, adipl Lorem ipsum Lorem.Lorem ipsum dolor sit amet consectetur,
                            adipl consectetur, adipl Lorem ipsum
                        </motion.p>

                        <motion.p initial={{ opacity: 0, y: 50 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            viewport={{ once: true, amount: 0.2 }}
                            transition={{ duration: 0.5 }} >
                            Lorem ipsum Lorem ipsum dolor sit ametr sit amet consectetur, adipl consectetur ,adipl Lorem ipsum Lorem.
                        </motion.p>
                    </div>
                </div>

                {/* Metrics Section */}
                {/* <div>
                    <h2 className="text-3xl font-bold text-gray-900 mb-4">Measuring our impact on your performance.</h2>
                    <p className="text-gray-600 mb-8 max-w-2xl">
                        Lorem ipsum dolor sit amet consectetur, adipisicing elit. A, ab nemo! Expedita! dolor sit amet consectetur,
                        adipisicing elit.
                    </p>

                    <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                        {metrics.map((metric, index) => (
                            <div key={index} className="text-center">
                                <div className="text-4xl md:text-5xl font-bold text-gray-900 mb-2">{metric.number}</div>
                                <div className="text-gray-600">{metric.label}</div>
                            </div>
                        ))}
                    </div>
                </div> */}

                {/* <div className="flex flex-col md:flex-row items-center md:items-start text-center md:text-left gap-12">
                    <div className="max-w-lg">
                        <h2 className="text-3xl font-bold text-gray-900 mb-4">
                            Measuring our impact on your performance.
                        </h2>
                        <p className="text-gray-600 mb-8">
                            Lorem ipsum dolor sit amet consectetur, adipisicing elit. A, ab nemo! Expedita! dolor sit amet consectetur,
                            adipisicing elit.
                        </p>
                    </div>

                    <div className="flex flex-wrap justify-center md:justify-start gap-8">
                        {metrics.map((metric, index) => (
                            <div key={index} className="text-center min-w-[120px]">
                                <div className="text-4xl md:text-5xl font-bold text-gray-900 mb-2">{metric.number}</div>
                                <div className="text-gray-600">{metric.label}</div>
                            </div>
                        ))}
                    </div>
                </div> */}

                <OurMissionAim />

                <motion.div className="grid grid-cols-1 md:grid-cols-[1fr_2fr] gap-0 sm:gap-12 items-start text-center md:text-left px-4" initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true, amount: 0.2 }}
                    transition={{ duration: 0.5 }}  >

                    <div className="sm:max-w-lg mx-auto md:mx-0">
                        <h2 className="text-3xl md:text-4xl font-bold text-[#434343] mb-4 inter">
                            Measuring our impact on your performance.
                        </h2>
                        <p className="text-[#000000AB] mb-8 manrope font-medium text-[15px] opacity-90">
                            Lorem ipsum dolor sit amet consectetur, adipisicing elit. A, ab nemo! Expedita! dolor sit amet consectetur,
                            adipisicing elit.
                        </p>
                    </div>


                    <div className="grid grid-cols-3 sm:grid-cols-3 gap-8 justify-center">
                        {counters.map((metric, index) => (
                            <div
                                key={index}
                                className={`text-center pr-4 ${index < 2 ? 'sm:border-r-2 border-[#00000014]' : ''}`}
                            >
                                <div className="text-2xl sm:text-4xl md:text-5xl font-semibold text-[#434343] mb-2 inter">
                                    {metric.count}{metric.number.includes('+') && '+'}
                                </div>
                                <div className="text-[#434343] inter opacity-50 text-sm sm:text-lg">
                                    {metric.label}
                                </div>
                            </div>
                        ))}
                    </div>




                </motion.div>


            </div>
        </section>
    )
}

