import { Rocket } from "@mui/icons-material"

export default function ProcessTimeline() {
    const steps = [
        {
            title: "Discovery & Research",
            description:
                "Understand client needs and market opportunities through consultations, competitor analysis, and feasibility studies. Present a tailored solution proposal with timelines and cost estimates.",
        },
        {
            title: "Planning & Strategy",
            description:
                "Create a roadmap by documenting requirements, developing wireframes, selecting technology stack, and planning the project timeline with clear milestones and deliverables.",
        },
        {
            title: "Design & User Experience",
            description:
                "Design intuitive, user-friendly UI/UX. Develop interactive prototypes for client feedback and finalize designs aligned with brand and user expectations.",
        },
        {
            title: "Development",
            description:
                "Using Agile methodology, develop front-end, back-end, and integrations in sprints, with regular client updates and demos after each sprint.",
        },
        {
            title: "Quality Assurance & Testing",
            description:
                "Conduct thorough testing (automated, manual, performance, UAT) to ensure a bug-free, secure product. Fix bugs and optimize performance.",
        },
        {
            title: "Deployment & Launch",
            description:
                "Review, deploy the product to live environments, and provide post-launch monitoring. Offer client handoff with documentation and training.",
        },
        {
            title: "Maintenance & Support",
            description:
                "Provide ongoing support, updates, and performance monitoring. Collaborate with the client for future improvements and versions.",
        },
    ]

    return (
        <div className="w-full overflow-x-hidden py-0 px-4">
            <div className="max-w-7xl mx-auto">
                {/* Timeline */}
                <div className="relative">
                    {/* Green connecting line */}
                    <div className="absolute left-4 md:left-1/2 top-0 bottom-0 w-1 bg-emerald-500 transform -translate-x-1/2" />

                    {steps.map((step, index) => (
                        <div
                            key={index}
                            className={`relative flex flex-col md:flex-row gap-8 md:gap-16 mb-16 ${index % 2 === 0 ? "md:flex-row-reverse" : ""
                                }`}
                        >
                            {/* Timeline dot */}
                            <div className="absolute left-4 md:left-1/2 w-8 h-8 bg-white border-4 border-emerald-500 rounded-full transform -translate-x-1/2" />

                            {/* Content */}
                            <div className={`ml-12 md:ml-0 md:w-1/2 ${index % 2 === 0 ? "md:pr-24" : "md:pl-24"}`}>
                                    <h3 className="text-xl font-semibold mb-3 text-[#0C0C0C] ">{step.title}</h3>
                                <p className="text-gray-600 leading-relaxed">{step.description}</p>
                            </div>

                            {/* Spacer for alternating layout */}
                            <div className="hidden md:block md:w-1/2" />
                        </div>
                    ))}

                    {/* Rocket at the end */}
                    {/* <div className="absolute bottom-0 right-4 md:right-0 transform translate-y-1/2">
                        <div className="w-12 h-12 bg-emerald-500 rounded-full flex items-center justify-center text-white">
                            <Rocket className="w-6 h-6" />
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    )
}

