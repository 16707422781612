import React from "react";
import { useState, useEffect } from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer/Footer";
import PortfolioSection from "../../components/BestWork/BestWork";
import AllWork from "../../components/AllWork/AllWork";
import TechStack from "../../components/TechStackSection/TechStack";
import CTMSection from "../../components/CTMSection/CTMSection";
import { useMediaQuery } from "@mui/material";
import Loader from "../../components/Loader1/Loader";


function OurWork() {

//   let  isDesktop = useMediaQuery(("min"))

      const isDesktop = useMediaQuery('(min-width:1024px)');
  


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);



    const [isOpen, setIsOpen] = useState(false)


    const [loading, setLoading] = useState(true); 


    useEffect(() => {
       const handleLoad = () => {
         setTimeout(() => setLoading(false), 1500); 
       };
     
       if (document.readyState === "complete") {
         setTimeout(() => setLoading(false), 1500);
       } else {
         window.addEventListener("load", handleLoad);
       }
     
       return () => window.removeEventListener("load", handleLoad);
     }, []);
     
  
     
     if (loading) {
       return <Loader/>;
     }

    return (



        <div className="relative flex min-h-screen py-10 flex-col bg-white">

            <main className="flex-1 relative" style={{ zIndex: "999" }}>

                <div className="w-full relative">
                    <Navbar isOpen={isOpen} setIsOpen={setIsOpen} />




                </div>



                {isDesktop ?   <svg xmlns="http://www.w3.org/2000/svg" width="22%" height="17%" viewBox="0 0 500 351"
                    className="absolute  right-[-5%] top-0"
                >
                    <path
                        d="M 486.864 13.61 C 390.545 194.927 248.175 159.17 204.087 125.947 C 158.968 91.947 218.97 66.259 287.488 106.239 C 356.006 146.219 355.756 187.164 337.472 207.033 C 288.765 259.964 159.719 192.438 143.713 210.412 C 138.097 216.718 190.328 248.139 220.094 267.002 C 157.192 262.78 57.785 292.624 13.136 337.39"
                        stroke="url(#gradient1)"
                        stroke-width="26.27"
                        stroke-linejoin="round"
                        stroke-linecap="round"
                        fill="transparent"
                        opacity="1"
                        pathLength="1"
                        stroke-dashoffset="1"
                        stroke-dasharray="1px 1px">




                        <animate
                            attributeName="stroke-dashoffset"
                            from="1"
                            to="0"
                            dur="3s"
                            fill="freeze" />
                    </path>
                    <defs>

                        <linearGradient id="gradient1" x1="0%" y1="0%" x2="100%" y2="100%">
                            <stop offset="0%" stop-color="#2BAAE2" />
                            <stop offset="100%" stop-color="#24B574" />
                        </linearGradient>
                    </defs>
                </svg> : null }

              
                {/* <AllWork /> */}
                {/* <TechStack/> */}
                <AllWork />
                <CTMSection />



                {/* <animate
                            attributeName="stroke-dashoffset"
                            values="1; 0; 1"
                            dur="4s"
                            repeatCount="indefinite"
                        /> */}








                <Footer />


            </main>
        </div>


    );
}

export default OurWork;
