import { Description, AccessTime } from "@mui/icons-material"
import main_img from "../../assets/images/about/main_img.svg"
// import "./OurMission.css"
import { motion } from "framer-motion";
import mobile_img from "../../assets/images/WorkDetails/Shipora/mobile_img.webp"


export default function ShiporaWireframe() {
    const features = [
        {
            icon: <Description className="text-emerald-600 w-6 h-6" />,
            title: "Our Mission",
            description: "Connect tools for unified workflow efficiency.",
        },
        {
            icon: <AccessTime className="text-emerald-600 w-6 h-6" />,
            title: "Our Vision",
            description: "User-friendly interface boosts team productivity.",
        },
    ]

    return (
        <section className="py-16 px-4 mission">
            <div className="max-w-6xl mx-auto">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center">

                    {/* Left Side - Text Content */}
                    <div className="space-y-8">
                        <motion.div
                            className="space-y-6"
                            initial={{ opacity: 0, y: 50 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            viewport={{ once: true, amount: 0.2 }}
                            transition={{ duration: 0.5 }}
                        >
                            <h2 className="text-2xl md:text-3xl lg:text-5xl leading-tight  text-[#272666]">
                                Lorem Ipsum Dolor, sit amet Consecteur
                            </h2>
                            <p className="text-[#676767] text-lg md:text-xl ">
                                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Provident in doloremque officia dignissimos deleniti inventore porro possimus, suscipit natus. Commodi iure numquam facilis itaque?

                                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Provident in doloremque officia dignissimos deleniti inventore porro possimus, suscipit natus. Commodi iure numquam facilis itaque?
                            </p>

                            <p className="text-[#676767] text-lg md:text-xl ">
                                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Provident in doloremque officia dignissimos deleniti inventore porro possimus, suscipit natus. Commodi iure numquam facilis itaque?

                                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Provident in doloremque officia dignissimos deleniti inventore porro possimus, suscipit natus. Commodi iure numquam facilis itaque?
                            </p>
                        </motion.div>
                    </div>

                    {/* Right Side - Image */}
                    <motion.div
                        initial={{ opacity: 0, y: 50 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true, amount: 0.2 }}
                        transition={{ duration: 0.5 }}


                        className="flex justify-center md:justify-end"
                    >
                        <img
                            src={mobile_img}
                            alt="Team collaboration illustration"
                            className="w-full md:max-w-md "
                        />
                    </motion.div>
                </div>
            </div>
        </section>


    )
}

