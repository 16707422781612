import React from 'react'
import "./AboutPage.css"
import Navbar from '../../components/Navbar'
import { useState, useEffect } from 'react'
import AboutComp from '../../components/AboutCompoenent/AboutComp'
import Footer from '../../components/Footer/Footer'
// import Gallery from '../../components/AboutCompoenent/Gallery'
// import AboutComp from '../../components/AboutCompoenent/AboutComp'
// import About from '../../components/AboutCompoenent/about'
import DevelopmentSection from '../../components/DevelopmentSection/DevelopmentSection'
import GalleryComp from '../../components/Gallery/GalleryComp'
import TeamImages from '../../components/TeamImages/TeamImages'
import banner_img from "../../assets/images/about/banner_img.png"
import { useMediaQuery } from '@mui/material'
import Loader from '../../components/Loader1/Loader'
const AboutPage = () => {
    const isDesktop = useMediaQuery('(min-width:1024px)');
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const [isOpen, setIsOpen] = useState(false)
    console.log("sakgdajsgdjjjjjjjjjjjj")

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const handleLoad = () => {
            setTimeout(() => setLoading(false), 1500);
        };

        if (document.readyState === "complete") {
            setTimeout(() => setLoading(false), 1500);
        } else {
            window.addEventListener("load", handleLoad);
        }

        return () => window.removeEventListener("load", handleLoad);
    }, []);



    if (loading) {
        return <Loader />;
    }



    return (
        <div className="relative flex min-h-screen py-10 flex-col bg-white">

            <main className="flex-1 relative " style={{ zIndex: "999" }}>

                <div className="w-full relative">
                    <Navbar isOpen={isOpen} setIsOpen={setIsOpen} />



                </div>

                {/* <svg xmlns="http://www.w3.org/2000/svg" width="25%" height="6%" viewBox="0 0 500 351"
                    className="absolute  right-[-5%] top-0 -z-20 opacity-65"
                >
                    <path
                        d="M 486.864 13.61 C 390.545 194.927 248.175 159.17 204.087 125.947 C 158.968 91.947 218.97 66.259 287.488 106.239 C 356.006 146.219 355.756 187.164 337.472 207.033 C 288.765 259.964 159.719 192.438 143.713 210.412 C 138.097 216.718 190.328 248.139 220.094 267.002 C 157.192 262.78 57.785 292.624 13.136 337.39"
                        stroke="url(#gradient1)"
                        stroke-width="26.27"
                        stroke-linejoin="round"
                        stroke-linecap="round"
                        fill="transparent"
                        opacity="1"
                        pathLength="1"
                        stroke-dashoffset="1"
                        stroke-dasharray="1px 1px">




                        <animate
                            attributeName="stroke-dashoffset"
                            from="1"
                            to="0"
                            dur="3s"
                            fill="freeze" />
                    </path>
                    <defs>

                        <linearGradient id="gradient1" x1="0%" y1="0%" x2="100%" y2="100%">
                            <stop offset="0%" stop-color="#2BAAE2" />
                            <stop offset="100%" stop-color="#24B574" />
                        </linearGradient>
                    </defs>
                </svg> */}









                {isDesktop ?
                    <svg xmlns="http://www.w3.org/2000/svg" width="22%" height="6%" viewBox="0 0 500 351"
                        className="absolute  right-[-3%] top-0 -z-20 draw-line">
                        <path
                            d="M472.903 83.8C417.302 35.747 321.102 -11.368 240.895 35.459C111.796 110.834 194.484 225.891 258.778 246.414C314.373 264.161 343.363 199.518 243.126 162.942C177.606 139.035 86.553 147.243 51.352 198.732C3.224 269.132 72.078 316.981 104.714 324.385C158.609 336.612 163.716 281.692 101.748 273.198C38.974 264.593 0.97 303.747 21.932 412.542"
                            stroke="url(#gradient1)"

                            stroke-width="32"
                            stroke-linecap="round"
                            fill="transparent"
                            opacity="1"
                            pathLength="1"
                            stroke-dashoffset="0"
                            stroke-dasharray="1 1"
                        >



                            <animate
                                attributeName="stroke-dashoffset"
                                from="1"
                                to="0"
                                dur="3s"
                                fill="freeze" />
                        </path>
                        <defs>

                            <linearGradient id="gradient1" x1="0%" y1="0%" x2="100%" y2="100%">
                                <stop offset="0%" stop-color="#2BAAE2" />
                                <stop offset="100%" stop-color="#24B574" />
                            </linearGradient>
                        </defs>
                    </svg>

                    :
                    null
                }




                <AboutComp />

                <GalleryComp />


                <Footer />
            </main>
        </div>)
}

export default AboutPage
