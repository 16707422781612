import React from "react";

import { HeroSection } from "../../components/HeroSection";
import Navbar from "../../components/Navbar";


import { useMediaQuery } from "@mui/material";

import { useState } from "react";

import { useEffect } from "react";

import Footer from "../../components/Footer/Footer";

import CTMSection from "../../components/CTMSection/CTMSection";
import FAQsWork from "../../components/FAQsWork/FAQsWork";
import CareerHero from "../../components/CareerHero/CareerHero";
import CareerWorking from "../../components/CareerWorking/CareerWorking";
import CareerOpen from "../../components/CareerOpen/CareerOpen";
import Loader from "../../components/Loader1/Loader";
import CTMPricing from "../../components/CTMSection/CTMPricing";






function CareerPage() {

  const isDesktop = useMediaQuery('(min-width:1024px)');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [isOpen, setIsOpen] = useState(false)

  const [loading, setLoading] = useState(true);


  useEffect(() => {
    const handleLoad = () => {
      setTimeout(() => setLoading(false), 1500);
    };

    if (document.readyState === "complete") {
      setTimeout(() => setLoading(false), 1500);
    } else {
      window.addEventListener("load", handleLoad);
    }

    return () => window.removeEventListener("load", handleLoad);
  }, []);



  if (loading) {
    return <Loader />;
  }

  return (



    <div className="relative flex min-h-screen py-10 flex-col bg-[#ffff]">





      <main className="flex-1">

      



        <div className="w-full relative" style={{ zIndex: "999" }}>
          <Navbar isOpen={isOpen} setIsOpen={setIsOpen} />

          <div className="relative -z-10">

            <CareerHero />




          </div>








        </div>

        <CareerWorking />



        <CareerOpen />






        <FAQsWork />

        <CTMPricing />
        <Footer />







      </main>
    </div>


  );
}

export default CareerPage;
