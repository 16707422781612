import android from "../assets/images/TechStack/android.svg.png"
import ios from "../assets/images/TechStack/ios.svg.png"
import adobe from "../assets/images/TechStack/adobe-xd-svgrepo-com 1.png"
import angular from "../assets/images/TechStack/fi_15484402.png"
import figma from "../assets/images/TechStack/figma-svgrepo-com 1.png"
import flutter from "../assets/images/TechStack/flutter.svg fill.png"
import frame from "../assets/images/TechStack/Frame.png"
import express from "../assets/images/TechStack/icons8-express-js 1.png"
import mongodb from "../assets/images/TechStack/mongodb-svgrepo-com 1.png"
import nextjs from "../assets/images/TechStack/nextjs-icon-svgrepo-com 1.png"
import node from "../assets/images/TechStack/nodejs-logo-svgrepo-com 1.png"
import sql from "../assets/images/TechStack/postgresql-svgrepo-com 1.png"
import react from "../assets/images/TechStack/react-native.svg.png"
import sanity from "../assets/images/TechStack/Sanity 1.png"
import shopify from "../assets/images/TechStack/shopify-svgrepo-com 1.png"
import strapi from "../assets/images/TechStack/strapi-icon-svgrepo-com 1.png"
import webflow from "../assets/images/TechStack/webflow-svgrepo-com 1.png"
import woocommerce from "../assets/images/TechStack/woocommerce-icon-svgrepo-com 1.png"
import wordpress from "../assets/images/TechStack/wordpress-svgrepo-com 1.png"
import framer from "../assets/images/TechStack/framer.png"
// import svg_android from "../../assets/images/svg_android.svg"
// import svg_sanity from "../../assets/images/sanity_svg.svg"
import svg_sanity from "../assets/images/sanity_svg.svg"
import svg_android from "../assets/images/svg_android.svg"
import dart from "../assets/images/TechStack/dart_png.png"
import aws from "../assets/images/TechStack/aws.png"
import python from "../assets/images/TechStack/python.png"
import postman from "../assets/images/TechStack/postman.png"
import selenium from "../assets/images/TechStack/selenium.png"
import llama from "../assets/images/TechStack/meta.png"
import openai from "../assets/images/TechStack/openai.png"
import jira from "../assets/images/TechStack/jira.png"
import gemma from "../assets/images/TechStack/gemma.png"
import soapui from "../assets/images/TechStack/SOAPUI.svg"
import tensorflow from "../assets/images/TechStack/icons8-tensorflow 1.png"
import keras from "../assets/images/TechStack/keras.png"
import scikit_learn from "../assets/images/TechStack/scikit-learn 1.png"
import pytoarch from "../assets/images/TechStack/icons8-pytorch 1.png"
import spacy from "../assets/images/TechStack/spaCy-01 1.png"
import opencv from "../assets/images/TechStack/opencv.png"
import yolo from "../assets/images/TechStack/icons8-yolo 1.png"
import azure from "../assets/images/TechStack/azure-icon 1.png"
import google_cloud from "../assets/images/TechStack/google-cloud-3 1.png"
import angular_hover from "../assets/images/TechStack/angular-hover.png"
import sanity_hover from "../assets/images/TechStack/sanity-hover.png"
import postgress_hover from "../assets/images/TechStack/postgress-hover.png"
import scikit_hover from "../assets/images/TechStack/scikit-hover.png"














export const techWeb = [

    {
        name: "Angular",
        logo: angular,
        hover: angular_hover,
    },

    {
        name: "Next JS",
        logo: nextjs,
    },
    {
        name: "Express.js",
        logo: express,
    },
    {
        name: "Node Js",
        logo: node,
    },
    {
        name: "PostgreSQL",
        logo: sql,
        hover: postgress_hover,
    },
    {
        name: "Mongodb",
        logo: mongodb,
    },


    {
        name: "React",
        logo: react,
    },


    {
        name: "WooCommerce",
        logo: woocommerce,
    },
    {
        name: "Shopify",
        logo: shopify,
    },
    {
        name: "WordPress",
        logo: wordpress,
    },



    {
        name: "Strapi",
        logo: strapi,
    },
    {
        name: "Sanity",
        logo: svg_sanity,
        hover: sanity_hover,
    },
    {
        name: "Medusajs",
        logo: frame,
    },


    {
        name: "Python",
        logo: python,
    },



]


