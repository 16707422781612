import { SmartToy, PhoneAndroid, Computer, Brush, WebAsset, BugReport, ArrowForward } from "@mui/icons-material"
import ai_dev from "../../../assets/images/Services/ai_dev.svg"
import web_app from "../../../assets/images/Services/web_app.svg"
import mobile_app from "../../../assets/images/Services/mb_app.svg"
import ui_ux from "../../../assets/images/Services/ui-ux.svg"
// import quality from "../../../assets/images/Services/quality.svg"
import quality from "../../../assets/images/Services/quality_testing.svg"

import cms from "../../../assets/images/Services/sms.svg"
import { motion } from 'framer-motion';
import right_arrow from "../../../assets/images/Services/CustomProduct/right_arrow.svg"
import { Link } from "react-router-dom"
export default function OtherServices({ currentService }) {
    const services = [


        {

            title: "SaaS Product Development",
            description: "Transform ideas into reality with tailored product solutions.",
            image: web_app,
            url: "/saas-product-development"
        },
        {

            title: "Web App Development",
            description: "Build responsive and high-performing web applications.",
            image: web_app,
            url: "/web-app-development"

        },
        {

            title: "UI/UX Design",
            description: "Craft intuitive designs that enhance user engagement.",
            image: ui_ux,
            url: "/ui-ux-design"


        },
        {

            title: "CMS Development",
            description: "Manage your content with a flexible and easy-to-use CMS.",
            image: cms,
            url: "/cms-development"


        },

        {

            title: "Quality Assurance & Testing",
            description: "Ensure flawless performance with thorough QA testing.",
            image: quality,
            url: "/quality-assurance"


        },

    ]

    const servicesfirst = [
        {

            title: "AI-Enabled Solutions",
            description: "Empower your business with AI solutions for automation and growth.",
            image: ai_dev,
            url: "/ai-enabled-solutions"

        },
        {

            title: "Mobile App Development",
            description: "Lorem ipsum dolor sit amet dolor sit ametconsectetur, adipisicing elit",
            image: mobile_app,
            url: "/mobile-app-development"


        },





    ]



    let filteredServices = services.filter(service => service.title !== currentService);
    let filteredServicesFirst = servicesfirst.filter(service => service.title !== currentService);


    if (filteredServicesFirst.length < 2) {

        const productDevelopment = filteredServices.find(service => service.title === "SaaS Product Development");

        if (productDevelopment) {

            filteredServicesFirst = [...filteredServicesFirst, productDevelopment];


            filteredServices = filteredServices.filter(service => service.title !== "SaaS Product Development");
        }
    }

    return (
        <section className="pt-16 pb-0 sm:pt-16 sm:pb-16 px-4">
            <div className="max-w-7xl lg:max-w-[1320px] mx-auto">

                <motion.div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 items-center" initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true, amount: 0.2 }}
                    transition={{ duration: 0.5 }}>

                    <div className="col-span-1 md:col-span-2">
                        {/* <h2 className="text-3xl md:text-4xl font-medium mb-6">
                            Lorem ipsum <span className="text-emerald-500">dolor sit amet</span> dolor sit ametco
                        </h2>
                        <p className="text-gray-600">
                            Lorem ipsum dolor sit amet, dolor sit ametconsectetur, adipisicing elit.Lorem ipsum dolor sit amet, dolor
                            sit ametconsectetur, adipisicing elit
                        </p> */}

                        <h2 className="text-3xl md:text-4xl font-medium mb-6">
                        Smart Solutions for a <span className="text-emerald-500"> Digital-First</span>  World
                        </h2>
                        <p className="text-gray-600">
                        Empowering businesses with cutting-edge digital solutions. From AI innovation to seamless web development, we craft technology that drives success.
                        </p>

                    </div>

                    {filteredServicesFirst.map((service, index) => (

                        <div key={index} className="group p-4 border rounded-2xl ">

                            <div className="flex items-center justify-between mb-4">
                                <div className="text-gray-600">
                                    <img src={service?.image} alt="" />
                                </div>
                                <Link to={service?.url} className="bg-[#F8F9FA] text-[#333333] text-xs sm:text-sm font-medium px-3 sm:px-4 py-1.5 sm:py-2 rounded-full border border-[#EDEFF3] flex items-center gap-2">
                                Know More
                                    <img src={right_arrow} alt="Arrow" className="w-3 h-3" />
                                </Link>


                            </div>


                            <h3 className="text-lg font-medium mb-2">{service?.title}</h3>
                            <p className="text-gray-600">{service?.description}</p>



                        </div>

                    ))}


                    {/* <div className="group p-4 border rounded-2xl ">



                   
                        <div className="flex items-center justify-between mb-4">
                            <div className="text-gray-600">
                                <img src={mobile_app} alt="" />
                            </div>
                            <a href="#" className="bg-[#F8F9FA] text-[#333333] text-xs sm:text-sm font-medium px-3 sm:px-4 py-1.5 sm:py-2 rounded-full border border-[#EDEFF3] flex items-center gap-2">
                                Visit Website
                                <img src={right_arrow} alt="Arrow" className="w-3 h-3" />
                            </a>


                        </div>


                        <h3 className="text-lg font-medium mb-2">Mobile App Development</h3>
                        <p className="text-gray-600">Lorem ipsum dolor sit amet dolor sit ametconsectetur, adipisicing elit</p>


                        
                    </div> */}
                </motion.div>


                <motion.div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mt-4" initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true, amount: 0.2 }}
                    transition={{ duration: 0.5 }}>
                    {filteredServices.map((service, index) => (
                        <div key={index} className="group p-4 border rounded-2xl ">

                            {/* <div className="mb-4 text-gray-600"><img src={ai_dev} alt="" /></div> */}
                            <div className="flex items-center justify-between mb-4">
                                <div className="text-gray-600">
                                    <img src={service?.image} alt="" />
                                </div>
                                <Link to={service?.url} className="bg-[#F8F9FA] text-[#333333] text-xs sm:text-sm font-medium px-3 sm:px-4 py-1.5 sm:py-2 rounded-full border border-[#EDEFF3] flex items-center gap-2">
                  Know More
                                    <img src={right_arrow} alt="Arrow" className="w-3 h-3" />
                                </Link>

                            </div>


                            <h3 className="text-lg font-medium mb-2">{service.title}</h3>
                            <p className="text-gray-600 ">{service.description}</p>


                        </div>
                    ))}
                </motion.div>
            </div>
        </section>
    )
}

