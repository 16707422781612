import React from 'react'
import img1 from "../../../assets/images/Services/CustomProduct/chooseOverlay.svg"
import img2 from "../../../assets/images/Services/CustomProduct/chooseOverlay2.svg"
import img3 from "../../../assets/images/Services/CustomProduct/chooseOverlay3.svg"
import img4 from "../../../assets/images/Services/CustomProduct/chooseOverlay4.svg"
import { motion } from "framer-motion";

const CustomSection = () => {

    const technologies = [
        // Row 1
        {
            name: "Experience with Startups",
            logo: img1,
            description: "Share your experience working with startups and understanding the challenges they face."
        },
        {
            name: "Agile Development Process",
            logo: img2,
            description: "Emphasize your flexible, iterative approach to building SaaS solutions."

        },

        {
            name: "Scalable Solutions",
            logo: img3,
            description: "Highlight how your SaaS products are built to scale as the startup grows."

        },
        {
            name: "Customer-Centric Approach",
            logo: img4,
            description: "Describe how you ensure that the product is aligned with the startup’s target market and user needs."

        },

    ]
    return (
        <section className="relative py-16 px-4 about-comp">


            <div className="max-w-7xl mx-auto">

                <motion.div className="text-center mb-12" initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true, amount: 0.2 }}
                    transition={{ duration: 0.5 }}
                >
                    <h2 className="text-3xl md:text-5xl font-medium text-[#212A40] mb-4 poppins lg-text">   Why<span className="text-[#24B574]">  Choose </span> Us? </h2>
                    <p className="text-[#485E79] max-w-2xl text-lg mx-auto">
                        Lorem ipsum dolor sit amet consectetur, adipisicing elit A, ab nemo! Expedita!Lorem ipsum dolor sit amet consectetur
                    </p>
                </motion.div>

                <motion.div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8 justify-items-center " initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true, amount: 0.2 }}
                    transition={{ duration: 0.5 }}
                >
                    {technologies.map((tech, index) => (
                        <div
                            key={index}
                            className="flex flex-col cursor-pointer items-center justify-center group bg-white  rounded-3xl p-5 w-full border border-[#F2F3F8]"
                        >
                            <div className="mb-3 flex items-center justify-center ">
                                <img
                                    src={tech.logo || "/placeholder.svg"}
                                    alt={`${tech.name} logo`}
                                    // className="object-contain group-hover:filter group-hover:brightness-0 group-hover:invert"
                                    className={`object-contain 
                                
                                
                                
                               `}
                                />
                            </div>
                            <span className="text-xl text-[#3A3A3A] text-center font-600 ">{tech.name}</span>
                            <p className="text-center text-sm text-[#6B7280] mt-2">{tech.description}</p>
                        </div>
                    ))}


                </motion.div>





            </div>
        </section>
    )
}

export default CustomSection
