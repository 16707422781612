import React from 'react'
import img1 from "../../../assets/images/Services/MobileApp/WhyChoose/img1.svg"
import img2 from "../../../assets/images/Services/MobileApp/WhyChoose/img2.svg"
import img3 from "../../../assets/images/Services/MobileApp/WhyChoose/img3.svg"
import img4 from "../../../assets/images/Services/MobileApp/WhyChoose/img4.svg"
import { motion } from "framer-motion";

const WhyChooseTR = () => {

    const technologies = [
        // Row 1
        {
            name: "Proven Expertise",
            logo: img1,
            description: "Years of experience in building scalable and high-performance mobile applications."
        },
        {
            name: "Client Satisfaction",
            logo: img2,
            description: "We prioritize seamless user experiences, ensuring customer success and engagement."

        },

        {
            name: "Custom Solutions",
            logo: img3,
            description: "Tailor-made apps designed to align with your business goals and audience."

        },
        {
            name: "Cutting-Edge Technology",
            logo: img4,
            description: "Utilizing the latest frameworks and innovations for top-notch performance."

        },

    ]
    return (
        <section className="relative py-16 px-4 whyTR">


            <div className="max-w-7xl mx-auto">

                <motion.div className="text-center mb-12" initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true, amount: 0.2 }}
                    transition={{ duration: 0.5 }}
                >
                    <h2 className="text-3xl md:text-5xl font-semibold text-[#020710] mb-4 poppins lg-text max-w-2xl mx-auto">   Why Choose TR for Mobile App Development</h2>
                    <p className="text-[#7A8089] max-w-4xl text-lg mx-auto">
                    We deliver high-quality, user-centric mobile apps tailored to your business needs. Here’s what sets us apart
                    </p>
                </motion.div>

                <motion.div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8 justify-items-center " initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true, amount: 0.2 }}
                    transition={{ duration: 0.5 }}
                >
                    {technologies.map((tech, index) => (
                        <div
                            key={index}
                            className="flex flex-col cursor-pointer items-center justify-center group bg-white  rounded-3xl px-5 py-8 w-full border border-[#F2F3F8]"
                        >
                            <div className="mb-3 flex items-center justify-center ">
                                <img
                                    src={tech.logo || "/placeholder.svg"}
                                    alt={`${tech.name} logo`}
                                    // className="object-contain group-hover:filter group-hover:brightness-0 group-hover:invert"
                                    className={`object-contain 
                                
                                
                                
                               `}
                                />
                            </div>
                            <span className="text-xl text-[#3A3A3A] text-center font-600 ">{tech.name}</span>
                            <p className="text-center text-sm text-[#6B7280] mt-2">{tech.description}</p>
                        </div>
                    ))}


                </motion.div>





            </div>
        </section>
    )
}

export default WhyChooseTR
