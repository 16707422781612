import { Speed, Group, BarChart, Rocket, Timeline, Assessment } from "@mui/icons-material"
import native_mobile from "../../../assets/images/Services/MobileApp/native_mmobile.svg"
import app_maintain from "../../../assets/images/Services/MobileApp/app_updates.svg"
import cross_plat from "../../../assets/images/Services/MobileApp/cross.svg"
import app_int from "../../../assets/images/Services/MobileApp/app_int.svg"
import app_design from "../../../assets/images/Services/MobileApp/app.svg"
import scalable from "../../../assets/images/Services/MobileApp/scalable.svg"
// import native_mobile from"../../../assets/images/Services/MobileApp/native_mmobile.svg"
import mobile_img from "../../../assets/images/Services/MobileApp/mobile_trans.png"
import mobile_bg from "../../../assets/images/Services/MobileApp/mobile_bg.png"
import { useEffect, useState } from "react"
import { motion } from "framer-motion"


import slide1 from "../../../assets/images/Services/MobileApp/slide1.png"
import slide2 from "../../../assets/images/Services/MobileApp/slide2.png"
import slide3 from "../../../assets/images/Services/MobileApp/slide3.png"
import slide4 from "../../../assets/images/Services/MobileApp/slide4.png"
import slide5 from "../../../assets/images/Services/MobileApp/slide5.png"
import slide6 from "../../../assets/images/Services/MobileApp/slide6.png"
import slide7 from "../../../assets/images/Services/MobileApp/slide7.png"
const slides = [slide1, slide2, slide3, slide4, slide5, slide6, slide7];
export default function OurMobileAppDev() {

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
    }, 4000); // Change image every 4 seconds

    return () => clearInterval(interval); // Cleanup on unmount
  }, []);

  const topFeatures = [
    "Scalability to meet evolving project needs.",
    "Enhanced productivity through workflows.",
    "Improved collaboration for teamwork.",
    "Clear accountability with defined roles.",
  ]

  const advantages = [
    {
      icon: native_mobile,
      title: "Native Mobile App Development",
      description: "Apps designed specifically for iOS or Android platforms.",
    },
    {
      icon: app_maintain,
      title: "App Maintenance & Updates",
      description: "Ongoing support to keep your app up to date with the latest features and performance improvements.",
    },
    {
      icon: cross_plat,
      title: "Cross-Platform Development",
      description: "Apps that work on both iOS and Android, maximizing reach and efficiency",
    },
    {
      icon: app_int,
      title: "App Integration",
      description: " Integration of third-party APIs and services to extend functionality.",
    },
    {
      icon: app_design,
      title: "App Design & UI/UX",
      description: "User-focused design services to ensure your app is intuitive and engaging.",
    },
    {
      icon: scalable,
      title: "Scalability",
      description: "Easily scale to meet evolving business needs and project requirements.",
    },
  ]

  return (
    <section className="py-16 px-4 ourmobileapp">
      <div className="max-w-7xl mx-auto">

        <motion.div className="text-center max-w-3xl mx-auto mb-20" initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true, amount: 0.2 }}
          transition={{ duration: 0.5 }}>
          <h2 className="text-3xl font-medium mb-4 lg-text text-[#0C0C0C] ">Our <span className="text-[#24B574]">Mobile App </span>Development Services</h2>
          <p className="text-[#666666]" style={{ fontSize: "17px" }}>
            Break down the different types of services you provide. Use bullet points or sections for each service to make it easy for users to navigate.
          </p>
        </motion.div>


        <div className="relative w-full min-h-[50vh] overflow-hidden flex flex-col justify-end">

          <div className="hidden lg:block absolute left-1/2 bottom-[-25%] -translate-x-1/2 w-[35vw] max-w-[290px]">
            <div className="aspect-[9/19.5] overflow-hidden relative">

              <img
               src={slides[currentIndex]}
                alt="Mobile Background"
                className="absolute inset-0 top-0 left-0 w-full h-full object-contain rounded-[50px]"
              />


              <img src={mobile_img} alt="Mobile Content" className="relative w-full h-full" />
            </div>
          </div>

          {/* Advantages Section (Responsive) */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {advantages.map((advantage, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true, amount: 0.2 }}
                transition={{ duration: 0.5 }}
                className={`p-6 bg-[#F8F9FA] rounded-2xl ${index % 2 === 0 ? "md:mr-[0vw] lg:mr-[15vw]" : "md:ml-[0vw] lg:ml-[15vw]"
                  } border border-[#EDEFF3]`}
              >

                {/* className={`p-6 bg-[#F8F9FA] rounded-2xl ${index % 2 === 0 ? "md:mr-[17vw] lg:mr-[15vw]" : "md:ml-[17vw] lg:ml-[15vw]"
              } border border-[#EDEFF3]`} */}

                {/* Row for Icon and Title */}
                <div className="flex items-center gap-3 mb-4">
                  <img src={advantage?.icon} alt="" />
                  <h3 className="text-base font-medium text-[#262626]">{advantage.title}</h3>
                </div>

                {/* Description */}
                <p className="text-[#666666] text-base">{advantage.description}</p>
              </motion.div>
            ))}
          </div>
        </div>

      </div>
    </section>
  )
}

