import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useLocation } from "react-router-dom"
import {
    Person,
    Schedule,
    Code,
    CheckCircle,
    ArrowForward,
    SmartToy,
    PhoneAndroid,
    Web,
    Palette,
    Storage,
    BugReport,
} from "@mui/icons-material"
import step1 from "../../assets/images/ConsultForm/select_services.png"
import step2 from "../../assets/images/ConsultForm/personal_details.png"
import step3 from "../../assets/images/ConsultForm/select_timeline.png"
import service1 from "../../assets/images/ConsultForm/service1.svg"
import service2 from "../../assets/images/ConsultForm/service2.svg"
import service3 from "../../assets/images/ConsultForm/service3.svg"
import service4 from "../../assets/images/ConsultForm/service4.svg"
import service5 from "../../assets/images/ConsultForm/service5.svg"
import service6 from "../../assets/images/ConsultForm/service6.svg"
import service7 from "../../assets/images/ConsultForm/service7.svg"
import leftbottom from "../../assets/images/ConsultForm/logoshadow.png"
import { img } from "framer-motion/client"
import arrow from "../../assets/images/ConsultForm/right_arrow.png"
import selected from "../../assets/images/ConsultForm/selected.png"
import "./Consult.css"
export default function MultiStepForm() {
    const navigate = useNavigate()
    const [currentStep, setCurrentStep] = useState(1)
    const [selectedServices, setSelectedServices] = useState(null)
    const [projectDescription, setProjectDescription] = useState("")
    const [isReferenceUrlEnabled, setIsReferenceUrlEnabled] = useState(false);
    const [referenceUrl, setReferenceUrl] = useState("");

    const steps = [
        {
            number: 1,
            title: "Select Services",
            icon: <Code className="w-6 h-6" />,
            img: step1
        },
        {
            number: 2,
            title: "Personal Details",
            icon: <Person className="w-6 h-6" />,
            img: step2
        },
        {
            number: 3,
            title: "Select Timeline",
            icon: <Schedule className="w-6 h-6" />,
            img: step3
        },
    ]

    const services = [

        {
            id: "AI-Enabled Solutions",
            title: "AI-Enabled Solutions",
            icon: <SmartToy className="w-6 h-6" />,
            img: service2

        },
        {
            id: "SaaS Product Development",
            title: "SaaS Product Development",
            icon: <Code className="w-6 h-6" />,
            img: service1
        },
       
        {
            id: "Mobile App Development",
            title: "Mobile App Development",
            icon: <PhoneAndroid className="w-6 h-6" />,
            img: service3

        },
        {
            id: "Web App Development",
            title: "Web App Development",
            icon: <Web className="w-6 h-6" />,
            img: service4

        },
        {
            id: "UI/UX Design",
            title: "UI/UX Design",
            icon: <Palette className="w-6 h-6" />,
            img: service5

        },
        {
            id: "CMS Development",
            title: "CMS Development",
            icon: <Storage className="w-6 h-6" />,
            img: service6

        },
        {
            id: "Quality AssuranceTesting",
            title: "Quality AssuranceTesting",
            icon: <BugReport className="w-6 h-6" />,
            img: service7

        },
    ]

    const handleServiceToggle = (id) => {
        setSelectedServices(id); // Set the clicked service as the only selected one
    };

    // const handleContinue = () => {
    //     let isValid = true;

    //     // Check Project Description Validation (50 characters)
    //     if (projectDescription.length < 50) {
    //         setError("Project description must be at least 50 characters long.");
    //         isValid = false;
    //     } else {
    //         setError("");
    //     }

    //     // Check URL Validation (if not empty, it should be a valid URL)
    //     const urlPattern = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;
    //     if (referenceUrl && !urlPattern.test(referenceUrl)) {
    //         setUrlError("Please enter a valid URL (e.g., https://example.com)");
    //         isValid = false;
    //     } else {
    //         setUrlError("");
    //     }

    //     // Navigate only if all validations pass
    //     if (isValid) {
    //         navigate("/personal-details");
    //     }
    // };


    const handleContinue = () => {
        let errors = {}; // Store validation errors
        let isValid = true;
    
        // Validate Project Description (Minimum 50 characters)
        if (projectDescription.length > 0 && projectDescription.length < 50) {
            errors.projectDescription = "Project description must be at least 50 characters long.";
            isValid = false;
        }
    
        // Validate URL (if provided, it must be a valid URL)
        const urlPattern = /^(https?:\/\/)?([\w-]+\.)+[\w-]+(\/[\w-./?%&=]*)?$/;
        if (referenceUrl && !urlPattern.test(referenceUrl)) {
            errors.referenceUrl = "Please enter a valid URL (e.g., https://example.com)";
            isValid = false;
        }
    
        // Set errors in state
        setError(errors.projectDescription || "");
        setUrlError(errors.referenceUrl || "");
    
        // if (!isValid) return; 

        if (!isValid) {
            setError(errors.projectDescription || "");
            return;
        }
    
        // Store form data in localStorage
        localStorage.setItem(
            "step1",
            JSON.stringify({
                selectedServices,
                projectDescription,
                referenceUrl,
                isReferenceUrlEnabled
            })
        );
    
        // Navigate to the next step
        navigate("/personal-details");
    };
    


    const [error, setError] = useState("");
    const [urlError, setUrlError] = useState("");


    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === "projectDescription") {
            setProjectDescription(value);
    
            // Show error message only if user starts typing but doesn't meet 50 chars
            setError(value.length > 0 && value.length < 50 ? "Project description must be at least 50 characters long." : "");
        }

        if (name === "referenceUrl") {
            setReferenceUrl(value);

            // URL Validation using regex
            const urlPattern = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;

            setUrlError(value && !urlPattern.test(value) ? "Please enter a valid URL (e.g., https://example.com)" : "");
        }
    };

    useEffect(() => {
     
        const storedData = JSON.parse(localStorage.getItem("step1")) || {};
        if (storedData.selectedService) setSelectedServices(storedData.selectedService);
        if (storedData.projectDescription) setProjectDescription(storedData.projectDescription);
        if (storedData.referenceUrl) setReferenceUrl(storedData.referenceUrl);
        if (storedData.isReferenceUrlEnabled !== undefined) setIsReferenceUrlEnabled(storedData.isReferenceUrlEnabled);
      }, []);


    return (

        <section className="py-12 px-4 relative consult">


            <img
                src={leftbottom}
                alt="Overlay"
                className="absolute left-0 bottom-0 w-36 sm:w-44 md:w-60 lg:w-96 "
            />
            <div className="max-w-4xl mx-auto ">

                {/* Progress Steps */}
                <div className="flex items-center justify-center mb-12">
                    {steps.map((step, index) => (
                        <div key={step.number} className="flex items-center">


                            <div className="flex flex-col items-center">

                                <div
                                    className={`flex items-center justify-center rounded-full border-2  p-1 md:p-2
          ${currentStep >= step.number ? "border-grad text-emerald-500" : "border-[#A1AEBE] text-gray-400"}`}


                                >

                                    <img src={step.img} alt="" className="w-6 md:w-8" />
                                </div>

                                <p
                                    className={`text-sm sm:text-lg md:text-xl font-semibold mt-2  text-center
    ${currentStep >= step.number
                                            ? "bg-gradient-to-b from-[#24B574] to-[#2BAAE2] bg-clip-text text-transparent"
                                            : "text-gray-400"
                                        }`}
                                >
                                    {step.title}
                                </p>

                            </div>


                            {index < steps.length - 1 && (
                                <div
                                    className={`w-16 sm:w-24 h-0.5 mx-1 sm:mx-4 
          ${currentStep > step.number ? "bg-emerald-500" : "bg-[#A1AEBE]"}`}
                                />
                            )}
                        </div>
                    ))}

                </div>

                {/* Form Content */}
                <div className="bg-white rounded-2xl p-8 border-2 border-[#EBEDEF] relative">
                    {currentStep === 1 && (
                        <div className="space-y-9">
                            <div>
                                <label className="block text-base sm:text-lg md:text-xl font-semibold mb-4 sm:mb-8 text-[#3E3838]">
                                    Select Service<span className="">*</span>
                                </label>
                                <div className="flex flex-wrap justify-start gap-4 sm:gap-6">
                                    {services.map((service) => (
                                        <button
                                            key={service.id}
                                            onClick={() => handleServiceToggle(service.id)}
                                            className={`relative flex items-center gap-2 px-3 py-2 sm:px-4 sm:py-2 rounded-full border transition-colors border-[#E9EBED]`}
                                        >
                                            <div className="text-emerald-500">
                                                <img src={service?.img} alt="" className="w-4 h-4 sm:w-6 sm:h-6 md:w-8 md:h-8" />
                                            </div>
                                            <span className="text-xs sm:text-base md:text-lg font-medium text-[#757D84]">{service.title}</span>

                                            <div className="border border-[#E9EBED] rounded-full " style={{ padding: "1px" }}>

                                                <div
                                                    className={`w-4 h-4 sm:w-5 sm:h-5 md:w-7 md:h-7  rounded-full   ml-auto flex items-center justify-center transition-all
    ${selectedServices === service?.id
                                                            ? "border-green-500 bg-gradient-to-b from-[#24B574] to-[#2BAAE2]"
                                                            : "bg-transparent"}`}
                                                >
                                                    {selectedServices === service?.id && (
                                                        <img src={selected} alt="Selected" className="w-2 h-2 md:w-3 md:h-3" />
                                                    )}
                                                </div>
                                            </div>

                                        </button>
                                    ))}
                                </div>

                            </div>

                            <div>
                                <label className="block text-base sm:text-lg md:text-xl font-semibold mb-4 sm:mb-8 text-[#3E3838]">
                                    Briefly describe Project Requirements (Optional)
                                </label>
                                <textarea
                                    name="projectDescription"
                                    value={projectDescription}
                                    onChange={handleChange}
                                    placeholder="Enter your message"
                                    rows={4}
                                    className="w-full p-3 rounded-xl border border-[#8888884F] focus:outline-none focus:ring-2 focus:ring-emerald-500"
                                />
                                {error && <p className="text-red-500 text-sm mt-2">{error}</p>}

                            </div>



                            <div className="flex items-center gap-2">
                                <input
                                    type="checkbox"
                                    id="referenceUrlCheckbox"
                                    checked={isReferenceUrlEnabled}
                                    onChange={() => setIsReferenceUrlEnabled(!isReferenceUrlEnabled)}

                                    className="w-5 h-5 text-emerald-500 border-[#8888884F] rounded focus:ring-emerald-500 border"
                                />
                                <label htmlFor="referenceUrlCheckbox" className="text-sm md:text-base text-[#434343] cursor-pointer font-medium">
                                    Do you want to add a reference URL? (Optional)
                                </label>
                            </div>


                            {isReferenceUrlEnabled && (
                                <div className="mt-4">
                                    <label className="block text-sm md:text-lg font-medium sm:mb-4 mb-2 text-[#636363]">
                                        Enter Reference URL (Optional)
                                    </label>
                                    <input
                                        type="url"
                                        name="referenceUrl"
                                        value={referenceUrl}
                                        onChange={handleChange}
                                        placeholder="https://example.com"
                                        className="w-full p-3 rounded-lg border border-gray-200 focus:outline-none focus:ring-2 focus:ring-emerald-500 mb-2 sm:mb-4"
                                    />
                                    {urlError && <p className="text-red-500 text-sm mt-2">{urlError}</p>}

                                    <span className="text-xs sm:text-sm text-[#8C8C8C]">
                                        If you have a specific website, design, or project as a reference, you can share its link here.
                                        This will help us understand your vision and requirements better.
                                    </span>
                                </div>
                            )}

                            <div className="flex justify-end">
                                <button
                                    onClick={handleContinue}
                                    // disabled={selectedServices.length === 0}
                                    className="flex items-center gap-2 text-sm px-3 py-2 sm:text-base sm:px-5 sm:py-2 md:px-6 md:py-3 md:text-lg
             bg-gradient-to-r from-[#25B57E] to-[#2BAAE2] 
             text-white rounded-xl transition-all 
             hover:opacity-90 disabled:opacity-50 disabled:cursor-not-allowed"
                                >
                                    Continue
                                    {/* <ArrowForward className="w-5 h-5" /> */}
                                    <img src={arrow} alt="" className="w-4 sm:w-5 md:w-6" />
                                </button>

                            </div>
                        </div>
                    )}
                </div>
            </div>

        </section>
    )
}

