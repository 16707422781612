import React from 'react'
import "./UIUX.css"
import { motion } from "framer-motion";
import Prototype_img from "../../../assets/images/Services/UI-UX/prototype.png"




const PrototypeShowcase = () => {


    return (

        <div className='py-16'>

            <section className="relative py-16 md:py-28 px-4 whychooseui bg-gradient-to-b from-[#1DBB91] to-[#ffffff]">

                <div>

                    <div className="max-w-7xl mx-auto">

                        <motion.div className="text-center mb-12 max-w-5xl mx-auto" initial={{ opacity: 0, y: 50 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            viewport={{ once: true, amount: 0.2 }}
                            transition={{ duration: 0.5 }}
                        >

                            <span className='text-[#ffff] max-w-2xl text-sm sm:text-base mx-auto mb-4 font-semibold'>Prototyping</span>
                            <h2 className="text-3xl md:text-5xl font-semibold text-[#ffff]   lg-text max-w-3xl mx-auto" >
                                Prototyping and create stunning animations  </h2>
                            {/* <p className="text-[#485E79] max-w-2xl text-lg mx-auto">
                        Lorem ipsum dolor sit amet consectetur, adipisicing elit A, ab nemo! Expedita!Lorem ipsum dolor sit amet consectetur
                    </p> */}
                        </motion.div>















                        <motion.div className='flex justify-center' initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true, amount: 0.2 }}


                    transition={{ duration: 0.5 }}>

                            <img src={Prototype_img} alt="" />
                        </motion.div>

                    </div>





                </div>
            </section>

        </div>
    )
}

export default PrototypeShowcase
