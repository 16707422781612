import React from "react";
import { useState, useEffect } from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer/Footer";
import CTMSection from "../../components/CTMSection/CTMSection";
import TestimonialsSection from "../../components/Testimonials/Testimonials";
import Banner from "../../components/Services/CustomProductDev/Banner";
import TechStackAlt from "../../components/TechStackAlt/TechStackAlt";
import "./QualityAssurance.css"
// import quality_assurance from "../../assets/images/Services/Quality Assurance/quality_assurance.svg"
import quality_assurance from "../../assets/images/Services/quality-assurance.png"

import BreadCrumbService from "../../components/BreadCrumbService/BreadCrumbService";
import OtherServices from "../../components/Services/CustomProductDev/OtherServices";
import StatsSection from "../../components/Services/WebDev/StatsSection";
import SpecialitySec from "../../components/Services/WebDev/SpecialitySec";
import StartSection from "../../components/Services//QualityTesting/StartSection";
import TestingPorcess from "../../components/Services/QualityTesting/TestingPorcess";
import TestingType from "../../components/Services/QualityTesting/TestingType";
import mobile_bg from "../../assets/images/Services/Quality Assurance/mobile_bg.png";
import slide1 from "../../assets/images/Services/Quality Assurance/slide1.png"
import Loader from "../../components/Loader1/Loader";


function QualityAssurance() {


  const data = [
    {
      title: "Quality assurance & Testing",
      description: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. A, ab nemo! Expedita!Lorem ipsum dolor sit amet consectetur, adipisicing elit. A, ab nemo! Expedita!",
      img: quality_assurance,
    },

  ]

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [isOpen, setIsOpen] = useState(false)

  const [loading, setLoading] = useState(true);


  useEffect(() => {
    const handleLoad = () => {
      setTimeout(() => setLoading(false), 1500);
    };

    if (document.readyState === "complete") {
      setTimeout(() => setLoading(false), 1500);
    } else {
      window.addEventListener("load", handleLoad);
    }

    return () => window.removeEventListener("load", handleLoad);
  }, []);



  if (loading) {
    return <Loader />;
  }


  return (



    <div className="relative flex min-h-screen py-10 flex-col bg-white">

      <main className="flex-1 relative " style={{ zIndex: "999" }}>

        <div className="w-full relative">
          <Navbar isOpen={isOpen} setIsOpen={setIsOpen} />




        </div>


        {/* <Banner/> */}
        <BreadCrumbService data={data} />
        <StartSection />
        <TestingType />
        <StatsSection />
        <TestingPorcess />



        <SpecialitySec />
        {/* <TechStackAlt /> */}

        <OtherServices currentService="Quality Assurance & Testing" />

        <TestimonialsSection />


        <CTMSection />











        <Footer />


      </main>
    </div>


  );
}

export default QualityAssurance;
