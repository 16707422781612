import {
    Person,
    Group,
    BarChart,
    Security,
    Assignment,
    Chat,
    Sync,
    People,
    FormatListBulleted,
} from "@mui/icons-material"
import img1 from "../../assets/images/Services/AIDev/dev_discovery.svg"
import img2 from "../../assets/images/MobileDevPage/DevSolutions/ui-ux.svg"
import img3 from "../../assets/images/Services/AIDev/dev_modal.svg"
import img4 from "../../assets/images/Services/AIDev/dev_testing.svg"
// import img5 from "../../assets/images/Services/AIDev/dev_deploy.svg"
import img5 from "../../assets/images/MobileDevPage/DevSolutions/app.svg"
import img6 from "../../assets/images/MobileDevPage/DevSolutions/api.svg"
import img7 from "../../assets/images/MobileDevPage/DevSolutions/react_native.svg"


import { motion, AnimatePresence } from "framer-motion";
import main_img from "../../assets/images/MobileDevPage/DevSolutions/img1.png"

import "./MobileAppSolution.css"
export default function MobileAppSolution() {
    const keyFeatures = [
        {
            icon: <Person className="w-5 h-5" />,
            text: "From concept to deployment, we craft AI solutions that drive transformation",
        },
        {
            icon: <Group className="w-5 h-5" />,
            text: `"Your vision, powered by AI—delivered with excellence at every step."`,
        },
        {
            icon: <BarChart className="w-5 h-5" />,
            text: "Advanced analytics for data-driven decision-making.",
        },
        {
            icon: <Security className="w-5 h-5" />,
            text: "Robust Data Security Measures",
        },
    ]

    const detailedFeatures = [
        {
            icon: img1,
            title: "Mobile Application Consulting",
            description:
                "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Consequatur atque ullam, rerum itaque tenetur minima in aut quidem cupiditate a hic, animi sapiente? Numquam nulla repudiandae expedita alias.",
        },
        {
            icon: img2,
            title: "UI/UX Design for Mobile Apps",
            description:
                "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Consequatur atque ullam, rerum itaque tenetur minima in aut quidem cupiditate a hic, animi sapiente? Numquam nulla repudiandae expedita alias.",
        },
        {
            icon: img3,
            title: "Flutter App Development",
            description:
                "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Consequatur atque ullam, rerum itaque tenetur minima in aut quidem cupiditate a hic, animi sapiente? Numquam nulla repudiandae expedita alias.",
        },
        // {
        //     icon: img4,
        //     title: "React Native App Development",
        //     description:
        //         "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Consequatur atque ullam, rerum itaque tenetur minima in aut quidem cupiditate a hic, animi sapiente? Numquam nulla repudiandae expedita alias.",
        // },
        {
            icon: img7,
            title: "Native Application Development",
            description:
                "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Consequatur atque ullam, rerum itaque tenetur minima in aut quidem cupiditate a hic, animi sapiente? Numquam nulla repudiandae expedita alias.",
        },

        {
            icon: img5,
            title: "App Maintenance, Migration and Upgrade",
            description:
                "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Consequatur atque ullam, rerum itaque tenetur minima in aut quidem cupiditate a hic, animi sapiente? Numquam nulla repudiandae expedita alias.",
        },

        {
            icon: img6,
            title: "Mobile App API Integrations",
            description:
                "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Consequatur atque ullam, rerum itaque tenetur minima in aut quidem cupiditate a hic, animi sapiente? Numquam nulla repudiandae expedita alias.",
        },

    ]

    return (
        <section className="py-16 px-4 min-h-screen mobile-app-solution">
            <div className="max-w-7xl mx-auto">
                <motion.div className="grid grid-cols-1 lg:grid-cols-2 gap-12" initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true, amount: 0.2 }}
                    transition={{ duration: 0.5 }}
                >

                    <div className="space-y-8 lg:sticky lg:top-1 self-start">
                        <div>
                            <h2 className="text-3xl md:text-4xl font-semibold mb-3 lg-text text-[#3F3F3F]">Our Tailored Mobile App Solutions for Diverse Needs
                            </h2>
                            <p className="text-[#666666]" style={{ fontSize: "16px", lineHeight: "27.2px" }}>
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum impedit magnam labore quia dolore ratione nihil repellat rem minima! Natus accusamus dignissimos ipsum optio ad architecto veritatis perspiciatis repudiandae ipsam
                            </p>
                        </div>


                        <div className="space-y-4 hidden lg:block">
                            {/* {keyFeatures.map((feature, index) => (
                                <div key={index} className="flex items-center gap-3 text-gray-700 rounded-full border border-[#E6E9EE] p-2" style={{ display: "inline-flex" }}>
                                
                                    <img src={verify_icon} alt="" className="w-5 h-5"/>
                                    <span style={{ fontSize: "15.51px", lineHeight: '23.26px', color: "#333333" }}>{feature.text}</span>
                                </div>
                            ))} */}

                            <img src={main_img} alt="" />
                        </div>
                    </div>


                    <div className="space-y-6   ">
                        {detailedFeatures.map((feature, index) => (
                            <div key={index} className="bg-white rounded-2xl p-6 border border-[#E6E9EE]">
                                <div className="flex flex-col gap-4">

                                    <div className="flex items-center gap-2">
                                        <img src={feature.icon} alt={feature.title} className="object-contain" />
                                        <h3 className="font-semibold text-[#262626] text-xl sm:text-2xl">{feature.title}</h3>
                                    </div>


                                    <p className="text-gray-600 text-sm" style={{ fontSize: "15.51px", lineHeight: "23.26px" }}>{feature.description}</p>
                                </div>

                            </div>
                        ))}
                    </div>
                </motion.div>
            </div>
        </section>
    )
}

