import ArrowForwardIcon from "@mui/icons-material/ArrowForward"
import mobile_img from "../../assets/images/mobile.png"
import map from "../../assets/images/map.png"
import "./SplitLanding.css"
import { Link } from "react-router-dom"
import arrowicon from "../../assets/images/arrowicon.png"
import { useRef, useEffect, useState } from "react"
import { motion } from "framer-motion";
import { useMediaQuery } from "@mui/material"
import WorldMap from "../WorldMap/WorldMap"
import worldmapsvg from "../../assets/images/mapsvg.svg"
import svgmap from "../../assets/images/World_map.png"
// import 

export default function SplitLanding() {

    const matches = useMediaQuery('(max-width:767px)')

    const countries = [
        { name: "Canada", position: { top: "35%", left: "20%" } },
        { name: "USA", position: { top: "48%", left: "20%" } },
        { name: "UAE", position: { top: "55%", left: "62%" } },
        { name: "India", position: { top: "55%", left: "70%" } },
        { name: "AUS", position: { top: "78%", left: "85%" } },
    ];
    const [hoveredCountry, setHoveredCountry] = useState(null);
    return (

        <section className="py-16 px-4">


            <motion.div className="mx-auto max-w-7xl relative" initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true, amount: 0.2 }}
                transition={{ duration: 0.5 }} >

                <h4 className="text-center" style={{ fontWeight: "600", color: "#111111", fontSize: "16px" }} >
                    How we Help

                </h4>
                <h2 className="px-4 py-8 text-center text-3xl  md:py-12 md:text-4xl lg:text-5xl md-padding lg-text" style={{ color: "#111111", fontWeight: "600" }}>
                    We provide a smart solution for

                    {!matches && <br />} {/* Only render <br /> if not on a small screen */}
                    a fast-moving world at your fingertips.
                </h2>

                <div className="flex flex-col gap-6  lg:flex-row md:gap-8  text-center">
                    {/* Left Section */}
                    {/* <div className="relative flex-1 overflow-hidden rounded-3xl bg-gradient-to-r from-[#2AB7B1] to-[#3CCF91] p-8 md:p-12">

                        <div>
                        <span className="inline-block rounded-full bg-white px-4 py-1 text-sm font-medium">Mobile App</span>
                        
                        <h2 className="mt-6 text-3xl font-bold text-white md:text-4xl lg:text-5xl">Read and reply to comments</h2>

                        <button className="mt-6 flex items-center text-white underline mx-auto">
                            Learn More
                            <ArrowForwardIcon className="ml-2" />
                        </button>

                        </div>

                        <div className="mt-12 md:mt-16">
                            <img
                                src={mobile_img}
                                alt="Mobile App Interface"
                                className="mx-auto "
                            />
                        </div>
                    </div> */}

                    <div className="relative flex-1 overflow-hidden rounded-3xl bg-gradient-to-r from-[#2AB7B1] to-[#3CCF91] p-8 md:p-12">
                        <div>
                            <span
                                className="inline-block rounded-full bg-white px-4 py-1 text-sm cursor-pointer transition-all duration-300 ease-in-out transform hover:scale-105 hover:bg-[#F1F1F1] hover:text-[#111111] hover:shadow-md"
                                style={{ color: "#111111", fontWeight: "600" }}
                            >
                                Mobile App
                            </span>


                            <h2 className="mt-6 text-3xl  text-white md:text-4xl font-600 ">Connect, Comment & Collaborate</h2>

                            {/* <button className="mt-6 flex items-center text-white  mx-auto">
                                Learn More
                           
                                <img src={arrowicon} className="ml-2" />
                            </button> */}

                            {/* border-b-2 border-white */}

                            <Link to="/mobile-app-development">
                                <button className="mt-6 flex items-center text-white mx-auto pb-2 font-semibold cursor-pointer transition-all duration-300 ease-in-out transform hover:scale-105 ">
                                    Learn More
                                    <img
                                        src={arrowicon}
                                        className="ml-2 transition-transform duration-300 transform hover:translate-x-2"
                                        alt="Arrow"
                                    />
                                </button>
                            </Link>


                        </div>

                        <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 mt-12 md:mt-16 imp-none">
                            <img
                                src={mobile_img}
                                alt="Mobile App Interface"
                                className="mx-auto"
                            />
                        </div>

                    </div>


                    {/* Right Section */}
                    <div className="flex-1 overflow-hidden rounded-3xl bg-[#F9FAFB] p-8 md:p-12">
                        <span
                            className="inline-block rounded-full border border-[#111111] px-4 py-1 text-sm font-semibold cursor-pointer transition-all duration-300 ease-in-out transform hover:scale-105 hover:bg-[#111111] hover:text-white hover:border-[#111111]"
                        >
                            Global Network
                        </span>


                        <h2 className="mt-6 text-3xl font-600 font-color md:text-4xl ">
                            Speed Without Borders – Fast, Reliable, and Always Connected.

                        </h2>

                        <div className="mt-12 md:mt-16">
                            <div className="relative">


                                <img
                                    src={svgmap}
                                    alt="World Map"
                                    className="aspect-[2/1] w-full "
                                />

                                {countries.map((country, index) => (
                                    <div
                                        key={index}
                                        className="absolute flex items-center justify-center w-6 h-6 bg-white rounded-full glow"
                                        style={{ top: country.position.top, left: country.position.left }}
                                        onMouseEnter={() => setHoveredCountry(country.name)}
                                        onMouseLeave={() => setHoveredCountry(null)}
                                    >
                                        <div className="w-2 h-2 bg-blue-500 rounded-full"></div>

                                        {/* Tooltip: Show country name on hover */}
                                        {hoveredCountry === country.name && (
                                            <div
                                                className="absolute bg-black text-white text-xs px-2 py-1 rounded-lg"
                                                style={{
                                                    top: "-25px",
                                                    left: "50%",
                                                    transform: "translateX(-50%)",
                                                }}
                                            >
                                                {country.name}
                                            </div>
                                        )}
                                    </div>
                                ))}


                                {/* <div
                                    className="absolute top-[60%] left-[71%] w-[2%] h-[4%] bg-white rounded-full transition-all duration-300 ease-in-out transform hover:scale-150 hover:bg-blue-700 animate-blink"
                                    style={{ cursor: "pointer" }}
                                >

                                    <div>hhhjb</div>
                                    <div className="absolute top-[-20px] left-[-50%] text-xs bg-blue-500 text-white p-1 rounded opacity-0 hover:opacity-100 transition-opacity duration-300 inter">
                                        India
                                    </div>
                                </div>



                                <div
                                    className="absolute top-[60%] left-[63%] w-[2%] h-[4%] bg-blue-500 rounded-full transition-all duration-300 ease-in-out transform hover:scale-150 hover:bg-blue-700"
                                    style={{ cursor: "pointer" }}
                                >
                                    <div className="absolute top-[-20px] left-[-50%] text-xs bg-blue-500 text-white p-1 rounded opacity-0 hover:opacity-100 transition-opacity duration-300 inter">
                                        UAE
                                    </div>
                                </div>

                                <div
                                    className="absolute top-[52%] left-[20%] w-[2%] h-[4%] bg-blue-500 rounded-full transition-all duration-300 ease-in-out transform hover:scale-150 hover:bg-blue-700"
                                    style={{ cursor: "pointer" }}
                                >
                                    <div className="absolute top-[-20px] left-[-50%] text-xs bg-blue-500 text-white p-1 rounded opacity-0 hover:opacity-100 transition-opacity duration-300 inter">
                                        US
                                    </div>
                                </div>

                                <div
                                    className="absolute top-[40%] left-[20%] w-[2%] h-[4%] bg-blue-500 rounded-full transition-all duration-300 ease-in-out transform hover:scale-150 hover:bg-blue-700"
                                    style={{ cursor: "pointer" }}
                                >
                                    <div className="absolute top-[-20px] left-[-50%] text-xs bg-blue-500 text-white p-1 rounded opacity-0 hover:opacity-100 transition-opacity duration-300 inter">
                                        Canada
                                    </div>
                                </div>

                                <div
                                    className="absolute top-[81%] right-[10%] w-[2%] h-[4%] bg-blue-500 rounded-full transition-all duration-300 ease-in-out transform hover:scale-150 hover:bg-blue-700"
                                    style={{ cursor: "pointer" }}
                                >
                                    <div className="absolute top-[-20px] left-[-50%] text-xs bg-blue-500 text-white p-1 rounded opacity-0 hover:opacity-100 transition-opacity duration-300 inter">
                                        Australia
                                    </div>
                                </div> */}
                                {/* 
                                <div
                                    className="absolute top-[30%] left-[40%] w-[10%] h-[15%] bg-yellow-500 opacity-50"
                                    style={{
                                        borderRadius: '50%', // Makes the highlight circular, but can be adjusted
                                        cursor: "pointer"
                                    }}
                                ></div> */}

                                {/* <WorldMap/> */}
                            </div>
                        </div>
                    </div>
                </div>

            </motion.div>

        </section>

    )
}

