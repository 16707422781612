import "./WebDevComp.css"
import { useState, useEffect } from "react";
import { motion } from "framer-motion";
export default function StatsSection() {
    const metrics = [
        {
            number: "10",
            label: "Years of Experience",
        },
        {
            number: "50",
            label: "Client Projects",
        },
        {
            number: "40",
            label: "Happy Customer",
        },
    ]

    const [counters, setCounters] = useState(
        metrics.map((metric) => ({
            ...metric,
            count: 0,
        }))
    );

    useEffect(() => {
        const interval = setInterval(() => {
            setCounters((prevCounters) =>
                prevCounters.map((metric) => {
                    if (metric.count < parseInt(metric.number.replace('+', ''))) {
                        return { ...metric, count: metric.count + 1 };
                    }
                    return metric;
                })
            );
        }, 170);

        return () => clearInterval(interval);
    }, []);

    return (
        // <div className="w-full bg-[#0A0A0A] rounded-3xl">
        //     <div className="max-w-7xl mx-auto p-8 md:p-12">

        <section className="py-16 px-4 stats">

            <motion.div className="max-w-7xl  bg-[#0A0A0A] rounded-3xl p-8 md:p-16 lg:p-20 mx-auto" initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true, amount: 0.2 }}
                    transition={{ duration: 0.5 }}>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
         
                    <div>
                        <h2 className="text-3xl md:text-3xl text-white lg-text">
                                Lorem ipsum dolor sit amet! <span className="text-[#24B574] font-bold">performance.</span>
                        </h2>
                    </div>

             
                    <div className="grid grid-cols-1 sm:grid-cols-3 gap-8">
                        {counters.map((metric, index) => (
                            <div key={index} className="text-center md:text-left">
                                <div className="text-4xl md:text-4xl font-semibold text-white mb-2 number">
                                {metric.count}{metric.number.includes('+') && '+'}
                                    <span className="text-white">+</span>
                                </div>
                                    <div className="text-[#FFFFFF] text-sm md:text-lg lg:text-xl opacity-50">{metric.label}</div>
                            </div>
                        ))}
                    </div>
                </div>

            </motion.div>

        </section>
        //     </div>
        // </div>
    )
}

