import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import {
    Person,
    Schedule,
    Code,
    CheckCircle,
    ArrowForward,
    SmartToy,
    PhoneAndroid,
    Web,
    Palette,
    Storage,
    BugReport,
} from "@mui/icons-material"
import step1 from "../../assets/images/ConsultForm/select_services.png"
import step2 from "../../assets/images/ConsultForm/personal_details.png"
import step3 from "../../assets/images/ConsultForm/select_timeline.png"
import service1 from "../../assets/images/ConsultForm/service1.png"
import service2 from "../../assets/images/ConsultForm/service2.png"
import service3 from "../../assets/images/ConsultForm/service3.png"
import service4 from "../../assets/images/ConsultForm/service4.png"
import pricing1 from "../../assets/images/ConsultForm/pricing1.svg"
import pricing2 from "../../assets/images/ConsultForm/pricing2.svg"
import pricing3 from "../../assets/images/ConsultForm/pricing3.svg"
import pricing4 from "../../assets/images/ConsultForm/pricing4.svg"
import pricing5 from "../../assets/images/ConsultForm/pricing5.svg"

import arrow from "../../assets/images/ConsultForm/right_arrow.png"
import selected from "../../assets/images/ConsultForm/selected.png"
import "./Consult.css"
import discuss_img from "../../assets/images/ConsultForm/need_to_discuss.png"
import step3_active from "../../assets/images/ConsultForm/step3_active.png"
import step2_active from "../../assets/images/ConsultForm/step2_active.png"
import leftbottom from "../../assets/images/ConsultForm/logoshadow.png"
import thank_you_webm from "../../assets/webm/thank-you.webm"
import thank_you_webm2 from "../../assets/webm/thank-you-2.webm"
import emailjs from "@emailjs/browser";

export default function SelectTimeline() {

    const navigate = useNavigate()
    const [currentStep, setCurrentStep] = useState(3)
    const [selectedServices, setSelectedServices] = useState(null)
    const [selectedBudget, setSelectedBudget] = useState(null)

    const [projectDescription, setProjectDescription] = useState("")
    const [isReferenceUrlEnabled, setIsReferenceUrlEnabled] = useState(false);
    const [referenceUrl, setReferenceUrl] = useState("");
    const [showPopup, setShowPopup] = useState(false); // State to control popup
    const steps = [
        {
            number: 1,
            title: "Select Services",
            icon: <Code className="w-6 h-6" />,

            activeImg: step1
            , inactiveImg: step1
        },
        {
            number: 2,
            title: "Personal Details",
            icon: <Person className="w-6 h-6" />,

            activeImg: step2_active
            , inactiveImg: step2
        },
        {
            number: 3,
            title: "Select Timeline",
            icon: <Schedule className="w-6 h-6" />,

            activeImg: step3_active
            , inactiveImg: step2
        },
    ]

    const services = [
        {
            id: "1 Month",
            title: "1 Month",
            icon: <Code className="w-6 h-6" />,
            img: service1
        },
        {
            id: "2-3 Month",
            title: "2-3 Month",
            icon: <SmartToy className="w-6 h-6" />,
            img: service2

        },
        {
            id: "4-6 Month",
            title: "4-6 Month",
            icon: <PhoneAndroid className="w-6 h-6" />,
            img: service3

        },
        {
            id: "Flexible",
            title: "Flexible",
            icon: <Web className="w-6 h-6" />,
            img: service4

        },

    ]

    const pricingModal = [


        {
            id: "Dedicated Development Team",
            title: "Dedicated Development Team",
            description: "Get a team of experts dedicated to your IT project, working full-time to deliver high-quality solutions. Ideal for long-term projects with evolving requirements.",

            img: pricing1
        },
        {
            id: "Fixed-Price Model",

            title: "Fixed-Price Model",
            description: "Get a team of experts dedicated to your IT project, working full-time to deliver high-quality solutions. Ideal for long-term projects with evolving requirements.",

            img: pricing2

        },
        {
            id: "Time & Material Model",

            title: "Time & Material Model",
            description: "Get a team of experts dedicated to your IT project, working full-time to deliver high-quality solutions. Ideal for long-term projects with evolving requirements.",

            img: pricing3

        },
        {
            id: "Offshore Development",

            title: "Offshore Development",
            description: "Get a team of experts dedicated to your IT project, working full-time to deliver high-quality solutions. Ideal for long-term projects with evolving requirements.",

            img: pricing4
        },

        {
            id: "Need to Discuss",

            title: "Need to Discuss",
            description: "Not sure which plan is right for you? Let’s talk! Our team is here to understand your unique needs and guide you toward the best solution. Reach out to us, and we’ll help you explore the options that align perfectly with your goals and budget.",


            img: pricing5
        },




    ]
    const handleServiceToggle = (id) => {
        setSelectedServices(id);
    };

    const handleBudgetToggle = (id) => {
        setSelectedBudget(id);
    };


    // const handleContinue = () => {

    //     setShowPopup(true); 

    // }







    const handleContinue = () => {
        let errors = {};
        let isValid = true;

        // Validate Timeline Selection
        // if (!selectedServices) {
        //     errors.selectedServices = "Please select a timeline.";
        //     isValid = false;
        // }

        // Validate Pricing Model Selection
        // if (!selectedModal) {
        //     errors.selectedModal = "Please select a pricing model.";
        //     isValid = false;
        // }
        // localStorage.setItem("WhereTheyHear", JSON.stringify(selectedServices));


        const storedWhereTheyHear = JSON.parse(localStorage.getItem("WhereTheyHear")) || "";

        // Set Errors
        if (errors.selectedServices) alert(errors.selectedServices);
        if (errors.selectedModal) alert(errors.selectedModal);

        if (!isValid) return;

        // Collect all form data from localStorage
        const step1Data = JSON.parse(localStorage.getItem("step1")) || {};
        const step2Data = JSON.parse(localStorage.getItem("step2")) || {};
        const step3Data = {
            selectedTimeline: selectedServices,
            selectedPricingModel: selectedModal,
            selectedBudget:selectedBudget
            
        };

        // Convert selectedServices into an array if it's a string
        let formattedServices = step2Data.selectedServices;
        if (typeof formattedServices === "string") {
            formattedServices = [formattedServices]; // Convert to an array
        }

        // Merge all steps data
        const finalFormData = { ...step1Data, ...step2Data, ...step3Data };
        console.log(finalFormData)

        // Prepare EmailJS parameters
        const emailParams = {
            full_name: finalFormData.fullName,
            email: finalFormData.email,
            phone: finalFormData.phoneNumber,
            country_name: finalFormData.country.name,
            country_code: finalFormData.country.code,
            project_description: finalFormData.projectDescription,
            reference_url: finalFormData.referenceUrl || "N/A",
            selected_services: finalFormData?.selectedServices, // Convert array to string
            WhereTheyHear: storedWhereTheyHear,
            selected_timeline: finalFormData.selectedTimeline,
            selected_pricing_model: finalFormData.selectedPricingModel,
            budget:finalFormData.selectedBudget
        };

        // Send email using EmailJS
        emailjs.send(
            "service_yd1la42", // Replace with your EmailJS Service ID
            "template_vevft1m", // Replace with your EmailJS Template ID
            emailParams,
            "V3AyLL9jbdM8ZN2kf" // Replace with your EmailJS Public Key
        )
            .then(() => {
                // Success: Show Thank You popup and clear localStorage
                setShowPopup(true);
                localStorage.removeItem("step1");
                localStorage.removeItem("step2");
                localStorage.removeItem("step3");
                localStorage.removeItem("WhereTheyHear");
            })
            .catch((error) => {
                console.error("Email sending failed:", error);
                alert("Failed to send email. Please try again.");
            });
    };


    useEffect(() => {
        const savedData = JSON.parse(localStorage.getItem("step3"));
        if (savedData) {
            setSelectedServices(savedData.selectedTimeline || "");
            setSelectedModal(savedData.selectedPricingModel || "");
        }
    }, []);

    const [selectedModal, setSelectedModal] = useState(null);

    const handleSelectModal = (id) => {
        setSelectedModal((prev) => (prev === id ? null : id));
    };

    useEffect(() => {
        if (showPopup) {
            const timer = setTimeout(() => {
                navigate("/"); // Redirect to homepage
            }, 2500); // 3-second delay

            return () => clearTimeout(timer); // Cleanup on unmount
        }
    }, [showPopup, navigate]);

    const budgets = [
        {
            id: "Under ₹2 Lakh",
            title: "Under ₹2 Lakh",
            icon: <Code className="w-6 h-6" />,
            // img: service1
        },
        {
            id: "₹2–5 Lakh",
            title: "₹2–5 Lakh",
            // icon: <SmartToy className="w-6 h-6" />,
            // img: service2

        },
        {
            id: "₹5–10 Lakh",
            title: "₹5–10 Lakh",
            // icon: <PhoneAndroid className="w-6 h-6" />,
            // img: service3

        },
        {
            id: "Over ₹10 Lakh",
            title: "Over ₹10 Lakh",
            // icon: <Web className="w-6 h-6" />,
            // img: service4

        },

    ]
    return (

        <section className="py-12 px-4 relative consult">


            <img
                src={leftbottom}
                alt="Overlay"
                className="absolute left-0 bottom-0 w-36 sm:w-44 md:w-60 lg:w-96 "
            />
            <div className="max-w-4xl mx-auto ">

                {/* import thank_you_webm from "../../assets/webm/thank-you.webm"; */}

                {showPopup && (
                    <div
                        className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 p-4"
                        style={{ pointerEvents: "auto" }}
                    >
                        <div className="bg-white px-6 py-8 sm:px-10 sm:py-12 rounded-3xl text-center border-2 border-[#EBEDEF] max-w-sm w-full">
                            {/* Thank You Video */}
                            <video autoPlay loop muted className="w-32 sm:w-40 mx-auto rounded-lg">
                                <source src={thank_you_webm2} type="video/webm" />
                                Your browser does not support the video tag.
                            </video>

                            <h2 className="text-2xl sm:text-3xl font-semibold mt-4">Thank You!</h2>
                            <p className="text-gray-600 mt-6 sm:mt-8 text-lg sm:text-xl">
                                We appreciate you reaching out to us! We will review your request and get back to you as soon as possible.
                            </p>
                        </div>
                    </div>
                )}




                {/* Progress Steps */}
                <div className="flex items-center justify-center mb-12">
                    {steps.map((step, index) => (
                        <div key={step.number} className="flex items-center">


                            <div className="flex flex-col items-center">

                                <div
                                    className={`flex items-center justify-center rounded-full border-2  p-1 md:p-2
          ${currentStep >= step.number ? "border-grad text-emerald-500" : "border-[#A1AEBE] text-gray-400"}`}


                                >

                                    <img
                                        src={currentStep >= step.number ? step.activeImg : step.inactiveImg}
                                        alt=""
                                        className="w-6 md:w-8"
                                    />

                                </div>

                                <p
                                    className={`text-sm sm:text-lg md:text-xl font-semibold mt-2  text-center
    ${currentStep >= step.number
                                            ? "bg-gradient-to-b from-[#24B574] to-[#2BAAE2] bg-clip-text text-transparent"
                                            : "text-gray-400"
                                        }`}
                                >
                                    {step.title}
                                </p>

                            </div>


                            {index < steps.length - 1 && (
                                <div
                                    className={`w-16 sm:w-24 h-0.5 mx-1 sm:mx-4 
          ${currentStep > step.number ? "bg-emerald-500" : "bg-[#A1AEBE]"}`}
                                />
                            )}
                        </div>
                    ))}

                </div>

                {/* Form Content */}
                <div className="bg-white rounded-2xl p-8 border-2 border-[#EBEDEF] relative">
                    {currentStep === 3 && (
                        <div className="space-y-9">
                            <div>
                                <label className="block text-base sm:text-lg md:text-xl font-semibold mb-4 sm:mb-8 text-[#3E3838]">
                                    Project Timeline (Optional)
                                </label>
                                <div className="flex flex-wrap justify-start gap-4 sm:gap-6">
                                    {services.map((service) => (
                                        <button
                                            key={service.id}
                                            onClick={() => handleServiceToggle(service.id)}
                                            className={`relative flex items-center gap-2 px-3 py-2 sm:px-4 sm:py-2 rounded-full border transition-colors border-[#E9EBED]`}
                                        >

                                            <span className="text-xs sm:text-base md:text-lg font-medium text-[#757D84]">{service.title}</span>

                                            <div className="border border-[#E9EBED] rounded-full " style={{ padding: "1px" }}>

                                                <div
                                                    className={`w-4 h-4 sm:w-5 sm:h-5 md:w-7 md:h-7  rounded-full   ml-auto flex items-center justify-center transition-all
    ${selectedServices === service?.id
                                                            ? "border-green-500 bg-gradient-to-b from-[#24B574] to-[#2BAAE2]"
                                                            : "bg-transparent"}`}
                                                >
                                                    {selectedServices === service?.id && (
                                                        <img src={selected} alt="Selected" className="w-2 h-2 md:w-3 md:h-3" />
                                                    )}
                                                </div>
                                            </div>

                                        </button>
                                    ))}
                                </div>

                            </div>

                            <div>
                                <label className="block text-base sm:text-lg md:text-xl font-semibold mb-4 sm:mb-8 text-[#3E3838]">
                                    Estimated budget? (Optional)
                                </label>
                                <div className="flex flex-wrap justify-start gap-4 sm:gap-6">
                                    {budgets.map((budget) => (
                                        <button
                                            key={budget.id}
                                            onClick={() => handleBudgetToggle(budget.id)}
                                            className={`relative flex items-center gap-2 px-3 py-2 sm:px-4 sm:py-2 rounded-full border transition-colors border-[#E9EBED]`}
                                        >

                                            <span className="text-xs sm:text-base md:text-lg font-medium text-[#757D84]">{budget.title}</span>

                                            <div className="border border-[#E9EBED] rounded-full " style={{ padding: "1px" }}>

                                                <div
                                                    className={`w-4 h-4 sm:w-5 sm:h-5 md:w-7 md:h-7  rounded-full   ml-auto flex items-center justify-center transition-all
    ${selectedBudget === budget?.id
                                                            ? "border-green-500 bg-gradient-to-b from-[#24B574] to-[#2BAAE2]"
                                                            : "bg-transparent"}`}
                                                >
                                                    {selectedBudget === budget?.id && (
                                                        <img src={selected} alt="Selected" className="w-2 h-2 md:w-3 md:h-3" />
                                                    )}
                                                </div>
                                            </div>

                                        </button>
                                    ))}
                                </div>

                            </div>



                            <div>
                                <label className="block text-base sm:text-lg md:text-xl font-semibold mb-4 sm:mb-8 text-[#3E3838]">
                                    Pricing Model (Optional)
                                </label>


                                <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-6 h-full">

                                    <div className="lg:col-span-2 md:col-span-1 grid grid-cols-1 sm:grid-cols-2 gap-6">

                                        {pricingModal.slice(0, 4).map((pricing) => (
                                            <div className={`p-4 bg-[#FFFFFF] rounded-xl border border-[#E6E9EE] flex flex-col gap-3 cursor-pointer ${selectedModal === pricing.id ? "border-[#24B574]" : "border-[#E6E9EE]"
                                                }`} key={pricing.id} onClick={() => handleSelectModal(pricing?.id)}>

                                                <div className="flex justify-between items-center">
                                                    <div className="flex items-center justify-center p-3 bg-[#FAFAFA] rounded-full">
                                                        <img src={pricing?.img} alt="Icon Left" className="w-6 h-6" />
                                                    </div>

                                                    <div className="border border-[#E9EBED] rounded-full " style={{ padding: "1px" }}>

                                                        <div
                                                            className={`w-4 h-4 sm:w-5 sm:h-5 md:w-7 md:h-7  rounded-full   ml-auto flex items-center justify-center transition-all

                                                            
                                                          ${selectedModal === pricing.id
                                                                    ? "border-green-500 bg-gradient-to-b from-[#24B574] to-[#2BAAE2]"
                                                                    : "bg-transparent"}`}
                                                        >

                                                            {selectedModal === pricing.id && <img src={selected} alt="Selected" className="w-2 h-2 md:w-3 md:h-3" />}

                                                            {/* <img src={selected} alt="Selected" className="w-2 h-2 md:w-3 md:h-3" /> */}

                                                        </div>
                                                    </div>
                                                </div>


                                                <h2 className="text-lg md:text-xl font-semibold text-[#757D84] ">
                                                    {pricing?.title}
                                                </h2>
                                                <p className="text-[#888888] text-sm sm:text-base">
                                                    {pricing?.description}
                                                </p>
                                            </div>

                                        ))}





                                    </div>
                                    {pricingModal.slice(4, 5).map((pricing) => (



                                        <div className={`lg:col-span-1 md:col-span-1 bg-[#FFFFFF] rounded-xl  p-4 border border-[#E6E9EE] flex flex-col gap-7 cursor-pointer ${selectedModal === pricing.id ? "border-[#24B574]" : "border-[#E6E9EE]"
                                            }`} key={pricing.id} onClick={() => handleSelectModal(pricing?.id)}>
                                            <div className="flex justify-between items-center">
                                                <div className="flex items-center justify-center p-3 bg-[#FAFAFA] rounded-full">
                                                    <img src={pricing5} alt="Icon Left" className="w-6 h-6" />
                                                </div>

                                                <div className="border border-[#E9EBED] rounded-full " style={{ padding: "1px" }}>

                                                    <div
                                                        className={`w-4 h-4 sm:w-5 sm:h-5 md:w-7 md:h-7  rounded-full   ml-auto flex items-center justify-center transition-all

                                                            
                                                          ${selectedModal === pricing.id
                                                                ? "border-green-500 bg-gradient-to-b from-[#24B574] to-[#2BAAE2]"
                                                                : "bg-transparent"}`}
                                                    >

                                                        {selectedModal === pricing.id && <img src={selected} alt="Selected" className="w-2 h-2 md:w-3 md:h-3" />}

                                                        {/* <img src={selected} alt="Selected" className="w-2 h-2 md:w-3 md:h-3" /> */}

                                                    </div>
                                                </div>
                                            </div>

                                            <img src={discuss_img} alt="" />
                                            <h2 className="text-lg md:text-xl font-semibold text-[#757D84]">
                                                Need to Discuss
                                            </h2>
                                            <p className="text-[#888888] text-sm md:text-base">
                                                Not sure which plan is right for you? Let’s talk! Our team is here to understand your unique needs and guide you toward the best solution. Reach out to us, and we’ll help you explore the options that align perfectly with your goals and budget.
                                            </p>

                                        </div>
                                    ))}
                                </div>



                            </div>



                            {/* <div className="flex items-center gap-2">
                                <input
                                    type="checkbox"
                                    id="referenceUrlCheckbox"
                                    checked={isReferenceUrlEnabled}
                                    onChange={() => setIsReferenceUrlEnabled(!isReferenceUrlEnabled)}

                                    className="w-5 h-5 text-emerald-500 border-[#8888884F] rounded focus:ring-emerald-500 border"
                                />
                                <label htmlFor="referenceUrlCheckbox" className="text-sm md:text-base text-[#434343] cursor-pointer font-medium">
                                    Do you want to add a reference URL? (Optional)
                                </label>
                            </div> */}



                            <div className="flex justify-end">
                                <button
                                    onClick={handleContinue}
                                    // disabled={selectedServices.length === 0}
                                    className="flex items-center gap-2 text-sm px-3 py-2 sm:text-base sm:px-5 sm:py-2 md:px-6 md:py-3 md:text-lg
             bg-gradient-to-r from-[#25B57E] to-[#2BAAE2] 
             text-white rounded-xl transition-all 
             hover:opacity-90 disabled:opacity-50 disabled:cursor-not-allowed"
                                >
                                    Submit
                                    {/* <ArrowForward className="w-5 h-5" /> */}
                                    <img src={arrow} alt="" className="w-4 sm:w-5 md:w-6" />
                                </button>

                            </div>
                        </div>
                    )}
                </div>
            </div>

        </section>
    )
}

