import React from 'react'
import { useState } from 'react';
import { motion } from "framer-motion";
import { Description, AccessTime } from "@mui/icons-material"
import main_img from "../../../assets/images/about/main_img.svg"
import { Add, Remove } from "@mui/icons-material"
import "./Custom.css"
import whocanuse from "../../../assets/images/Services/CustomProduct/whocanuse.svg"
import overlay_img from "../../../assets/images/Services/CustomProduct/Overlay.svg"
import pr1 from "../../../assets/images/Services/CustomProduct/pr1.svg"
import pr2 from "../../../assets/images/Services/CustomProduct/pr2.svg"
import pr3 from "../../../assets/images/Services/CustomProduct/pr3.svg"
import pr4 from "../../../assets/images/Services/CustomProduct/pr4.svg"
import pr5 from "../../../assets/images/Services/CustomProduct/pr5.svg"


import { Link } from 'react-router-dom';
const WhoCanUse = () => {

    const features = [
        {
            icon: <Description className="text-emerald-600 w-6 h-6" />,
            title: "Our Mission",
            description: "Connect tools for unified workflow efficiency.",
        },
        {
            icon: <AccessTime className="text-emerald-600 w-6 h-6" />,
            title: "Our Vision",
            description: "User-friendly interface boosts team productivity.",
        },
    ]


    const [openIndex, setOpenIndex] = useState(null)

    const faqs = [
        {
            question: "How secure is my data?",
            answer:
                "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Voluptatibus asperiores deserunt veniam, expedita distinctio perferendis. Aperiam quaerat ipsum ducimus nemo!",
            image: pr1,

        },
        {
            question: "Can I customize the platform?",
            answer:
                "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Voluptatibus asperiores deserunt veniam, expedita distinctio perferendis. Aperiam quaerat ipsum ducimus nemo!",
            image: pr2,
        },
        {
            question: "Is there a free trial available?",
            answer:
                "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Voluptatibus asperiores deserunt veniam, expedita distinctio perferendis. Aperiam quaerat ipsum ducimus nemo!",
            image: pr3,
        },
        {
            question: "How do I upgrade my plan?",
            answer:
                "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Voluptatibus asperiores deserunt veniam, expedita distinctio perferendis. Aperiam quaerat ipsum ducimus nemo!",
            image: pr4,
        },

        {
            question: "How do I upgrade my plan?",
            answer:
                "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Voluptatibus asperiores deserunt veniam, expedita distinctio perferendis. Aperiam quaerat ipsum ducimus nemo!",
            image: pr5,
        },
    ]

    const toggleFAQ = (index) => {
        setOpenIndex(openIndex === index ? null : index)
    }


    return (
        <section className="py-0 sm:py-16 px-4 who">
            <div className="max-w-7xl mx-auto">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-stretch">

                    <div className=''>

                        <div className="space-y-8 ">

                            {/* <motion.div className="inline-block px-4  bg-[#F1F7FC] rounded-full text-[#3D3D3D] py-1  " initial={{ opacity: 0, y: 50 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                viewport={{ once: true, amount: 0.2 }}
                                transition={{ duration: 0.5 }}  >
                                <span className="text-[#4D4D4D] font-medium text-lg ">Who can use</span>
                            </motion.div> */}


                            <motion.div className="space-y-6" initial={{ opacity: 0, y: 50 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                viewport={{ once: true, amount: 0.2 }}
                                transition={{ duration: 0.5 }}  >
                                <h2 className="text-3xl md:text-4xl font-medium leading-tight hanken lg-text text-[#0C0C0C]">Our <span className='text-[#24B574]'>Process</span></h2>
                                <p className="text-base md:text-lg text-[#485E79]">
                                    Lorem ipsum dolor sit amet consectetur, adipisicing elit. A, ab nemo! Expedita!
                                </p>
                            </motion.div>



                            <div className="">

                                <motion.div className="" initial={{ opacity: 0, y: 50 }}
                                    whileInView={{ opacity: 1, y: 0 }}
                                    viewport={{ once: true, amount: 0.2 }}
                                    transition={{ duration: 0.5 }}>
                                    {faqs.map((faq, index) => (
                                        <div
                                            key={index}
                                            className={`overflow-hidden ${index !== faqs.length - 1 ? 'border-b' : ''}`}

                                        >
                                            <button
                                                onClick={() => toggleFAQ(index)}
                                                className="w-full px-6 py-6 flex items-center justify-between text-left hover:bg-gray-100 transition-colors duration-200"
                                            >
                                                {/* Image at the start */}

                                                <div className='flex items-center'>
                                                    <img src={faq.image} alt="faq-icon" className="w-8 h-8 mr-4" />

                                                    <span className="font-medium text-[#333333] inter text-lg">{faq.question}</span>
                                                </div>

                                                <span className="ml-4 flex-shrink-0 bg-[#333333] p-2 rounded-full">
                                                    {openIndex === index ? (
                                                        <Remove className="w-5 h-5 text-white" />
                                                    ) : (
                                                        <Add className="w-5 h-5 text-white" />
                                                    )}
                                                </span>
                                            </button>

                                            {/* Answer */}
                                            <div
                                                className={`px-6 overflow-hidden transition-all duration-200 ease-in-out ${openIndex === index ? "max-h-48 py-4" : "max-h-0"
                                                    }`}
                                            >
                                                <p className="text-gray-600">{faq.answer}</p>
                                            </div>
                                        </div>
                                    ))}
                                </motion.div>

                            </div>
                        </div>

                    </div>



                    <motion.div className='flex flex-col h-full' initial={{ opacity: 0, y: 50 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true, amount: 0.2 }}
                        transition={{ duration: 0.5 }}>

                        <article className="rounded-2xl overflow-hidden transition-all duration-300 ease-in-out bg-gradient box-shadow-blog cursor-pointer relative">
                            <div className="relative overflow-hidden p-3">
                                <div className="relative w-full h-full rounded-xl overflow-hidden">
                                    <img src={whocanuse} className="w-full h-full object-cover rounded-xl" />
                                    {/* <div className="absolute bottom-0 left-0 w-full h-1/2 bg-gradient-to-t from-[rgba(51,133,255,0.5)] to-[rgba(51,133,255,0)] pointer-events-none rounded-2xl"></div> */}
                                    <div className="absolute bottom-0 left-0 w-full h-1/2 bg-gradient-to-t from-[rgba(36,181,116,0.35)] to-[rgba(36,181,116,0)] pointer-events-none rounded-3xl"></div>


                                </div>
                            </div>

                            <div className="p-6 flex flex-col md:flex-row md:items-center justify-between">
                                <div className="md:max-w-[70%]">
                                    <h3 className="font-semibold text-xl mb-2 text-gray-900 line-clamp-2 inter">
                                        Ready to Get Started?
                                    </h3>
                                    <p className="text-[#606060] line-clamp-2 inter pr-8 font-medium">
                                        Connect with us now
                                    </p>
                                </div>

                                <div className="mt-4 md:mt-0">
                                    {/* <button className=" text-[#3D3D3D] px-5 py-2 rounded-full font-semibold text-base border border-[#E1E4EB]">
                                        Contact Us
                                    </button> */}

                                    <Link to="/consult-us">

                                    <button className="text-[#3D3D3D] px-5 py-2 rounded-full font-semibold text-base border border-[#E1E4EB] hover:bg-[#3D3D3D] hover:text-white transition duration-300">
                                        Contact Us
                                    </button>
                                    </Link>
                                </div>
                            </div>


                        </article>
                    </motion.div>
                </div>
            </div>
        </section>
    )
}

export default WhoCanUse















