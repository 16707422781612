import React from 'react'
import img1 from "../../../assets/images/Services/CustomProduct/Overlay.svg"
import img2 from "../../../assets/images/Services/CustomProduct/Overlay2.svg"
import img3 from "../../../assets/images/Services/CustomProduct/Overlay3.svg"
import img4 from "../../../assets/images/Services/CustomProduct/Overlay4.svg"
import { motion } from "framer-motion";
import startup from "../../../assets/images/Services/CustomProduct/startup.png"
import ig1 from "../../../assets/images/Services/CustomProduct/Saas.png"
import ig2 from "../../../assets/images/Services/CustomProduct/mvp.png"
import ig3 from "../../../assets/images/Services/CustomProduct/cloud.png"
import ig4 from "../../../assets/images/Services/CustomProduct//API.png"
import ig5 from "../../../assets/images/Services/CustomProduct/ui-ux.png"
import ig6 from "../../../assets/images/Services/CustomProduct/security.png"
import mobile_app_img from "../../../assets/images/Services/CustomProduct/mobile_app_img.svg"


const InfoSection = () => {

    const technologies = [
        // Row 1
        {
            name: "Custom SaaS Development",
            logo: ig1,
            description: "It is crucial to create a content management system (CMS) before thriving deep into your business ideas."
        },
        {
            name: "MVP Development",
            logo: ig2,
            description: "It is crucial to create a content management system (CMS) before thriving deep into your business ideas."

        },

        {
            name: "Cloud Integration",
            logo: ig3,
            description: "It is crucial to create a content management system (CMS) before thriving deep into your business ideas."

        },

        {
            name: "API Integration",
            logo: ig4,
            description: "It is crucial to create a content management system (CMS) before thriving deep into your business ideas."
        },
        {
            name: "UI/UX Design",
            logo: ig5,
            description: "It is crucial to create a content management system (CMS) before thriving deep into your business ideas."

        },

        {
            name: "Security and Compliance",
            logo: ig6,
            description: "It is crucial to create a content management system (CMS) before thriving deep into your business ideas."

        },


    ]
    return (

        <section className="relative py-0 md:py-16 px-4 info-comp">


            <div className="max-w-7xl mx-auto">

                <motion.div className="text-center mb-12 max-w-5xl mx-auto" initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true, amount: 0.2 }}
                    transition={{ duration: 0.5 }}
                >
                    <h2 className="text-3xl md:text-5xl font-medium text-[#212A40] mb-4 poppins lg-text">
                        Lorem ipsum dolor sit<span className="text-[#24B574]">  amet consectetur, adipisicing elit.</span> </h2>
                    {/* <p className="text-[#485E79] max-w-2xl text-lg mx-auto">
                        Lorem ipsum dolor sit amet consectetur, adipisicing elit A, ab nemo! Expedita!Lorem ipsum dolor sit amet consectetur
                    </p> */}
                </motion.div>











                <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-start p-0 lg:p-4 ">

                    <motion.div
                        className="space-y-8 flex flex-col h-full rounded-2xl bg-[#29AFB7] border border-[#F2F3F8] pt-10 pl-10 pr-10 pb-0 items-center justify-between  text-center relative group"
                        initial={{ opacity: 0, y: 50 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true, amount: 0.2 }}
                        transition={{ duration: 0.5 }}
                    >
                        <div className="space-y-5">

                            <h2 className="text-3xl font-semibold text-white">Lorem ipsum dolor sit amet
                            </h2>


                            <p className="text-lg text-white">
                                Seamlessly schedule meetings with ease and efficiency, eliminating any unnecessary complications.
                            </p>
                        </div>
                        {/* <div className="bg-overlay min-h-[380px] rounded-2xl rounded-b-none bg-white opacity-[8%] w-[94%] absolute -bottom-5 left-1/2 transform -translate-x-1/2 z-10 animate-pulse-overlay"></div>

                   
                        <div className="bg-overlay min-h-[400px] rounded-2xl rounded-b-none bg-white opacity-25 w-[86%] absolute bottom-0 left-1/2 transform -translate-x-1/2 z-0 animate-pulse-overlay "></div> */}

                        <div className="bg-overlay min-h-[350px] rounded-2xl rounded-b-none bg-white opacity-[8%] w-[87%] absolute -bottom-5 z-10 hidden lg:block animate-pulse-overlay"></div>


                        <div className="bg-overlay min-h-[380px] rounded-2xl rounded-b-none bg-white opacity-25 w-[83%] absolute bottom-0  animate-pulse-overlay hidden lg:block "></div>

                        <div className="py-4 px-2 bg-white rounded-2xl rounded-b-none mt-auto z-10" >
                            <img
                                src={mobile_app_img}
                                alt="Product Development"
                                className="w-[300px] sm:w-[350px] md:w-[400px] lg:w-[450px] h-auto object-contain mx-auto"
                            />
                        </div>



                    </motion.div>


                    <motion.div className="flex flex-col h-full rounded-2xl bg-white border border-[#F2F3F8] p-6     lg:p-8" initial={{ opacity: 0, y: 50 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true, amount: 0.2 }}
                        transition={{ duration: 0.5 }}>
                        <div className="space-y-4">
                            <h3 className="text-2xl md:text-3xl font-medium text-[#0C0C0C]">
                                Why your business needs a custom mobile app
                            </h3>

                            <div>
                                <ul className="list-disc pl-6">
                                    <li className="font-medium text-lg text-black">
                                        Enhanced customer engagement:
                                    </li>
                                </ul>
                                <p className="text-[#606060] pl-6 font-medium text-lg mt-3">
                                    A mobile app provides a direct channel to your customers. Unlike websites or emails that might get ignored,
                                    an app with push notifications keeps your audience informed about promotions, updates, and special offers in real time.
                                </p>
                            </div>


                            <div>
                                <ul className="list-disc pl-6">
                                    <li className="font-medium text-lg text-black">
                                    Increased brand visibility & recognition :
                                    </li>
                                </ul>
                                <p className="text-[#606060] pl-6 font-medium text-lg mt-3">
                                Your brand is always at your customer’s fingertips. A well-designed app with a recognizable logo and seamless user experience increases brand recall and loyalty.

                                </p>
                            </div>


                            <div>
                                <ul className="list-disc pl-6">
                                    <li className="font-medium text-lg text-black">
                                    Better customer experience & support :
                                    </li>
                                </ul>
                                <p className="text-[#606060] pl-6 font-medium text-lg mt-3">
                                With features like chat support, AI-powered assistants, or FAQs, you can provide instant help to customers, improving their overall experience and reducing frustration.


                                </p>
                            </div>
                        </div>
                    </motion.div>

                </div>




                <motion.div className="text-center mb-12" initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true, amount: 0.2 }}
                    transition={{ duration: 0.5 }}
                >
                    {/* <h2 className="text-3xl md:text-5xl font-medium text-[#212A40] mb-4 poppins lg-text">
                        Lorem ipsum dolor sit<span className="text-[#24B574]">  amet consectetur, adipisicing elit.</span> </h2> */}
                    {/* <p className="text-[#485E79] max-w-2xl text-lg mx-auto">
                        Lorem ipsum dolor sit amet consectetur, adipisicing elit A, ab nemo! Expedita!Lorem ipsum dolor sit amet consectetur
                    </p> */}
                </motion.div>

                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 justify-items-center " initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true, amount: 0.2 }}
                    transition={{ duration: 0.5 }}
                >

                    {/* relative rounded-xl overflow-hidden bg-gradient box-shadow-blog cursor-pointer  */}
                    {technologies.map((tech, index) => (
                        <motion.div
                            key={index}
                            className=" cursor-pointer   bg-white  rounded-3xl p-5  border border-[#F2F3F8]"
                            initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true, amount: 0.2 }}
                    transition={{ duration: 0.5 }}
                        >

                            <div className='py-4 '>

                                <p className="text-xl text-[#020710] text-center font-600">{tech.name}</p>
                                <p className="text-center text-sm text-[#7A8089] my-3">{tech.description}</p>

                            </div>
                            <div className="overflow-hidden p-1 ">
                                <img
                                    src={tech.logo || "/placeholder.svg"}
                                    alt={`${tech.name} logo`}
                                    // className="object-contain group-hover:filter group-hover:brightness-0 group-hover:invert"
                                    className={`w-full h-full object-cover 
                                
                                
                                
                               `}
                                />
                            </div>

                        </motion.div>
                    ))}


                </div>





            </div>
        </section>
    )
}

export default InfoSection
