import React from 'react'

import { motion } from "framer-motion";
const ShiporaCommon = () => {
    return (
        <motion.section className="relative py-12  common" initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true, amount: 0.2 }}
            transition={{ duration: 0.5 }}>
            <div className="max-w-3xl mx-auto flex flex-col items-start text-left space-y-4">
                <h3 className="text-2xl md:text-4xl  text-[#292929] lg-text">
                    An outline of our Design Workflow <br />from start to finish
                </h3>
                <span className="text-[#676767] text-lg">
                From concept to completion, our structured design workflow ensures a seamless development process. Each stage, from research to prototyping, is carefully executed to bring the best possible product to life.
                </span>
            </div>
        </motion.section>


    )
}

export default ShiporaCommon
