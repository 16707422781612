import React from 'react'
import { motion } from 'framer-motion'
// import "./AIServices.css"
import begin_img from "../../../assets/images/Services/Quality Assurance/testingbanner.png"
import "./QualityTesting.css"
import next_gen from "../../../assets/images/Services/Quality Assurance/automated.png"
import transfom from "../../../assets/images/Services/Quality Assurance/cicd.png"
import empower from "../../../assets/images/Services/MobileApp/empowering.svg"
import ai_powered from "../../../assets/images/Services/Quality Assurance/performance.png"

const grid_data = [


    {
        img: next_gen,
        text: "Automated Testing"
    },


    {
        img: transfom,
        text: "CI/CD Integration"
    },


    {
        img: empower,
        text: "Manual Testing"
    },


    {
        img: ai_powered,
        text: "Performance & Load Testing"
    },
]
const StartSection = () => {
    return (

        <section className="relative  py-0 md:pb-16 px-4 startsec ">


            <div className="max-w-7xl mx-auto">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-start p-0 lg:p-4 ">

                    <motion.div
                        className="space-y-8 flex flex-col h-full relative group justify-center"
                        initial={{ opacity: 0, y: 50 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true, amount: 0.2 }}
                        transition={{ duration: 0.5 }}
                    >

                        <img src={begin_img} alt="" className='rounded-xl' />



                    </motion.div>


                    <motion.div className="flex flex-col h-full justify-center" initial={{ opacity: 0, y: 50 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true, amount: 0.2 }}
                        transition={{ duration: 0.5 }}>
                        <div className="space-y-7">

                            {/* <span className="font-medium text-[#333333]  px-4 py-2   rounded-full border border-[#EDEFF3] bg-[#F8F9FA] ">Mobile App</span> */}
                            <h3 className="text-2xl md:text-4xl font-semibold text-[#000000] lg-text">
                            Delivering Flawless User Experiences with Cutting-Edge Quality Assurance
                            </h3>

                            <div>
                                

                                <p className=" text-[#666666]" style={{ fontSize: "17px" }}>

                                At Tech Rabbit, we offer comprehensive Quality Assurance Testing services to ensure that your software applications perform optimally, securely, and are free from defects. We work alongside your development team to deliver high-quality products that exceed user expectations. Our QA specialists utilize the latest testing methodologies and tools to ensure superior performance, functionality, and usability.
                                </p>


                            </div>

                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                {/* <div className="bg-white rounded-2xl p-4 border border-[#E6E9EE] flex items-center gap-3">
                             
                                    <img src={next_gen} alt="Next Gen" className="" />

                         
                                    <p className="text-[#4C4C4C] text-lg font-medium">
                                        Our team of developers ensures seamless,
                                    </p>
                                </div>
                                <div className="bg-white  rounded-2xl p-4 border border-[#E6E9EE]">

                                    <p className="text-[#4C4C4C] text-lg font-medium ">
                                        We build apps that grow with your business,
                                    </p>
                                </div>

                                <div className="bg-white  rounded-2xl p-4 border border-[#E6E9EE]">

                                    <p className="text-[#4C4C4C] text-lg font-medium">
                                        Our team of developers ensures seamless,
                                    </p>
                                </div>
                                <div className="bg-white  rounded-2xl p-4 border border-[#E6E9EE]">

                                    <p className="text-[#4C4C4C] text-lg font-medium ">
                                        We build apps that grow with your business,
                                    </p>
                                </div> */}

                                {grid_data.map((item, index) => {
                                    return (
                                        <div className="bg-white rounded-2xl px-2 py-3  border border-[#E6E9EE] flex items-center gap-3" key={index}>

                                            <img src={item?.img} alt="Next Gen" className="" />


                                            <p className="text-[#000000] text-lg font-medium">
                                                {item?.text}
                                            </p>
                                        </div>
                                    );
                                })}



                            </div>






                        </div>
                    </motion.div>

                </div>
            </div>
        </section>
    )
}

export default StartSection
