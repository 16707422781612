import React from 'react'
import explore from "../../assets/images/WorkDetails/SindhiSoulmate/plans.svg"
import color from "../../assets/images/WorkDetails/SindhiSoulmate/color.svg"
import img1 from "../../assets/images/WorkDetails/Shipora/img1.webp"
import img2 from "../../assets/images/WorkDetails/Shipora/img2.webp"
import img3 from "../../assets/images/WorkDetails/Shipora/img3.webp"
import img4 from "../../assets/images/WorkDetails/Shipora/img4.webp"
import img5 from "../../assets/images/WorkDetails/Shipora/img5.webp"
import img6 from "../../assets/images/WorkDetails/Shipora/img6.webp"
import img7 from "../../assets/images/WorkDetails/Shipora/img7.webp"
import img9 from "../../assets/images/WorkDetails/Shipora/img9.webp"
import img10 from "../../assets/images/WorkDetails/Shipora/img10.webp"
import img11 from "../../assets/images/WorkDetails/Shipora/img11.webp"
import img12 from "../../assets/images/WorkDetails/Shipora/img12.webp"
import img15 from "../../assets/images/WorkDetails/Shipora/img15.webp"








import group_img from "../../assets/images/WorkDetails/SindhiSoulmate/Frame 1000003751.png"

import sindhi_soulmate from "../../assets/images/WorkDetails/SindhiSoulmate/singhi_soulmate.png"
import bg_wireframe from "../../assets/images/WorkDetails/SindhiSoulmate/wireframe.png"
import bg_img2 from "../../assets/images/WorkDetails/SindhiSoulmate/Frame 1000003747.png"




import { motion } from "framer-motion";
import ShiporaWireframe from './ShiporaWireframe'
import AccountSteps from '../ProjectDetails/AppSteps'
import ShiporaSteps from './ShiporaSteps'
import ScrollComp from '../ProjectDetails/ScrollComp'
import ShiporaScrollComp from './ShiporaScrollComp'
import PageInfo from './PageInfo'
import FinalInfo from './FinalInfo'
import TechUsed from '../ProjectDetails/TechUsed'
import WorkCards from '../ProjectDetails/WorkCards'
import CTMSection from '../CTMSection/CTMSection'



const ShiporaOverview = () => {
    return (

        <>
            <section className="relative px-4 project">
                {/* First Image Section */}
                <motion.div className="max-w-7xl mx-auto" initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true, amount: 0.2 }}
                    transition={{ duration: 0.5 }}>
                    <div>
                        <img
                            src={img1}
                            alt="Featured story"
                            className="w-full"
                        />
                    </div>
                </motion.div>

                {/* First Text Section */}
                <motion.section className="relative py-12 common" initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true, amount: 0.2 }}
                    transition={{ duration: 0.5 }}>
                    <div className="max-w-3xl mx-auto flex flex-col items-start text-left space-y-4">
                        <h3 className="text-2xl md:text-4xl text-[#292929] lg-text">
                            Shipora Brand Guidelines
                        </h3>
                        <span className="text-[#676767] text-lg">
                            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Provident in doloremque officia dignissimos deleniti inventore porro possimus, suscipit natus. Commodi iure numquam facilis itaque?
                        </span>
                    </div>
                </motion.section>

                <div className=' space-y-8'>

                    <motion.div className="max-w-7xl mx-auto" initial={{ opacity: 0, y: 50 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true, amount: 0.2 }}
                        transition={{ duration: 0.5 }}>
                        <div>
                            <img
                                src={img2}
                                alt="Featured story"
                                className="w-full"
                            />
                        </div>
                    </motion.div>

                    <motion.div className="max-w-7xl mx-auto" initial={{ opacity: 0, y: 50 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true, amount: 0.2 }}
                        transition={{ duration: 0.5 }}>
                        <div>
                            <img
                                src={img3}
                                alt="Featured story"
                                className="w-full"
                            />
                        </div>
                    </motion.div>

                    {/* <motion.div className="max-w-7xl mx-auto" initial={{ opacity: 0, y: 50 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true, amount: 0.2 }}
                        transition={{ duration: 0.5 }}>
                        <div>
                            <img
                                src={img2}
                                alt="Featured story"
                                className="w-full"
                            />
                        </div>
                    </motion.div> */}
                </div>

                <motion.section className="relative py-12 common" initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true, amount: 0.2 }}
                    transition={{ duration: 0.5 }}>
                    <div className="max-w-3xl mx-auto flex flex-col items-start text-left space-y-4">
                        <h3 className="text-2xl md:text-4xl text-[#000000] lg-text">
                            Made Custom Visual Icon for <br />
                            fitting the style directions
                        </h3>
                        <span className="text-[#676767] text-lg">
                            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Provident in doloremque officia dignissimos deleniti inventore porro possimus, suscipit natus. Commodi iure numquam facilis itaque?
                        </span>
                    </div>
                </motion.section>

                <motion.div className="max-w-7xl mx-auto" initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true, amount: 0.2 }}
                    transition={{ duration: 0.5 }}>
                    <div>
                        <img
                            src={img4}
                            alt="Featured story"
                            className="w-full"
                        />
                    </div>
                </motion.div>

                {/* First Text Section */}
                <motion.section className="relative py-12 common" initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true, amount: 0.2 }}
                    transition={{ duration: 0.5 }}>
                    <div className="max-w-3xl mx-auto flex flex-col items-start text-left space-y-4">
                        <h3 className="text-2xl md:text-4xl text-[#000000] lg-text">
                            Simple & clean, user-friendly design.
                        </h3>
                        <span className="text-[#676767] text-lg">
                            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Provident in doloremque officia dignissimos deleniti inventore porro possimus, suscipit natus. Commodi iure numquam facilis itaque?
                        </span>
                    </div>
                </motion.section>

                <motion.div className="max-w-7xl mx-auto " initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true, amount: 0.2 }}
                    transition={{ duration: 0.5 }}>
                    <div>
                        <img
                            src={img5}
                            alt="Featured story"
                            className="w-full"
                        />
                    </div>
                </motion.div>

                <motion.section className="relative py-12 common" initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true, amount: 0.2 }}
                    transition={{ duration: 0.5 }}>
                    <div className="max-w-3xl mx-auto flex flex-col items-start text-left space-y-4">
                        <h3 className="text-2xl md:text-4xl text-[#272666] lg-text">
                        Lorem Ipsum dolor sit amet consectetur 
                        </h3>
                        <span className="text-[#676767] text-lg">
                        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Provident in doloremque officia dignissimos deleniti inventore porro possimus, suscipit natus. Commodi iure numquam facilis itaque?
                        </span>
                    </div>
                </motion.section>

                <motion.div className="max-w-7xl mx-auto " initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true, amount: 0.2 }}
                    transition={{ duration: 0.5 }}>
                    <div>
                        <img
                            src={img6}
                            alt="Featured story"
                            className="w-full"
                        />
                    </div>
                </motion.div>






            </section>
            {/* <WireFrame /> */}
            <ShiporaWireframe />

            {/* <section className="relative px-4 project">
     
                <motion.div className="max-w-7xl mx-auto" initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true, amount: 0.2 }}
                    transition={{ duration: 0.5 }}>
                    <div>
                        <img
                            src={bg_wireframe}
                    

                            alt="Featured story"
                            className="w-full"
                        />
                    </div>
                </motion.div>

   
                <motion.section className="relative py-12 common" initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true, amount: 0.2 }}
                    transition={{ duration: 0.5 }}>
                    <div className="max-w-3xl mx-auto flex flex-col items-start text-left space-y-4">
                        <h3 className="text-2xl md:text-4xl text-[#292929] lg-text">
                            Sindhi Soulmate Brand Guidelines
                        </h3>
                        <span className="text-[#676767] text-lg">
                            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Provident in doloremque officia dignissimos deleniti inventore porro possimus, suscipit natus. Commodi iure numquam facilis itaque?
                        </span>
                    </div>
                </motion.section>

                <motion.div className="max-w-7xl mx-auto" initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true, amount: 0.2 }}
                    transition={{ duration: 0.5 }}>
                    <div>
                        <img
                            src={sindhi_soulmate}
                            alt="Featured story"
                            className="w-full"
                        />
                    </div>
                </motion.div>
            </section> */}

            {/* <WireFrame /> */}


            {/* <motion.section className="relative px-4 " initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true, amount: 0.2 }}
                transition={{ duration: 0.5 }}>


           
                <section className="relative common">
                    <div className="max-w-3xl mx-auto flex flex-col items-start text-left space-y-4">
                        <h3 className="text-2xl md:text-4xl text-[#272666] lg-text">
                            Lorem Ipsum dolor sit amet consectetur
                        </h3>
                        <span className="text-[#676767] text-lg">
                            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Provident in doloremque officia dignissimos deleniti inventore porro possimus, suscipit natus. Commodi iure numquam facilis itaque?
                        </span>

                 
                    </div>
                </section>

            </motion.section> */}

            <ShiporaSteps />

            <motion.section className="relative px-4 pb-16" initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true, amount: 0.2 }}
                transition={{ duration: 0.5 }}>


                <section className="relative common">
                    <div className="max-w-3xl mx-auto flex flex-col items-start text-left space-y-4">
                        <h3 className="text-2xl md:text-4xl text-[#272666] lg-text">
                            Lorem Ipsum dolor sit amet consectetur
                        </h3>
                        <span className="text-[#676767] text-lg">
                            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Provident in doloremque officia dignissimos deleniti inventore porro possimus, suscipit natus. Commodi iure numquam facilis itaque?
                        </span>

                        <span className="text-[#676767] text-lg">
                            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Provident in doloremque officia dignissimos deleniti inventore porro possimus, suscipit natus. Commodi iure numquam facilis itaque?
                        </span>
                    </div>
                </section>

            </motion.section>

            <section className='px-4'>

                <motion.div className="max-w-7xl mx-auto" initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true, amount: 0.2 }}
                    transition={{ duration: 0.5 }}>
                    <div>
                        <img
                            src={img9}
                            // src={img5}

                            alt="Featured story"
                            className="w-full"
                        />
                    </div>
                </motion.div>
            </section>
            <ShiporaScrollComp />

            <section className='px-4'>

                <motion.div className="max-w-7xl mx-auto " initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true, amount: 0.2 }}
                    transition={{ duration: 0.5 }}>
                    <div>
                        <img
                            src={img11}
                            alt="Featured story"
                            className="w-full"
                        />
                    </div>
                </motion.div>

                <motion.section className="relative py-12 common" initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true, amount: 0.2 }}
                    transition={{ duration: 0.5 }}>
                    <div className="max-w-7xl mx-auto flex flex-col items-start text-left space-y-4">
                        <h3 className="text-2xl md:text-4xl text-[#272666] lg-text">
                            Lorem Ipsum dolor sit amet consectetur
                        </h3>
                        <span className="text-[#676767] text-lg">
                            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Provident in doloremque officia dignissimos deleniti inventore porro possimus, suscipit natus. Commodi iure numquam facilis itaque?
                        </span>

                        <span className="text-[#676767] text-lg">
                            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Provident in doloremque officia dignissimos deleniti inventore porro possimus, suscipit natus. Commodi iure numquam facilis itaque?
                        </span>
                    </div>
                </motion.section>

                <motion.div className="max-w-7xl mx-auto " initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true, amount: 0.2 }}
                    transition={{ duration: 0.5 }}>
                    <div>
                        <img
                            src={img7}
                            alt="Featured story"
                            className="w-full"
                        />
                    </div>
                </motion.div>


                <motion.section className="relative py-12 common" initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true, amount: 0.2 }}
                    transition={{ duration: 0.5 }}>
                    <div className="max-w-7xl mx-auto flex flex-col items-start text-left space-y-4">
                        <h3 className="text-2xl md:text-4xl text-[#272666] lg-text">
                            Lorem Ipsum dolor sit amet consectetur
                        </h3>
                        <span className="text-[#676767] text-lg">
                            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Provident in doloremque officia dignissimos deleniti inventore porro possimus, suscipit natus. Commodi iure numquam facilis itaque?
                        </span>

                        <span className="text-[#676767] text-lg">
                            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Provident in doloremque officia dignissimos deleniti inventore porro possimus, suscipit natus. Commodi iure numquam facilis itaque?
                        </span>
                    </div>
                </motion.section>

                <motion.div className="max-w-7xl mx-auto " initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true, amount: 0.2 }}
                    transition={{ duration: 0.5 }}>
                    <div>
                        <img
                            src={img10}
                            alt="Featured story"
                            className="w-full"
                        />
                    </div>
                </motion.div>

                <motion.section className="relative py-12 common" initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true, amount: 0.2 }}
                    transition={{ duration: 0.5 }}>
                    <div className="max-w-7xl mx-auto flex flex-col items-start text-left space-y-4">
                        <h3 className="text-2xl md:text-4xl text-[#272666] lg-text">
                            Lorem Ipsum dolor sit amet consectetur
                        </h3>
                        <span className="text-[#676767] text-lg">
                            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Provident in doloremque officia dignissimos deleniti inventore porro possimus, suscipit natus. Commodi iure numquam facilis itaque?
                        </span>

                        <span className="text-[#676767] text-lg">
                            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Provident in doloremque officia dignissimos deleniti inventore porro possimus, suscipit natus. Commodi iure numquam facilis itaque?
                        </span>
                    </div>
                </motion.section>

                <motion.div className="max-w-7xl mx-auto " initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true, amount: 0.2 }}
                    transition={{ duration: 0.5 }}>
                    <div>
                        <img
                            src={img12}
                            alt="Featured story"
                            className="w-full"
                        />
                    </div>
                </motion.div>

                <motion.section className="relative pt-12 common" initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true, amount: 0.2 }}
                    transition={{ duration: 0.5 }}>
                    <div className="max-w-7xl mx-auto flex flex-col items-start text-left space-y-4">
                        <h3 className="text-2xl md:text-4xl text-[#272666] lg-text">
                            Lorem Ipsum dolor sit amet consectetur
                        </h3>
                        <span className="text-[#676767] text-lg">
                            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Provident in doloremque officia dignissimos deleniti inventore porro possimus, suscipit natus. Commodi iure numquam facilis itaque?
                        </span>

                        <span className="text-[#676767] text-lg">
                            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Provident in doloremque officia dignissimos deleniti inventore porro possimus, suscipit natus. Commodi iure numquam facilis itaque?
                        </span>
                    </div>
                </motion.section>

            </section>

            <PageInfo />

            <section className='px-4'>
                <motion.div className="max-w-7xl mx-auto " initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true, amount: 0.2 }}
                    transition={{ duration: 0.5 }}>
                    <div>
                        <img
                            src={img15}
                            alt="Featured story"
                            className="w-full"
                        />
                    </div>
                </motion.div>

                <motion.section className="relative pt-12 common" initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true, amount: 0.2 }}
                    transition={{ duration: 0.5 }}>
                    <div className="max-w-7xl mx-auto flex flex-col items-start text-left space-y-4">
                        <h3 className="text-2xl md:text-4xl text-[#272666] lg-text">
                            Lorem Ipsum dolor sit amet consectetur
                        </h3>
                        <span className="text-[#676767] text-lg">
                            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Provident in doloremque officia dignissimos deleniti inventore porro possimus, suscipit natus. Commodi iure numquam facilis itaque?
                        </span>

                        <span className="text-[#676767] text-lg">
                            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Provident in doloremque officia dignissimos deleniti inventore porro possimus, suscipit natus. Commodi iure numquam facilis itaque?
                        </span>
                    </div>
                </motion.section>


            </section>


            <FinalInfo/>


            {/* <motion.section className="relative px-4 pb-16"   initial={{ opacity: 0, y: 50 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true, amount: 0.2 }}
                        transition={{ duration: 0.5 }}>


                <section className="relative common">
                    <div className="max-w-3xl mx-auto flex flex-col items-start text-left space-y-4">
                        <h3 className="text-2xl md:text-4xl text-[#272666] lg-text">
                            Lorem Ipsum dolor sit amet consectetur
                        </h3>
                        <span className="text-[#676767] text-lg">
                            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Provident in doloremque officia dignissimos deleniti inventore porro possimus, suscipit natus. Commodi iure numquam facilis itaque?
                        </span>

                        <span className="text-[#676767] text-lg">
                            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Provident in doloremque officia dignissimos deleniti inventore porro possimus, suscipit natus. Commodi iure numquam facilis itaque?
                        </span>
                    </div>
                </section>

            </motion.section> */}
            
            <section className='px-4'>
               

                <motion.div className="max-w-6xl mx-auto pb-16"   initial={{ opacity: 0, y: 50 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true, amount: 0.2 }}
                        transition={{ duration: 0.5 }}>


                    <div className="space-y-10">
                        <div className="grid grid-cols-1 md:grid-cols-[0.5fr_2.5fr] gap-4 items-start">
                    
                            <h2 className="text-2xl  text-[#000000] font-normal">The Result</h2>

                            <div className="space-y-4">
                                <p className="text-[#676767] text-lg">
                                    Lorem ipsum dolor, sit amet consectetur adipisicing elit. Provident in doloremque officia dignissimos
                                    deleniti inventore porro possimus, suscipit natus. Commodi iure voluptate voluptatum quo ducimus eaque error
                                    numquam facilis itaque?
                                </p>
                                <p className="text-[#676767] text-lg">
                                    Lorem ipsum dolor, sit amet consectetur adipisicing elit. Provident in doloremque officia dignissimos
                                    deleniti inventore porro possimus, suscipit natus. Commodi iure voluptate voluptatum quo ducimus eaque error
                                    adipisicing elit. Provident in doloremque i iure voluptate voluptatum quo ducimus eaque error numquam
                                    facilis itaque?
                                </p>

                                <p className="text-[#676767] text-lg">
                                    Lorem ipsum dolor, sit amet consectetur adipisicing elit. Provident in doloremque officia dignissimos
                                    deleniti inventore porro possimus, suscipit natus. Commodi iure voluptate voluptatum quo ducimus eaque error
                                    adipisicing elit. Provident in doloremque i iure voluptate voluptatum quo ducimus eaque error numquam
                                    facilis itaque?
                                </p>
                            </div>
                        </div>



                    </div>

                </motion.div>
            </section>
        <TechUsed />
            <WorkCards />

            <CTMSection /> 




        </>

    )
}

export default ShiporaOverview
