
import { TechMobile } from "../../data/techStackMobile";
import { useState } from "react";

import { motion } from "framer-motion";
import left_shadow from "../../assets/images/MobileDevPage/Tech/left_shadow.svg"
import right_shadow from "../../assets/images/MobileDevPage/Tech/right_shadow.svg"

import { techstackai } from "../../data/techStackAI";
import "./MobileDevTech.css"
export default function MobileDevTech() {


    const [hoveredIndex, setHoveredIndex] = useState(null);


    return (
        <section className="py-0 sm:py-16 px-4  mobile-tech relative" initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true, amount: 0.2 }}
            transition={{ duration: 0.5 }} >

            <img
                src={left_shadow}
                alt="Left Shadow"
                className="absolute top-0 left-0  w-auto h-full z-0"
            />


            <img
                src={right_shadow}
                alt="Right Shadow"
                className="absolute top-0 right-0 w-auto h-full z-0"
            />
            {/* <motion.div className="text-center mb-12" initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true, amount: 0.2 }}
                transition={{ duration: 0.5 }} >
                <h2 className="text-3xl md:text-5xl font-medium text-[#212A40] mb-4 poppins lg-text">Tech-Stack we use</h2>
                <p className="text-[#485E79] max-w-2xl text-lg mx-auto">
                    From front-end to back-end, we utilize the latest tools and frameworks to deliver seamless, high-quality digital experiences.

                </p>
            </motion.div> */}

            <motion.div className="text-center mb-14 max-w-7xl mx-auto relative" initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true, amount: 0.2 }}
                transition={{ duration: 0.5 }}
            >

                {/* <h2 className="text-3xl md:text-5xl font-medium text-[#020710] mb-4  lg-text max-w-4xl mx-auto"></h2> */}

                <motion.div className="inline-flex items-center gap-4  mb-4 " initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true, amount: 0.2 }}
                    transition={{ duration: 0.5 }}  >
                    <span className="bg-gradient-to-b from-[#25B57E] to-[#2BAAE2] text-transparent bg-clip-text font-bold text-base">
                        INTEGRATION
                    </span>

                    <div className="w-16 h-[2px] bg-[#1DBB91]"></div>
                </motion.div>
                <h2 className="text-3xl md:text-4xl font-bold text-[#0F0F0F] mb-4   max-w-2xl mx-auto lg-text">   Seamless Connections with Your Favorite Apps</h2>
                <p className="text-[#4B4B57] max-w-xl text-lg mx-auto">
                    Empowering you to take charge of your financial future with intuitive tools and personalized insights.
                </p>
            </motion.div>



            <>
                <div className="space-y-5 relative max-w-7xl mx-auto" initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true, amount: 0.2 }}
                    transition={{ duration: 0.5 }} >



                    <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-4 gap-8 justify-items-center " initial={{ opacity: 0, y: 50 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true, amount: 0.2 }}
                        transition={{ duration: 0.5 }} >


                        {TechMobile.map((tech, index) => (
                            <div
                                key={index}
                                className={`flex flex-col cursor-pointer items-center justify-center group bg-white shadow-[0px_0px_3px_rgba(60,72,88,0.15)] 
                                      rounded-lg p-4 transition-all duration-300 
                                      hover:bg-gradient-to-b from-[#1DBB91] to-[#2BAAE2]`}
                                onMouseEnter={() => setHoveredIndex(index)}
                                onMouseLeave={() => setHoveredIndex(null)}
                            >

                                <div className="w-32 lg:w-40 h-20 mb-3 flex items-center justify-center transition-transform duration-300 hover:-translate-y-2">
                                    <img
                                        src={hoveredIndex === index && tech.hover ? tech.hover : tech.logo || "/placeholder.svg"}

                                        alt={`${tech.name} logo`}
                                        className={`object-contain transition-all duration-300
          ${["Webflow", "Node Js", "Scikit-Learn"].includes(tech.name) ? "" : "w-14"} 
          ${["Sanity", "Angular", "PostgreSQL", "Scikit-Learn"].includes(tech.name) ? "" : "group-hover:filter group-hover:brightness-0 group-hover:invert"}
        `}
                                    />
                                </div>
                                <span className="text-xl text-[#3C4858] text-center font-600 group-hover:text-[#FFFFFFCC] transition-colors duration-300">{tech.name}</span>
                            </div>
                        ))}


                    </div>


                </div>


            </>






















        </section>
    )
}

