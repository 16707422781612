import React from "react";
import { useState, useEffect } from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer/Footer";

import "./TermsAndCondition.css"

import CTMSection from "../../components/CTMSection/CTMSection";
import TermsComp from "../../components/TermsAndCondition/TermsComp";




function TermsAndCondition() {


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    const [isOpen, setIsOpen] = useState(false)

    return (



        <div className="relative flex min-h-screen py-10 flex-col bg-white">

            <main className="flex-1 relative " style={{ zIndex: "999" }}>

                <div className="w-full relative">
                    <Navbar isOpen={isOpen} setIsOpen={setIsOpen} />




                </div>


                <TermsComp/>
                <CTMSection/>


               




                <Footer />


            </main>
        </div>


    );
}

export default TermsAndCondition;
