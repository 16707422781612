import React from 'react';
import "./BreadCrumbService.css";
import overlay_blog from "../../assets/images/blog-overlay.svg"


import { motion } from 'framer-motion';

const BreadCrumbService = ({ data }) => {
    return (

        <>


            <motion.section className="py-14 px-4 text-white text-center breadcrumb-srvc relative overflow-hidden -z-10 pointer-events-none" initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true, amount: 0.2 }}
                transition={{ duration: 0.5 }} >
                <div className="max-w-7xl mx-auto gradient-header rounded-2xl pb-20 pt-20 sm:pt-9 min-h-[500px] md:min-h-[580px] px-4 relative" style={{ borderRadius: "48px" }}>
                    {/* <div className="flex flex-col sm:flex-row sm:justify-between items-center sm:items-center h-full px-4 sm:px-6 lg:px-2">

                        <div className="mb-6 sm:mb-0">
                            <h2 className="text-2xl sm:text-4xl md:text-5xl font-medium text-white poppins lg:text-6xl text-center sm:text-left">
                                {data[0].title}
                            </h2>
                        </div>

                  
                        <div className="text-center sm:text-left">
                            <p className="text-white max-w-3xl text-base sm:text-lg md:text-xl mx-auto sm:mx-0 manrope">
                                {data[0].description}
                            </p>
                        </div>
                    </div> */}

                    <div className="grid grid-cols-1 lg:grid-cols-2 items-center h-full px-4 md:px-6 lg:px-2">

                        <div className="mb-6 md:mb-8  w-[100%] lg:mb-0 lg:w-[80%]">
                            <h2 className="text-3xl sm:text-4xl md:text-5xl font-medium text-white poppins lg:text-6xl text-center lg:text-left">
                                {data[0].title}
                            </h2>
                        </div>

                        {/* Right Column: Paragraph */}
                        <div className="text-center lg:text-left">
                            <p className="text-white max-w-3xl text-base sm:text-lg md:text-xl mx-auto lg:mx-0 manrope">
                                {data[0].description}
                            </p>
                        </div>

                    </div>


                    {/* Image container */}
                    <div className="absolute bottom-[-16px] left-1/2 transform -translate-x-1/2 p-4 z-10" style={{ width: "90%" }}>
                        <img
                            src={data[0]?.img}
                            alt="Product Development"
                            className="w-[300px] sm:w-[400px] md:w-[500px] lg:w-[580px] h-auto object-contain mx-auto"
                        />
                    </div>

                </div>

                {/* Adding the product development image at the bottom */}


                <img src={overlay_blog} alt="Blog Overlay" className="absolute inset-0 w-full h-full object-cover opacity-100 overlay-animation-breadcrumb " />
            </motion.section>




        </>

    );
};

export default BreadCrumbService;
