import NotFoundImg from "../../assets/images/thankyou/thankyouimg.svg";
import bg_image from "../../assets/images/thankyou/bg-thank.png"
import { Link } from "react-router-dom";


const ThankYouPage = () => {
    return (
        <div className="flex flex-col items-center justify-center min-h-screen text-center px-4"  style={{ backgroundImage: `url(${bg_image})`, backgroundSize: "cover", backgroundPosition: "center" }}>
            <img src={NotFoundImg} alt="404 Not Found" className="w-16 sm:w-24 md:w-32 mb-6" />
            <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-semibold mb-6 text-[#0C0C0C]">Thank You for Reaching Out!</h1>
            <p className="text-base sm:text-lg text-[#666666] mb-6 max-w-3xl">
            We've received your request and will get in touch with you soon. Our team is reviewing your details to provide the best possible solution. Stay connected—we'll be in touch shortly!
            </p>




            <Link to={"/"}>
                <button

                    className={`text-white px-12 py-3 text-sm sm:text-base font-semibold consultation-btn rounded-full  `}



                >
               Back to Home
                </button>
            </Link>
        </div>
    );
};

export default ThankYouPage;
