import React from "react";

import { HeroSection } from "../../components/HeroSection";
import Navbar from "../../components/Navbar";




import { useState } from "react";

import { useEffect } from "react";

import Footer from "../../components/Footer/Footer";
import "./ApplyJob.css"
import CTMSection from "../../components/CTMSection/CTMSection";
import FAQsWork from "../../components/FAQsWork/FAQsWork";
import CareerHero from "../../components/CareerHero/CareerHero";
import CareerWorking from "../../components/CareerWorking/CareerWorking";
import CareerOpen from "../../components/CareerOpen/CareerOpen";
import JobReq from "../../components/JobReq/JobReq";
import JobApplicationForm from "../../components/ApplyJobForm/ApplyJobForm";

import { useMediaQuery } from "@mui/material";
import CTMPricing from "../../components/CTMSection/CTMPricing";




function ApplyJob() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [isOpen, setIsOpen] = useState(false)

    const isDesktop = useMediaQuery('(min-width:1024px)');
    return (



        <div className="relative flex min-h-screen py-10 flex-col bg-[#ffff]">



            <main className="flex-1">

                <div className="w-full relative" style={{ zIndex: "999" }}>
                    <Navbar isOpen={isOpen} setIsOpen={setIsOpen} />



                   
                    <JobReq />



                </div>

               

                <svg xmlns="http://www.w3.org/2000/svg" width="400px"  viewBox="0 0 500 351"
                        className="absolute  -right-7 top-[0]  hidden  d-vis"
                        style={{zIndex:"0",}}
                    >
                        <path
                            d="M 486.864 13.61 C 390.545 194.927 248.175 159.17 204.087 125.947 C 158.968 91.947 218.97 66.259 287.488 106.239 C 356.006 146.219 355.756 187.164 337.472 207.033 C 288.765 259.964 159.719 192.438 143.713 210.412 C 138.097 216.718 190.328 248.139 220.094 267.002 C 157.192 262.78 57.785 292.624 13.136 337.39"
                            stroke="url(#gradient1)"
                            stroke-width="26.27"
                            stroke-linejoin="round"
                            stroke-linecap="round"
                            fill="transparent"
                            opacity="1"
                            pathLength="1"
                            stroke-dashoffset="1"
                            stroke-dasharray="1px 1px">




                            <animate
                                attributeName="stroke-dashoffset"
                                from="1"
                                to="0"
                                dur="3s"
                                fill="freeze" />
                        </path>
                        <defs>

                            <linearGradient id="gradient1" x1="100%" y1="0%" x2="100%" y2="100%">
                                <stop offset="0%" stop-color="#2BAAE2" />
                                <stop offset="100%" stop-color="#24B574" />
                            </linearGradient>
                        </defs>
                    </svg>




                <JobApplicationForm />

                {/* <CareerWorking/>
                <CareerOpen/>




                

      
 */}
                <FAQsWork />

                <CTMPricing />
                <Footer />







            </main>
        </div>


    );
}

export default ApplyJob;
