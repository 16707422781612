import React from 'react'
import { motion } from 'framer-motion'
import "./WebDevComp.css"
import begin_img from "../../../assets/images/Services/AIDev/begin_img.png"
import react_img from "../../../assets/images/Services/WebApp/react.svg"
import angular_img from "../../../assets/images/Services/WebApp/angular.svg"
import nodejs_img from "../../../assets/images/Services/WebApp/nodejs.svg"
import { img } from 'framer-motion/client'
import cursor_img from "../../../assets/images/Services/WebApp/cursor.svg"
import custom_web_Dev from "../../../assets/images/Services/WebApp/custom_Web_Dev.png"


const KeyFrameWorks = [
    {


        text: "React.js  Dynamic, responsive user interfaces with fast load times.",
        img: react_img,
    },
    {

        text: `Angular A complete, scalable framework for enterprise-level apps.`,
        img: angular_img,
    },
    {

        text: "Node.js & Express Backend solutions for fast, scalable, and real-time applications.",
        img: nodejs_img,
    },

]


const FrameWorks = () => {
    return (

        <section className="relative  py-0 md:py-16 px-4 ">


            <div className="max-w-7xl mx-auto">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center p-0 lg:p-4 framework">

                    <motion.div
                        className="space-y-8 flex flex-col h-full relative group justify-center "
                        initial={{ opacity: 0, y: 50 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true, amount: 0.2 }}
                        transition={{ duration: 0.5 }}
                    >

                        <img src={custom_web_Dev} alt="" />



                    </motion.div>


                    <motion.div className="flex flex-col h-full justify-center" initial={{ opacity: 0, y: 50 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true, amount: 0.2 }}
                        transition={{ duration: 0.5 }}>
                        <div className="space-y-7">

                            <span className="font-medium text-[#333333]  px-3 py-2  gap-1  rounded-full border border-[#EDEFF3] bg-[#F8F9FA] text-sm flex" style={{ display: "inline-flex" }}>

                                <img src={cursor_img} alt="" className="w-5 h-5" />

                                <span >Free Trial</span>

                            </span>
                            <h3 className="text-2xl md:text-4xl font-medium text-[#0C0C0C] lg-text">
                                Custom <span className='text-[#25B57E]'> Web Development </span>with Modern Frameworks
                            </h3>


                            <p className=" text-[#666666] text-base" >

                                We build high-performance, scalable web applications using the latest JavaScript frameworks. From dynamic single-page applications (SPAs) to complex, feature-rich web apps, we craft solutions tailored to your business requirements. Our team ensures seamless user experiences and robust performance, powered by
                            </p>

                            {/* <div className="space-y-4">
                                {keyFeatures.map((feature, index) => (
                                    <div key={index} className="flex items-center gap-3 text-gray-700 rounded-full border border-[#E6E9EE] p-2" style={{ display: "inline-flex" }}>

                                        <img src={verify_icon} alt="" className="w-5 h-5" />
                                        <span style={{ fontSize: "15.51px", lineHeight: '23.26px', color: "#333333" }}>{feature.text}</span>
                                    </div>
                                ))}
                            </div> */}

                            <div className="space-y-4">

                                {KeyFrameWorks.map((feature, index) => (

                                    <div className="flex items-center gap-3 text-[#3D3D3D] rounded-full border border[#E6E9EE] p-2 font-medium text-sm" style={{ display: "inline-flex" }}>

                                        <img src={feature?.img} alt="" className="w-5 h-5" />

                                        <span >{feature?.text}</span>
                                    </div>

                                ))}

                            </div>




                        </div>
                    </motion.div>

                </div>
            </div>
        </section>
    )
}

export default FrameWorks
