import React from 'react'

import { motion } from "framer-motion";

import ig1 from "../../../assets/images/Services/AIDev/team_exp.png"
import ig2 from "../../../assets/images/Services/AIDev/tailored.png"
import ig3 from "../../../assets/images/Services/AIDev/proven.png"



const Expertise = () => {

    const technologies = [
        // Row 1
        {
            name: "Team Experience",
            logo: ig1,
            description: "Showcase the experience of your team, including key skills, certifications, and successful projects."
        },
        {
            name: "Tailored Solutions",
            logo: ig2,
            description: "Emphasize that your AI solutions are customized to meet the unique needs of your clients."

        },

        {
            name: "Proven Results",
            logo: ig3,
            description: "Highlight the tangible benefits clients can expect (e.g., increased efficiency)."

        },

       


    ]
    return (

        <section className="relative py-0 sm:py-16 px-4 about-comp">


            <div className="max-w-7xl mx-auto">

                <motion.div className="text-center mb-12 max-w-5xl mx-auto" initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true, amount: 0.2 }}
                    transition={{ duration: 0.5 }}
                >
                    <h2 className="text-3xl md:text-5xl font-medium text-[#212A40] mb-4 poppins lg-text">
                        Our <span className="text-[#24B574]">Expertise</span>  and Our <span className="text-[#24B574]">Approach</span>  </h2>
                    <p className="text-[#485E79] max-w-2xl text-lg mx-auto">
                        Lorem ipsum dolor sit amet consectetur, adipisicing elit A, ab nemo! Expedita!Lorem ipsum dolor sit amet consectetur
                    </p>
                </motion.div>
















                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 justify-items-center " initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true, amount: 0.2 }}
                    transition={{ duration: 0.5 }}
                >

                    {/* relative rounded-xl overflow-hidden bg-gradient box-shadow-blog cursor-pointer  */}
                    {technologies.map((tech, index) => (
                        <motion.div
                            key={index}
                            className=" cursor-pointer   bg-white  rounded-3xl p-5  border border-[#F2F3F8] w-full"
                            initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true, amount: 0.2 }}
                    transition={{ duration: 0.5 }}
                        >

                            <div className='py-4 '>

                                <p className="text-lg md:text-2xl text-[#171717] text-center font-medium">{tech.name}</p>
                                <p className="text-center  text-[#7A8089] my-3" style={{fontSize:"15px"}}>{tech.description}</p>

                            </div>
                            <div className="overflow-hidden p-1 ">
                                <img
                                    src={tech.logo || "/placeholder.svg"}
                                    alt={`${tech.name} logo`}
                                    // className="object-contain group-hover:filter group-hover:brightness-0 group-hover:invert"
                                    className={`w-full h-full object-cover 
                                
                                
                                
                               `}
                                />
                            </div>

                        </motion.div>
                    ))}


                </div>





            </div>
        </section>
    )
}

export default Expertise
