import React from 'react'
import explore from "../../assets/images/WorkDetails/SindhiSoulmate/plans.svg"
import color from "../../assets/images/WorkDetails/SindhiSoulmate/color.svg"
import img1 from "../../assets/images/WorkDetails/SindhiSoulmate/Frame 1000003737.svg"
import img2 from "../../assets/images/WorkDetails/SindhiSoulmate/Frame 1000003739.svg"
import img3 from "../../assets/images/WorkDetails/SindhiSoulmate/Frame 1000003740.svg"
import img4 from "../../assets/images/WorkDetails/SindhiSoulmate/Container.svg"
import WireFrame from './WireFrame'
import group_img from "../../assets/images/WorkDetails/SindhiSoulmate/Frame 1000003751.webp"

import sindhi_soulmate from "../../assets/images/WorkDetails/SindhiSoulmate/singhi_soulmate.webp"
import bg_wireframe from "../../assets/images/WorkDetails/SindhiSoulmate/wireframe.webp"
import bg_img2 from "../../assets/images/WorkDetails/SindhiSoulmate/Frame 1000003747.webp"



import AccountSteps from './AppSteps'
import ScrollComp from './ScrollComp'
import TechUsed from './TechUsed'
import WorkCards from './WorkCards'
import CTMSection from '../CTMSection/CTMSection'

import { motion } from "framer-motion";



const ProjectOverveiw = () => {
    return (

        <>
            <section className="relative px-4 project">
                {/* First Image Section */}
                <motion.div className="max-w-7xl mx-auto" initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true, amount: 0.2 }}
                    transition={{ duration: 0.5 }}>
                    <div>
                        <img
                            src={explore}
                            alt="Featured story"
                            className="w-full"
                        />
                    </div>
                </motion.div>

                {/* First Text Section */}
                <motion.section className="relative py-12 common" initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true, amount: 0.2 }}
                    transition={{ duration: 0.5 }}>
                    <div className="max-w-3xl mx-auto flex flex-col items-start text-left space-y-4">
                        <h3 className="text-2xl md:text-4xl text-[#292929] lg-text">
                            Sindhi Soulmate Brand Guidelines
                        </h3>
                        <span className="text-[#676767] text-lg">
                            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Provident in doloremque officia dignissimos deleniti inventore porro possimus, suscipit natus. Commodi iure numquam facilis itaque?
                        </span>
                    </div>
                </motion.section>

                <div className=' space-y-8'>

                    <motion.div className="max-w-7xl mx-auto" initial={{ opacity: 0, y: 50 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true, amount: 0.2 }}
                        transition={{ duration: 0.5 }}>
                        <div>
                            <img
                                src={color}
                                alt="Featured story"
                                className="w-full"
                            />
                        </div>
                    </motion.div>

                    <motion.div className="max-w-7xl mx-auto" initial={{ opacity: 0, y: 50 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true, amount: 0.2 }}
                        transition={{ duration: 0.5 }}>
                        <div>
                            <img
                                src={img1}
                                alt="Featured story"
                                className="w-full"
                            />
                        </div>
                    </motion.div>

                    <motion.div className="max-w-7xl mx-auto" initial={{ opacity: 0, y: 50 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true, amount: 0.2 }}
                        transition={{ duration: 0.5 }}>
                        <div>
                            <img
                                src={img2}
                                alt="Featured story"
                                className="w-full"
                            />
                        </div>
                    </motion.div>
                </div>

                <motion.section className="relative py-12 common" initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true, amount: 0.2 }}
                    transition={{ duration: 0.5 }}>
                    <div className="max-w-3xl mx-auto flex flex-col items-start text-left space-y-4">
                        <h3 className="text-2xl md:text-4xl text-[#272666] lg-text">
                            Made Custom Visual Icon for <br />
                            fitting the style directions
                        </h3>
                        <span className="text-[#676767] text-lg">
                            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Provident in doloremque officia dignissimos deleniti inventore porro possimus, suscipit natus. Commodi iure numquam facilis itaque?
                        </span>
                    </div>
                </motion.section>

                <motion.div className="max-w-7xl mx-auto" initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true, amount: 0.2 }}
                    transition={{ duration: 0.5 }}>
                    <div>
                        <img
                            src={img3}
                            alt="Featured story"
                            className="w-full"
                        />
                    </div>
                </motion.div>

                {/* First Text Section */}
                <motion.section className="relative py-12 common" initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true, amount: 0.2 }}
                    transition={{ duration: 0.5 }}>
                    <div className="max-w-3xl mx-auto flex flex-col items-start text-left space-y-4">
                        <h3 className="text-2xl md:text-4xl text-[#272666] lg-text">
                            Simple & clean, user-friendly design.
                        </h3>
                        <span className="text-[#676767] text-lg">
                            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Provident in doloremque officia dignissimos deleniti inventore porro possimus, suscipit natus. Commodi iure numquam facilis itaque?
                        </span>
                    </div>
                </motion.section>

                <motion.div className="max-w-7xl mx-auto" initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true, amount: 0.2 }}
                    transition={{ duration: 0.5 }}>
                    <div>
                        <img
                            src={img4}
                            alt="Featured story"
                            className="w-full"
                        />
                    </div>
                </motion.div>





            </section>
            <WireFrame />

            <section className="relative px-4 project">
                {/* First Image Section */}
                <motion.div className="max-w-7xl mx-auto" initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true, amount: 0.2 }}
                    transition={{ duration: 0.5 }}>
                    <div>
                        <img
                            src={bg_wireframe}
                            // src={img5}

                            alt="Featured story"
                            className="w-full"
                        />
                    </div>
                </motion.div>

                {/* First Text Section */}
                <motion.section className="relative py-12 common" initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true, amount: 0.2 }}
                    transition={{ duration: 0.5 }}>
                    <div className="max-w-3xl mx-auto flex flex-col items-start text-left space-y-4">
                        <h3 className="text-2xl md:text-4xl text-[#292929] lg-text">
                            Sindhi Soulmate Brand Guidelines
                        </h3>
                        <span className="text-[#676767] text-lg">
                            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Provident in doloremque officia dignissimos deleniti inventore porro possimus, suscipit natus. Commodi iure numquam facilis itaque?
                        </span>
                    </div>
                </motion.section>

                <motion.div className="max-w-7xl mx-auto" initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true, amount: 0.2 }}
                    transition={{ duration: 0.5 }}>
                    <div>
                        <img
                            src={sindhi_soulmate}
                            alt="Featured story"
                            className="w-full"
                        />
                    </div>
                </motion.div>
            </section>

            <WireFrame />


            <motion.section className="relative px-4 " initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true, amount: 0.2 }}
                transition={{ duration: 0.5 }}>


                {/* First Text Section */}
                <section className="relative common">
                    <div className="max-w-3xl mx-auto flex flex-col items-start text-left space-y-4">
                        <h3 className="text-2xl md:text-4xl text-[#272666] lg-text">
                            Lorem Ipsum dolor sit amet consectetur
                        </h3>
                        <span className="text-[#676767] text-lg">
                            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Provident in doloremque officia dignissimos deleniti inventore porro possimus, suscipit natus. Commodi iure numquam facilis itaque?
                        </span>

                        {/* <span className="text-[#676767] text-lg">
                        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Provident in doloremque officia dignissimos deleniti inventore porro possimus, suscipit natus. Commodi iure numquam facilis itaque?
                        </span> */}
                    </div>
                </section>

            </motion.section>

            <AccountSteps />

            <motion.section className="relative px-4 pb-16" initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true, amount: 0.2 }}
                transition={{ duration: 0.5 }}>


                <section className="relative common">
                    <div className="max-w-3xl mx-auto flex flex-col items-start text-left space-y-4">
                        <h3 className="text-2xl md:text-4xl text-[#272666] lg-text">
                            Lorem Ipsum dolor sit amet consectetur
                        </h3>
                        <span className="text-[#676767] text-lg">
                            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Provident in doloremque officia dignissimos deleniti inventore porro possimus, suscipit natus. Commodi iure numquam facilis itaque?
                        </span>

                        <span className="text-[#676767] text-lg">
                            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Provident in doloremque officia dignissimos deleniti inventore porro possimus, suscipit natus. Commodi iure numquam facilis itaque?
                        </span>
                    </div>
                </section>

            </motion.section>

            <section className='px-4'>

                <motion.div className="max-w-7xl mx-auto"   initial={{ opacity: 0, y: 50 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true, amount: 0.2 }}
                        transition={{ duration: 0.5 }}>
                    <div>
                        <img
                            src={bg_img2}
                            // src={img5}

                            alt="Featured story"
                            className="w-full"
                        />
                    </div>
                </motion.div>
            </section>
            <ScrollComp />

            <motion.section className="relative px-4 pb-16"   initial={{ opacity: 0, y: 50 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true, amount: 0.2 }}
                        transition={{ duration: 0.5 }}>


                <section className="relative common">
                    <div className="max-w-3xl mx-auto flex flex-col items-start text-left space-y-4">
                        <h3 className="text-2xl md:text-4xl text-[#272666] lg-text">
                            Lorem Ipsum dolor sit amet consectetur
                        </h3>
                        <span className="text-[#676767] text-lg">
                            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Provident in doloremque officia dignissimos deleniti inventore porro possimus, suscipit natus. Commodi iure numquam facilis itaque?
                        </span>

                        <span className="text-[#676767] text-lg">
                            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Provident in doloremque officia dignissimos deleniti inventore porro possimus, suscipit natus. Commodi iure numquam facilis itaque?
                        </span>
                    </div>
                </section>

            </motion.section>

            <section className='px-4'>
                <motion.div className="max-w-7xl mx-auto"   initial={{ opacity: 0, y: 50 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true, amount: 0.2 }}
                        transition={{ duration: 0.5 }}>
                    <div>
                        <img
                            src={group_img}
                            // src={img5}

                            alt="Featured story"
                            className="w-full"
                        />
                    </div>
                </motion.div>


                <motion.div className="max-w-6xl mx-auto py-16"   initial={{ opacity: 0, y: 50 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true, amount: 0.2 }}
                        transition={{ duration: 0.5 }}>


                    <div className="space-y-10">
                        <div className="grid grid-cols-1 md:grid-cols-[0.5fr_2.5fr] gap-4 items-start">
                            {/* Left Side - Heading */}
                            <h2 className="text-2xl  text-[#000000] font-normal">The Result</h2>

                            {/* Right Side - Description */}
                            <div className="space-y-4">
                                <p className="text-[#676767] text-lg">
                                    Lorem ipsum dolor, sit amet consectetur adipisicing elit. Provident in doloremque officia dignissimos
                                    deleniti inventore porro possimus, suscipit natus. Commodi iure voluptate voluptatum quo ducimus eaque error
                                    numquam facilis itaque?
                                </p>
                                <p className="text-[#676767] text-lg">
                                    Lorem ipsum dolor, sit amet consectetur adipisicing elit. Provident in doloremque officia dignissimos
                                    deleniti inventore porro possimus, suscipit natus. Commodi iure voluptate voluptatum quo ducimus eaque error
                                    adipisicing elit. Provident in doloremque i iure voluptate voluptatum quo ducimus eaque error numquam
                                    facilis itaque?
                                </p>

                                <p className="text-[#676767] text-lg">
                                    Lorem ipsum dolor, sit amet consectetur adipisicing elit. Provident in doloremque officia dignissimos
                                    deleniti inventore porro possimus, suscipit natus. Commodi iure voluptate voluptatum quo ducimus eaque error
                                    adipisicing elit. Provident in doloremque i iure voluptate voluptatum quo ducimus eaque error numquam
                                    facilis itaque?
                                </p>
                            </div>
                        </div>



                    </div>

                </motion.div>
            </section>
            <TechUsed />
            <WorkCards />

            <CTMSection />




        </>

    )
}

export default ProjectOverveiw
