import React from "react";
import { useState, useEffect } from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer/Footer";
import CTMSection from "../../components/CTMSection/CTMSection";
import TestimonialsSection from "../../components/Testimonials/Testimonials";
import Banner from "../../components/Services/CustomProductDev/Banner";
// import ai_dev from "../../assets/images/Services/CustomProduct/ai_dev.svg"
import ai_dev from "../../assets/images/Services/ai-dev.png"


import TechStackAlt from "../../components/TechStackAlt/TechStackAlt";
import "./AiDev.css"
import BreadCrumbService from "../../components/BreadCrumbService/BreadCrumbService";
import CustomSection from "../../components/Services/CustomProductDev/CustomSection";
import OtherServices from "../../components/Services/CustomProductDev/OtherServices";
import CustomCTM from "../../components/Services/CustomProductDev/CustomCTM";
import AIServices from "../../components/Services/AIDev/AIServices";
import BeginSection from "../../components/Services/AIDev/BeginSection";
import IndustriesWeServe from "../../components/Services/AIDev/IndustriesWeServe";
import Expertise from "../../components/Services/AIDev/Expertise";
import DevProcess from "../../components/Services/AIDev/DevProcess";
import { techstackai } from "../../data/techStackAI";
import Loader from "../../components/Loader1/Loader";
import WhyChooseAI from "../../components/Services/AIDev/WhyChooseAI";



function AiDev() {

    const data = [
        {
            title: "AI-Enabled Solutions",
            description: "Harness the power of AI to automate tasks, enhance decision-making, and improve efficiency. We build smart, data-driven solutions tailored to your business needs!",
            img: ai_dev,
        },

    ]



    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const [isOpen, setIsOpen] = useState(false)

    const [loading, setLoading] = useState(true); 


    useEffect(() => {
       const handleLoad = () => {
         setTimeout(() => setLoading(false), 1500); 
       };
     
       if (document.readyState === "complete") {
         setTimeout(() => setLoading(false), 1500);
       } else {
         window.addEventListener("load", handleLoad);
       }
     
       return () => window.removeEventListener("load", handleLoad);
     }, []);
     
 
     
     if (loading) {
       return <Loader />;
     }


    return (



        <div className="relative flex min-h-screen py-10 flex-col bg-white">

            <main className="flex-1 relative " style={{ zIndex: "999" }}>

                <div className="w-full relative">
                    <Navbar isOpen={isOpen} setIsOpen={setIsOpen} />




                </div>


                {/* <Banner/> */}
                <BreadCrumbService data={data} />
                <BeginSection />
                <IndustriesWeServe />

                <AIServices />









                <TechStackAlt technologies={techstackai} name="ai"/>
                <DevProcess />
       
                <WhyChooseAI />
                <CustomCTM />
                <Expertise />
       
                <OtherServices currentService="AI-Enabled Solutions" />
                <TestimonialsSection />


                <CTMSection />











                <Footer />


            </main>
        </div>


    );
}

export default AiDev;
