import React from "react";
import LandingPage from "./pages/LandingPage";
import { useEffect } from "react";
import { Routes, Route , Navigate} from "react-router-dom";
import { useLocation } from "react-router-dom";
import CardsList from "./Sample/CardTest";
import OurWork from "./pages/OurWork/OurWork";
import StackCard from "./components/StackCard";
import BlogPage from "./pages/Blogs/BlogPage";
import BlogDetails from "./pages/BlogDeatils/BlogDeatils";
import AboutPage from "./pages/About/AboutPage";
import Animatedcard from "./components/AnimatedCard/Animatedcard";
import ProjectDetails from "./components/ProjectDetails/ProjectDetails";
import WorkDetails from "./pages/WorkDetails/WorkDetails";
import JumpStackCard from "./components/JumpStackCard/JumpStackCard";
import CustomProducts from "./pages/CustomProductDevelopment/CustomProduct";
import AiDev from "./pages/AiDev/AiDev";
import MobileAppDev from "./pages/MobileAppDev/MobileAppDev";
import WebAppDev from "./pages/WebAppDev/WebAppDev";
import UiUxDesign from "./pages/UiUxDesign/UiUxDesign";
import QualityAssurance from "./pages/QualityAssurance/QualityAssurance";
import CmsDev from "./pages/CmsDev/CmsDev";
import Contact from "./pages/Contact/Contact";
import Services from "./pages/ServicesPage/Services";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import TermsAndCondition from "./pages/TermsAndCondition/TermsAndCondition";
import ExpandningCards from "./components/ExpandningCards/ExpandningCards";
import Fall from "./components/FallAnimation/Fall";
import PricingPage from "./pages/PricingPage/PricingPage";
import ShiporaPage from "./pages/ProjectPages/ShiporaPage/ShiporaPage";
import Loader from "./components/Loader1/Loader";
import Consult from "./components/ConsultationForm/Consult";
import PersonalDetails from "./components/ConsultationForm/PersonalDetails";
import SelectTimeline from "./components/ConsultationForm/SelectTimeline";
import CareerPage from "./pages/CareerPage/CareerPage";
import ApplyJob from "./pages/ApplyJob/ApplyJob";
import ParenticianPage from "./pages/ProjectPages/Parentician/ParenticianPage";
import NotFound from "./pages/404NotFound/NotFound";
import MobileDevPage from "./pages/MobileDevPage/MobileDevPage";
import CookieConsent from "./pages/CookiesConstent";
import ConsultationPopup from "./pages/MobileDevPage/ConsultationPopup";
import ThankYouPage from "./pages/ThankYouPage/ThankYouPage";




function App() {

  const location = useLocation()

  useEffect(() => {
    const allowedRoutes = ["/consult-us", "/personal-details", "/select-timeline"];
    if (!allowedRoutes.includes(location.pathname)) {
      localStorage.removeItem("step1");
      localStorage.removeItem("step2");
      localStorage.removeItem("step3");
      localStorage.removeItem("WhereTheyHear");
    }
  }, [location.pathname]); 

  return (



    <>

    <ConsultationPopup>

    {/* <CookieConsent/> */}
      <Routes>



        <Route path="/" element={<LandingPage />} />
        <Route path="/mobile-app-development-landing-page" element={<MobileDevPage />} />

        <Route path="/career" element={<CareerPage />} />
        <Route path="/404-not-found" element={<NotFound />} />
        <Route path="/thank-you" element={<ThankYouPage />} />


        <Route path="*" element={<Navigate to="/404-not-found" />} />
        

        <Route path="/work" element={<OurWork />} />
        <Route path="/blogs" element={<BlogPage />} />
        <Route path="/blogs/:slug" element={<BlogDetails />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/animate" element={<Animatedcard />} />

        <Route path="/projects/sindhi-soulmate" element={<WorkDetails />} />
        {/* <Route path="/project-details" element={<WorkDetails />} /> */}

        <Route path="/contact" element={<Contact />} />

        
        <Route path="/jump-stack" element={<JumpStackCard />} />
        <Route path="/services" element={<Services />} />

        


        
        <Route path="/saas-product-development" element={<CustomProducts />} />
        <Route path="/ai-enabled-solutions" element={<AiDev />} />
        <Route path="/mobile-app-development" element={<MobileAppDev />} />
        <Route path="/web-app-development" element={<WebAppDev />} />
        <Route path="/ui-ux-design" element={<UiUxDesign />} />
        <Route path="/quality-assurance" element={<QualityAssurance />} />
        <Route path="/cms-development" element={<CmsDev />} />
        <Route path="/consult-us" element={<Consult />} />
        <Route path="/personal-details" element={<PersonalDetails />} />
        <Route path="/select-timeline" element={<SelectTimeline />} />





        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-and-condition" element={<TermsAndCondition />} />
        <Route path="/pricing" element={<PricingPage />} />

        <Route path="/projects/shipora" element={<ShiporaPage />} />
        <Route path="/projects/parentician" element={<ParenticianPage />} />

        <Route path="/job" element={<ApplyJob />} />


        <Route path="/loader" element={<Loader />} />








        






        {/* <Route path="/stackcard" element={<StackCard />} /> */}







      </Routes>

      </ConsultationPopup>
    </>
  )
}

export default App;
