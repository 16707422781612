import { Description, AccessTime } from "@mui/icons-material"
import main_img from "../../assets/images/about/main_img.svg"
import "./OurMission.css"
import { motion } from "framer-motion";


export default function OurMissionAim() {
    const features = [
        {
            icon: <Description className="text-emerald-600 w-6 h-6" />,
            title: "Our Mission",
            description: "Connect tools for unified workflow efficiency.",
        },
        {
            icon: <AccessTime className="text-emerald-600 w-6 h-6" />,
            title: "Our Vision",
            description: "User-friendly interface boosts team productivity.",
        },
    ]

    return (
        <section className="py-10 sm:py-16 px-4 mission">
            <div className="max-w-7xl mx-auto">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
           
                    <div className="space-y-8">
              
                        <motion.div className="inline-block px-4 py-1 bg-white rounded-full border border-[#C5D1E7] " initial={{ opacity: 0, y: 50 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            viewport={{ once: true, amount: 0.2 }}
                            transition={{ duration: 0.5 }}  >
                            <span className="text-[#4D4D4D] font-medium text-lg">Our Mission & Vision</span>
                        </motion.div>

             
                        <motion.div className="space-y-6" initial={{ opacity: 0, y: 50 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            viewport={{ once: true, amount: 0.2 }}
                            transition={{ duration: 0.5 }}  >
                            <h2 className="text-3xl md:text-5xl font-bold leading-tight hanken lg-text text-[#0A0322]">Work and Collaborate with Your Team</h2>
                            <p className="text-[#6E717B] text-base hanken">
                       Lorem ipsum dolor sit amet consectetur adipisicing elit. A officiis inventore modi, consequuntur harum sunt blanditiis error consequatur et accusamus vel dolorem doloribus ipsa atque, maxime nam repudiandae, aspernatur quae.
                            </p>
                        </motion.div>

              
                        <motion.div className="grid grid-cols-1 sm:grid-cols-2 gap-6" initial={{ opacity: 0, y: 50 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            viewport={{ once: true, amount: 0.2 }}
                            transition={{ duration: 0.5 }} >
                            {features.map((feature, index) => (
                                <div
                                    key={index}
                                    className="p-6 rounded-2xl"
                                    style={{
                                        backgroundColor: index === 0 ? "#f0fdf4" : "#f0f9ff",
                                    }}
                                >
                                    <div className="bg-white p-3 rounded-lg w-fit mb-4">{feature.icon}</div>
                                    <h3 className="font-bold text-lg mb-2 text-[#0A0322] hanken">{feature.title}</h3>
                                    <p className="text-[#6E717B] hanken text-base">{feature.description}</p>
                                </div>
                            ))}
                        </motion.div>
                    </div>

            
                    <motion.div className="mission-grad rounded-3xl p-8 hidden sm:block" initial={{ opacity: 0, y: 50 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true, amount: 0.2 }}
                        transition={{ duration: 0.5 }} >
                        <img
                            src={main_img}
                            alt="Team collaboration illustration"
                            className="w-full h-auto"
                        />
                    </motion.div>
                </div>
            </div>
        </section>
    )
}

