
import footer_logo from "../../assets/images/Footer/Logo.png"
import contact from "../../assets/images/Footer/SVG.png"
import email from "../../assets/images/Footer/SVG1.png"
import india_img from "../../assets/images/Footer/india.png"
import canada_img from "../../assets/images/Footer/canada.png"
import footer1 from "../../assets/images/Footer/footer_1.png"
import footer2 from "../../assets/images/Footer/footer_2.png"
import footer3 from "../../assets/images/Footer/footer_3.png"
import footer4 from "../../assets/images/Footer/footer_4.png"
import rating_star from "../../assets/images/Footer/rating.svg"
import svg1 from "../../assets/images/Footer/SVG-1.svg"

import svg3 from "../../assets/images/Footer/SVG.svg"
import svg4 from "../../assets/images/Footer/linkdin.svg"
import { Link } from "react-router-dom"




export default function Footer() {
    const ratings = [
        {
            name: "Clutch",
            rating: "4.6",
            logo: footer1,
        },
        {
            name: "GoodFirms",
            rating: "4.5",
            logo: footer2,
        },
        {
            name: "DesignRush",
            rating: "4.3",
            logo: footer3,
        },
        {
            name: "TopDevelopers",
            rating: "4.7",
            logo: footer4,
        },
    ]

    // const services = [
    //     "UI/UX Services",
    //     "Mobile App Development",
    //     "Web App Development",
    //     "AI Development",
    //     "Enterprise Mobility",
    //     "Headless CMS",
    // ]

    // const services = [
    //     "Custom Products Development",
    //     "AI Development",
    //     "Mobile App Development",
    //     "Web App Development",
    //     "UI/UX Design",
    //     "Quality Assurance Testing",
    //     "CMS Development"
    // ]
    const services = [
        { name: "AI-Enabled Solutions", path: "/ai-enabled-solutions" },
        { name: " SaaS Product Development", path: "/saas-product-development" },

        { name: "Mobile App Development", path: "/mobile-app-development" },
        { name: "Web App Development", path: "/web-app-development" },
        { name: "CMS Development", path: "/cms-development" },
        { name: "UI/UX Design", path: "/ui-ux-design" },
        { name: "Quality Assurance & Testing", path: "/quality-assurance" },

    ];



    const about = [
        { name: " Career", path: "/career" },
        { name: "Blogs", path: "/Blogs" },
        { name: "T & C", path: "/terms-and-condition" },
        { name: " Policy", path: "/privacy-policy" },

        // { name: "Contact", path: "/contact" }
    ];

    return (
        <footer className=" text-white  px-4 ">
            {/* Ratings Bar */}
            <div className=" ">
                <div className="max-w-6xl mx-auto px-8 bg-[#2D2D2D] rounded-3xl radius-res md:rounded-full foot-sec">
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 res-ft ">
                        {ratings.map((item, index) => (
                            <div
                                key={index}
                                className={`${index > 0 ? "border-left-res" : "" /* Add left border for 2nd, 3rd, and 4th items */
                                    }`}
                            >
                                <div
                                    key={index}
                                    className={`flex items-center justify-center gap-2 py-5 md:py-7 `}

                                >
                                    <img src={item.logo || "/placeholder.svg"} alt={item.name} className="h-6 w-auto" />
                                    <img src={rating_star} alt="Rating Star" className="w-5 h-5" />
                                    <span className="font-15" >{item.rating}</span>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>


                {/* <div className="max-w-6xl mx-auto px-8 bg-[#2D2D2D] rounded-full">
                    <div className="grid grid-cols-2 md:grid-cols-4 gap-4 py-4">
                        {ratings.map((item, index) => (
                            <div>
                                <div
                                    key={index}
                                    className={`flex items-center justify-center gap-2 p-4 `}

                                >
                                    <img src={item.logo || "/placeholder.svg"} alt={item.name} className="h-6 w-auto" />
                                    <img src={rating_star} alt="Rating Star" className="w-5 h-5" />
                                    <span className="font-15" >{item.rating}</span>
                                </div>
                            </div>
                        ))}
                    </div>
                </div> */}

            </div>

            {/* Main Footer */}
            <div className="max-w-7xl mx-auto px-8 py-16 bg-[#2D2D2D] mt-12 rounded-3xl relative" style={{cursor:"auto"}}>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">

                    <div className="space-y-8">

                        <div className="space-y-6">
                            <img
                                src={footer_logo}
                                alt="Tech Rabbit"

                            />
                            <p className="text-white text-sm fightree opacity-80">
                                Transforming the way businesses grow with smart, scalable, and future-ready digital solutions. Let’s innovate, accelerate, and shape the future together!
                            </p>

                        </div>

                        <div className="space-y-4">
                            <h3 className="text-[#B4B4B4] text-xl font-bold fightree">Follow Us</h3>
                            <div className="flex items-center gap-4">

                                <a
                                    href="https://in.linkedin.com/company/techrabbitindia"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="rounded-full bg-white p-3 hover:scale-110 hover:shadow-lg transition-transform duration-200 ease-in-out cursor-pointer"
                                >
                                    <img src={svg4} alt="LinkedIn Icon" className="w-5 h-5" />
                                </a>

                                <a
                                    href="https://www.instagram.com/techrabbitindia/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="rounded-full bg-white p-3 hover:scale-110 hover:shadow-lg transition-transform duration-200 ease-in-out"
                                >
                                    <img src={svg1} alt="Instagram" className="w-5 h-5" />
                                </a>

                                {/* <Link to="/" className="rounded-full bg-white p-3 hover:scale-110 hover:shadow-lg transition-transform duration-200 ease-in-out">
                                    <img src={svg3} alt="Social Icon 3" className="w-5 h-5" />
                                </Link> */}

                            </div>
                        </div>


                    </div>



                    <div className="flex justify-start md:justify-center ">
                        <div>
                            <h3 className="text-lg font-bold mb-4 text-[#ADACB0]">Services</h3>
                            <ul className="space-y-4">
                                {services.map((service, index) => (
                                    <li key={index}>
                                        <Link
                                            to={service?.path}
                                            className="text-[#C7C7C7] hover:text-transparent hover:bg-clip-text hover:bg-gradient-to-r hover:from-[#22989F] hover:to-[#1DBB91] inter font-medium underline decoration-solid transition-all duration-300 ease-in-out"
                                            style={{ fontSize: "15px" }}
                                        >
                                            {service?.name}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </div>


                    </div>



                    <div className="space-y-10 ">
                        {/* <div className="flex gap-8">
                            <div className="display-flex">
                                <img src={india_img} className="mb-2" />
                                <h4 className="text-lg font-bold mb-2 text-transparent bg-clip-text bg-gradient-to-r from-[#22989F] to-[#1DBB91] fightree">INDIA</h4>
                                <p className="text-white text-sm text-center font-medium opacity-80">
                       
                                    939/940,Sun Gravitas, Nr.Shyamal Cross Road, Ahmedabad - 380015
                                </p>
                            </div>
                            <div className="display-flex" >
                                <img src={canada_img} className="mb-2" />
                                <h4 className="text-lg font-bold mb-2 text-transparent bg-clip-text bg-gradient-to-r from-[#22989F] to-[#1DBB91] fightree">CANADA</h4>
                                <p className="text-white text-sm text-center font-medium opacity-80">
                                    40, Treeline blvd, Brampton, Ontario L6P1E4
                                </p>
                            </div>
                        </div> */}

                        <div className="grid grid-cols-2 gap-8">
                            <div className="flex flex-col items-center text-center">
                                <img src={india_img} className="mb-2" />
                                <h4 className="text-lg font-bold mb-2 text-transparent bg-clip-text bg-gradient-to-r from-[#22989F] to-[#1DBB91] fightree">INDIA</h4>
                                <p className="text-white text-sm font-medium opacity-80">
                                    939/940, Sun Gravitas, Ahmedabad 
                                </p>
                            </div>
                            <div className="flex flex-col items-center text-center">
                                <img src={canada_img} className="mb-2" />
                                <h4 className="text-lg font-bold mb-2 text-transparent bg-clip-text bg-gradient-to-r from-[#22989F] to-[#1DBB91] fightree">CANADA</h4>
                                <p className="text-white text-sm font-medium opacity-80">
                                    40, Treeline Blvd, Brampton, Ontario 
                                </p>
                            </div>
                        </div>

                        <div className="flex items-start gap-8" style={{ justifyContent: 'center' }}>
                            <div className="flex items-start gap-2">
                                <div className="hidden lg:block">
                                    <img src={contact} alt="Phone Icon" className="" />
                                </div>
                                <div>
                                    <span className="block text-sm font-normal text-[#9D9D9D]">Contact Number</span>
                                    <span className="fightree font-bold text-[#9D9D9D]">+917600213313</span>
                                </div>
                            </div>

                            <div className="flex items-start gap-2">
                                <div className="hidden lg:block">
                                    <img src={email} alt="Email Icon" className="" />
                                </div>
                                <div>
                                    <span className="block text-sm font-normal text-[#9D9D9D]">Email Address</span>
                                    <span className="fightree font-bold text-[#9D9D9D]">hello@techrabbit.io</span>
                                </div>
                            </div>
                        </div>

                        <div className="">

                            <ul className="flex space-x-4 justify-center flex-wrap privacy">
                                {about.map((item, index) => (
                                    <li key={index}>
                                        <Link
                                            to={item?.path}
                                            className="text-[#C7C7C7] hover:text-transparent hover:bg-clip-text hover:bg-gradient-to-r hover:from-[#22989F] hover:to-[#1DBB91] inter font-medium underline decoration-solid transition-all duration-300 ease-in-out"

                                        >
                                            {item?.name}
                                        </Link>
                                    </li>
                                ))}
                            </ul>

                        </div>








                    </div>
                </div>


            </div>
        </footer>
    )
}

