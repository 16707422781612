import React from 'react'
import { useState, useEffect } from 'react';
import { motion } from "framer-motion";
import { Description, AccessTime } from "@mui/icons-material"
import main_img from "../../../assets/images/about/main_img.svg"
import { Add, Remove } from "@mui/icons-material"
import { Close } from '@mui/icons-material';
import whocanuse from "../../../assets/images/Services/CustomProduct/whocanuse.svg"
import overlay_img from "../../../assets/images/Services/CustomProduct/Overlay.svg"
import pr1 from "../../../assets/images/Services/Quality Assurance/req_analysis.svg"
import pr2 from "../../../assets/images/Services/Quality Assurance/test_plan.svg"
import pr3 from "../../../assets/images/Services/Quality Assurance/test_Case.svg"
import pr4 from "../../../assets/images/Services/Quality Assurance/test_exec.svg"
import pr5 from "../../../assets/images/Services/Quality Assurance/final_report.svg"
import drop1 from "../../../assets/images/Services/Quality Assurance/drop1.png"
import drop2 from "../../../assets/images/Services/Quality Assurance/drop2.png"

import drop3 from "../../../assets/images/Services/Quality Assurance/drop3.png"

import corss_iconn from "../../../assets/images/Services/Quality Assurance/cross_icon.svg"
import "../../FallAnimation/Fall.css"
const TestingPorcess = () => {
    const [cards, setCards] = useState([]);

    useEffect(() => {
        const interval = setInterval(() => {
            setCards((prev) => {
                if (prev.length < 5) {
                    return [...prev, { id: prev.length }];
                }
                return prev;
            });
        }, 600); // New card every 600ms

        return () => clearInterval(interval);
    }, []);
    const features = [
        {
            icon: <Description className="text-emerald-600 w-6 h-6" />,
            title: "Our Mission",
            description: "Connect tools for unified workflow efficiency.",
        },
        {
            icon: <AccessTime className="text-emerald-600 w-6 h-6" />,
            title: "Our Vision",
            description: "User-friendly interface boosts team productivity.",
        },
    ]


    const [openIndex, setOpenIndex] = useState(null)

    const faqs = [
        {
            question: "Requirement Analysis",
            answer:
                "We begin by understanding the project requirements and aligning our testing efforts with your business goals.",
            image: pr1,

        },
        {
            question: "Test Planning",
            answer:
                "Our experts create a detailed test plan outlining testing objectives, timelines, resources, and responsibilities.",
            image: pr2,
        },
        {
            question: "Test Case Design",
            answer:
                "We design test cases that cover all functional and non-functional aspects of your application.",
            image: pr3,
        },
        {
            question: "Test Execution",
            answer:
                "Using both manual and automated testing techniques, we execute the test cases to identify bugs, vulnerabilities, and usability issues.",
            image: pr4,
        },

        {
            question: "Final Report",
            answer:
                " We provide a comprehensive QA report, highlighting test coverage, issues found, and overall application health.",
            image: pr5,
        },


    ]

    const dropPositions = [
        { yEnd: "80%", delay: 0 },  // First drop at the bottom
        { yEnd: "60%", delay: 0.3 }, // Second drop above first
        { yEnd: "40%", delay: 0.6 }  // Third drop at the top of the stack
    ];

    const toggleFAQ = (index) => {
        setOpenIndex(openIndex === index ? null : index)
    }


    return (
        <section className="py-0 sm:py-16 px-4 who">
            <div className="max-w-7xl mx-auto">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-stretch">

                    <div className=' '>

                        <div className="space-y-8 ">

                            {/* <motion.div className="inline-block px-4  bg-[#F1F7FC] rounded-full text-[#3D3D3D] py-1  " initial={{ opacity: 0, y: 50 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                viewport={{ once: true, amount: 0.2 }}
                                transition={{ duration: 0.5 }}  >
                                <span className="text-[#4D4D4D] font-medium text-lg ">Who can use</span>
                            </motion.div> */}


                            <motion.div className="space-y-6" initial={{ opacity: 0, y: 50 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                viewport={{ once: true, amount: 0.2 }}
                                transition={{ duration: 0.5 }}  >
                                <h2 className="text-3xl md:text-4xl font-medium leading-tight hanken lg-text text-[#0C0C0C]">Our <span className='text-[#24B574]'>Process</span></h2>
                                <p className="text-base md:text-lg text-[#485E79]">
                                    Lorem ipsum dolor sit amet consectetur, adipisicing elit. A, ab nemo! Expedita!
                                </p>
                            </motion.div>



                            <div className="">

                                <motion.div className="" initial={{ opacity: 0, y: 50 }}
                                    whileInView={{ opacity: 1, y: 0 }}
                                    viewport={{ once: true, amount: 0.2 }}
                                    transition={{ duration: 0.5 }}>
                                    {faqs.map((faq, index) => (
                                        <div
                                            key={index}
                                            className={`overflow-hidden ${index !== faqs.length - 1 ? 'border-b' : ''}`}

                                        >
                                            <button
                                                onClick={() => toggleFAQ(index)}
                                                className="w-full px-6 py-6 flex items-center justify-between text-left hover:bg-gray-100 transition-colors duration-200"
                                            >
                                                {/* Image at the start */}

                                                <div className='flex items-center'>
                                                    <img src={faq.image} alt="faq-icon" className="w-8 h-8 mr-4" />

                                                    <span className="font-semibold text-[#333333] inter text-xl">{faq.question}</span>
                                                </div>

                                                <span className="ml-4 flex-shrink-0 bg-[#333333] p-2 rounded-full">
                                                    {openIndex === index ? (
                                                        <Close className="w-5 h-5 text-white" />
                                                    ) : (
                                                        <Add className="w-5 h-5 text-white" />
                                                    )}
                                                </span>
                                            </button>

                                            {/* Answer */}
                                            <div
                                                className={`px-6 overflow-hidden transition-all duration-200 ease-in-out ${openIndex === index ? "max-h-48 py-4" : "max-h-0"
                                                    }`}
                                            >
                                                <p className="text-[#606060] font-medium text-lg">{faq.answer}</p>
                                            </div>
                                        </div>
                                    ))}
                                </motion.div>

                            </div>
                        </div>

                    </div>



                    <motion.div className='flex flex-col min-h-[400px] sm:min-h-[500px] lg:min-h-full border border-[#E1E4EB] bg-[#FAFAFA] rounded-2xl  text-base sm:text-xl fall' initial={{ opacity: 0, y: 50 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true, amount: 0.2 }}
                        transition={{ duration: 0.5 }}>

                        <div class="confetti">Poor Analytics</div>
                        <div class="confetti">Engaging Contents</div>
                        <div class="confetti">No Strategy</div>
                        <div class="confetti">Customer acquisition</div>
                        <div class="confetti">No Ideas</div>
                        <div class="confetti">Poor Analytics</div>
                        <div class="confetti">Engaging Contents</div>
                        <div class="confetti">No Strategy</div>
                        <div class="confetti">Customer acquisition</div>
                        <div class="confetti">No Ideas</div>

                        {/* <div class="confetti">Poor Analytics</div>
                        <div class="confetti">Engaging Contents</div>
                        <div class="confetti">No Strategy</div>
                        <div class="confetti">Customer acquisition</div>
                        <div class="confetti">No Ideas</div> */}
           

                        {/* <div class="confetti"></div>
                        <div class="confetti"></div>
                        <div class="confetti"></div>
                        <div class="confetti"></div>
                        <div class="confetti"></div>
                        <div class="confetti"></div>
                        <div class="confetti"></div>
                        <div class="confetti"></div>
                        <div class="confetti"></div>
                        <div class="confetti"></div>

                        <div class="confetti"></div>
                        <div class="confetti"></div>
                        <div class="confetti"></div>
                        <div class="confetti"></div>
                        <div class="confetti"></div>
                        <div class="confetti"></div>
                        <div class="confetti"></div>
                        <div class="confetti"></div>
                        <div class="confetti"></div>
                        <div class="confetti"></div>

                        <div class="confetti"></div>
                        <div class="confetti"></div>
                        <div class="confetti"></div>
                        <div class="confetti"></div>
                        <div class="confetti"></div>
                        <div class="confetti"></div>
                        <div class="confetti"></div>
                        <div class="confetti"></div>
                        <div class="confetti"></div>
                        <div class="confetti"></div> */}
                    </motion.div>
                </div>
            </div>
        </section>
    )
}

export default TestingPorcess















