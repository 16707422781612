import { CheckCircle } from "@mui/icons-material"
import elementor_img from "../../../assets/images/Services/CMS/elementor.png"
import webflow_img from "../../../assets/images/Services/CMS/webflow.png"
import shopify_img from "../../../assets/images/Services/CMS/shopify.png"

import "./CMSDesign.css"
import element_icon from "../../../assets/images/Services/CMS/element_icon.png"
import web_icon from "../../../assets/images/Services/CMS/webflow_icon.png"
import { motion } from "framer-motion";
export default function TechWeUse() {
    const platforms = [
        {
            title: "Elementor & WordPress",
            description: "Powerful website builder with the flexibility of WordPress",
            features: "Drag-and-drop editing, full customization, SEO optimization, mobile responsive.",
            image:
                elementor_img,
            icon: element_icon
        },
        {
            title: "Webflow",
            description: "A no-code platform for web design and development.",
            features: "Visual design, CMS flexibility, built-in hosting, dynamic content management.",
            image:
                webflow_img,
            icon: web_icon
        },
        {
            title: "Framer",
            description: "High-performance design platform with CMS functionality.",
            features: "Advanced interactions, animations, responsive design, SEO control.",
            image:
                elementor_img,
            icon: web_icon
        },
        {
            title: "Shopify",
            description: "E-commerce platform for building online stores.",
            features: "E-commerce tools, payment gateways, inventory management, customizable themes.",
            image:
                shopify_img,
            icon: web_icon
        },
    ]

    return (
        <section className="pt-0 pb-16 sm:pt-16 sm:pb-16 px-4 techweuse">
            <div className="max-w-6xl mx-auto">
                {/* Header */}
                <motion.div className="text-center mb-12" initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true, amount: 0.2 }}


                    transition={{ duration: 0.5 }}>
                    <h2 className="text-3xl md:text-4xl font-bold mb-4 lg-text">Lorem Ipsum dolor sit amet consectetur.</h2>
                    <p className="text-[#485E79] max-w-2xl text-base md:text-lg mx-auto">
                        Lorem ipsum dolor sit amet consectetur, adipisicing elit A, ab nemo! Expedita!Lorem ipsum dolor sit amet consectetur
                    </p>

                </motion.div>

                {/* Platform Grid */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    {platforms.map((platform, index) => (
                        <motion.div key={index} className="relative bg-[#EAEAEA4D] rounded-2xl p-6 " initial={{ opacity: 0, y: 50 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true, amount: 0.2 }}
    
    
                        transition={{ duration: 0.5 }}>
                            {/* Check Icon */}
                            <div className="absolute top-6 right-6 bg-white rounded-full p-2 "

                            >
                                {/* <CheckCircle className="text-emerald-500 w-5 h-5" /> */}
                                <img src={platform?.icon} alt="" className="w-4 h-4 sm:w-6 sm:h-6" />
                            </div>


                            {/* Content */}
                            <div className="space-y-4">
                                <h3 className="texty-xl md:text-2xl font-semibold text-[#111111]">{platform.title}</h3>
                                <p className="text-[#616161] text-lg">{platform.description}</p>

                                {/* Platform Image */}
                                <div className="relative rounded-xl overflow-hidden flex justify-center">
                                    <img
                                        src={platform.image || "/placeholder.svg"}
                                        alt={platform.title}
                                        className=""
                                    />
                                </div>

                                {/* Features */}
                                <p className="text-lg text-[black]">{platform.features}</p>
                            </div>
                        </motion.div>
                    ))}
                </div>
            </div>
        </section>
    )
}

