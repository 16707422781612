import React from 'react'
import { useState, useEffect } from 'react'
import left_bg_shadow from "../../assets/images/MobileDevPage/HeroSection/left_shadow.svg"
import right_bg_shadow from "../../assets/images/MobileDevPage/HeroSection/right_shadow.svg"



import left_draw from "../../assets/images/MobileDevPage/left_draw.svg"
import right_Draw from "../../assets/images/MobileDevPage/right_draw.svg"

import { motion, AnimatePresence } from "framer-motion";
import verify_icon from "../../assets/images/Services/verify_icon.svg"

import MobileDevNavbar from '../../components/MobileDevNavbar/MobileDevNavbar'
import { MobileDevHero } from '../../components/MobileDevHero/MobileDevHero'
import MobileDevAbout from '../../components/MobileDevAbout/MobileDevAbout'
import MobileDevChoose from '../../components/MobileDevChoose/MobileDevChoose'
import mainshadow from "../../assets/images/MobileDevPage/HeroSection/shadow_bg.svg"
import MobileAppSolution from '../../components/MobileAppSolution/MobileAppSolution'
import MobileDevTech from '../../components/MobileDevTech/MobileDevTech'
import MobileDevFeatures from '../../components/MobileDevFeatures/MobieDevFeatures'
import MobileDevCTM from '../../components/MobileDevCTM/MobileDevCTM'
import MobileDevFooter from '../../components/MobileDevFooter/MobileDevFooter'
import MobileDevConsult from '../../components/MobileDevConsult/MobileDevConsult'
import MobileDevLast from '../../components/MobileDevLast/MobileDevLast'
import Loader from '../../components/Loader1/Loader'
import Contact from '../Contact/Contact'
import ContactSection from '../../components/ContactSection/ContactSection'
import MobileDevContact from '../../components/ContactSection/MobileDevContact'
import ConsultationPopup from './ConsultationPopup'
import ContactPopup from './ContactPopup'
import { useSelector } from 'react-redux'
const MobileDevPage = () => {
    const {showConsultation } = useSelector((state) => state.authModal);

    const [loading, setLoading] = useState(true);


    useEffect(() => {
        const handleLoad = () => {
            setTimeout(() => setLoading(false), 2500);
        };

        if (document.readyState === "complete") {
            setTimeout(() => setLoading(false), 2500);
        } else {
            window.addEventListener("load", handleLoad);
        }

        return () => window.removeEventListener("load", handleLoad);
    }, []);



    if (loading) {
        return <Loader />;
    }

    return (

    

        <>


        <div className="relative flex min-h-screen py-10 flex-col bg-white jakarta">



        
            {/* <img
                src={mainshadow}
                alt="Main Shadow"
                className="absolute top-0 left-1/2 transform -translate-x-1/2 w-full max-w-[1250px] "
            /> */}

            <motion.img
                src={left_draw}
                alt="Left Shadow"
                animate={{ y: [0, 10, 0] }}
                transition={{ duration: 3, repeat: Infinity, ease: "easeInOut" }}
                className="absolute top-0 left-0 w-auto"
            />

            <motion.img
                src={right_Draw}
                alt="Right Shadow"
                animate={{ y: [0, -10, 0] }}
                transition={{ duration: 3, repeat: Infinity, ease: "easeInOut" }}
                className="absolute top-0 right-0 w-auto"
            />


            <img
                src={left_bg_shadow}
                alt="Left Shadow"
                className="absolute top-0 left-0  w-auto z-0"
            />


            <img
                src={right_bg_shadow}
                alt="Right Shadow"
                className="absolute top-0 right-0 w-auto z-0"
            />

            <main className="flex-1">
                <div className="w-full relative" style={{ zIndex: 999 }}>
                    <MobileDevNavbar />

                    {showConsultation &&    <ContactPopup/> }

                 
                    {/* <ConsultationPopup/> */}
                    <div className="relative -z-10">
                        <MobileDevHero />
                    </div>
                    {/* <Clients/> */}
                    <MobileDevAbout />
                    <MobileDevChoose />
                    <MobileDevFeatures />
                    <MobileDevConsult />

                    <MobileAppSolution />
                    <MobileDevTech />
                    <MobileDevLast />

                    {/* <Contact/> */}

                    {/* <ContactSection/> */}
                    {/* <MobileDevContact/> */}

                    <MobileDevCTM />
                    <MobileDevFooter />
                </div>
            </main>
        </div></>
    )
}

export default MobileDevPage


