

import logo from "../../assets/images/tr.webp"

import { Link } from "react-router-dom"
import pointer_arrow from "../../assets/images/MobileDevPage/HeroSection/pointer_arrow.svg"
import { useDispatch } from "react-redux"
import { openConsultation } from "../../redux/authModalSlice"

export default function MobileDevNavbar() {
const dispatch = useDispatch()






  return (

    <>


      <nav
        className="mx-auto max-w-4xl bg-[#FFFFFF] backdrop-blur-sm rounded-full px-6 py-4 flex items-center justify-between border border-[#E5E7EB] navbar nav-auto"
        style={{ width: "90%" }}
      >

        <Link className="text-white text-2xl font-medium w-12 h-12"><img src={logo} alt="Logo" /></Link>




        <div className="flex items-center ">







          <div className="flex space-x-4">

            {/* <Link to={"/consult-us"}> */}
              <button
                className=" text-white px-3 py-2 sm:px-3 sm:py-3 rounded-full text-xs sm:text-sm font-semibold consultation-btn inline-flex items-center gap-2 "

                onClick={()=>dispatch(openConsultation())}

              >
                CONSULTATION

                <img src={pointer_arrow} alt="Arrow" className="border border-[#FFFFFF8F] rounded-full p-1 w-6" />
              </button>



              {/* <button className="text-white px-6 py-3 rounded-full text-base font-medium consultation-btn mt-8 inline-flex items-center gap-2">
                                          Get a Free Consultation
                                          <img src={pointer_arrow} alt="Arrow" className="border border-[#FFFFFF8F] rounded-full p-1" />
                                      </button> */}

            {/* </Link> */}

          </div>
        </div>






      </nav>



    </>

  )
}

