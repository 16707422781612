import React from 'react'
import { CheckCircle, AccessTime, Settings, Chat, ArrowForward } from "@mui/icons-material"
import "./CareerWorking.css"
import icon1 from "../../assets/images/CareerPage/icon1.svg"
import icon2 from "../../assets/images/CareerPage/icon2.svg"
import icon3 from "../../assets/images/CareerPage/icon3.svg"
import icon4 from "../../assets/images/CareerPage/icon4.svg"
import icon5 from "../../assets/images/CareerPage/icon5.svg"
import icon6 from "../../assets/images/CareerPage/icon6.svg"



const CareerWorking = () => {


    const benefits = [
        {
            icon: <CheckCircle className="w-6 h-6 text-emerald-500" />,
            title: "Work from anywhere",
            description:
                "Excepteur sint occaecat cupidat non proident sunt in culpam qui the officia mollit aliquip ex ea comm.",
                img:icon1,
        },
        {
            icon: <AccessTime className="w-6 h-6 text-emerald-500" />,
            title: "Flexible hours",
            description:
                "Excepteur sint occaecat cupidat non proident sunt in culpam qui the officia mollit aliquip ex ea comm.",
                img:icon2,

        },
        {
            icon: <Settings className="w-6 h-6 text-emerald-500" />,
            title: "Robust system",
            description:
                "Excepteur sint occaecat cupidat non proident sunt in culpam qui the officia mollit aliquip ex ea comm.",
                img:icon3,

        },
        {
            icon: <Chat className="w-6 h-6 text-emerald-500" />,
            title: "Safe environment",
            description:
                "Excepteur sint occaecat cupidat non proident sunt in culpam qui the officia mollit aliquip ex ea comm.",
                img:icon4,

        },

        {
            icon: <Chat className="w-6 h-6 text-emerald-500" />,
            title: "401K plan",
            description:
                "Excepteur sint occaecat cupidat non proident sunt in culpam qui the officia mollit aliquip ex ea comm.",
                img:icon5,

        },


        {
            icon: <Chat className="w-6 h-6 text-emerald-500" />,
            title: "Paid vacations",
            description:
                "Excepteur sint occaecat cupidat non proident sunt in culpam qui the officia mollit aliquip ex ea comm.",
                img:icon6,

        },
    ]
    return (
        <section className='py-0 sm:py-16 px-4 career-working'>



            <div className='max-w-6xl mx-auto'>

                <div className="text-center mb-16">
                    <span className="text-base font-semibold text-[#111111]">Benefits</span>
                    <h2 className="text-3xl md:text-5xl font-semibold mt-2 mb-4 lg-text text-[#111111]">Working at Tech Rabbit</h2>
                    <p className="text-[#5F6980] max-w-2xl mx-auto text-lg md:text-xl">
                        Designing has never been so fast. Browse hundreds of beautifully designed layouts and have your project
                        ready in minutes.
                    </p>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-10">
                    {benefits.map((benefit, index) => (
                        <div key={index} className="flex flex-col gap-4">
                            {/* Row 1: Icon and Title */}
                            <div className="flex items-center gap-4">
                                <div className="flex-shrink-0"><img src={benefit?.img} alt="" /></div>
                                <h3 className="text-lg md:text-xl font-semibold text-[#282828]">{benefit.title}</h3>
                            </div>

                            {/* Row 2: Description */}
                            <p className="text-[#5F6980] text-base ">{benefit.description}</p>
                        </div>
                    ))}

                </div>



            </div>
        </section>
    )
}

export default CareerWorking
