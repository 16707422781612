import React from 'react'
import { motion } from 'framer-motion'

import begin_img from "../../../assets/images/Services/CMS/starting.png"

const StartSection = () => {
    return (

        <section className="relative  py-0 md:py-16 px-4 ">


            <div className="max-w-7xl mx-auto">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-start p-0 lg:p-4 startsection">



                    <motion.div className="flex flex-col h-full justify-center" initial={{ opacity: 0, y: 50 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true, amount: 0.2 }}
                        transition={{ duration: 0.5 }}>
                        <div className="space-y-6 md:space-y-10">
                            <h3 className="text-2xl md:text-4xl font-semibold text-[#0C0C0C] lg-text">
                                What is CMS Development and Why Does Your Business Need It?
                            </h3>

                            <div>


                                <p className=" text-[#606060] text-sm sm:text-base" >

                                    A brief explanation of Content Management Systems (CMS) and their importance for businesses in managing their websites, content, and e-commerce. Highlight the ease of use, flexibility, and scalability CMS provides.
                                </p>


                            </div>

                            <span
                                className="font-medium text-[#333333] px-4 py-2 rounded-full border border-[#EDEFF3] 
  bg-white cursor-pointer inline-block transition duration-300 ease-in-out 
     hover:border-[#DADADA] hover:bg-black  hover:text-[white]"
                            >
                                Get Started
                            </span>



                        </div>
                    </motion.div>

                    <motion.div
                        className="space-y-8 flex flex-col h-full relative group justify-center"
                        initial={{ opacity: 0, y: 50 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true, amount: 0.2 }}
                        transition={{ duration: 0.5 }}
                    >

                        <img src={begin_img} alt="" className='rounded-2xl' />



                    </motion.div>


                </div>
            </div>
        </section>
    )
}

export default StartSection
