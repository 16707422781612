import React from 'react'
import Navbar from '../../components/Navbar'
import { useState, useEffect } from 'react'
import Footer from '../../components/Footer/Footer'
import ProjectDetails from '../../components/ProjectDetails/ProjectDetails'
import ProjectOverveiw from '../../components/ProjectDetails/ProjectOverveiw'
import Loader from '../../components/Loader1/Loader'


const WorkDetails = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [isOpen, setIsOpen] = useState(false)

  const [loading, setLoading] = useState(true);


  useEffect(() => {
    const handleLoad = () => {
      setTimeout(() => setLoading(false), 1500);
    };

    if (document.readyState === "complete") {
      setTimeout(() => setLoading(false), 1500);
    } else {
      window.addEventListener("load", handleLoad);
    }

    return () => window.removeEventListener("load", handleLoad);
  }, []);


  if (loading) {
    return <Loader />;
  }

  return (
    <div className="relative flex min-h-screen py-10 flex-col bg-white">

      <main className="flex-1 relative " style={{ zIndex: "999" }}>

        <div className="w-full relative">
          <Navbar isOpen={isOpen} setIsOpen={setIsOpen} />




        </div>
        <ProjectDetails />
        <ProjectOverveiw />
        <Footer />
      </main>
    </div>)
}

export default WorkDetails
