import React from "react";
import { useState, useEffect } from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer/Footer";
import CTMSection from "../../components/CTMSection/CTMSection";
import TestimonialsSection from "../../components/Testimonials/Testimonials";
import Banner from "../../components/Services/CustomProductDev/Banner";
import TechStackAlt from "../../components/TechStackAlt/TechStackAlt";
import "./CmsDev.css"

// import CMS from "../../assets/images/Services/CMS/CMS.svg"
import CMS from "../../assets/images/Services/cms-dev.png"

import BreadCrumbService from "../../components/BreadCrumbService/BreadCrumbService";
import ExpandningCards from "../../components/ExpandningCards/ExpandningCards";
import TechWeUse from "../../components/Services/CMSDesign/TechWeUse";
import StartSection from "../../components/Services/CMSDesign/StartSection";
import OtherServices from "../../components/Services/CustomProductDev/OtherServices";
import { techCms } from "../../data/techStackCMS";
import Loader from "../../components/Loader1/Loader";
function CmsDev() {


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    const data = [
        {
          title: "CMS Development",
          description: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. A, ab nemo! Expedita!Lorem ipsum dolor sit amet consectetur, adipisicing elit. A, ab nemo! Expedita!",
          img: CMS, 
        },

    ]

    const [isOpen, setIsOpen] = useState(false)
    const [loading, setLoading] = useState(true); 


    useEffect(() => {
       const handleLoad = () => {
         setTimeout(() => setLoading(false), 1500); 
       };
     
       if (document.readyState === "complete") {
         setTimeout(() => setLoading(false), 1500);
       } else {
         window.addEventListener("load", handleLoad);
       }
     
       return () => window.removeEventListener("load", handleLoad);
     }, []);
     
 
     
     if (loading) {
       return <Loader />;
     }

    return (



        <div className="relative flex min-h-screen py-10 flex-col bg-white">

            <main className="flex-1 relative " style={{ zIndex: "999" }}>

                <div className="w-full relative">
                    <Navbar isOpen={isOpen} setIsOpen={setIsOpen} />




                </div>

                {/* <Banner /> */}
                <BreadCrumbService data={data}/>
                <StartSection/>
                <ExpandningCards/>
                <TechWeUse/>





                <TechStackAlt technologies={techCms}/>
                <OtherServices currentService="CMS Development"/>

                <TestimonialsSection />


                <CTMSection />











                <Footer />


            </main>
        </div>


    );
}

export default CmsDev;
