import "./TermsComp.css"

export default function TermsComp() {
    const sectionpt1 = [
        {
            title: "Scope of Services",
            content:
                "Details about the types of personal information collected, such as names, addresses, contact information, and possibly sensitive information related to interior services or projects.",
        },
        {
            title: "Payment Terms",
            content:
                "Measures taken to protect personal information from unauthorized access, disclosure, alteration,  or destruction, which might include encryption,  access controls, and regular security  assessments.  Information about the methods used to securely  dispose of or anonymize personal information  once it is no longer needed for its original purpose.  This might include shredding physical documents, deleting electronic records, or anonymizing data to  remove identifying information.",
        },
        {
            title: "Confidentiality",
            content:
                "Identification of the types of external entities with whom the agency may share personal information. This could include contractors, consultants, vendors, partner organizations, or other government agencies. Explanation of why the agency shares personal information with third parties. This might include facilitating services, ",
        },
        // {
        //     title: "Payment Terms",
        //     content:
        //         "Measures taken to protect personal information from unauthorized access, disclosure, alteration, or destruction, which might include encryption, access controls, and regular security assessments. Information about the methods used to securely dispose of or anonymize personal information once it is no longer needed for its original purpose. This might include shredding physical documents, deleting electronic records, or anonymizing data to remove identifying information.",
        // },
        // {
        //     title: "Confidentiality",
        //     content:
        //         "Measures taken to protect personal information from unauthorized access, disclosure, alteration, or destruction, which might include encryption, access controls, and regular security assessments.",
        // },
        // {
        //     title: "Termination of Services",
        //     content:
        //         "Explanation of how personal information is used to maintain the security of interior agency systems, prevent unauthorized access or fraud, and protect against other unlawful activities. Assurance that personal information will only be collected and used for purposes consistent with individuals' consent or preferences, where applicable, and in accordance with applicable privacy laws and regulations.",
        // },
        // {
        //     title: "Insurance and Liability",
        //     content:
        //         "Explanation of how personal information is used to maintain the security of interior agency systems, prevent unauthorized access or fraud, and protect against other unlawful activities. Assurance that personal information will only be collected and used for purposes consistent with individuals' consent or preferences, where applicable, and in accordance with applicable privacy laws and regulations.",
        // },
        // {
        //     title: "Change Orders",
        //     content:
        //         "Details about the types of personal information collected, such as names, addresses, contact information, and possibly sensitive information related to interior services or projects.",
        // },
        // {
        //     title: "Intellectual Property",
        //     content:
        //         "Details about the types of personal information collected, such as names, addresses, contact information, and possibly sensitive information related to interior services or projects.",
        // },
    ]


    const sectionpt2 = [

        {
            title: "Client Responsibilities",
            content:
                "Explanation of how personal information is used to maintain the security of interior agency systems, prevent unauthorized access or fraud, and protect against other unlawful activities. Assurance that personal information will only be collected and used for purposes consistent with individuals' consent or preferences, where applicable, and in accordance with applicable privacy laws and regulations.",
        },


     {
            title: "Termination of Services",
            content:
                "Explanation of how personal information is used to maintain the security of interior agency systems, prevent unauthorized access or fraud, and protect against other unlawful activities. Assurance that personal information will only be collected and used for purposes consistent with individuals' consent or preferences, where applicable, and in accordance with applicable privacy laws and regulations.",
        },
      
        {
            title: "Insurance and Liability",
            content:
                "Explanation of how personal information is used to maintain the security of interior agency systems, prevent unauthorized access or fraud, and protect against other unlawful activities. Assurance that personal information will only be collected and used for purposes consistent with individuals' consent or preferences, where applicable, and in accordance with applicable privacy laws and regulations.",
        },



    ]

    const sectionpt3 = [
        {
            "title": "Project Timeline",
            "content": "The project timeline involves identifying the types of external entities with whom the agency may share personal information. These entities could include contractors, consultants, vendors, partner organizations, or other government agencies. The timeline will outline the reasons for sharing personal information, such as facilitating services, conducting research or analysis, collaborating on projects, or complying with legal requirements. Additionally, it will specify the categories of personal information shared and define limitations on its use by third parties to ensure compliance with data protection policies."
        },
        
        {
            title: "Change Orders",
            content:
                "Details about the types of personal information collected, such as names, addresses, contact information, and possibly sensitive information related to interior services or projects.",
        },
        {
            title: "Intellectual Property",
            content:
                "Details about the types of personal information collected, such as names, addresses, contact information, and possibly sensitive information related to interior services or projects.",
        },



    ]

    return (

        <section className="py-16 px-4 TermsComp">
            <div className="max-w-6xl mx-auto ">

                <h1 className="text-3xl md:text-4xl font-semibold text-[#201E1C] text-center mb-12 lg-text">Terms & Conditions</h1>


                <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-8">

                    <div className="grid grid-cols-1  gap-8 ">
                        {sectionpt1.map((section, index) => (
                            <div key={index} className="space-y-4 border border-[#FAF4F3] rounded-2xl p-4 max-h-fit">
                                <h2 className="text-lg md:text-xl font-medium sm-text">{section.title}</h2>
                                <p className="text-[#333333] leading-relaxed text-sm md:text-base ">{section.content}</p>
                            </div>
                        ))}
                    </div>

                    <div className="grid grid-cols-1  gap-8">
                        {sectionpt2.map((section, index) => (
                            <div key={index} className="space-y-4 border border-[#FAF4F3] rounded-2xl p-4 max-h-fit">
                                <h2 className="text-lg md:text-xl font-medium sm-text">{section.title}</h2>
                                <p className="text-[#333333] leading-relaxed text-sm md:text-base ">{section.content}</p>
                            </div>
                        ))}
                    </div>

                    <div className="grid grid-cols-1  gap-8">
                        {sectionpt3.map((section, index) => (
                            <div key={index} className="space-y-4 border border-[#FAF4F3] rounded-2xl p-4 max-h-fit">
                                <h2 className="text-lg md:text-xl font-medium sm-text">{section.title}</h2>
                                <p className="text-[#333333] leading-relaxed text-sm md:text-base ">{section.content}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

        </section>
    )
}

