import {
    Person,
    Group,
    BarChart,
    Security,
    Assignment,
    Chat,
    Sync,
    People,
    FormatListBulleted,
} from "@mui/icons-material"
import img1 from "../../../assets/images/Services/AIDev/dev_discovery.svg"
import img2 from "../../../assets/images/Services/AIDev/dev_data.svg"
import img3 from "../../../assets/images/Services/AIDev/dev_modal.svg"
import img4 from "../../../assets/images/Services/AIDev/dev_testing.svg"
import img5 from "../../../assets/images/Services/AIDev/dev_deploy.svg"
import { useRef, useEffect, useState } from "react"

import verify_icon from "../../../assets/images/Services/verify_icon.svg"

export default function DevProcess() {
    const keyFeatures = [
        {
            icon: <Person className="w-5 h-5" />,
            text: "From concept to deployment, we craft AI solutions that drive transformation",
        },
        {
            icon: <Group className="w-5 h-5" />,
            text: `"Your vision, powered by AI—delivered with excellence at every step."`,
        },
        {
            icon: <BarChart className="w-5 h-5" />,
            text: "Advanced analytics for data-driven decision-making.",
        },
        {
            icon: <Security className="w-5 h-5" />,
            text: "Robust Data Security Measures",
        },
    ]

    const detailedFeatures = [
        {
            icon: img1,
            title: "Discovery and Consultation",
            description:
                "Understand the client's needs and define the project scope.",
        },
        {
            icon: img2,
            title: "Data Preparation",
            description:
                "Facilitate seamless collaboration and communication across your teams with SAP's intuitive messaging platform. From instant messaging to group chats.",
        },
        {
            icon: img3,
            title: "Model Development",
            description:
                "Sync your data effortlessly across devices and platforms with SAP's simple data syncing feature. Whether you're accessing information from your desktop, laptop, or mobile device.",
        },
        {
            icon: img4,
            title: "Testing and Evaluation",
            description:
                "Easily add and manage team members within SAP, facilitating smooth project onboarding and collaboration for enhanced efficiency and teamwork.",
        },
        {
            icon: img5,
            title: "Deployment and Monitoring",
            description:
                "Never miss a deadline again with SAP's comprehensive deadline tracking feature. Set project milestones, deadlines, and reminders to keep your team on track and focused on deliverables.",
        },

    ]

    return (
        <section className="py-16 px-4 min-h-screen dev-process">
            <div className="max-w-7xl mx-auto">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">

                    <div className="space-y-8 lg:sticky lg:top-1 self-start">
                        <div>
                            <h2 className="text-3xl md:text-4xl font-semibold mb-3 lg-text">Our <span className="text-[#24B574]">Development</span> Process</h2>
                            <p className="text-[#666666]" style={{ fontSize: "16.48px", lineHeight: "24.71px" }}>
                                At TR, we follow a streamlined and collaborative AI development process to deliver innovative, scalable, and effective solutions. Our approach integrates cutting-edge technologies with deep industry insights, ensuring that each project is tailored to meet the unique needs of our clients.
                            </p>
                        </div>


                        <div className="space-y-4">
                            {keyFeatures.map((feature, index) => (
                                <div key={index} className="flex items-center gap-3 text-gray-700 rounded-full border border-[#E6E9EE] p-2" style={{ display: "inline-flex" }}>
                                
                                    <img src={verify_icon} alt="" className="w-5 h-5"/>
                                    <span style={{ fontSize: "15.51px", lineHeight: '23.26px', color: "#333333" }}>{feature.text}</span>
                                </div>
                            ))}
                        </div>
                    </div>


                    <div className="space-y-6   ">
                        {detailedFeatures.map((feature, index) => (
                            <div key={index} className="bg-white rounded-2xl p-6 border border-[#E6E9EE]">
                                <div className="flex flex-col gap-4">
                 
                                    <div className="flex items-center gap-2">
                                    <img src={feature.icon} alt={feature.title} className="object-contain"/>
                                            <h3 className="font-semibold text-[#262626] text-2xl">{feature.title}</h3>
                                    </div>

                  
                                    <p className="text-gray-600 text-sm" style={{fontSize:"15.51px", lineHeight:"23.26px"}}>{feature.description}</p>
                                </div>

                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    )
}

