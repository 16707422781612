import { useState } from "react"
import { motion } from "framer-motion";
import "./CTMSection.css"
import bg_image from "../../assets/images/Blogs/ctm-bg.svg"
import bg from "../../assets/images/Blogs/bg-image.svg"
import arrow from "../../assets/images/Blogs/arrow.svg"
import left_shadow from "../../assets/images/Blogs/shadow-left.svg"
import right_shadow from "../../assets/images/Blogs/shadow-right.svg"
import leftvector from "../../assets/images/Blogs/left-vector.svg"
import rightvector from "../../assets/images/Blogs/right-vector.svg"

import top_left from "../../assets/images/BlogDeatils/tleft.png"
import top_right from "../../assets/images/BlogDeatils/tright.png"
import bottom_left from "../../assets/images/BlogDeatils/bleft.png"
import bottom_right from "../../assets/images/BlogDeatils/bright.png"
import { useNavigate } from "react-router-dom";





export default function CTMSection() {

    const navigate = useNavigate()
    return (
        // <section className="py-16 px-4 ctm">
        //     <div className="max-w-7xl mx-auto rounded-3xl shadow-[0px_0px_4px_0px_#00000040] p-2">
        //         <div className="relative h-auto min-h-[580px] max-h-[590px] px-4 py-10 flex flex-col items-center justify-center text-center text-black rounded-3xl border border-[#F3F3F1]">
        //             <h1 className="text-3xl md:text-5xl font-semibold mb-6 max-w-4xl manrope lg-text">
        //                 Lorem ipsum Dolor Sit Amet Consectetur
        //             </h1>

        //             <p className="text-lg md:text-lg text-black-200 mb-8 max-w-2xl inter font-normal">Lorem ipsum dolor sit amet consectetur,</p>

        //             <button className="group flex items-center gap-2 button-gradient px-6 py-3 rounded-full transition-colors gradient-text-ctm inter text-sm font-medium border border-l-[#24B574] border-t-[#24B574] border-b-[#2BAAE2] border-r-[#2BAAE2]">
        //                 Connect Now
        //                 <img src={arrow} alt="Arrow" className="w-5 h-5 group-hover:translate-x-1 transition-transform" />
        //             </button>
        //         </div>
        //     </div>
        // </section>

        <motion.section className="pt-0 pb-16 sm:pt-16 sm:pb-16 px-4 ctm" initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true, amount: 0.2 }}
            transition={{ duration: 0.5 }}>
            <div className="max-w-7xl mx-auto rounded-[2.8125rem] shadow-[0px_0px_4px_0px_#00000040] p-1 relative hidden sm:block">
                {/* Background Vector Images */}
                <div className="absolute left-[10%] top-[30%] z-[0] floating-left">
                    <img src={leftvector} alt="" />
                </div>

                <div className="absolute right-[10%] top-[20%] z-[0] floating-right">
                    <img src={rightvector} alt="" />
                </div>


                <div className="relative h-auto min-h-[570px] max-h-[590px] px-4 py-10 flex flex-col items-center justify-center text-center text-black rounded-[2.8125rem] border border-[#F3F3F1] bg-no-repeat bg-left-top bg-right-top"
                    style={{
                        backgroundImage: `url(${left_shadow}), url(${right_shadow})`,
                        backgroundSize: 'auto, auto',
                        backgroundPosition: 'left top, right top'
                    }}>

                    {/* Text Content */}
                    <p className="text-center text-[#37312F] mb-6 border border-[#F3F3F1] rounded-full px-4 py-1">
                        Elevate Your Business
                    </p>

                    <h2 className="text-3xl md:text-5xl font-semibold mb-6 max-w-4xl manrope lg-text urbanist">
                    Transform Ideas into Powerful Digital Solutions
                    </h2>

                    <p className="text-lg md:text-lg text-black-200 mb-8 max-w-2xl inter font-normal">
                    Build smarter, faster, and more innovative digital products with our expert services.
                    </p>

                    {/* Email Input and Button */}
                    {/* <div className="max-w-4xl mx-auto flex flex-col sm:flex-row gap-4 relative bg-white rounded-3xl p-4 sm:p-[0.28rem] border border-[#F0ECE7] shadow-ctm urbanist">
                        <input
                            type="email"
                            placeholder="Your Email Address"
                            className="flex-1 px-6 py-3 rounded-full pr-16 focus:outline-none w-full sm:w-[60%] inter"
                        />
                        <button className="px-2 py-3 bg-gradient-to-r from-[#2AACD0] to-[#24B47B] text-white rounded-3xl hover:bg-gradient-to-r hover:from-[#24B47B] hover:to-[#2AACD0] transition-all text-lg w-[70%] sm:w-[40%] mx-auto sm:mx-auto border border-[#989897] shd-ctm">
                            Get Started
                        </button>
                    </div> */}

                    <button
                        className=" text-white px-6 md:px-8 py-2 rounded-full  font-medium consultation-btn text-lg"
                        style={{ height: "44px" }}
                        onClick={()=> navigate ("/consult-us")}
                    >
                        Get Started
                    </button>


                </div>
            </div>


            <motion.div className="max-w-7xl mx-auto rounded-[2.8125rem] shadow-[0px_0px_4px_0px_#00000040] p-1 relative block sm:hidden" initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true, amount: 0.2 }}
                    transition={{ duration: 0.5 }}>
                    {/* Background Vector Images */}
                    <div className="absolute left-[10%] top-[30%] z-[0] floating-left">
                        <img src={leftvector} alt="" className="w-14" />
                    </div>

                    <div className="absolute right-[10%] top-[20%] z-[0] floating-right">
                        <img src={rightvector} alt="" className="w-14" />
                    </div>


                    <div className="relative h-auto min-h-[450px] max-h-[590px] px-4 py-10 flex flex-col items-center justify-center text-center text-black rounded-[2.8125rem] border border-[#F3F3F1] bg-no-repeat bg-left-top bg-right-top"
                        style={{
                            backgroundImage: `url(${top_left}), url(${top_right}),url(${bottom_left}),url(${bottom_right})`,
                            backgroundSize: 'auto, auto, auto, auto',
                            backgroundPosition: 'left top, right top, left bottom, right bottom'
                        }}>

                        {/* Text Content */}
                        <p className="text-center text-[#37312F] mb-6 border border-[#F3F3F1] rounded-full px-4 py-1 text-sm">
                            Elevate Your Business
                        </p>

                        <h2 className="text-lg md:text-2xl font-semibold mb-6 max-w-4xl manrope  urbanist">
                        Transform Ideas into Powerful Digital Solutions
                        </h2>

                        <p className=" text-sm text-black-200 mb-8 max-w-2xl inter font-normal" >
                        Build smarter, faster, and more innovative digital products with our expert services.

                        </p>

                        {/* Email Input and Button */}
                        {/* <div className="max-w-4xl mx-auto flex flex-col sm:flex-row gap-4 relative bg-white rounded-3xl p-4 sm:p-[0.28rem] border border-[#F0ECE7] shadow-ctm urbanist">
                <input
                  type="email"
                  placeholder="Your Email Address"
                  className="flex-1 px-6 py-3 rounded-full pr-16 focus:outline-none w-full sm:w-[60%] inter"
                />
                <button className="px-2 py-3 bg-gradient-to-r from-[#2AACD0] to-[#24B47B] text-white rounded-3xl hover:bg-gradient-to-r hover:from-[#24B47B] hover:to-[#2AACD0] transition-all text-lg w-[70%] sm:w-[40%] mx-auto sm:mx-auto border border-[#989897] shd-ctm">
                  Get Started
                </button>
              </div> */}

                        <button
                            className=" text-white px-4 py-2 rounded-full text-sm md:text-base font-semibold consultation-btn"
                            onClick={()=> navigate ("/consult-us")}

                        >
                            Get Started
                        </button>

                    </div>
                </motion.div>
        </motion.section>







    )
}


// import { ArrowForward } from "@mui/icons-material"

// export default function CTMSection() {
//     return (
//         <section className="py-16 px-4 rounded-2xl ctm">

//             <div className="max-w-7xl mx-auto relative h-[490px] overflow-hidden rounded-2xl">

//                 <div className="absolute inset-0">
//                     <img
//                         src={bg}
//                         alt="Office workspace"
//                         className="w-full h-full object-cover rounded-2xl"
//                     />

//                     <div className="absolute inset-0 bg-gradient-to-r from-black/70 to-black/70" />

//                     <div className="absolute left-1/4 top-1/2 -translate-y-1/2">
//                         <div className="w-[400px] h-[400px] rounded-full bg-blue-500/20 blur-[100px]" />
//                     </div>
//                 </div>


//                 <div className="relative h-full max-w-6xl mx-auto px-4 flex flex-col items-center justify-center text-center text-white">
//                     <h1 className="text-3xl md:text-5xl font-semibold mb-6 max-w-4xl manrope lg-text">
//                         Lorem ipsum Dolor Sit Amet Consectetur
//                     </h1>

//                     <p className="text-lg md:text-lg text-gray-200 mb-8 max-w-2xl inter font-normal">Lorem ipsum dolor sit amet consectetur,</p>

//                     <button className="group flex items-center gap-2 button-gradient px-6 py-3 rounded-full transition-colors gradient-text-ctm inter text-sm font-medium border border-l-[#24B574] border-t-[#24B574]  border-b-[#2BAAE2]  border-r-[#2BAAE2]">
//                         Connect Now
//                         <img src={arrow} alt="Arrow" className="w-5 h-5 group-hover:translate-x-1 transition-transform" />
//                     </button>

//                 </div>

//             </div>
//         </section>
//     )
// }



