
import bg from "../../assets/images/MobileDevPage/consult.png"
import logo from "../../assets/images/MobileDevPage/parentician_logo.svg"
import shipora_logo from "../../assets/images/MobileDevPage/shipora_logo.svg"
import { useState, useEffect } from "react"
import laptop from "../../assets/images/MobileDevPage/laptop_img.png"

import bg_shipora from "../../assets/images/MobileDevPage/bg_shipora.png"
import pointer_arrow from "../../assets/images/MobileDevPage/HeroSection/pointer_arrow.svg"
import shipora_lap from "../../assets/images/MobileDevPage/shipora_lap.png"
import { motion, AnimatePresence } from "framer-motion";
import bg_sindhisoulmate from "../../assets/images/MobileDevPage/bg_sindhi-soulmate.png"
import sindhisoulmate_logo from "../../assets/images/MobileDevPage/sindhisoulmate_logo.svg"
import { openConsultation } from "../../redux/authModalSlice"
import { useDispatch } from "react-redux"
import sindhisoulmate_lap from "../../assets/images/MobileDevPage/laptop_sindhisoulmate.png"
import { Link } from "react-router-dom"

export default function MobileDevConsult() {
const dispatch = useDispatch()
    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setActiveIndex((prevIndex) => (prevIndex + 1) % 3); // Cycle through 3 divs
        }, 5000); // Change every 3 seconds

        return () => clearInterval(interval); // Cleanup interval on unmount
    }, []);
    return (




        <motion.section className="py-0 sm:py-16 px-4 rounded-2xl ctm"      initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true, amount: 0.2 }}
        transition={{ duration: 0.5 }}
>

            {activeIndex === 0 && (
                <div className="max-w-7xl mx-auto relative min-h-[490px] overflow-hidden rounded-2xl  flex justify-center items-center" >

                    <div className="absolute inset-0">
                        <img
                            src={bg}
                            alt="Office workspace"
                            className="w-full h-full object-cover rounded-2xl"
                        />
                    </div>


                    <div className="relative z-10 p-6 grid grid-cols-1 md:grid-cols-2 gap-6 h-full items-center">

                        <div className="flex flex-col space-y-6  p-6 rounded-2xl">

                            <div className="flex justify-start">
                                <img src={logo} alt="Parentician Logo" className="h-16 w-auto" />
                            </div>


                            <p className="text-white text-lg">
                                Parentician is a dedicated platform designed to support parents at every
                                stage of their journey. It offers valuable teens and teens, fostering
                                informed and supportive parenting. Parentician empowers parents with
                                tools and guidance to navigate the challenges of raising children with
                                confidence.
                            </p>


                            <div>


{/* 
                                <button className="text-white px-3 py-2 sm:px-3 sm:py-3 md:px-6 md:py-3 rounded-full text-xs sm:text-base font-medium consultation-btn  inline-flex items-center gap-2 ">
                                    Get a Free Consultation
                                    <img src={pointer_arrow} alt="Arrow" className="border border-[#FFFFFF8F] rounded-full p-1 w-6 lg:w-auto" />
                                </button> */}

                                
                         <button   onClick={()=>dispatch(openConsultation())} className="text-white px-3 py-2 sm:px-3 sm:py-3 md:px-6 md:py-3 rounded-full text-xs sm:text-base font-medium consultation-btn  inline-flex items-center gap-2 ">
                                Get a Free Consultation
                                <img src={pointer_arrow} alt="Arrow" className="border border-[#FFFFFF8F] rounded-full p-1 w-6 lg:w-auto" />
                            </button>

                            </div>
                        </div>


                        <div className="flex justify-center items-center">
                            <img
                                src={laptop}
                                alt="Parenting Support"
                                className="w-full h-auto rounded-2xl object-cover"
                            />
                        </div>
                    </div>
                </div>)}



            {activeIndex === 1 && (<div className="max-w-7xl mx-auto relative min-h-[490px] overflow-hidden rounded-2xl  flex justify-center items-center">

                <div className="absolute inset-0">
                    <img
                        src={bg_shipora}
                        alt="Office workspace"
                        className="w-full h-full object-cover rounded-2xl"
                    />
                </div>


                <div className="relative z-10 p-6 grid grid-cols-1 md:grid-cols-2 gap-6 h-full items-center">

                    <div className="flex flex-col space-y-6  p-6 rounded-2xl">

                        <div className="flex justify-start">
                            <img src={shipora_logo} alt="Shipora Logo" className="h-16 w-auto" />
                        </div>


                        <p className="text-white text-lg">
                            Shipora is a comprehensive shipment platform that  delivery solutions for both personal and business needs. In addition to shipment services, Shipora also features a marketplace that allows users to buy and sell goods, making it a one-stop solution
                        </p>


                        <div>



                        <button    onClick={()=>dispatch(openConsultation())} className="text-white px-3 py-2 sm:px-3 sm:py-3 md:px-6 md:py-3 rounded-full text-xs sm:text-base font-medium consultation-btn  inline-flex items-center gap-2 ">
                                Get a Free Consultation
                                <img src={pointer_arrow} alt="Arrow" className="border border-[#FFFFFF8F] rounded-full p-1 w-6 lg:w-auto" />
                            </button>

                        </div>
                    </div>


                    <div className="flex justify-center items-center">
                        <img
                            src={shipora_lap}
                            alt="Parenting Support"
                            className="w-full h-auto rounded-2xl object-cover"
                        />
                    </div>
                </div>
            </div>)}

            {activeIndex === 2 && (


                <div className="max-w-7xl mx-auto relative min-h-[490px] overflow-hidden rounded-2xl flex justify-center items-center">

                    <div className="absolute inset-0">
                        <img
                            src={bg_sindhisoulmate}
                            alt="Office workspace"
                            className="w-full h-full object-cover rounded-2xl"
                        />
                    </div>


                    <div className="relative z-10 p-6 grid grid-cols-1 md:grid-cols-2 gap-6 h-full items-center">

                        <div className="flex flex-col space-y-6  p-6 rounded-2xl">

                            <div className="flex justify-start">
                                <img src={sindhisoulmate_logo} alt="Shipora Logo" className="h-16 w-auto" />
                            </div>


                            <p className="text-white text-lg">
                                Sindhi Soulmate is a matrimonial app specifically tailored for the Sindhi community, aiming to connect, Soulmate helps users find life partners who align with their cultural, religious, and personal preferences. The app combines community.
                            </p>


                            <div>



                          <button   onClick={()=>dispatch(openConsultation())} className="text-white px-3 py-2 sm:px-3 sm:py-3 md:px-6 md:py-3 rounded-full text-xs sm:text-base font-medium consultation-btn  inline-flex items-center gap-2 ">
                                Get a Free Consultation
                                <img src={pointer_arrow} alt="Arrow" className="border border-[#FFFFFF8F] rounded-full p-1 w-6 lg:w-auto" />
                            </button>

                            </div>
                        </div>


                        <div className="flex justify-center items-center">
                            <img
                                src={sindhisoulmate_lap}
                                alt="Parenting Support"
                                className="w-full h-[319px]  object-cover hidden md:block"
                            />
                        </div>
                    </div>
                </div>)}
        </motion.section>
    )
}



