import React from "react";

import { HeroSection } from "../../components/HeroSection";
import Navbar from "../../components/Navbar";




import { useState } from "react";

import { useEffect } from "react";

import Footer from "../../components/Footer/Footer";
import { PricingHero } from "../../components/PricingHero/PricingHero";
import CTMSection from "../../components/CTMSection/CTMSection";
import FAQsWork from "../../components/FAQsWork/FAQsWork";
import PricingModels from "../../components/PricingModals/PricingModals";
import left_top from "../../assets/images/Pricing/left-top.png"
import right_top from "../../assets/images/Pricing/right-top.png"
import PricingCard from "../../components/PricingCard/PricingCard";
import Loader from "../../components/Loader1/Loader";
import CTMPricing from "../../components/CTMSection/CTMPricing";





function PricingPage() {



    const [isOpen, setIsOpen] = useState(false)

    const [loading, setLoading] = useState(true); 


    useEffect(() => {
       const handleLoad = () => {
         setTimeout(() => setLoading(false), 1500); 
       };
     
       if (document.readyState === "complete") {
         setTimeout(() => setLoading(false), 1500);
       } else {
         window.addEventListener("load", handleLoad);
       }
     
       return () => window.removeEventListener("load", handleLoad);
     }, []);
     
   
     
     if (loading) {
       return <Loader />;
     }

    return (



        <div className="relative flex min-h-screen py-10 flex-col bg-[#FAF9F8]">

            <img
                src={left_top}
                alt="Left Overlay"
                className="absolute top-0 left-0 w-36 md:w-80 -z-1 animate__animated animate__fadeInTopLeft"
            />
            <img
                src={right_top}
                alt="Right Overlay"
                className="absolute top-0 right-0 w-36 md:w-80 -z-1  animate__animated animate__fadeInTopRight "
            />

            <main className="flex-1">

                <div className="w-full relative" style={{ zIndex: "999" }}>
                    <Navbar isOpen={isOpen} setIsOpen={setIsOpen} />

                    <div className="relative -z-10">
                        <PricingHero isOpen={isOpen} setIsOpen={setIsOpen} />





                    </div>








                </div>

                {/* <PricingModels /> */}
                <PricingCard />



                <FAQsWork />

                <CTMPricing />
                <Footer />







            </main>
        </div>


    );
}

export default PricingPage;
