
// import "./OurMission.css"
import { motion } from "framer-motion";
import mobile_img from "../../assets/images/WorkDetails/ParenticianPage/final_info.webp"
import mobile_img2 from "../../assets/images/WorkDetails/ParenticianPage/final_info2.webp"


export default function FinalInfo() {


    return (
        <section className="py-16 px-4 ">


            <div className="max-w-7xl mx-auto">

                <div className="space-y-16">
                    <div className={`grid grid-cols-1 lg:grid-cols-2 gap-12 items-center bg-[#EEF2FB] p-6 sm:p-10 md:p-12 lg:p-12 rounded-2xl`}>

                        <img
                            src={mobile_img}
                            alt="Team collaboration illustration"
                            className="w-full object-contain"
                        />


                        <div className="space-y-8 ">
                            <div
                                className="space-y-6 "
                                initial={{ opacity: 0, y: 50 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                viewport={{ once: true, amount: 0.2 }}
                                transition={{ duration: 0.5 }}
                            >
                                <h2 className="text-2xl md:text-3xl leading-tight  text-[#272666]">
                                    Lorem Ipsum Dolor, sit amet Consecteur
                                </h2>
                                <p className="text-[#676767] text-lg md:text-xl ">
                                    Lorem ipsum dolor, sit amet consectetur adipisicing elit. Provident in doloremque officia dignissimos deleniti inventore porro possimus, suscipit natus. Commodi iure numquam facilis itaque?
                                </p>

                                <p className="text-[#676767] text-lg md:text-xl ">
                                    Lorem ipsum dolor, sit amet consectetur adipisicing elit. Provident in doloremque officia dignissimos deleniti inventore porro possimus, suscipit natus. Commodi iure numquam facilis itaque?
                                </p>

                                <p className="text-[#676767] text-lg md:text-xl ">
                                    Lorem ipsum dolor, sit amet consectetur adipisicing elit. Provident in doloremque officia dignissimos deleniti inventore porro possimus, suscipit natus. Commodi iure numquam facilis itaque?
                                </p>
                            </div>
                        </div>




                    </div>

                    {/* <div className={`grid grid-cols-1 lg:grid-cols-2 gap-12 items-center bg-[#EEF2FB] p-6 sm:p-10 md:p-12 lg:p-12 rounded-2xl`}>

                    

                        <div className="space-y-8 ">
                            <div
                                className="space-y-6 "
                                initial={{ opacity: 0, y: 50 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                viewport={{ once: true, amount: 0.2 }}
                                transition={{ duration: 0.5 }}
                            >
                                <h2 className="text-2xl md:text-3xl leading-tight  text-[#272666]">
                                    Lorem Ipsum Dolor, sit amet Consecteur
                                </h2>
                                <p className="text-[#676767] text-lg md:text-xl ">
                                    Lorem ipsum dolor, sit amet consectetur adipisicing elit. Provident in doloremque officia dignissimos deleniti inventore porro possimus, suscipit natus. Commodi iure numquam facilis itaque?
                                </p>

                                <p className="text-[#676767] text-lg md:text-xl ">
                                    Lorem ipsum dolor, sit amet consectetur adipisicing elit. Provident in doloremque officia dignissimos deleniti inventore porro possimus, suscipit natus. Commodi iure numquam facilis itaque?
                                </p>

                                <p className="text-[#676767] text-lg md:text-xl ">
                                    Lorem ipsum dolor, sit amet consectetur adipisicing elit. Provident in doloremque officia dignissimos deleniti inventore porro possimus, suscipit natus. Commodi iure numquam facilis itaque?
                                </p>
                            </div>
                        </div>

                        <img
                            src={mobile_img}
                            alt="Team collaboration illustration"
                            className="w-full object-contain"
                        />





                    </div> */}

                    {/* <div className={`grid grid-cols-1 md:grid-cols-2 gap-12 items-center `}>

                       


                        <div
                            className="space-y-6 "
                            initial={{ opacity: 0, y: 50 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            viewport={{ once: true, amount: 0.2 }}
                            transition={{ duration: 0.5 }}
                        >
                            <h2 className="text-3xl md:text-4xl lg:text-5xl leading-tight  text-[#272666]">
                                Lorem Ipsum Dolor, sit amet Consecteur
                            </h2>
                            <p className="text-[#676767] text-2xl md:text-lg ">
                                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Provident in doloremque officia dignissimos deleniti inventore porro possimus, suscipit natus. Commodi iure numquam facilis itaque?
                            </p>

                            <p className="text-[#676767] text-2xl md:text-lg ">
                                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Provident in doloremque officia dignissimos deleniti inventore porro possimus, suscipit natus. Commodi iure numquam facilis itaque?
                            </p>
                        </div>

                        <img
                            src={mobile_img2}
                            alt="Team collaboration illustration"
                            className="w-full "
                        />
                    </div> */}


                </div>
            </div>


        </section>


    )
}
















