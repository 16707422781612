




import "./MobileDevHero.css"
import overlay1 from "../../assets/images/MobileDevPage/HeroSection/overlay_icon1.png"
import overlay2 from "../../assets/images/MobileDevPage/HeroSection/overlay_icon2.png"
import overlay3 from "../../assets/images/MobileDevPage/HeroSection/overlay_icon3.png"
import overlay4 from "../../assets/images/MobileDevPage/HeroSection/overlay_icon4.png"
import overlay5 from "../../assets/images/MobileDevPage/HeroSection/overlay_icon5.png"
import overlay6 from "../../assets/images/MobileDevPage/HeroSection/overlay_icon6.png"
import pointer_arrow from "../../assets/images/MobileDevPage/HeroSection/pointer_arrow.svg"
import GoalBasedMobile from "./GoalBasedMobile"
import { ChevronRight } from "@mui/icons-material";
import { openConsultation } from "../../redux/authModalSlice"
import Clients from "./Clients"

import { motion, AnimatePresence } from "framer-motion";

import { Link } from "react-router-dom"
import { useDispatch } from "react-redux"

export function MobileDevHero() {

    const dispatch = useDispatch()



    return (

        <>
            <section className="container mx-auto px-4 py-16 text-center md:py-16 relative mobiledevhero" >


                <div className="absolute inset-0 pointer-events-none overflow-hidden -z-10">

                    <img src={overlay1} alt="" className="overlay left-10 top-10 w-8 md:w-10 lg:w-16 opacity-70 animate-float-rotate hidden sm:block" />
                    <img src={overlay2} alt="" className="overlay left-16 top-1/3 w-8 md:w-10 lg:w-16 opacity-60 animate-zigzag hidden sm:block" />
                    <img src={overlay3} alt="" className="overlay left-28 top-2/3 w-8 md:w-10 lg:w-16  opacity-75  animate-float-scale hidden sm:block" />


                    <img src={overlay4} alt="" className="overlay right-10 top-12 w-8 md:w-10 lg:w-16 animate-wave hidden sm:block" />
                    <img src={overlay5} alt="" className="overlay right-16 top-1/3 w-8 md:w-10 lg:w-16 opacity-60 animate-fade hidden sm:block" />
                    <img src={overlay6} alt="" className="overlay right-28 top-2/3 w-8 md:w-10 lg:w-16 opacity-75  animate-zigzag hidden sm:block" />
                </div>

                <div className="animation" >

                    {/* <div className="inline-flex items-center gap-1 sm:gap-3 bg-[#F9FAFB]  px-2 py-2 rounded-full border border-[#6868811A] ">

                        <div className="bg-black text-white text-xs sm:text-sm font-medium px-3 py-1 rounded-full">
                            New
                        </div>


                        <span className="text-[#4B4B57] font-medium text-xs sm:text-base">Your Smart Finance Companion</span>

                        <ChevronRight className="text-[#4B4B57] " />
                    </div> */}

                    <motion.div
                        className="inline-flex items-center gap-1 sm:gap-3 bg-[#F9FAFB] px-2 py-2 rounded-full border border-[#6868811A]"
                    >
                        <motion.div
                            animate={{ scale: [1, 1.1, 1] }}
                            transition={{ duration: 1.2, repeat: Infinity, ease: "easeInOut" }}
                            className="bg-black text-white text-xs sm:text-sm font-medium px-3 py-1 rounded-full"
                        >
                            New
                        </motion.div>

                        <span className="text-[#4B4B57] font-medium text-xs sm:text-base">
                            Your Smart Tech Companion
                        </span>

                        <ChevronRight className="text-[#4B4B57]" />
                    </motion.div>


                    {/* 

                    <h1 className="mx-auto max-w-5xl lg:max-w-3xl text-3xl md:text-4xl lg:text-5xl  lg-text text-[#0F0F0F] font-bold pt-16" >

                        Tech Rabbit – Hopping into the Future of Mobile Apps!

                    </h1> */}


                    <motion.h1
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 1 }}
                        className="mx-auto max-w-5xl lg:max-w-3xl text-3xl md:text-4xl lg:text-5xl text-[#0F0F0F] font-bold pt-16 lg-text"
                    >
                        {Array.from("Tech Rabbit – Hopping into the Future of Mobile Apps!").map((char, index) => (
                            <motion.span
                                key={index}
                                initial={{ y: -10, opacity: 0 }}
                                animate={{ y: 0, opacity: 1 }}
                                transition={{ duration: 1, delay: index * 0.05 }}
                            >
                                {char}
                            </motion.span>
                        ))}
                    </motion.h1>


                    <div style={{

                    }}>
                        {/* <p className={`mx-auto mt-6  text-lg max-w-3xl text-[#4B4B57] `} >
                            We build powerful iOS, Android, and cross-platform applications tailored to your business needs. Innovation meets performance with Tech Rabbit.

                        </p> */}

                        <motion.p
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 1, ease: "easeOut" }}
                            className="mx-auto mt-6 text-lg max-w-3xl text-[#4B4B57]"
                        >
                            We build powerful iOS, Android, and cross-platform applications tailored to your business needs. Innovation meets performance with Tech Rabbit.
                        </motion.p>



                       <button   onClick={()=>dispatch(openConsultation())} className="text-white px-3 py-2 sm:px-3 sm:py-3 md:px-6 md:py-3 rounded-full text-xs sm:text-base font-medium consultation-btn mt-8 inline-flex items-center gap-2">
                            Get Free Consultation
                            <img src={pointer_arrow} alt="Arrow" className="border border-[#FFFFFF8F] rounded-full p-1 w-6 lg:w-auto" />
                        </button>



                        {/* <button className="text-white px-3 py-2 sm:px-3 sm:py-3 md:px-6 md:py-3 rounded-full text-xs sm:text-base font-medium consultation-btn  inline-flex items-center gap-2 border border-[#FFFFFF]">
                        Get a Free Consultation
                        <img src={pointer_arrow} alt="Arrow" className="border border-[#FFFFFF8F] rounded-full p-1 w-6 lg:w-auto" />
                    </button> */}

                    </div>
                </div>



            </section>

            <GoalBasedMobile />
            <Clients />



        </>
    )
}

