import { SmartToy, PhoneAndroid, Computer, Brush, WebAsset, BugReport, ArrowForward } from "@mui/icons-material"
// import ai_dev from "../../../assets/images/Services/ai_dev.svg"
import web_app from "../../../assets/images/Services/web_app.svg"
import mobile_app from "../../../assets/images/Services/mobile_app.svg"
// import ui_ux from "../../../assets/images/Services/ui-ux.svg"
// import quality from "../../../assets/images/Services/quality.svg"
import cms from "../../../assets/images/Services/sms.svg"
import { motion } from 'framer-motion';
import right_arrow from "../../../assets/images/Services/CustomProduct/right_arrow.svg"
import product_dev from "../../../assets/images/Services/product_dev.svg"
import web_Dev from "../../../assets/images/Services/svc_web.svg"
import ai_dev from "../../../assets/images/Services/svc_ai.svg"
import mobile_dev from "../../../assets/images/Services/svc_mobile.svg"
import ui_ux from "../../../assets/images/Services/svc_ui.svg"
import cms_dev from "../../../assets/images/Services/svc_cms.svg"
import quality from "../../../assets/images/Services/svc_quality.svg"
import business_success from "../../../assets/images/Services/buisness_success.svg"
import { Link } from "react-router-dom";
import "./UIUX.css"
export default function ServicesWeOffer() {
    const services = [
        {
            title: "UI Design",
            description: "Visual design, interface design, and branding.Visual design, interface design, and branding.",
            image: product_dev,
            url: "/product-development",
        },
        {
            title: "UX Research",
            description: "User research, personas, journey mapping, etc.User research, personas, journey mapping, etc.User research, personas, journey mapping, etc.",
            image: web_Dev,
            url: "/web-app-development",
        },
        {
            title: "Wireframing & Prototyping",
            description: "Low-fidelity and high-fidelity wireframes.Low-fidelity and high-fidelity wireframes.Low-fidelity and high-fidelity wireframes.",
            image: ai_dev,
            url: "/ai-development",
        },
        {
            title: "Usability Testing",
            description: "Feedback collection and iterative improvementFeedback collection and iterative improvement",
            image: mobile_app,
            url: "/mobile-app-development",
        },
        {
            title: "Design Systems",
            description: "Creation of reusable components and patterns.Creation of reusable components and patterns.Creation of reusable components and patterns.",
            image: ui_ux,
            url: "/ui-ux-design",
        },
        {
            title: "Mobile App & Web Design",
            description: "Crafting intuitive and visually stunning interfaces for web and mobile to enhance user engagement.",
            image: cms_dev,
            url: "/cms-development",
        }
        ,
        
        {
            title: "Consulting & Strategy",
            description: "Design audits, design sprints, or workshops.Design audits, design sprints, or workshops.Design audits, design sprints, or workshops.",
            image: quality,
            url: "/quality-assurance",
        },
    ];
    

    return (
        <section className="pb-0 sm:pb-16 px-4 services-offer">
            <div className="max-w-7xl mx-auto">

                <motion.div className="text-center mb-12" initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true, amount: 0.2 }}
                    transition={{ duration: 0.5 }}
                >

 
                    {/* <span className="font-medium text-[#333333]  px-4 py-2   rounded-full border border-[#EDEFF3] bg-[#F8F9FA] ">Our Services</span> */}
                    <h2 className="text-3xl md:text-3xl font-semibold text-[#0C0C0C] mb-4 mt-0 sm:mt-8 poppins lg-text max-w-2xl mx-auto">  <span className="text-[#1DBB91]">Services</span> We Offer</h2>
                    <p className="text-[#666666] max-w-2xl text-lg mx-auto">
                    Break down the different types of services you provide. Use bullet points or sections for each service to make it easy for users to navigate.
                    </p>
                </motion.div>




                <motion.div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mt-4" >
                    {services.map((service, index) => (
                        <motion.div key={index} className="group p-5 border border-[#E8EAF1] rounded-xl " initial={{ opacity: 0, y: 50 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            viewport={{ once: true, amount: 0.2 }}
                            transition={{ duration: 0.5 }}>

                
                            <div className="flex items-center justify-between mb-4">
                                <div className="mb-4 bg-[#FAFAFA] border border-[#EDEFF3] rounded-xl p-2">
                                    <img src={service?.image} alt="" className="w-10 h-10" />
                                </div>



                            </div>


                            <h3 className="text-lg font-medium mb-2">{service.title}</h3>
                            <p className="text-gray-600 mb-4">{service.description}</p>
                         



                        </motion.div>
                    ))}

                    <motion.div className="group p-5 border border-[#E8EAF1] rounded-xl " initial={{ opacity: 0, y: 50 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true, amount: 0.2 }}
                        transition={{ duration: 0.5 }}>


                        <div className="mt-4">
                            <img
                                src={business_success}
                                alt="Business Success"
                                className="w-full h-auto rounded-lg"
                            />

                        </div>



                    </motion.div>


                </motion.div>
            </div>
        </section>
    )
}

