import React from 'react'
import { AccountCircle } from "@mui/icons-material"
import "./BlogPersonal.css"

import "./BlogPersonal.css"

import { motion } from "framer-motion";

import calender from "../../assets/images/BlogDeatils/calendor.png"
import blog_Small from "../../assets/images/BlogDeatils/blog_small.png"
import blog_Small2 from "../../assets/images/BlogDeatils/blog-small-2.png"
import blog_Small3 from "../../assets/images/BlogDeatils/blog-small-3.png"



import bottom_left from "../../assets/images/BlogDeatils/bleft.png"
import bottom_right from "../../assets/images/BlogDeatils/bright.png"

import leftvector from "../../assets/images/Blogs/left-vector.svg"
import rightvector from "../../assets/images/Blogs/right-vector.svg"
import top_left from "../../assets/images/BlogDeatils/tleft.png"
import top_right from "../../assets/images/BlogDeatils/tright.png"

const BlogTagsColumn = () => {
    return (
        <div className="flex flex-col gap-4 sticky top-4 h-fit ">


            <div className="px-4">

                <motion.div className="rounded-[2.8125rem] shadow-[0px_0px_4px_0px_#00000040] p-1 relative" initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true, amount: 0.2 }}
                    transition={{ duration: 0.5 }}>
                    {/* Background Vector Images */}
                    <div className="absolute left-[10%] top-[30%] z-[-1] floating-left">
                        <img src={leftvector} alt="" className="w-14" />
                    </div>

                    <div className="absolute right-[10%] top-[20%] z-[-1] floating-right">
                        <img src={rightvector} alt="" className="w-14" />
                    </div>


                    <div className="relative h-auto min-h-[450px] max-h-[590px] px-4 py-10 flex flex-col items-center justify-center text-center text-black rounded-[2.8125rem] border border-[#F3F3F1] bg-no-repeat bg-left-top bg-right-top"
                        style={{
                            backgroundImage: `url(${top_left}), url(${top_right}),url(${bottom_left}),url(${bottom_right})`,
                            backgroundSize: 'auto, auto, auto, auto',
                            backgroundPosition: 'left top, right top, left bottom, right bottom'
                        }}>

                        {/* Text Content */}
                        <p className="text-center text-[#37312F] mb-6 border border-[#F3F3F1] rounded-full px-4 py-1 text-sm">
                            Elevate Your Business
                        </p>

                        <h2 className="text-lg md:text-2xl font-semibold mb-6 max-w-4xl manrope  urbanist">
                        Transform Ideas into Powerful Digital Solutions
                        </h2>

                        <p className=" text-sm text-black-200 mb-8 max-w-2xl inter font-normal" >
                        Build smarter, faster, and more innovative digital products with our expert services.
                        </p>

                        {/* Email Input and Button */}
                        {/* <div className="max-w-4xl mx-auto flex flex-col sm:flex-row gap-4 relative bg-white rounded-3xl p-4 sm:p-[0.28rem] border border-[#F0ECE7] shadow-ctm urbanist">
                <input
                  type="email"
                  placeholder="Your Email Address"
                  className="flex-1 px-6 py-3 rounded-full pr-16 focus:outline-none w-full sm:w-[60%] inter"
                />
                <button className="px-2 py-3 bg-gradient-to-r from-[#2AACD0] to-[#24B47B] text-white rounded-3xl hover:bg-gradient-to-r hover:from-[#24B47B] hover:to-[#2AACD0] transition-all text-lg w-[70%] sm:w-[40%] mx-auto sm:mx-auto border border-[#989897] shd-ctm">
                  Get Started
                </button>
              </div> */}

                        <button
                            className=" text-white px-4 py-2 rounded-full text-sm md:text-base font-semibold consultation-btn"

                        >
                            Get Started
                        </button>

                    </div>
                </motion.div>
            </div>



            <motion.div className="p-4 " initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true, amount: 0.2 }}
                transition={{ duration: 0.5 }}>
                <h3 className="text-lg font-semibold mb-4 text-[#282C32] inline-block border-b-2 border-[#1DBB91] pb-3">
                    Categories
                </h3>



                <div className="flex flex-col gap-4">

                    <div className="flex justify-between items-center text-[#282C32] border border-[#F0F0F0] py-4 px-4 rounded-xl transition-all  hover:text-white cursor-pointer hover:opacity-90 grad-hover">
                        <span className="fn-17 ">Database Security</span>
                        <span className="fn-17 ">(08)</span>
                    </div>

                    <div className="flex justify-between items-center text-[#282C32] border border-[#F0F0F0] py-4 px-4 rounded-xl transition-all  hover:text-white cursor-pointer hover:opacity-90 grad-hover">
                        <span className="fn-17 ">IT Consultancy</span>
                        <span className="fn-17 ">(08)</span>
                    </div>

                    <div className="flex justify-between items-center text-[#282C32] border border-[#F0F0F0] py-4 px-4 rounded-xl transition-all  hover:text-white cursor-pointer hover:opacity-90 grad-hover">
                        <span className="fn-17 ">App Development</span>
                        <span className="fn-17">(08)</span>
                    </div>
                    <div className="flex justify-between items-center text-[#282C32] border border-[#F0F0F0] py-4 px-4 rounded-xl transition-all  hover:text-white cursor-pointer hover:opacity-90 grad-hover">
                        <span className="fn-17">UI/UX</span>
                        <span className="fn-17">(08)</span>
                    </div>



                </div>
            </motion.div>



            <motion.div className="p-4 " initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true, amount: 0.2 }}
                transition={{ duration: 0.5 }}>
                <h3 className="text-lg font-semibold mb-4 text-[#282C32] inline-block border-b-2 border-[#1DBB91] pb-3">
                    Recent Post
                </h3>



                <div className="flex flex-col gap-4">

                    <div className="flex gap-4 border-b-2 border-[#F0F0F0] pb-4 items-center">
                        <div className="w-20 h-20 overflow-hidden rounded-lg flex-shrink-0">
                            <img
                                src={blog_Small}
                                alt="Blog image"
                                className="w-full h-full object-cover"
                            />
                        </div>

                        <div className="flex flex-col">
                            {/* <span className="fn-15 text-[#1DBB91]">18 Dec, 2024</span> */}

                            <span className="flex items-center gap-1 fn-15 text-[#1DBB91]">
                                <img src={calender} alt="Admin" className="w-4 h-4" />
                                18 Dec, 2024
                            </span>

                            <span className="fn-17 text-[#282C32] mt-1">
                                Keep Your Business Safe & Endure High Availability
                            </span>
                        </div>
                    </div>


                    <div className="flex gap-4 border-b-2 border-[#F0F0F0] pb-4 items-center">
                        <div className="w-20 h-20 overflow-hidden rounded-lg flex-shrink-0">
                            <img
                                src={blog_Small2}
                                alt="Blog image"
                                className="w-full h-full object-cover"
                            />
                        </div>

                        <div className="flex flex-col">
                            {/* <span className="fn-15 text-[#1DBB91]">18 Dec, 2024</span> */}

                            <span className="flex items-center gap-1 fn-15 text-[#1DBB91]">
                                <img src={calender} alt="Admin" className="w-4 h-4" />
                                18 Dec, 2024
                            </span>

                            <span className="fn-17 text-[#282C32] mt-1">
                                Keep Your Business Safe &
                                Endure High Availability
                            </span>
                        </div>
                    </div>

                    <div className="flex gap-4  pb-4 items-center">
                        <div className="w-20 h-20 overflow-hidden rounded-lg flex-shrink-0">
                            <img
                                src={blog_Small3}
                                alt="Blog image"
                                className="w-full h-full object-cover"
                            />
                        </div>

                        <div className="flex flex-col">
                            {/* <span className="fn-15 text-[#1DBB91]">18 Dec, 2024</span> */}

                            <span className="flex items-center gap-1 fn-15 text-[#1DBB91]">
                                <img src={calender} alt="Admin" className="w-4 h-4" />
                                18 Dec, 2024
                            </span>

                            <span className="fn-17 text-[#282C32] mt-1">
                                Keep Your Business Safe &
                                Endure High Availability
                            </span>
                        </div>
                    </div>



                </div>
            </motion.div>


{/* 
            <motion.div className="p-4" initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true, amount: 0.2 }}
                transition={{ duration: 0.5 }}>
                <h3 className="text-lg font-semibold mb-4 text-[#282C32] inline-block border-b-2 border-[#1DBB91] pb-3">
                    Tags
                </h3>

                <div className="flex flex-wrap gap-3 ">
                    <span className="text-[#282C32] px-4 py-2 rounded-md border border-[#E6E5E5] text-sm sm:text-base">
                        Security
                    </span>


                    <span className="text-[#282C32] px-4 py-2 rounded-md border border-[#E6E5E5] text-sm sm:text-base">
                        Business
                    </span>


                    <span className="text-[#282C32] px-4 py-2 rounded-md border border-[#E6E5E5] text-sm sm:text-base">
                        Digital
                    </span>


                    <span className="text-[#282C32] px-4 py-2 rounded-md border border-[#E6E5E5] text-sm sm:text-base">
                        Technology
                    </span>


                    <span className="text-[#282C32] px-4 py-2 rounded-md border border-[#E6E5E5] text-sm sm:text-base">
                        Change
                    </span>


                    <span className="text-[#282C32] px-4 py-2 rounded-md border border-[#E6E5E5] text-sm sm:text-base">
                        Video
                    </span>



                    <span className="text-[#282C32] px-4 py-2 rounded-md border border-[#E6E5E5] text-sm sm:text-base">
                        UI/UX Desing
                    </span>
                </div>
            </motion.div> */}


        </div>






     
  )
}

export default BlogTagsColumn
