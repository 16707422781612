

import { useState } from "react"


import { motion } from "framer-motion"

import srvc_graph from "../../assets/images/graph.svg"
import "./ServiceGraph.css"
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Text } from "recharts"
import ProcessTimeline from "./MobileGraph"




const data = [
    { name: "Discovery & Research", value: 30, description: "Understand client needs and market opportunities." },
    { name: "Planning & Strategy", value: 70, description: "Create a roadmap, select technology, and plan timelines." },
    { name: "Design & UX", value: 40, description: "Develop wireframes and interactive UI/UX prototypes." },
    { name: "Development", value: 80, description: "Implement features using Agile sprints and testing." },
    { name: "QA & Testing", value: 50, description: "Ensure product quality through manual and automated tests." },
    { name: "Deployment & Launch", value: 90, description: "Deploy the final product with monitoring and training." },
    { name: "Maintenance & Support ", value: 100, description: "Provide continuous updates, fixes, and client support." }
];

const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
        return (
            <div className="p-3 bg-white shadow-md rounded-md border border-gray-200 max-w-xs">
                <p className="text-gray-800 font-semibold">{payload[0].payload.name}</p>
                <p className="text-gray-600 text-sm">{payload[0].payload.description}</p>
            </div>
        );
    }
    return null;
};

// const CustomDot = (props) => {
//     const { cx, cy, payload, index } = props;

//     // Alternate positioning: Even indexes go above, Odd indexes go below
//     const isAbove = index % 2 === 0;
//     const yOffset = isAbove ? -40 : 30; // Above: -40, Below: +30

//     return (
//         <>
//             {/* Circle Dot */}
//             <circle cx={cx} cy={cy} r={6} fill="#10B981" stroke="#fff" strokeWidth={2} />

//             {/* Name Label */}
//             <Text x={cx} y={cy + yOffset} fill="#333" fontSize={14} fontWeight="bold" textAnchor="middle">
//                 {payload.name}
//             </Text>

//             {/* Description Label */}
//             <Text x={cx} y={cy + yOffset + (isAbove ? 15 : -15)} fill="#666" fontSize={12} width={180} textAnchor="middle">
//                 {payload.description}
//             </Text>
//         </>
//     );
// };


// const CustomDot = (props) => {
//     const { cx, cy } = props;
//     return (
//         <circle cx={cx} cy={cy} r={6} fill="#10B981" stroke="#fff" strokeWidth={2} />
//     );
// };



export default function ServiceGraph() {


    return (
        <section className="py-0 sm:py-16 px-4 workprocess">
            <div className="max-w-7xl mx-auto">

                <motion.div className="text-center mb-12" initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true, amount: 0.2 }}
                    transition={{ duration: 0.5 }}
                >

                    <h2 className="text-3xl md:text-3xl font-medium text-[#0C0C0C] mb-4 mt-0 sm:mt-8 poppins lg-text max-w-2xl mx-auto">Lorem <span className="text-[#1DBB91]">ipsum</span> dolor</h2>
                    <p className="text-[#666666] max-w-3xl text-lg mx-auto">
                        Offering the most recent modern technology to grow your business exponentially equipped with top-quality technology that meets your desires.

                    </p>
                </motion.div>
                {/* <div className="relative w-full h-[400px] p-4">
          
                    {data.map((item, index) => {
                        const isAbove = index % 2 === 0; 
                        const positions = [
                            { left: "0%", bottom: "50%" },  
                            { left: "12%", bottom: "10%" }, 
                            { left: "27%", top: "10%" }, 
                            { left: "45%", bottom: "10%" }, 
                            { left: "59%", top: "10%" },  
                            { left: "80%", bottom: "40%" }, 
                            { left: "98%", top: "0%" }   
                        ];

                        return (
                            <div
                                key={index}
                                className="absolute text-center w-40"
                                style={positions[index]}
                            >
                                <p className="text-gray-800 font-semibold">{item.name}</p>
                                <p className="text-gray-600 text-sm">{item.description}</p>
                            </div>
                        );
                    })}

                    <ResponsiveContainer width="100%" height={400}>
                        <LineChart data={data}>
                            <Line
                                type="monotone"
                                dataKey="value"
                                stroke="#10B981"
                                strokeWidth={3}
                                // dot={<CustomDot />}
                                activeDot={{ fill: "#10B981", stroke: "#fff", strokeWidth: 2, r: 8 }}
                            />
                        </LineChart>
                    </ResponsiveContainer>
                </div> */}
                <ProcessTimeline/>

            </div>
        </section>
    )
}

