import { AccountCircle } from "@mui/icons-material"
import "./BlogPersonal.css"

import "./BlogPersonal.css"

import BlogBanner from "./BlogBanner"
import BlogColumn from "./BlogColumn"
import BlogTagsColumn from "./BlogTagsColumn"
import blogFirst from "../../data/blogData.json"
import blogAI from "../../data/blogAI.json"
import blogWeb from "../../data/blogWeb.json"
import { useParams } from "react-router-dom";
export default function BlogPersonal() {

  const { slug } = useParams();

  // console.log("Blog",blogFirst)


  const allBlogs = [...blogFirst, ...blogWeb, ...blogAI];

  const selectedBlog = allBlogs.find((blog) => blog?.topic?.slug === slug);

  // console.log("allBlogs", allBlogs)

  // console.log("selectedBlog", selectedBlog)


  // const contentArray = [
  //   {
  //     type: "tags",
  //     tags: [
  //       { label: "Productivity", bgColor: "#FFFFFF", borderColor: "#E1E4EB" },
  //       { label: "Aug 8, 2024", bgColor: "#FFFFFF", borderColor: "#E1E4EB" }
  //     ]
  //   },
  //   {
  //     type: "header",
  //     text: "Custom mobile app for your business Why & how to build one"
  //   },
  //   {
  //     type: "paragraph",
  //     text: "Discover why your business needs a custom mobile app and how to develop one for growth, customer engagement, and income. Get expert insights today!"
  //   },
  //   {
  //     type: "author",
  //     authorName: "techrabbit",
  //     authorLogo: bloglogo
  //   },
  //   {
  //     type: "section",
  //     content: [
  //       {
  //         type: "header",
  //         text: "Introduction, Augmented Reality in the Classroom"
  //       },
  //       {
  //         type: "paragraph",
  //         text: "In today’s digital world, businesses need to be where their customers are – and that’s on mobile devices. A custom mobile app is no longer a luxury; it’s a necessity. Whether you're a startup, a local business, or an enterprise, a well-designed app can boost engagement, streamline operations, and enhance customer satisfaction. But how do you develop a custom mobile app that meets your business needs? Let’s explore in depth!"
  //       }
  //     ]
  //   },

  // ];

  // const imageArray = [
  //   { src: image1, alt: "AR planning sketches and materials" },
  //   { src: blogimage, alt: "Web design workspace" },

  // ];
  return (
    <article className="max-w-[1400px] mx-auto px-4 py-8 blog-personal inter  relative  ">

      <BlogBanner blogData= {selectedBlog} />
      {/* md:grid-cols-[2fr_1.3fr]  */}
      <div className="grid grid-cols-1  lg:grid-cols-[3fr_1.1fr] gap-6">

        {/* Left Column - Blog Posts */}
        <BlogColumn blogData= {selectedBlog} />

        {/* Right Column - Blog Posts */}
        <BlogTagsColumn />






      </div>




    </article>

    // <article className="max-w-5xl mx-auto px-4 py-8 blog-personal inter relative -z-10 pointer-events-none">
    //   <motion.div className="mb-8 bg-white rounded-2xl p-1" initial={{ opacity: 0, y: 50 }} whileInView={{ opacity: 1, y: 0 }} viewport={{ once: true, amount: 0.2 }} transition={{ duration: 0.5 }}>
    //     <div className="bg-gradient-to-b from-[#EBFFFA] to-[#FFFFFF] rounded-2xl border border-[#E1E4EB] p-6">


    //       {contentArray.filter(section => section.type === "tags").map((section, index) => (
    //         <div key={index} className="flex items-center mb-4 gap-2">
    //           {section.tags.map((tag, index) => (
    //             <span key={index} className="text-sm font-medium text-[#3D3D3D] bg-[#FFFFFF] px-3 py-1 rounded-full border" style={{ borderColor: tag.borderColor }}>
    //               {tag.label}
    //             </span>
    //           ))}
    //         </div>
    //       ))}

    //       {/* Render Header */}
    //       {contentArray.filter(section => section.type === "header").map((section, index) => (
    //         <h1 key={index} className="text-3xl md:text-4xl font-medium text-[#191919] mb-4 inter lg-text">
    //           {section.text}
    //         </h1>
    //       ))}


    //       {contentArray.filter(section => section.type === "paragraph").map((section, index) => (
    //         <p key={index} className="text-base text-[#606060] mb-6 inst-sans">
    //           {section.text}
    //         </p>
    //       ))}


    //       {contentArray.filter(section => section.type === "author").map((section, index) => (
    //         <div key={index} className="flex items-center gap-2 border-t border-[#E1E4EB] pt-4">
    //           <div className="w-8 h-8 rounded-full bg-teal-500 flex items-center justify-center">
    //             <img src={section.authorLogo} alt="" />
    //           </div>
    //           <span className="text-[#191919] font-medium">{section.authorName}</span>
    //         </div>
    //       ))}

    //     </div>
    //   </motion.div>


    //   {imageArray.map((image, index) => (
    //     <motion.img
    //       key={index}
    //       src={image.src}
    //       alt={image.alt}
    //       className="w-full rounded-xl mb-8"
    //       initial={{ opacity: 0, y: 50 }}
    //       whileInView={{ opacity: 1, y: 0 }}
    //       viewport={{ once: true, amount: 0.2 }}
    //       transition={{ duration: 0.5 }}
    //     />
    //   ))}


    // </article>


  )
}

