import React from 'react'
import { motion } from 'framer-motion'
import "./AIServices.css"
import begin_img from "../../../assets/images/Services/AIDev/begin_img.png"

const BeginSection = () => {
    return (

        <section className="relative  py-0 md:py-16 px-4 ">


            <div className="max-w-7xl mx-auto">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-start p-0 lg:p-4 beginsection">

                    <motion.div
                        className="space-y-8 flex flex-col h-full relative group justify-center"
                        initial={{ opacity: 0, y: 50 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true, amount: 0.2 }}
                        transition={{ duration: 0.5 }}
                    >

                        <img src={begin_img} alt="" />



                    </motion.div>


                    <motion.div className="flex flex-col h-full justify-center" initial={{ opacity: 0, y: 50 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true, amount: 0.2 }}
                        transition={{ duration: 0.5 }}>
                        <div className="space-y-4">
                            <h3 className="text-2xl md:text-4xl font-semibold text-[#0C0C0C] lg-text">
                                Lorem ipsum dolor sit amet ipsum consectetur, adipis
                            </h3>

                            <div>
                                <p className=" text-[#606060]" style={{ fontSize: "17px" }}>

                                    Explore SAP's standout features that drive productivity and success. From task
                                </p>

                                <p className=" text-[#606060]" style={{ fontSize: "17px" }}>

                                    tracking to seamless collaboration, discover how SAP transforms project
                                    management.Your brand is always at your customer’s fingertips. A well designed app with a recognizable logo and seamless user experience increases brand recall and loyalty
                                </p>

                                <p className=" text-[#606060]" style={{ fontSize: "17px" }}>


                                    Your brand is always at your customer’s fingertips. A well-designed app with a recognizable logo and seamless user experience increases brand recall and loyalty.



                                </p>

                                <p className=" text-[#606060]" style={{ fontSize: "17px" }}>
                                    Your brand is always at your customer’s fingertips. A well-designed app with a recognizable logo and seamless user experience increases brand recall and loyalty.
                                </p>
                            </div>


                        </div>
                    </motion.div>

                </div>
            </div>
        </section>
    )
}

export default BeginSection
