import parentician from "../../assets/images/BestWork/parentician.png"
import satori from "../../assets/images/BestWork/satori.png"
import { useRef } from "react"
import shipora from "../../assets/images/BestWork/shipora.png"
// import { motion } from "framer-motion"
import sindhisoulmate from "../../assets/images/BestWork/sindhisoulmate.png"
import { Link } from "react-router-dom"
import sneh from "../../assets/images/BestWork/sneh.png"

import pharma from "../../assets/images/BestWork/pharma.png"
import parentician_logo from "../../assets/images/BestWork/parentician_logo.png"
import pharma_logo from "../../assets/images/BestWork/pharma_logo.png"
import satori_logo from "../../assets/images/BestWork/satori_logo.png"
import shipora_logo from "../../assets/images/BestWork/shipora_logo.png"
import sindhsolumate_logo from "../../assets/images/BestWork/sindhsolumate_logo.png"
import sneh_logo from "../../assets/images/BestWork/sneh_logo.png"

import KOV from "../../assets/images/BestWork/KOV.png"
import KOV_logo from "../../assets/images/BestWork/KOV_logo.png"

import SecondOpinion from "../../assets/images/BestWork/SecondOpinion.png"
import SecondOpinion_logo from "../../assets/images/BestWork/SecondOpinion_logo.png"

import QCC from "../../assets/images/BestWork/QCC.png"
import QCC_logo from "../../assets/images/BestWork/QCC_logo.png"



import { useNavigate } from "react-router-dom"



import { motion } from "framer-motion";
import arrow_icon from "../../assets/images/arrow_icon.svg"
import { useState, useEffect } from "react"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import filter from "../../assets/images/BestWork/filter.svg"
import line_Svg from "../../assets/images/aniline.svg"
import lg1 from "../../assets/images/BestWork/lg1.svg"
import lg2 from "../../assets/images/BestWork/lg2.svg"
import lg3 from "../../assets/images/BestWork/lg3.svg"
import lg4 from "../../assets/images/BestWork/lg2.svg"
import lg5 from "../../assets/images/BestWork/lg1.svg"


// import "./AllWork.css"







export default function AllWork() {
    const navigate = useNavigate()

    const [isOpen, setIsOpen] = useState(false)
    const [selected, setSelected] = useState("All work")

    const categories = ["All work", "Web App", "Mobile App", "UI/UX Design"]



    const projects = [
        {
            name: "Shipora",
            description: "A platform that facilitates private and e-commerce shipments, along with a marketplace for Indian vendors targeting the Canadian market.",
            image: shipora,
            logo: shipora_logo,
            tag: ["Web App", "Mobile App"],
            slug:"/projects/shipora"
        },
        {
            name: "Parentician",
            description: "A startup that supports modern parents with digital books, PDF checklists, webinars, doctor consultations, and informative blogs.",
            image:
                parentician,
            logo: parentician_logo,
            tag: ["UI Design"],
            slug:"/projects/parentician"


        },
        {
            name: "Pharma Ray",
            description: "A matrimonial platform designed for the Sindhi community, offering advanced matchmaking features.",
            image:
                pharma,
            logo: pharma_logo,
            tag: ["Mobile App"],
                  slug:"/work"
           

        },
        {
            name: "Sindhi Soulmate",
            description: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. A, ab nemo! Expedita!",
            image: sindhisoulmate,
            logo: sindhsolumate_logo,
            tag: ["Mobile App"],
            slug:"/projects/sindhi-soulmate"

        },
        {
            name: "Satori",
            description: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. A, ab nemo! Expedita!",
            image:
                satori,
            logo: satori_logo,
            tag: ["UI/UX Design"],
            slug:"/work"


        },
        {
            name: "Sneh",
            description: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. A, ab nemo! Expedita!",
            image:
                sneh,
            logo: sneh_logo,
            tag: ["Mobile App"],
            slug:"/work"


        },

        // {
        //     name: "Kov Interior",
        //     description: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. A, ab nemo! Expedita!",
        //     image:
        //         KOV,
        //     logo: KOV_logo,
        //     tag: ["Wordpress"],
        //     slug:"/work"


        // },


        {
            name: "Second Opinion",
            description: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. A, ab nemo! Expedita!",
            image:
                SecondOpinion,
            logo: SecondOpinion_logo,
            tag: ["Mobile App"],
            slug:"/work"


        },


        {
            name: "Qcc",
            description: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. A, ab nemo! Expedita!",
            image:
                QCC,
            logo: QCC_logo,
            tag: ["Mobile App"],
            slug:"/work"


        },


    ]
    const [currentImage, setCurrentImage] = useState(0);
    const images = [lg1, lg2, lg3]; // Example images, use your actual paths

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImage((prevImage) => (prevImage + 1) % images.length);
        }, 3000);

        return () => clearInterval(interval); // Clean up interval on unmount
    }, []);
    // const [currentIndex, setCurrentIndex] = useState(0);

    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         setCurrentIndex((prevIndex) => (prevIndex + 1) % logos.length);
    //     }, 2000); // Changes logo every 2 seconds

    //     return () => clearInterval(interval);
    // }, []);


    const stackRef = useRef(null);

    // useEffect(() => {
    //     let stack = stackRef.current;
    //     if (!stack) return;

    //     // Reverse the order of children initially
    //     [...stack.children].reverse().forEach((i) => stack.append(i));

    //     function swap() {
    //         let card = stack.querySelector(".card:last-child");
    //         if (!card) return;
    //         card.style.animation = "swap 700ms forwards";

    //         setTimeout(() => {
    //             card.style.animation = "";
    //             stack.prepend(card);
    //         }, 700);
    //     }

    //     // Auto-swap every 2 seconds
    //     const interval = setInterval(swap, 2000);

    //     // Cleanup interval on unmount
    //     return () => clearInterval(interval);
    // }, []);
    const [showAll, setShowAll] = useState(false);
    const filteredProjects = selected === "All work"
        ? projects
        : projects.filter(project => project.tag.includes(selected));

    const displayedProjects = showAll ? filteredProjects : filteredProjects.slice(0, 6);

    return (

        <>

            {/* <svg xmlns="http://www.w3.org/2000/svg" width="20%" height="20%" viewBox="0 0 500 351">
                <path
                    d="M 486.864 13.61 C 390.545 194.927 248.175 159.17 204.087 125.947 C 158.968 91.947 218.97 66.259 287.488 106.239 C 356.006 146.219 355.756 187.164 337.472 207.033 C 288.765 259.964 159.719 192.438 143.713 210.412 C 138.097 216.718 190.328 248.139 220.094 267.002 C 157.192 262.78 57.785 292.624 13.136 337.39"
                    stroke="url(#gradient1)"
                    stroke-width="26.27"
                    stroke-linejoin="round"
                    stroke-linecap="round"
                    fill="transparent"
                    opacity="1"
                    pathLength="1"
                    stroke-dashoffset="1"
                    stroke-dasharray="1px 1px">
                    <animate
                        attributeName="stroke-dashoffset"
                        from="1"
                        to="0"
                        dur="5s"
                        fill="freeze" />
                </path>
                <defs>

                    <linearGradient id="gradient1" x1="0%" y1="0%" x2="100%" y2="100%">
                        <stop offset="0%" stop-color="#2BAAE2" />
                        <stop offset="100%" stop-color="#24B574" />
                    </linearGradient>
                </defs>
            </svg> */}

            <section className="py-16 px-4 max-w-7xl mx-auto allwork relative   -z-10"
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true, amount: 0.2 }}
                transition={{ duration: 0.5 }}   >




                <motion.div className="relative text-left py-5 pt-0 md:py-7 lg:py-16" initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true, amount: 0.2 }}
                    transition={{ duration: 0.5 }} >

                    <div className="flex items-center gap-4 relative">
                        <h2 className="text-3xl md:text-5xl font-medium text-[#212A40] mb-4 poppins lg-text">
                            Our Work
                        </h2>
                        {/* <img
                            src={images[0]}
                            alt="Best Work Logo"
                            className={`absolute top-2 left-[21%] transition-opacity duration-500 ease-in-out ${currentImage === 0 ? 'z-10 animate__animated animate__slideInDown' : 'z-0'}`}
                        />

                        <img
                            src={images[1]}
                            alt="Best Work Logo"
                            className={`absolute top-3 left-[21%] transition-opacity duration-500 ease-in-out ${currentImage === 1 ? 'z-10 animate__animated animate__slideInDown' : 'z-0'}`}
                        />

                        <img
                            src={images[2]}
                            alt="Best Work Logo"
                            className={`absolute top-4 left-[21%] transition-opacity duration-500 ease-in-out ${currentImage === 2 ? 'z-10 animate__animated animate__slideInDown' : 'z-0'}`}
                        /> */}


                        {/* <div className="stack" ref={stackRef}>

                            <img
                                src={images[0]} className="card"/>
                                     <img
                                src={images[1]} className="card"/>
                                     <img
                                src={images[2]} className="card" />
                          
                        </div> */}

                    </div>



                    <p className="text-[#485E79] max-w-2xl text-lg ">
                        Lorem ipsum dolor sit amet consectetur, adipisicing elit. A, ab nemo! Expedita! Lorem ipsum dolor sit amet Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quibusdam, repudiandae?
                    </p>




                </motion.div>

                <motion.div className="w-full px-4 sm:px-6 lg:px-8 py-8 inter" initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true, amount: 0.2 }}
                    transition={{ duration: 0.5 }} >
                    <div className="flex flex-col sm:flex-row items-start sm:items-center gap-4 sm:gap-5 justify-end">
                        <h2 className="text-[#8C8C8C] text-base font-medium opacity-80">Filter Category</h2>

                        <div className="relative w-full sm:w-auto">
                            <button
                                onClick={() => setIsOpen(!isOpen)}
                                className="w-full sm:w-auto flex items-center justify-between gap-2 px-4 py-2 bg-[#F4F5F6] hover:bg-gray-100 rounded-full inter border border-[#DCDCDC] text-sm text-[#818181]"
                            >
                                <img src={filter} alt="Filter Icon" className="w-5 h-5" />
                                <span>{selected}</span>
                                <KeyboardArrowDownIcon className={`w-5 h-5 transition-transform ${isOpen ? "rotate-180" : ""}`} />
                            </button>

                            {isOpen && (
                                <div className="absolute right-0 mt-2 w-full sm:w-48 bg-white rounded-lg shadow-lg border border-gray-100 z-10">
                                    {categories.map((category) => (
                                        <button
                                            key={category}
                                            onClick={() => {
                                                setSelected(category)
                                                setIsOpen(false)
                                            }}
                                            className="w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-50 first:rounded-t-lg last:rounded-b-lg"
                                        >
                                            {category}
                                        </button>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                </motion.div>


                <motion.div className="relative" >

                    {/* <div className="absolute top-[-65px] right-0">
                    <button className="text-[#3D3D3D] px-6 py-3 rounded-full font-semibold bg-[#EFF1F4] view-all transition duration-200 ease-in-out hover:bg-[#D9DEE3] hover:text-[#2C2C2C]">
                        View All
                    </button>
                </div> */}



                    <motion.div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8" >
                        {displayedProjects.map((project, index) => (
                            <motion.div
                                key={index}
                                className="group rounded-2xl overflow-hidden bg-white hover:shadow-xl transition-shadow duration-300 cursor-pointer relative"
                                initial={{ opacity: 0, y: 50 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                viewport={{ once: true, amount: 0.2 }}
                                transition={{ duration: 0.5 }}
                                onClick={() => navigate(`${project?.slug}`)} 
                            >

                                <div className="relative aspect-[4/3] overflow-hidden">
                                    <img
                                        src={project.image || "/placeholder.svg"}
                                        alt={`${project.name} project screenshot`}
                                        className="w-full h-full object-cover transform group-hover:scale-105 transition-transform duration-300"
                                    />
                                </div>



                                <div className="p-6">
                                    <div className="flex items-start gap-3 mb-3">

                                        <div className="w-10 h-10 rounded-[10px] overflow-hidden flex-shrink-0 shadow-[0px_0px_1.19px_0px_#00000040] flex items-center justify-center">
                                            <img
                                                src={project.logo || "/placeholder.svg"}
                                                alt={`${project.name} logo`}
                                                className="max-w-full max-h-full object-contain"
                                            />
                                        </div>


                                        <div className="flex flex-col">
                                            <h3 className="font-semibold text-base text-[#434343] fightree">{project.name}</h3>
                                            <p className="text-[#8A8A8A] text-sm fightree pr-8">{project.description}</p>
                                            <div className="flex flex-wrap space-x-2 mt-2 sm:mt-4 pr-8">
                                                {project?.tag.map((tag, index) => (
                                                    <span
                                                        key={index}
                                                        className="text-xs md:text-sm py-1 px-3 rounded-full border border-[#E9E9E9] mb-2 text-[#5A5A5A]"
                                                    >
                                                        {tag}
                                                    </span>
                                                ))}
                                            </div>

                                        </div>


                                        {/* <div className="flex items-center justify-center ml-auto w-11 h-16" >
                                            <img
                                                src={arrow_icon}
                                                alt="Arrow Icon"

                                                className="bg-white rounded-full "
                                            />
                                        </div> */}


                                        <div className="absolute bottom-12 right-6  p-2 rounded-full transition-all duration-300 ease-in-out cursor-pointer hover:shadow-xl hover:scale-125 hover:bg-[#f9fafb] box-shadow-blog ">
                                            <img src={arrow_icon} alt="arrow icon" className="w-2.5 h-2.5" />
                                        </div>
                                    </div>
                                </div>

                            </motion.div>
                        ))}
                    </motion.div>
                    {/* <div className="flex justify-center mt-5">
                        <button
                            className="text-[#3D3D3D] px-6 py-3 rounded-full font-semibold bg-[#EFF1F4] view-all transition duration-200 ease-in-out hover:bg-[#D9DEE3] hover:text-[#2C2C2C]"
                            onClick={() => setShowAll(!showAll)}
                        >
                            {showAll ? 'Show Less' : 'View All'}
                        </button>
                    </div> */}
                    {filteredProjects.length > 6 && (
                        <div className="flex justify-center mt-5">
                            <button
                                className="text-white px-6 py-3 rounded-full font-medium bg-[#2D2D2D] 
                hover:bg-[#2C2C2C] hover:text-[#FFF] transition-transform duration-200 
                ease-in-out hover:scale-105 border border-[#D9DEE3]"
                                onClick={() => setShowAll(!showAll)}
                            >
                                {showAll ? 'Show Less' : 'View All'}
                            </button>
                        </div>
                    )}

                </motion.div>
            </section>

        </>
    )
}

