
import { useState } from "react"
import { useEffect } from "react"
import Typed from "typed.js";
import { useRef } from "react"
import "./PricingHero.css"
import overlay1 from "../../assets/images/Pricing/overlay_icon1.png"
import overlay2 from "../../assets/images/Pricing/overlay_icon2.png"
import overlay3 from "../../assets/images/Pricing/overlay_icon3.png"
import overlay4 from "../../assets/images/Pricing/overlay_icon4.png"
import overlay5 from "../../assets/images/Pricing/overlay_icon5.png"
import overlay6 from "../../assets/images/Pricing/overlay_icon6.png"
import PricingModels from "../PricingModals/PricingModals";

export function PricingHero({ isOpen }) {
    const fullText = "Streamlining Your Businesses With Web and App Development Services";
    const [displayedText, setDisplayedText] = useState("");
    const typingSpeed = 70;

    const pricingRef = useRef(null); // Create a reference for PricingModels

    const scrollToPricing = () => {
      if (pricingRef.current) {
        pricingRef.current.scrollIntoView({ behavior: "smooth" });
      }
    };
    return (

        <>
        <section className="container mx-auto px-4 py-16 text-center md:py-20 relative pricinghero" >


            <div className="absolute inset-0 pointer-events-none overflow-hidden -z-10">
                {/* Left Side Icons */}
                <img src={overlay1} alt="" className="overlay left-10 top-10 w-8 md:w-10 lg:w-16 opacity-70 animate-float-rotate hidden sm:block" />
                <img src={overlay2} alt="" className="overlay left-16 top-1/3 w-8 md:w-10 lg:w-16 opacity-60 animate-zigzag hidden sm:block" />
                <img src={overlay3} alt="" className="overlay left-28 top-2/3 w-8 md:w-10 lg:w-16  opacity-75  animate-float-scale hidden sm:block" />

                {/* Right Side Icons */}
                <img src={overlay4} alt="" className="overlay right-10 top-12 w-8 md:w-10 lg:w-16 animate-wave hidden sm:block" />
                <img src={overlay5} alt="" className="overlay right-16 top-1/3 w-8 md:w-10 lg:w-16 opacity-60 animate-fade hidden sm:block" />
                <img src={overlay6} alt="" className="overlay right-28 top-2/3 w-8 md:w-10 lg:w-16 opacity-75  animate-zigzag hidden sm:block" />
            </div>

            <div className="animation" >



                <h1 className="mx-auto max-w-5xl lg:max-w-3xl text-3xl md:text-4xl lg:text-5xl  lg-text text-[#191919] font-semibold" >

                    Choose the Right Pricing Model for Your Business

                </h1>


                <div style={{

                }}>
                    <p className={`mx-auto mt-6 max-w-2xl text-xl font-medium text-[#4D4D4D] ${isOpen ? "relative -z-10 pointer-events-none" : ""
                        }`} >
                        Flexible engagement models designed to fit your project’s scope, budget, and timeline.

                    </p>

                    <button
                        // className=" text-white px-6 py-2 rounded-full text-sm font-semibold consultation-btn mt-8"
                        className={`text-white px-4 py-3 sm:px-6 sm:py-3 rounded-3xl text-base sm:text-lg font-semibold consultation-btn mt-8 border-2 border-[#D0D0D0] `}

                        onClick={scrollToPricing}


                    >
                        Know Project Cost
                    </button>

                </div>
            </div>



        </section>

        <div ref={pricingRef}>

        <PricingModels/>
        </div>

        </>
    )
}

