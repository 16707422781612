import { Description, AccessTime } from "@mui/icons-material"

// import "./OurMission.css"
import { motion } from "framer-motion";
import mobile_img from "../../assets/images/WorkDetails/Shipora/img8.webp"




export default function ShiporaScrollComp() {

    // const [activeSection, setActiveSection] = useState(null);
    // useEffect(() => {
    //     const handleScroll = () => {
    //         const scroll = window.scrollY + window.innerHeight / 3;
    //         const sections = document.querySelectorAll('.section');

    //         let foundActiveSection = null;
    //         sections.forEach((section) => {
    //             const sectionTop = section.offsetTop;
    //             const sectionBottom = sectionTop + section.offsetHeight;

    //             if (sectionTop <= scroll && sectionBottom > scroll) {
    //                 foundActiveSection = section;
    //             }
    //         });

    //         setActiveSection(foundActiveSection);
    //     };

    //     window.addEventListener('scroll', handleScroll);
    //     handleScroll();  // Run once on mount to check initial scroll position.

    //     return () => {
    //         window.removeEventListener('scroll', handleScroll);  // Clean up on component unmount.
    //     };
    // }, []);
    const features = [
        {
            icon: <Description className="text-emerald-600 w-6 h-6" />,
            title: "Our Mission",
            description: "Connect tools for unified workflow efficiency.",
        },
        {
            icon: <AccessTime className="text-emerald-600 w-6 h-6" />,
            title: "Our Vision",
            description: "User-friendly interface boosts team productivity.",
        },
    ]

    return (
        <section className="py-16 px-4 scrollcomp">


            <div className="max-w-7xl mx-auto">
                <div className={`grid grid-cols-1 sm:grid-cols-2 gap-12 items-center `}>
                    <div


                        className="flex justify-center sm:justify-start "
                    >
                        <img
                            src={mobile_img}
                            alt="Team collaboration illustration"
                            className="w-full sm:max-w-md h-auto object-cover "
                        />
                    </div>

                    <div className="space-y-8 ">
                        <div
                            className="space-y-6 "
                            initial={{ opacity: 0, y: 50 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            viewport={{ once: true, amount: 0.2 }}
                            transition={{ duration: 0.5 }}
                        >
                            <h2 className="text-2xl md:text-3xl leading-tight  text-[#272666]">
                                    Lorem Ipsum Dolor, sit amet Consecteur
                                </h2>
                                <p className="text-[#676767] text-lg md:text-xl ">
                                    Lorem ipsum dolor, sit amet consectetur adipisicing elit. Provident in doloremque officia dignissimos deleniti inventore porro possimus, suscipit natus. Commodi iure numquam facilis itaque?
                                </p>

                                <p className="text-[#676767] text-lg md:text-xl ">
                                    Lorem ipsum dolor, sit amet consectetur adipisicing elit. Provident in doloremque officia dignissimos deleniti inventore porro possimus, suscipit natus. Commodi iure numquam facilis itaque?
                                </p>
                        </div>
                    </div>


                </div>

                {/* <div className={`grid grid-cols-1 sm:grid-cols-2 gap-12 items-center pt-10 `}>
                    <div


                        className="flex justify-center sm:justify-start "
                    >
                        <img
                            src={mobile_img}
                            alt="Team collaboration illustration"
                            className="w-full max-w-md h-auto object-cover "
                        />
                    </div>

                    <div className="space-y-8 ">
                        <div
                            className="space-y-6 "
                            initial={{ opacity: 0, y: 50 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            viewport={{ once: true, amount: 0.2 }}
                            transition={{ duration: 0.5 }}
                        >
                            <h2 className="text-3xl md:text-4xl lg:text-5xl leading-tight  text-[#272666]">
                                Lorem Ipsum Dolor, sit amet Consecteur
                            </h2>
                            <p className="text-[#676767] text-2xl md:text-lg ">
                                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Provident in doloremque officia dignissimos deleniti inventore porro possimus, suscipit natus. Commodi iure numquam facilis itaque?

                                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Provident in doloremque officia dignissimos deleniti inventore porro possimus, suscipit natus. Commodi iure numquam facilis itaque?
                            </p>

                            <p className="text-[#676767] text-2xl md:text-lg ">
                                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Provident in doloremque officia dignissimos deleniti inventore porro possimus, suscipit natus. Commodi iure numquam facilis itaque?

                                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Provident in doloremque officia dignissimos deleniti inventore porro possimus, suscipit natus. Commodi iure numquam facilis itaque?
                            </p>
                        </div>
                    </div>



                </div> */}
            </div>
        </section>


    )
}
















