import React from 'react'

import blog1 from "../../assets/images/BlogDeatils/blog1.png"
import { motion } from "framer-motion";
import admin from "../../assets/images/BlogDeatils/admin.png"
import comments from "../../assets/images/BlogDeatils/comments.png"
import serviece from "../../assets/images/BlogDeatils/it_serviece.png"
import image1_blog from "../../assets/images/BlogDeatils/image1.png"
import image2_blog from "../../assets/images/BlogDeatils/image2.png"
import fb from "../../assets/images/BlogDeatils/fb.png"
import twitter from "../../assets/images/BlogDeatils/twitter.png"
import yt from "../../assets/images/BlogDeatils/yt.png"
// import blogData from "../../data/blogData.json"
// import blogData from "../../data/blogWeb.json"
import quote_icon from "../../assets/images/BlogDeatils/quote.svg"

import blog1_banner from "../../assets/images/BlogDeatils/blog1.png"
import blog1_sub from "../../assets/images/BlogDeatils/image1.png"
import blog1_sub2 from "../../assets/images/BlogDeatils/image2.png"

import blog2_banner from "../../assets/images/BlogDeatils/ai1.jpg"
import blog2_sub from "../../assets/images/BlogDeatils/ai2.jpg"
import blog2_sub2 from "../../assets/images/BlogDeatils/ai3.jpg"

import blog3_banner from "../../assets/images/BlogDeatils/webapp_2.jpg"
import blog3_sub from "../../assets/images/BlogDeatils/webapp_1.jpg"
import blog3_sub2 from "../../assets/images/BlogDeatils/webapp_3.jpg"








const BlogColumn = ({blogData}) => {

  const imageMap = {
    blog1_banner: blog1_banner,
    blog2_banner:blog2_banner,
    blog3_banner:blog3_banner,
    blog1_sub:blog1_sub,
    blog1_sub2:blog1_sub2,
    blog2_sub:blog2_sub,
    blog2_sub2:blog2_sub2,
    blog3_sub:blog3_sub,
    blog3_sub2:blog3_sub2,

    

  };
  console.log(blogData)
  return (
    <div className="prose max-w-none">
      <motion.img
       src={imageMap[blogData?.image?.src]}
        alt="AR planning sketches and materials"
        className="w-full rounded-xl "
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true, amount: 0.2 }}
        transition={{ duration: 0.5 }}
      />


      {/* <div className="flex flex-wrap items-center text-gray-600 gap-6 py-7 border-b border-[#E6E6E6]"> */}

        {/* <span className="flex items-center gap-2 text-[#858585] font-medium text-sm sm:text-base">
          <img src={admin} alt="Admin" className="w-4 h-4" />
          By {blogData?.metadata?.author}
        </span> */}

        {/* <span className="flex items-center gap-2 text-[#858585] font-medium text-sm sm:text-base">
          <img src={comments} alt="Comments" className="w-4 h-4" />
          {blogData?.metadata?.comments} Comments
        </span>

        <span className="flex items-center gap-2 text-[#858585] font-medium text-sm sm:text-base">
          <img src={serviece} alt="IT Services" className="w-4 h-4" />
          {blogData?.metadata?.category}
        </span> */}

      {/* </div> */}





      <div className="px-3 mt-6">



        <motion.h2 className="text-3xl font-medium text-[#0C0C0C] mb-4 inter" initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true, amount: 0.2 }}
          transition={{ duration: 0.5 }} >{blogData?.content?.[0]?.text}</motion.h2>

        <motion.p className="text-[#606060] mb-8 font-medium text-lg inst-sans" initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true, amount: 0.2 }}
          transition={{ duration: 0.5 }} >
          {blogData?.content?.[1]?.text}
        </motion.p>


        <motion.h2 className="text-3xl font-medium text-[#0C0C0C] mb-4 inter" initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true, amount: 0.2 }}
          transition={{ duration: 0.5 }} >       {blogData?.content?.[2]?.text}
        </motion.h2>


        <ul className="list-disc list-inside text-[#606060] font-medium text-lg inst-sans">


          <motion.li className=" text-black" initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true, amount: 0.2 }}
            transition={{ duration: 0.5 }}> {blogData?.content?.[3]?.items?.[0]?.title}</motion.li>
          <motion.p className="mt-2 pl-6" initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true, amount: 0.2 }}
            transition={{ duration: 0.5 }}>
            {blogData?.content?.[3]?.items?.[0]?.description}
          </motion.p>




          <motion.div className="py-8" initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true, amount: 0.2 }}
            transition={{ duration: 0.5 }}>
            <div className="relative bg-[#F5F5F5] p-8 rounded-lg">
              {/* Green vertical accent line */}
              <div className="absolute left-0 top-0 h-full w-1 bg-emerald-500" />

              {/* Quote text */}
              <p className="pl-6 text-[#858585] italic text-lg font-medium leading-relaxed">
                {blogData?.content?.[4]?.text}
              </p>

              {/* Decorative quote mark */}
              <div className="absolute bottom-4 right-4">
                <img src={quote_icon} alt="Quote Icon" className="w-10 h-10 text-emerald-500 transform " />
              </div>
            </div>
          </motion.div>


          {blogData?.content?.[6]?.items?.map((item, index) => (
            <div key={index}>
              <motion.li
                className="text-black mt-4"
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true, amount: 0.2 }}
                transition={{ duration: 0.5 }}
              >
                {item.title}
              </motion.li>

              <motion.p
                className="mt-2 pl-6"
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true, amount: 0.2 }}
                transition={{ duration: 0.5 }}
              >
                {item.description}
              </motion.p>
            </div>
          ))}


    

          <motion.div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-8 " initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true, amount: 0.2 }}
            transition={{ duration: 0.5 }}>
            {/* Image 1 */}
            <div>
              <img src={imageMap[blogData?.content?.[8]?.items?.[0]?.src]} alt="Image 1" className="w-full h-full object-cover rounded-lg" />
            </div>

            {/* Image 2 */}
            <div>
              <img src={imageMap[blogData?.content?.[8]?.items?.[1]?.src]}  alt="Image 2" className="w-full h-full object-cover rounded-lg" />
            </div>
          </motion.div>
















          <motion.li className=" text-black mt-8" initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true, amount: 0.2 }}
            transition={{ duration: 0.5 }}>{blogData?.content?.[7]?.items?.[0]?.title}</motion.li>
          <motion.p className="mt-2 pl-6" initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true, amount: 0.2 }}
            transition={{ duration: 0.5 }}>
            {blogData?.content?.[7]?.items?.[0]?.description}


          </motion.p>

          <motion.li className=" text-black mt-4" initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true, amount: 0.2 }}
            transition={{ duration: 0.5 }}>{blogData?.content?.[7]?.items?.[1]?.title}</motion.li>
          <motion.p className="mt-2 pl-6" initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true, amount: 0.2 }}
            transition={{ duration: 0.5 }}>
            {blogData?.content?.[7]?.items?.[1]?.description}
          </motion.p>




        </ul>

      </div>




    

      <div className="px-3">
        <motion.h2 className="text-3xl font-medium text-[#0C0C0C] mb-2 mt-8" initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true, amount: 0.2 }}
          transition={{ duration: 0.5 }} > {blogData?.content?.[9]?.text}</motion.h2>



        <motion.p className="text-lg font-medium  my-4 inst-sans text-[black]" initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true, amount: 0.2 }}
          transition={{ duration: 0.5 }}  >            {blogData?.content?.[10]?.items?.[0]?.title}
        </motion.p>
        <motion.ul className="space-y-3 text-[#606060] font-medium list-disc pl-6 text-lg mb-8 inst-sans"
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true, amount: 0.2 }}
          transition={{ duration: 0.5 }} >


          {blogData?.content?.[10]?.items?.[0]?.details?.map((item, index) => (
            <li>{item}</li>


          ))}
        </motion.ul>

        <motion.p className="text-lg font-medium  my-4 inst-sans text-[black]" initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true, amount: 0.2 }}
          transition={{ duration: 0.5 }}  >  {blogData?.content?.[10]?.items?.[1]?.title}</motion.p>
        <motion.ul className="space-y-3 text-[#606060] font-medium list-disc pl-6 text-lg mb-8 inst-sans"
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true, amount: 0.2 }}
          transition={{ duration: 0.5 }} >
          <li><span className="text-[black]">{blogData?.content?.[10]?.items?.[1]?.pointer[0]} </span>– {blogData?.content?.[10]?.items?.[1]?.details[0]}</li>
          <li><span className="text-[black]">{blogData?.content?.[10]?.items?.[1]?.pointer[1]} </span>– {blogData?.content?.[10]?.items?.[1]?.details[1]}</li>
          <li><span className="text-[black]">{blogData?.content?.[10]?.items?.[1]?.pointer[2]}</span> – {blogData?.content?.[10]?.items?.[1]?.details[2]} </li>

        </motion.ul>

      </div>
      <motion.img
       src={imageMap[blogData?.image?.src]}
        alt="Web design workspace"
        className="w-full rounded-xl my-8" initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true, amount: 0.2 }}
        transition={{ duration: 0.5 }}
      />


      <div className="px-3">


        {blogData?.content?.[11]?.items?.map((item, index) => (

          <>
            <motion.p className="text-lg font-medium  my-4 inst-sans text-[black]" initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true, amount: 0.2 }}
              transition={{ duration: 0.5 }}  >{item?.title}</motion.p>
            <motion.ul className="space-y-3 text-[#606060] font-medium list-disc pl-6 text-lg mb-8 inst-sans"
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true, amount: 0.2 }}
              transition={{ duration: 0.5 }} >
              <li>{item?.description}</li>

            </motion.ul>
          </>

        ))}


        <motion.h2 className="text-lg font-medium text-[#606060] mb-1 inst-sans" initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true, amount: 0.2 }}
          transition={{ duration: 0.5 }} >Summary</motion.h2>

        <motion.p className="text-[#606060] mb-8 font-medium text-lg inst-sans" initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true, amount: 0.2 }}
          transition={{ duration: 0.5 }} >

          {blogData?.content?.[12]?.text}
        </motion.p>
      </div>

      <div className="flex flex-col md:flex-row justify-between py-9 border-t border-b border-[#E6E6E6]">

        <div className="flex text-sm font-medium text-[#3D3D3D] mb-4 md:mb-0 items-center">
          <span className="mr-4 text-[#282C32] font-semibold text-lg">Tags: </span>

          <div className="flex flex-wrap gap-2">
            <span className="text-[#282C32] px-4 py-2 rounded-md border border-[#E6E5E5] text-sm sm:text-base">
              News
            </span>

            <span className="text-[#282C32] px-4 py-2 rounded-md border border-[#E6E5E5] text-sm sm:text-base">
              Business
            </span>

            <span className="text-[#282C32] px-4 py-2 rounded-md border border-[#E6E5E5] text-sm sm:text-base">
              Marketing
            </span>

          </div>
        </div>


        <div className="flex items-center justify-start md:justify-end mt-4 md:mt-0">
          <span className="mr-4 text-[#282C32] font-semibold text-lg">Share: </span>
          <img src={fb} alt="Facebook" className="cursor-pointer" />
          <img src={twitter} alt="Twitter" className="cursor-pointer mx-2" />
          <img src={yt} alt="LinkedIn" className="cursor-pointer" />
        </div>

      </div>



    </div>
  )
}

export default BlogColumn