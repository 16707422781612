
import logistics from "../../../assets/images/Workflow/Logistics.svg"


import { useState } from "react"
import { Link } from "react-router-dom"
import ui_ux from "../../../assets/images/Workflow/ui-ux.svg"
import prototype from "../../../assets/images/Workflow/prototype.svg"



import land1 from "../../../assets/images/Services/UI-UX/1.png"
import land2 from "../../../assets/images/Services/UI-UX/2.png"
import land3 from "../../../assets/images/Services/UI-UX/3.png"

import icon1 from "../../../assets/images/Services/UI-UX/process1.svg"
import icon2 from "../../../assets/images/Services/UI-UX/process2.svg"
import icon3 from "../../../assets/images/Services/UI-UX/process3.svg"

import icon1_active from "../../../assets/images/Services/UI-UX/process1_active.svg"
import icon2_active from "../../../assets/images/Services/UI-UX/process2_active.svg"
import icon3_active from "../../../assets/images/Services/UI-UX/process3_active.svg"

import { motion } from "framer-motion";






export default function OurUIProcess() {

    const features = [
        {
            image: icon1,
            title: "Research & Discovery",
            description: " Understanding your vision, needs, and goals to design the perfect solution.",
            // main_img: svg7
            main_img: land1,
            active: icon1_active

        },
        {
            image: icon2,
            title: "Wireframing & Prototyping",
            description: "Creating intuitive, stunning, and user-friendly designs that engage and inspire.",
            main_img: land2,
            active: icon2_active
        },
        {
            image: icon3,
            title: "UI Design & Development",
            description: "Bringing ideas to life with interactive models for a real-time preview.",
            main_img: land3,
            active: icon3_active
        },

    ]

    const [selectedFeature, setSelectedFeature] = useState(features[0]); // Default to the first feature

    const handleFeatureClick = (feature) => {
        setSelectedFeature(feature); // Update the selected feature
    };

    return (

        <section className="relative py-0 sm:py-16 px-4 uiprocess">

            <div className="max-w-7xl mx-auto">

                <motion.div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center" initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true, amount: 0.2 }}


                    transition={{ duration: 0.5 }}>

                    <div className="space-y-8 flex flex-col h-full justify-center">


                        <div className="space-y-7 ">

                            <h2 className="text-3xl md:text-4xl font-semibold text-[#020710] lg-text">Our <span className="text-[#1DBB91]"> Process  </span>! </h2>
                            <p className="text-[#7A8089] mt-2 text-sm md:text-base max-w-2xl mx-auto">
                                Break down your design process into clear steps, such as Break down your design process into clear steps, such as
                            </p>

                            <div className="space-y-7 border-l-2 border-[#F2F3F8]">
                                {features.map((feature, index) => (
                                    <div
                                        key={index}
                                        onClick={() => handleFeatureClick(feature)}
                                        className={`flex items-center space-x-4  cursor-pointer  
                p-3 ${selectedFeature?.image === feature?.image ? "border-[#1DBB91]  border-l-2" : "border-transparent"}`}
                                    >
                                        <div className="p-2">
                                            <img
                                                src={selectedFeature?.image === feature?.image ? feature.active : feature.image}


                                                alt={feature.title} className="w-10 h-10 sm:w-full sm:h-full object-contain" />
                                        </div>
                                        <div>
                                            <h3 className="text-lg font-semibold md:text-xl">{feature.title}</h3>
                                            {selectedFeature?.image === feature?.image && <p className="text-[#666666] mt-1 text-sm md:text-base">{feature.description}</p>}
                                        </div>
                                    </div>
                                ))}
                            </div>




                        </div>







                    </div>


                    <div className="flex flex-col h-full">


                        <div className="flex-1 overflow-hidden rounded-3xl bg-[#0FB84908] p-5 md:p-5 ">
                            <div className="relative  flex justify-center items-center ">
                                <img
                                    src={selectedFeature?.main_img}
                                    alt={selectedFeature?.title}
                                    className="object-cover "
                                />
                            </div>
                        </div>
                                
                    </div>
                </motion.div>
            </div>
        </section>
    )
}