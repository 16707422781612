import { useState, useRef, useEffect } from "react"
import { Add, Remove } from "@mui/icons-material"
import "./FAQsWork.css"
import { motion, useInView } from "framer-motion";
import dorpdwon from "../../assets/images/dropdwon.svg"

export default function FAQsWork() {
    const [openIndex, setOpenIndex] = useState(null)
    const ref = useRef(null);
    const inView = useInView(ref, { once: true, amount: 0.5 });
    const [drawn, setDrawn] = useState(false);

    useEffect(() => {
        if (inView && !drawn) {
            setDrawn(true); // Mark as drawn once user enters
        }
    }, [inView, drawn]);

    const faqs = [
        {
            question: "What makes your development services stand out?",
            answer:
                "We don’t just build websites and apps—we craft digital experiences that are innovative, scalable, and tailored to your unique needs.",
        },
        {
            question: "Can you create a solution that fits my business perfectly?",
            answer:
                "Absolutely! We specialize in customized web and mobile app development that aligns with your goals, industry, and audience.",
        },
        {
            question: "How long will it take to develop my project?",
            answer:
                "Every great product takes time! Depending on the complexity, we’ll map out a timeline that ensures quality without unnecessary delays.",
        },
        {
            question: "Do you provide support after the project is launched?",
            answer:
                "Of course! Our partnership doesn’t end at launch. We offer maintenance, updates, and support to keep your solution running smoothly.",
        },

        {
            question: "How do we collaborate throughout the development process?",
            answer:
                "Transparency is key! We keep you in the loop with regular updates, feedback sessions, and a development process that keeps you involved at every step.",
        },
    ]

    const toggleFAQ = (index) => {
        setOpenIndex(openIndex === index ? null : index)
    }

    return (
        <motion.section ref={ref}
            className="py-0 sm:py-16 px-4 faq-work relative"
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true, amount: 0.2 }}
            transition={{ duration: 0.5 }}>
            {inView &&
                <svg xmlns="http://www.w3.org/2000/svg" width="30%" height="30%" viewBox="0 0 500 180" className="absolute top-0 draw-grad" style={{ left: "-7%" }} >
                    <path
                        d="M 487.73 95.487 C 442.064 149.265 318.627 108.37 280.583 85.269 C 232.412 56.02 263.103 13.923 301.644 38.99 C 356.367 74.583 339.186 154.153 252.821 165.773 C 168.88 177.066 118.215 93.322 113.752 54.054 C 107.819 1.845 183.1 -8.367 177.716 61.612 C 173.206 120.227 102.73 161.954 12.295 167.793"
                        stroke="url(#gradient1)"
                        stroke-width="23.72"
                        stroke-linejoin="round"
                        stroke-linecap="round"
                        fill="transparent"
                        opacity="1"
                        pathLength="1"
                        stroke-dashoffset="0px"
                        stroke-dasharray="1px 1px"
                    > {drawn && (
                        <animate
                            attributeName="stroke-dashoffset"
                            from="1"
                            to="0"
                            dur="5s"
                            fill="freeze"
                        />
                    )}
                    </path>

                    <defs>

                        <linearGradient id="gradient1" x1="0%" y1="0%" x2="100%" y2="100%">
                            <stop offset="0%" stop-color="#2BAAE2" />
                            <stop offset="100%" stop-color="#24B574" />
                        </linearGradient>
                    </defs>
                </svg>

            }


            <div className="max-w-7xl mx-auto">
                {/* Grid container for heading and FAQ section */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8  items-center">
                    {/* Heading Section */}
                    <div className="text-left">
                        <h2 className="text-3xl md:text-5xl font-medium mb-4 inter lg-text text-[#0C0C0C] inter ">
                            <span className="gradient-text-faq"> Frequently</span> Asked Questions
                        </h2>
                        <p className="text-[#485E79] max-w-2xl text-lg mx-auto manrope font-normal">
                        Have questions? We’ve Got Answers! Whether it’s about our process, technology, or how we can help your business grow, we’re here to answer them all!

                        </p>
                    </div>

                    {/* FAQ Section */}
                    <div className="space-y-4">
                        {faqs.map((faq, index) => (
                            <div
                                key={index}
                                className="bg-white rounded-2xl overflow-hidden"
                                style={{ border: "1px solid #F2F3F8" }}
                            >
                                <button
                                    onClick={() => toggleFAQ(index)}
                                    className="w-full px-6 py-3 flex items-center justify-between text-left hover:bg-gray-30 transition-colors duration-200"
                                >
                                    <span className="font-medium text-[#020710] inter text-lg">{faq.question}</span>
                                    <span className="ml-4 flex-shrink-0 p-2">
                                        {/* Rotate the dropdown based on the open state */}
                                        <img
                                            src={dorpdwon}
                                            alt="dropdown"
                                            className={`w-5 h-5 transform transition-transform duration-200 ${openIndex === index ? "rotate-180" : ""}`}
                                        />
                                    </span>
                                </button>

                                {/* Answer */}
                                <div
                                    className={`px-6 overflow-hidden transition-all duration-200 ease-in-out ${openIndex === index ? "max-h-48 py-4" : "max-h-0"}`}
                                >
                                    <p className="text-gray-600">{faq.answer}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

        </motion.section>
    )
}

