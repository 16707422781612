import React from 'react'
import "./AIServices.css"

import img1 from "../../../assets/images/Services/AIDev/machine_learning.svg"
import img2 from "../../../assets/images/Services/AIDev/natural.svg"
import img3 from "../../../assets/images/Services/AIDev/computer_vision.svg"
import img4 from "../../../assets/images/Services/AIDev/robotic.svg"
import img5 from "../../../assets/images/Services/AIDev/ai_startegy.svg"
import img6 from "../../../assets/images/Services/AIDev/ai_modal.svg"

import { motion } from "framer-motion";

const AIServices = () => {

    const technologies = [
        // Row 1
        {
            name: "Machine Learning Development",
            logo: img1,
            description: "Custom ML models for predictive analytics, recommendation engines, etc."
        },
        {
            name: "Natural Language Processing ",
            logo: img2,
            description: "Solutions like chatbots, language translation, sentiment analysis, etc."

        },

        {
            name: "Computer Vision",
            logo: img3,
            description: "Image and video analysis, facial recognition, object detection."

        },
        {
            name: "Robotic Process Automation",
            logo: img4,
            description: "Automating repetitive business tasks."

        },

        {
            name: "AI Strategy and Consulting",
            logo: img5,
            description: "Help businesses understand AI’s potential and how to implement it."

        },

        {
            name: "AI Model Deployment and Maintenance",
            logo: img6,
            description: "Ensuring AI models are operational and continuously optimized."

        },

    ]
    return (
        <section className="relative py-16 px-4 about-comp">


            <div className="max-w-7xl mx-auto">

                <motion.div className="text-center mb-12" initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true, amount: 0.2 }}
                    transition={{ duration: 0.5 }}
                >
                    <h2 className="text-3xl md:text-5xl font-medium text-[#212A40] mb-4 poppins lg-text">  <span className="text-[#24B574]">  AI Services  </span> We Offer</h2>
                    <p className="text-[#485E79] max-w-2xl text-lg mx-auto">
                        Lorem ipsum dolor sit amet consectetur, adipisicing elit A, ab nemo! Expedita!Lorem ipsum dolor sit amet consectetur
                    </p>
                </motion.div>

                <motion.div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3  gap-8 justify-items-center " initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true, amount: 0.2 }}
                    transition={{ duration: 0.5 }}
                >
                    {technologies.map((tech, index) => (
                        <div
                            key={index}
                            className="flex flex-col cursor-pointer items-center justify-center group bg-white  rounded-3xl p-5 w-full border-2 border-[#E6E9EE]"
                        >
                            <div className="mb-3 flex items-center justify-center ">
                                <img
                                    src={tech.logo || "/placeholder.svg"}
                                    alt={`${tech.name} logo`}
               
                                    className="object-contain "
                                />
                            </div>
                            <span className="text-xl text-[#3A3A3A] text-center font-600 ">{tech.name}</span>
                            <p className="text-center text-sm text-[#6B7280] mt-2">{tech.description}</p>
                        </div>
                    ))}


                </motion.div>





            </div>
        </section>
    )
}

export default AIServices














