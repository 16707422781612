import { ArrowForward, ArrowRightAlt, Groups, AttachMoney, Timer, Language } from "@mui/icons-material"
import "./PricingModals.css"
import icon1 from "../../assets/images/Pricing/img1.png"
import icon2 from "../../assets/images/Pricing/img2.png"
import icon3 from "../../assets/images/Pricing/img3.png"
import icon4 from "../../assets/images/Pricing/img4.png"
import { Link } from "react-router-dom"
import arrow_pointer from "../../assets/images/Pricing/pointer.svg"
import arrow from "../../assets/images/Pricing/arrow.svg"
import { useNavigate } from "react-router-dom"
import { motion } from "framer-motion";
export default function PricingModels() {
    const models = [
        {
            icon: icon1,
            title: "Dedicated Development Team",
            mainFeatures: ["Monthly pricing based on team size & expertise", "Long-term projects & scalable IT development"],
            features: ["Full-time dedicated experts", "Scalable & cost-efficient", "Transparent project tracking"],
            cta: "Hire a Team",
        },
        {
            icon: icon2,
            title: "Fixed-Price Model",
            mainFeatures: [
                "A predefined budget with a well-structured project scope",
                "Best suited for short-term projects with clear cost planning"
            ],
            features: [
                "No unexpected expenses",
                "Defined tasks & timelines",
                "Predictable project workflow"
            ],
            cta: "Get a Quote",
        }
        ,
        {
            icon: icon3,
            title: "Time & Material Model",
            mainFeatures: [
                "A flexible pricing approach where you pay based on actual work hours and evolving project scope",
                "Best suited for dynamic requirements and long-term projects that need continuous adaptation"
            ],
            features: [
                "Adjustable team allocation",
                "Ideal for agile execution",
                "Optimized resource spending"
            ],
            cta: "Start Your Project",
        }
        ,
        {
            icon: icon4,
            title: "Offshore Development",
            mainFeatures: [
                "Set up a remote development team with highly skilled IT professionals tailored to your project needs",
                "A scalable workforce solution that optimizes costs while ensuring high-quality software development"
            ],
            features: [
                "Dedicated offshore experts",
                "Seamless team extension",
                "Transparent work process"
            ],
            cta: "Scale Your Team",
        }


    ]

    const navigate = useNavigate()

    return (
        <section className="pb-0 sm:pb-16 px-4 pricing-modal">
            <div className="max-w-5xl mx-auto">

                <div className="text-center mb-12" initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true, amount: 0.2 }}
                    transition={{ duration: 0.5 }}>
                    <h2 className="text-3xl md:text-4xl font-semibold mb-4 lg-text">
                        <span className="bg-gradient-to-r from-[#25B57E] to-[#2BAAE2] text-transparent bg-clip-text">
                            Pricing Models
                        </span>    Section

                    </h2>
                    <p className="text-[#666666] max-w-2xl mx-auto text-lg">
                        Offering the most recent modern technology to grow your business exponentially equipped with top-quality
                        technology that meets your desires.
                    </p>
                </div>


                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    {models.map((model, index) => (
                        <motion.div key={index} className="bg-white rounded-xl p-5 sm:p-8 border border-[#E8EAF1]" initial={{ opacity: 0, y: 50 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            viewport={{ once: true, amount: 0.2 }}
                            transition={{ duration: 0.5 }}>

                            <div className="mb-6  bg-[#FAFAFA] rounded-2xl border-2 border-[#EDEFF3] p-3  items-center justify-center inline-flex"  >
                                <img src={model?.icon} alt="" className="w-8 h-8 sm:w-10 sm:h-10" />
                            </div>

                            <h3 className="text-xl sm:text-3xl lg:text-3xl font-medium  mb-6 text-[#262626]">{model.title}</h3>


                            <div className="space-y-4 mb-6">
                                {model.mainFeatures.map((feature, idx) => (
                                    <div key={idx} className="flex items-start gap-3">
                                        {/* <ArrowRightAlt className="w-6 h-6 text-emerald-500 flex-shrink-0 " /> */}
                                        <img src={arrow_pointer} alt="" className="w-3 h-7 md:h-7 md:w-6" />
                                        <p className="text-[#666666] text-base sm:text-xl ">{feature}</p>
                                    </div>
                                ))}
                            </div>


                            <ul className="space-y-3 mb-8 sm:pl-7">
                                {model.features.map((feature, idx) => (
                                    <li key={idx} className="flex items-center gap-2 text-[#666666] text-base sm:text-xl">
                                        <span className=" bg-[#666666] rounded-full" style={{ width: "4px", height: "4px" }} />
                                        {feature}
                                    </li>
                                ))}
                            </ul>


         
                         
                          <button className="flex items-center text-[#333333] font-medium px-4 py-2 rounded-full border border-[#EDEFF3] bg-[#F8F9FA] sm:ml-7 text-sm sm:text-base"
                          onClick={()=>{navigate("/consult-us")}}
                          >
                                {model.cta}
                                <img src={arrow} alt="" className="h-3 w-3 sm:w-4 sm:h-4 ml-2" />
                            </button>

                        </motion.div>
                    ))}
                </div>
            </div>
        </section>
    )
}

