import React from 'react'
import "./UIUX.css"
import { motion } from "framer-motion";
import banner from "../../../assets/images/Services/UI-UX/banner_img.png"




const DesignShowcase = () => {


    return (

        <section className="relative py-0 sm:py-16 px-4 whychooseui">


            <div className="max-w-7xl mx-auto">

                <motion.div className="text-center mb-12 max-w-5xl mx-auto" initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true, amount: 0.2 }}
                    transition={{ duration: 0.5 }}
                >
                    <h2 className="text-3xl md:text-5xl font-medium text-[#020710] mb-4  lg-text max-w-4xl mx-auto" >
                        Lorem ipsum dolor sit amet  </h2>
                    <p className="text-[#485E79] max-w-2xl text-lg mx-auto">
                        Lorem ipsum dolor sit amet consectetur, adipisicing elit A, ab nemo! Expedita!Lorem ipsum dolor sit amet consectetur
                    </p>
                </motion.div>
















                {/* <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 justify-items-center " initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true, amount: 0.2 }}
                    transition={{ duration: 0.5 }}
                >

                  
                    {technologies.map((tech, index) => (
                        <motion.div
                            key={index}
                            className=" cursor-pointer   bg-white  rounded-3xl p-5  border border-[#F2F3F8] w-full"
                            initial={{ opacity: 0, y: 50 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            viewport={{ once: true, amount: 0.2 }}
                            transition={{ duration: 0.5 }}
                        >

                            <div className='py-4 '>

                                <p className="text-lg md:text-2xl text-[#171717] text-center font-medium">{tech.name}</p>
                                <p className="text-center  text-[#7A8089] my-3" style={{fontSize:"15px"}}>{tech.description}</p>

                            </div>
                            <div className="overflow-hidden p-1 ">
                                <img
                                    src={tech.logo || "/placeholder.svg"}
                                    alt={`${tech.name} logo`}
                             
                                    className={`w-full h-full object-cover 
                                
                                
                                
                               `}
                                />
                            </div>

                        </motion.div>
                    ))}


                </div>
 */}

                <motion.div initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true, amount: 0.2 }}


                    transition={{ duration: 0.5 }}>

                    <img src={banner} alt="" className='rounded-xl' />
                </motion.div>




            </div>
        </section>
    )
}

export default DesignShowcase
