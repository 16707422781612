import React from "react";
import ux_reasearch from "../../assets/images/WorkDetails/SindhiSoulmate/lg1.png"
import ux_Design from "../../assets/images/WorkDetails/SindhiSoulmate/ux-design.png"
import Flutter from "../../assets/images/WorkDetails/SindhiSoulmate/Background+Shadow-5.png"
import React_img from "../../assets/images/WorkDetails/SindhiSoulmate/Background+Shadow-6.png"
import ui_design from "../../assets/images/WorkDetails/SindhiSoulmate/lg2.png"
import Prototyping from "../../assets/images/WorkDetails/SindhiSoulmate/Background+Shadow-4.png"
import Dart from "../../assets/images/WorkDetails/SindhiSoulmate/Background+Shadow-7.png"
import Node_img from "../../assets/images/WorkDetails/SindhiSoulmate/nodejs.png"
import Express_img from "../../assets/images/WorkDetails/SindhiSoulmate/Background+Shadow-2.png"
import MongoDB_img from "../../assets/images/WorkDetails/SindhiSoulmate/Background+Shadow-3.png"

import { motion } from "framer-motion";
const techStack = [
    { name: "UX Research", logo: ux_reasearch },
    { name: "UX Design", logo: ux_Design },
    { name: "UI Design", logo: ui_design },
    { name: "Prototyping", logo: Prototyping },
    { name: "Flutter", logo: Flutter },
    { name: "React", logo: React_img },
    { name: "Dart", logo: Dart },
    { name: "Node.js", logo: Node_img },
    { name: "Express.js", logo: Express_img },
    { name: "MongoDB", logo: MongoDB_img }
];

const TechUsed = () => {
    return (
        <motion.section className=" px-4" initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true, amount: 0.2 }}
            transition={{ duration: 0.5 }}>
            <div className="max-w-6xl mx-auto">
                {/* <h2 className="text-2xl font-semibold text-center mb-8">Technologies Used</h2> */}

                {/* Grid layout */}
                <div className="grid grid-cols-2 md:grid-cols-4 gap-0 items-center justify-center text-center">
                    {techStack.map((tech, index) => (
                        <div key={index} className="flex items-center justify-start p-4  ">
                            <img src={tech.logo} alt={tech.name} className="w-12 h-12 md:w-14 md:h-14 mr-4" />
                            <span className="text-base md:text-lg font-medium text-gray-700">{tech.name}</span>
                        </div>
                    ))}
                </div>
            </div>
        </motion.section>
    );
};

export default TechUsed;
