import React from 'react';
import sub_icon from "../../assets/images/subscribe_icon.png";
import dev_img from "../../assets/images/Developers.png";
import Card from './Card';  // Import the Card component
import { cardData } from '../../data/cardData';
import { useEffect } from 'react';
import { useState } from 'react';
import { useRef } from 'react';
import cursor_img from "../../assets/images/Services/WebApp/cursor.svg"  
const { ScrollObserver, valueAtPercentage } = window.aat;

  



export default function DevelopmentSection() {

    // const cardsContainerRef = useRef(null);

    // useEffect(() => {
    //     const cardsContainer = document.querySelector('.cards')
    //     const cards = document.querySelectorAll('.card')
    //     cardsContainer.style.setProperty('--cards-count', cards.length)
    //     cardsContainer.style.setProperty(
    //         '--card-height',
    //         `${cards[0].clientHeight}px`
    //     )
    //     Array.from(cards).forEach((card, index) => {
    //         const offsetTop = 20 + index * 20
    //         card.style.paddingTop = `${offsetTop}px`
    //         if (index === cards.length - 1) {
    //             return
    //         }
    //         const toScale = 1 - (cards.length - 1 - index) * 0.1
    //         const nextCard = cards[index + 1]
    //         const cardInner = card.querySelector('.card__inner')
    //         ScrollObserver.Element(nextCard, {
    //             offsetTop,
    //             offsetBottom: window.innerHeight - card.clientHeight
    //         }).onScroll(({ percentageY }) => {
    //             cardInner.style.scale = valueAtPercentage({
    //                 from: 1,
    //                 to: toScale,
    //                 percentage: percentageY
    //             })
    //             cardInner.style.filter = `brightness(${valueAtPercentage({
    //                 from: 1,
    //                 to: 0.6,
    //                 percentage: percentageY
    //             })})`
    //         })
    //     })
    // }, []);
    return (
        <div className="min-h-screen developer-section-gradient px-4 pt-12 pb-0 sm:pt-12 sm:pb-12 md:px-6 lg:px-8 wave-effect">
            <div className="mx-auto max-w-6xl text-center">
                <h2 className="mb-4 text-3xl   md:text-4xl lg:text-5xl gradient-text" >
                    Top Mobile Application Development
             
                    Company In India
                </h2>

              
                <p className="mb-3 text-lg text-white">  Your Vision, Our Innovation. Stunning designs, seamless performance, and next-gen digital solutions
                </p>
                <p className="mb-12 text-xl text-white fightree">all under one roof</p>

                <div className="space-y-8 cards" >

                    {cardData.map((card, index) => (
                        <Card
                            key={index} // Always use a unique key when mapping
                            title={card.title}
                            description={card.description}
                            image={card.image}
                            icon={card.icon}
                            index={index}
                            totalCards={cardData.length}
                        />
                    ))}

                </div>
            </div>
        </div>
    );
}
