import { Verified } from "@mui/icons-material"
import user_img from "../../assets/images/Testimonials/dp.png"
import user_img2 from "../../assets/images/Testimonials/dp2.png"
import user_img3 from "../../assets/images/Testimonials/dp3.png"
import user_img4 from "../../assets/images/Testimonials/dp4.png"
import user_img5 from "../../assets/images/Testimonials/dp5.png"
import { useRef, useEffect } from "react"
import verfied_img from "../../assets/images/Testimonials/verfied.png"
import "./Testimonials.css"
import "./SliderRight.css"
import { motion } from "framer-motion";
import verified_green from "../../assets/images/Testimonials/verified_green.svg"

export default function TestimonialsSection() {
    const trackRef = useRef(null);

    const testimonials = [
        {
            name: "Savannah Nguyen",
            image:
                user_img,
            company: "Lorem Ipsum",
            quote: "Outstanding experience from start to finish. The team was professional and attentive.",
        },
        {
            name: "Marvin McKinney",
            image:
                user_img4,
            company: "Lorem Ipsum",
            quote: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. A, ab nemo! Expedita!",
        },
        {
            name: "Dianne Russell",
            image:
                user_img2,
            company: "Lorem Ipsum",
            quote: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. A, ab nemo! Expedita!",
        },
        {
            name: "Ronald Richards",
            image:
                user_img3,
            company: "Lorem Ipsum",
            quote: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. A, ab nemo! Expedita!",
        },
        {
            name: "Courtney Henry",
            image:
                user_img5,
            company: "Lorem Ipsum",
            quote: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. A, ab nemo! Expedita!",
        },
        {
            name: "Alex McCoy",
            image:
                user_img3,
            company: "Lorem Ipsum",
            quote: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. A, ab nemo! Expedita!",
        },

        {
            name: "Vreene Sherty",
            image:
                user_img2,
            company: "Lorem Ipsum",
            quote: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. A, ab nemo! Expedita!",
        },

        // {
        //     name: "Tom McCoy",
        //     image:
        //         user_img,
        //     company: "Lorem Ipsum",
        //     quote: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. A, ab nemo! Expedita!",
        // },

        // {
        //     name: "Buno Isag",
        //     image:
        //         user_img,
        //     company: "Lorem Ipsum",
        //     quote: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. A, ab nemo! Expedita!",
        // },

        // {
        //     name: "Joe Bey",
        //     image:
        //         user_img,
        //     company: "Lorem Ipsum",
        //     quote: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. A, ab nemo! Expedita!",
        // },
    ]

    useEffect(() => {
        const track = trackRef.current;
        if (track) {
            // Duplicate items for smooth infinite scrolling
            track.innerHTML += track.innerHTML;
        }
    }, []);

    return (
        <motion.section className="py-16 px-4 testimonial" initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true, amount: 0.2 }}
            transition={{ duration: 0.5 }} >
            <div className="max-w-7xl mx-auto">
                {/* Header */}
                <div className="text-center mb-16">
                    <h2 className="text-3xl md:text-5xl font-medium text-[#020617] mb-4 poppins lg-text">Client Testimonials</h2>
                    <p className="text-[#020617] max-w-2xl text-lg mx-auto">
                    Our clients’ success stories speak for themselves! Discover how our expertise has transformed businesses, delivering seamless solutions and exceptional experiences.

                    </p>
                </div>

                {/* Testimonials Grid */}

                <div className="max-w-7xl mx-auto slider">
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8 slide-track" ref={trackRef}>
                    {[...testimonials, ...testimonials].map((testimonial, index) => (



                            <div
                                key={index}
                                // className={` bg-blue-50 rounded-2xl p-6 transition-transform duration-300 hover:-translate-y-1`}
                                className={` bg-[#F1F5F9] rounded-2xl p-6 transition-transform duration-300  slide `}

                            >
                                <div className="flex items-start gap-3 mb-4 ">
                                    <img
                                        src={testimonial.image || "/placeholder.svg"}
                                        alt={testimonial.name}
                                        className="w-10 h-10 rounded-full object-cover border-2 border-white"
                                    />
                                    <div className="flex-1">
                                        <div className="flex items-center gap-2">
                                            <h3 className="font-semibold text-[#020617] flex items-center gap-1">{testimonial.name}
                                            <img
                                                src={verified_green} // Use the imported image
                                                alt="Verified"
                                                className="w-4 h-4"
                                            />
                                            </h3>
                                            <div className="flex items-center gap-1  px-2 py-0.5 rounded-full">
                                                {/* <img
                                                    src={verfied_img} // Use the imported image
                                                    alt="Verified"
                                                    className="w-4 h-4" // You can adjust the size as needed
                                                /> */}
                                                <span className="text-sm text-[#64748B]">{testimonial.company}</span>
                                            </div>
                                        </div>
                                        <p className="mt-2 text-gray-600 text-sm leading-relaxed">"{testimonial.quote}"</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                </div>

                <div className="max-w-7xl mx-auto slider-right " style={{ marginTop: "20px" }}>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8 slide-track-right" ref={trackRef}>
                        {[ ...testimonials].map((testimonial, index) => (
                            <div
                                key={index}
                                className="bg-[#F1F5F9] rounded-2xl p-6 transition-transform duration-300 slide"
                            >
                                <div className="flex items-start gap-3 mb-4">
                                    <img
                                        src={testimonial.image || "/placeholder.svg"}
                                        alt={testimonial.name}
                                        className="w-10 h-10 rounded-full object-cover border-2 border-white"
                                    />
                                    <div className="flex-1">
                                        <div className="flex items-center gap-2">
                                            <h3 className="font-semibold text-[#020617] flex items-center gap-1">
                                                
                                                {testimonial.name}   
                                                
                                                <img
                                                src={verified_green} // Use the imported image
                                                alt="Verified"
                                                className="w-4 h-4"
                                            /></h3>
                                            <div className="flex items-center gap-1 px-2 py-0.5 rounded-full">

                                                <span className="text-sm text-[#64748B]">{testimonial.company}</span>
                                            </div>
                                        </div>
                                        <p className="mt-2 text-gray-600 text-sm leading-relaxed">"{testimonial.quote}"</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                </div>

            </div>
        </motion.section>
    )
}

