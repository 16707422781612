import { ShoppingCart, School, FitnessCenter, Label } from "@mui/icons-material"
import "./Industries.css"
import healthcare from "../../assets/images/Industries/healthcare.png"
import retail from "../../assets/images/Industries/retail.png"

import education from "../../assets/images/Industries/education.png"
import img1 from "../../assets/images/Industries/img1.png"
import img2 from "../../assets/images/Industries/img2.png"
import img3 from "../../assets/images/Industries/img3.png"
import img4 from "../../assets/images/Industries/img4.png"
import img5 from "../../assets/images/Industries/img5.png"
import img6 from "../../assets/images/Industries/img6.png"
import img7 from "../../assets/images/Industries/img7.png"
import logistics from "../../assets/images/Industries/distri.svg"
import ecom from "../../assets/images/Industries/ecom_retail.svg"
import e_learn from "../../assets/images/Industries/elearning.svg"
import healthcare_fit from "../../assets/images/Industries/health-fit.svg"
import { motion, AnimatePresence } from "framer-motion";
import food from "../../assets/images/Industries/food_restaurants.svg"
import travel from "../../assets/images/Industries/travel_hospita.svg"
import real_esate from "../../assets/images/Industries/real_estate.svg"
import solution from "../../assets/images/Industries/solution.svg"
import quick_com from "../../assets/images/Industries/quick_com.svg"
import startup from "../../assets/images/Industries/startup.svg"

import { useState, useEffect } from "react"


export default function IndustriesSection() {
  const initialIndustries = [

    {
      image: logistics,
      title: "Logistics & Distribution",
      description: (
        <>
          Optimize logistics and distribution with smart solutions.
          Improve supply chain efficiency and ensure timely delivery.
        </>
      )
    },


    {
      image: ecom,
      title: "Retail & E-commerce",
      description: (
        <>
          Empower your retail business with cutting-edge ecommerce solutions.
          Streamline operations, enhance customer experience, and boost sales.
        </>
      )
    },
    {
      image: e_learn,
      title: "Education & E-learning",
      description: (
        <>
          Revolutionize learning with modern e-learning platforms.
          Engage students with interactive content and online tools for better education.
        </>
      )
    },


  ]

  const sharedTags = [
    {
      name: "Healthcare & Fitness",
      image: healthcare_fit,
      description: (
        <>
          Enhance wellness with cutting-edge healthcare solutions.
          Leverage digital tools to improve patient care and fitness engagement.
        </>
      )
    },
    {
      name: "Real Estate Market",
      image: real_esate,
      description: (
        <>
          Transform the real estate industry with tech-driven solutions.
          Streamline property management and enhance buyer experience.
        </>
      )
    },
    {
      name: "Food & Restaurant",
      image: food,
      description: (
        <>
          Modernize the food & restaurant industry with digital solutions.
          Enhance customer experience, streamline orders, and increase efficiency.
        </>
      )
    },
    {
      name: "On-Demand Solutions",
      image: solution,
      description: (
        <>
          Power your business with on-demand service solutions.
          Provide seamless and efficient service delivery for your customers.
        </>
      )
    }
  ];

  const threetags = [
    {
      name: "Travel & Hospitality",
      image: travel,
      description: (
        <>
          Elevate travel and hospitality with tech-driven solutions.
          Enhance customer experiences and streamline operations.
        </>
      )
    },
    {
      name: "Quick Commerce",
      image: quick_com,
      description: (
        <>
          Revolutionize fast-paced commerce with instant delivery solutions.
          Optimize supply chains and ensure rapid order fulfillment.
        </>
      )
    },
    {
      name: "Startup Community",
      image: startup,
      description: (
        <>
          Empower startups with innovative solutions and digital tools.
          Accelerate growth, networking, and business success.
        </>
      )
    }
  ];

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 645);

  // Update state when window resizes
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 645);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [industries, setIndustries] = useState(initialIndustries);
  const handleSwap = (tag) => {
    if (!isMobile) return; // Prevent swap on larger screens
    setIndustries((prevIndustries) => [
      prevIndustries[0], // Keep first industry static
      prevIndustries[1], // Keep second industry static
      {
        image: tag.image, // Replace only the last industry
        title: tag.name,
        description: tag.description // Use dynamic description
      }
    ]);
  };

  return (
    <motion.section className="py-16 px-4 industries-section "
    >
      <div className="max-w-7xl mx-auto " >
        {/* Header */}
        <motion.div className="text-center mb-12" initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true, amount: 0.2 }}
          transition={{ duration: 0.5 }}>
          <h2 className="text-3xl md:text-5xl font-medium text-[#212A40] mb-4 poppins lg-text">Industries we Serve</h2>
          <p className="text-[#485E79] max-w-2xl text-lg mx-auto">
            From startups to enterprises, we deliver cutting-edge solutions that redefine industry standards and enhance engagements.
          </p>
        </motion.div>

        {/* Card Grid */}


        <motion.div
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6"
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true, amount: 0.2 }}
          transition={{ duration: 0.5 }}
        >
          <AnimatePresence mode="popLayout">
            {industries.map((industry, index) => (
              <motion.div
                key={industry.title} // Use unique key to track changes
                initial={{ opacity: 0, y: 20, scale: 0.9 }}
                animate={{ opacity: 1, y: 0, scale: 1 }}
                exit={{ opacity: 0, y: -20, scale: 0.9 }}
                transition={{ duration: 0.4 }}
                className="bg-white rounded-3xl p-10 transition-transform duration-300 hover:-translate-y-2"
              >
                <div className="flex flex-col items-center text-center gap-3 mb-4">
                  {/* Icon */}
                  <div className="p-3 bg-white rounded-full shadow-[0px_8px_50px_0px_#22989F4A] flex items-center justify-center">
                    <img
                      src={industry.image}
                      alt={industry.title}
                      className="w-8 h-8 rounded-full"
                    />
                  </div>

                  {/* Title */}
                  <h3 className="font-medium text-xl text-[#212A40] poppins">
                    {industry.title}
                  </h3>

                  {/* Description */}
                  <p className="text-[#485E79] text-lg font-normal">
                    {industry.description}
                  </p>
                </div>
              </motion.div>
            ))}
          </AnimatePresence>
        </motion.div>



        <motion.div className="max-w-5xl mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 mt-8" initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true, amount: 0.2 }}
          transition={{ duration: 0.5 }}>
          {sharedTags.map((tag, tagIndex) => (
            <div
              key={tagIndex}
              className="flex items-center gap-3 text-lg text-[#485E79] font-normal bg-white rounded-2xl p-2 cursor-pointer transition-transform duration-300 hover:scale-100 sm:hover:scale-100
"
              onClick={() => handleSwap(tag)}
            >
              {/* Image */}
              <img src={tag.image} alt={tag.name} className="w-7 h-7" />
              <span className="truncate manrope">{tag.name}</span>
            </div>
          ))}
        </motion.div>

        <motion.div className="max-w-3xl mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mt-3" initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true, amount: 0.2 }}
          transition={{ duration: 0.5 }}>
          {threetags.map((tag, tagIndex) => (
            <div
              key={tagIndex}
                   className="flex items-center gap-3 text-lg text-[#485E79] font-normal bg-white rounded-2xl p-2 cursor-pointer transition-transform duration-300 hover:scale-100 sm:hover:scale-100
"
              onClick={() => handleSwap(tag)}
            >
              {/* Image */}
              <img
                src={tag.image}
                alt={`${tag.name} image`}
                className="w-7 h-7"

              />

              <span className="truncate manrope">{tag.name}</span>
            </div>
          ))}
        </motion.div>



      </div>
    </motion.section>


  )
}

