import { HelpOutline, Settings, Apps } from "@mui/icons-material"
// import Question from "../../images/Question.png"
// import Menu from "../../images/Menu.png"
// import Setting from "../../images/Setting.png"
import { useState, useEffect, useRef } from "react"
import Menu from "../assets/images/Menu.png"
import logo from "../assets/images/tr.webp"
import { motion, AnimatePresence } from "framer-motion";
import { Link, useLocation } from 'react-router-dom'; // Import Link and useLocation
import ExpandLessIcon from "@mui/icons-material/ExpandLess"

import CloseIcon from "@mui/icons-material/Close"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";


export default function Navbar({ isOpen, setIsOpen }) {

  const location = useLocation(); // Get the current route
  const isActive = (path) => location.pathname === path ? 'active-nav' : '';
  const isActivesm = (path) => location.pathname === path ? 'mb-active-nav' : '';


  const isService = (path) => {
    return location.pathname === path ||
      location.pathname === "/product-development" ||
      location.pathname === "/ai-development" ||
      location.pathname === "/mobile-app-development" ||
      location.pathname === "/web-app-development" ||
      location.pathname === "/ui-ux-design" ||
      location.pathname === "/quality-assurance" ||
      location.pathname === "/cms-development"


      ? "active-nav" : "";
  };

  const isMbService = (path) => {
    return location.pathname === path ||
      location.pathname === "/product-development" ||
      location.pathname === "/ai-development" ||
      location.pathname === "/mobile-app-development" ||
      location.pathname === "/web-app-development" ||
      location.pathname === "/ui-ux-design" ||
      location.pathname === "/quality-assurance" ||
      location.pathname === "/cms-development"


      ? "mb-active-nav" : "";
  };
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const dropdownRef = useRef(null); // Reference for detecting clicks outside

  // Close dropdown on scroll
  useEffect(() => {
    const handleScroll = () => {
      setDropdownOpen(false);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const [isServicesOpen, setIsServicesOpen] = useState(false)
  const toggleMenu = () => {
    setIsOpen(!isOpen)
  }


  const serviceItems = [
    { name: "Product Dev", href: "#product-dev" },
    { name: "App Dev", href: "#app-dev" },
    { name: "Web Dev", href: "#web-dev" },
    { name: "Other Services", href: "#other-services" },
  ]




  return (

    <>


      <nav
        className="mx-auto max-w-4xl bg-[#2D2D2D] backdrop-blur-sm rounded-full px-6 py-4 flex items-center justify-between border border-white/30 navbar nav-auto"
        style={{ width: "90%" }}
      >
        {/* Logo */}
        {/* <div className="text-white text-2xl font-medium"><img src={logo} alt="Logo" /></div> */}
        <Link className="text-white text-2xl font-medium w-12 h-12" to={"/"}><img src={logo} alt="Logo" /></Link>

        {/* Desktop Links */}
        <div className="hidden md:flex items-center space-x-8">
          <Link to="/" className={isActive('/')}>
            Home
          </Link>
          <Link to="/about" className={isActive('/about')} style={{ color: "#CCCCCC" }}>
            About TR
          </Link>
          {/* Services with Dropdown */}
          <div className="relative flex items-center space-x-1" ref={dropdownRef}>
            {/* Clicking "Services" navigates without toggling dropdown */}
            <Link to="/services" className={isService("/services")}>Services</Link>


            <ExpandMoreIcon
              className="text-[#CCCCCC] cursor-pointer transition-transform duration-200"
              style={{ transform: dropdownOpen ? "rotate(180deg)" : "rotate(0deg)" }}
              onClick={(e) => {
                e.stopPropagation(); // Prevents triggering link click
                setDropdownOpen(!dropdownOpen);
              }}
            />



            <AnimatePresence>
              {dropdownOpen && (
                <motion.div
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -10 }}
                  transition={{ duration: 0.3, ease: "easeInOut" }}
                  className="absolute left-0 top-full mt-8 w-56 bg-[#2D2D2D] backdrop-blur-sm shadow-lg rounded-md overflow-hidden z-50 drop-nav py-3"
                >
                  <Link to="/ai-enabled-solutions" className={`block px-4 py-2 ${isActivesm('/ai-development')}`}>AI-Enabled Solutions</Link>
                  <Link to="/saas-product-development" className={`block px-4 py-2 ${isActivesm('/product-development')}`}>SaaS Product Development</Link>

                  <Link to="/mobile-app-development" className={`block px-4 py-2 ${isActivesm('/mobile-app-development')}`}>Mobile App Development</Link>

                  <Link to="/web-app-development" className={`block px-4 py-2 ${isActivesm('/web-app-development')}`}>Web App Development</Link>
                  <Link to="/cms-development" className={`block px-4 py-2 ${isActivesm('/cms-development')}`}>CMS Development</Link>
                  <Link to="/ui-ux-design" className={`block px-4 py-2 ${isActivesm('/ui-ux-design')}`}>UI/UX Design
                  </Link>

                  <Link to="/quality-assurance" className={`block px-4 py-2 ${isActivesm('/quality-assurance')}`}>Quality Assurance Testing
                  </Link>

                </motion.div>
              )}
            </AnimatePresence>
          </div>
          <Link to="/work" className={isActive('/work')} style={{ color: "#CCCCCC", marginLeft: "16px" }}>
            Portfolio
          </Link>
          <Link to="/pricing" className={isActive('/pricing')} style={{ color: "#CCCCCC" }}>
            Pricing
          </Link>
        </div>

        <div className="flex items-center ">




          <button
            // onClick={() => setIsOpen(!isOpen)} 
            className="focus:outline-none md:hidden"
            onClick={toggleMenu}
          >
            <img src={Menu} alt="Menu" className="w-6 h-6 cursor-pointer" />
          </button>



          <div className="hidden md:flex space-x-4">

            <Link to={"/contact"}>
              <button
                className=" text-white px-6 py-2 rounded-full text-sm font-semibold consultation-btn"
                style={{ height: "44px" }}
              >
                BOOK CONSULTATION
              </button>
            </Link>

          </div>
        </div>

        {/* {isOpen && (
        <div className="absolute top-[80px] right-0 w-full bg-[#2D2D2D] backdrop-blur-sm rounded-lg px-6 py-4 md:hidden z-50 nav-auto" initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.3, ease: "easeInOut" }}>
          <div className="flex flex-col space-y-4 ">
            <Link to="/" className={isActivesm('/')}>
              Home
            </Link>
            <Link to="/about" className={isActivesm('/about')}>
              About TR
            </Link>
            <Link to="/services" className={isActivesm('/services')}>
              Services
            </Link>
            <Link to="/work" className={isActivesm('/work')} onClick={() => console.log("clicked")}>
              Portfolio
            </Link>
            <Link to="/pricing" className={isActivesm('/pricing')}>
              Pricing
            </Link>
            <div className="flex space-x-4 mt-4">

              <Link to={"/contact"}>
                <button
                  className="text-white px-6 py-2 rounded-full text-sm font-semibold consultation-btn"
                  style={{ height: "44px" }} onClick={() => console.log("clicked")}
                >
                  BOOK CONSULTATION
                </button>

              </Link>
            </div>
          </div>
        </div>
      )} */}





      </nav>

      {/* {isOpen && (
        <AnimatePresence>
          <motion.nav
            initial={{ opacity: 0, x: "100%" }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: "100%" }}
            transition={{ duration: 0.3 }}
            className="fixed top-0 left-0 w-full h-full bg-[#2D2D2D] flex flex-col items-center justify-center space-y-8 text-white text-2xl z-50"
          >
            <button
              onClick={() => setIsOpen(false)}
              className="absolute top-6 right-6 text-3xl"
            >
              <CloseIcon />
            </button>

            <Link to="/" onClick={() => setIsOpen(false)}>Home</Link>
            <Link to="/about" onClick={() => setIsOpen(false)}>About TR</Link>
            <Link to="/services" onClick={() => setIsOpen(false)}>Services</Link>
            <Link to="/work" onClick={() => setIsOpen(false)}>Portfolio</Link>
            <Link to="/pricing" onClick={() => setIsOpen(false)}>Pricing</Link>
            <Link to="/contact">
              <button
                className="text-white px-6 py-2 rounded-full text-sm font-semibold consultation-btn"
                style={{ height: "44px" }}
                onClick={() => console.log("clicked")}
              >
                BOOK CONSULTATION
              </button>
            </Link>
          </motion.nav>
        </AnimatePresence>
      )} */}

      {/* {isOpen && (
        <AnimatePresence>
          <motion.nav
            initial={{ opacity: 0, x: "100%" }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: "100%" }}
            transition={{ duration: 0.3 }}
            className="fixed top-0 left-0 w-full h-full bg-[#2D2D2D] flex flex-col items-center justify-center space-y-8 text-white text-2xl z-50"
          >

            <button
              onClick={() => setIsOpen(false)}
              className="absolute top-6 right-6 text-3xl"
            >
              <CloseIcon />
            </button>


            <Link to="/" onClick={() => setIsOpen(false)}>Home</Link>
            <Link to="/about" onClick={() => setIsOpen(false)}>About TR</Link>

        
            <div className="relative flex flex-col items-center">
              <Link
                to="/services"
            
                className="flex items-center gap-1"
              >
                Services
                <ExpandMoreIcon
                  onClick={(e) => {
                    e.preventDefault();
                    setIsServicesOpen(!isServicesOpen);
                  }}
                  className="cursor-pointer"
                />
              </Link>

              <AnimatePresence>
                {isServicesOpen && (
                  <motion.div
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -10 }}
                    transition={{ duration: 0.2 }}
                    className="absolute top-full mt-2 bg-gray-800 p-4 rounded-md shadow-lg flex flex-col space-y-2 text-base"
                  >
                    <Link to="/services/web-dev" onClick={() => setIsOpen(false)}>Web Development</Link>
                    <Link to="/services/ui-ux" onClick={() => setIsOpen(false)}>UI/UX Design</Link>
                    <Link to="/services/mobile-apps" onClick={() => setIsOpen(false)}>Mobile Apps</Link>
                    <Link to="/services/consulting" onClick={() => setIsOpen(false)}>Consulting</Link>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>

            <Link to="/work" onClick={() => setIsOpen(false)}>Portfolio</Link>
            <Link to="/pricing" onClick={() => setIsOpen(false)}>Pricing</Link>


            <Link to="/contact">
              <button
                className="text-white px-6 py-2 rounded-full text-sm font-semibold consultation-btn"
                style={{ height: "44px" }}
                onClick={() => console.log("clicked")}
              >
                BOOK CONSULTATION
              </button>
            </Link>
          </motion.nav>
        </AnimatePresence>
      )} */}


      {/* {isOpen && (
        <AnimatePresence>
          <motion.nav
            initial={{ opacity: 0, x: "100%" }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: "100%" }}
            transition={{ duration: 0.3 }}
            className="fixed top-0 left-0 w-full h-full bg-[#2D2D2D] flex flex-col items-center justify-center space-y-4 text-white text-2xl z-50"
          >
       
            <button
              onClick={() => setIsOpen(false)}
              className="absolute top-6 right-6 text-3xl"
            >
              <CloseIcon />
            </button>

  
            <Link to="/" onClick={() => setIsOpen(false)}>Home</Link>
            <Link to="/about" onClick={() => setIsOpen(false)}>About TR</Link>


            <div className="flex flex-col items-center">
              <button
                onClick={() => setIsServicesOpen(!isServicesOpen)}
                className="flex items-center gap-2 focus:outline-none"
              >
                <Link to="/services" onClick={() => setIsOpen(false)}>Services</Link>
                <ExpandMoreIcon className={`transition-transform ${isServicesOpen ? "rotate-180" : "rotate-0"}`} />
              </button>

              <AnimatePresence>
                {isServicesOpen && (
                  <motion.div
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: "auto" }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={{ duration: 0.3 }}
                    className="flex flex-col items-center space-y-2 mt-2 text-lg"
                  >
                    <Link to="/services/web-dev" onClick={() => setIsOpen(false)}>Web Development</Link>
                    <Link to="/services/ui-ux" onClick={() => setIsOpen(false)}>UI/UX Design</Link>
                    <Link to="/services/mobile-apps" onClick={() => setIsOpen(false)}>Mobile Apps</Link>
                    <Link to="/services/consulting" onClick={() => setIsOpen(false)}>Consulting</Link>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>

            <Link to="/work" onClick={() => setIsOpen(false)}>Portfolio</Link>
            <Link to="/pricing" onClick={() => setIsOpen(false)}>Pricing</Link>

 
            <Link to="/contact">
              <button
                className="text-white px-6 py-2 rounded-full text-sm font-semibold consultation-btn"
                style={{ height: "44px" }}
                onClick={() => console.log("clicked")}
              >
                BOOK CONSULTATION
              </button>
            </Link>
          </motion.nav>
        </AnimatePresence>
      )} */}
      {isOpen && (

        <motion.nav
          initial={{ opacity: 0, x: "100%" }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: "100%" }}
          transition={{ duration: 0.3 }}
          className="fixed top-0 left-0 w-full h-full bg-[#1E1E1E] flex flex-col items-center justify-center space-y-6 text-white text-lg z-50 px-6"
        >

          <button
            onClick={() => setIsOpen(false)}
            className="absolute top-6 right-6 text-3xl text-gray-300 hover:text-white transition"
          >
            <CloseIcon />
          </button>

          {!isServicesOpen && <>




            <Link to="/" onClick={() => setIsOpen(false)} className={`nav-link text-xl sm:text-2xl ${isActivesm('/')}`} >Home</Link>
            <Link to="/about" onClick={() => setIsOpen(false)} className={`nav-link text-xl sm:text-2xl ${isActivesm('/about')}`}>About TR</Link>
          </>}


          <div className="flex flex-col items-center w-full">
            <button
              onClick={() => setIsServicesOpen(!isServicesOpen)}
              className={`flex items-center justify-center gap-2 text-xl sm:text-2xl `}
            >
              <Link to="/services" onClick={() => setIsOpen(false)} className={isMbService('/services')}>Services</Link>
              <ExpandMoreIcon className={`transition-transform ${isServicesOpen ? "rotate-180" : "rotate-0"}`} />
            </button>

            <AnimatePresence>
              {isServicesOpen && (
                <motion.div
                  initial={{ opacity: 0, height: 0 }}
                  animate={{ opacity: 1, height: "auto" }}
                  exit={{ opacity: 0, height: 0 }}
                  transition={{ duration: 0.3 }}
                  className="flex flex-col items-center space-y-8 w-full  py-4 px-6 rounded-md mt-2 "
                >

                  <Link to="/ai-enabled-solutions" onClick={() => setIsOpen(false)} className={`text-[#d0d0d0] sub-link text-lg sm:text-xl ${isActivesm('/ai-development')}`}>AI-Enabled Solutions</Link>

                  <Link to="/saas-product-development" onClick={() => setIsOpen(false)} className={`text-[#d0d0d0] sub-link text-lg sm:text-xl ${isActivesm('/product-development')}`}>SaaS Product Development</Link>
                  <Link to="/mobile-app-development" onClick={() => setIsOpen(false)} className={`text-[#d0d0d0] sub-link text-lg sm:text-xl ${isActivesm('/mobile-app-development')}`}>Mobile App Development</Link>
                  <Link to="/web-app-development" onClick={() => setIsOpen(false)} className={`text-[#d0d0d0] sub-link text-lg sm:text-xl ${isActivesm('/web-app-development')}`}>Web App Development</Link>
                  <Link to="/cms-development" onClick={() => setIsOpen(false)} className={`text-[#d0d0d0] sub-link text-lg sm:text-xl ${isActivesm('/cms-development')}`}>CMS Development</Link>

                  <Link to="/ui-ux-design" onClick={() => setIsOpen(false)} className={`text-[#d0d0d0] sub-link text-lg sm:text-xl ${isActivesm('/ui-ux-design')}`}>UI/UX Design</Link>
                  <Link to="/quality-assurance" onClick={() => setIsOpen(false)} className={`text-[#d0d0d0] sub-link text-lg sm:text-xl ${isActivesm('/quality-assurance')}`}>Quality Assurance Testing</Link>

                </motion.div>
              )}
            </AnimatePresence>
          </div>

          {!isServicesOpen && <>

            <Link to="/work" onClick={() => setIsOpen(false)} className={`nav-link text-xl sm:text-2xl ${isActivesm('/work')}`}>Portfolio</Link>
            <Link to="/pricing" onClick={() => setIsOpen(false)} className={`nav-link text-xl sm:text-2xl ${isActivesm('/pricing')}`}>Pricing</Link>

            <Link to="/contact">

              <button
                className="text-white px-6 py-2 rounded-full text-sm font-semibold consultation-btn"
                style={{ height: "44px" }} onClick={() => console.log("clicked")}
              >
                BOOK CONSULTATION
              </button>
            </Link>

          </>}
        </motion.nav>

      )}


    </>

  )
}

