// src/cardData.js

// import dev_img from "../assets/images/Developers.png";
// import sub_icon from "../assets/images/subscribe_icon.png";

import sub_icon from "../assets/images/subscribe_icon.png";




import img1 from "../assets/images/ImagesCards/img1.png"
import img2 from "../assets/images/ImagesCards/img2.png"
import img3 from "../assets/images/ImagesCards/img3.png"
import img4 from "../assets/images/ImagesCards/img4.png"

import img7 from "../assets/images/ImagesCards/img7.png"
import img8 from "../assets/images/ImagesCards/img8.png"
import img9 from "../assets/images/ImagesCards/img9.png"
import img10 from "../assets/images/ImagesCards/img10.png"



export const galleryData = [
  {
    title: (
      <>
        Custom Products <br /> Development
      </>
    ),
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis, consequuntur nam fugit accusamus ea doloremque veritatis excepturi harum aliquid illum quibusdam deleniti ipsam facere voluptas autem facilis architecto corporis. Consequatur!",
    image: img1,
    icon: sub_icon,
  },
  {
    title: (
      <>
        AI  Development
      </>
    ),
    description:
    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis, consequuntur nam fugit accusamus ea doloremque veritatis excepturi harum aliquid illum quibusdam deleniti ipsam facere voluptas autem facilis architecto corporis. Consequatur!",
    image: img2,
    icon: sub_icon,
  },
  {
    title: (
      <>
        Mobile App <br /> Development
      </>
    ),
    description:
    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis, consequuntur nam fugit accusamus ea doloremque veritatis excepturi harum aliquid illum quibusdam deleniti ipsam facere voluptas autem facilis architecto corporis. Consequatur!",
    image: img3,
    icon: sub_icon,
  },

  {
    title: (
      <>
        Web App  <br /> Development
      </>
    ),
    description:
    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis, consequuntur nam fugit accusamus ea doloremque veritatis excepturi harum aliquid illum quibusdam deleniti ipsam facere voluptas autem facilis architecto corporis. Consequatur!",
    image: img4,
    icon: sub_icon,
  },

  // {
  //   title: (
  //     <>
  //       UI/UX <br /> Design
  //     </>
  //   ),
  //   description:
  //     "Our design team focuses on creating visually appealing, intuitive interfaces that deliver seamless user experiences and engage users at every touchpoint.",
  //   image: img5,
  //   icon: sub_icon,
  // },
  

  // {
  //   title: (
  //     <>
  //       CMS <br /> Development
  //     </>
  //   ),
  //   description:
  //     "We provide flexible and scalable CMS solutions that enable easy content management, empowering teams to create, edit, and update content efficiently.",
  //   image: img6,
  //   icon: sub_icon,
  // },

  {
    title: (
      <>
        Quality Assurance<br /> Testing
      </>
    ),
    description:
    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis, consequuntur nam fugit accusamus ea doloremque veritatis excepturi harum aliquid illum quibusdam deleniti ipsam facere voluptas autem facilis architecto corporis. Consequatur!",
    image: img7,
    icon: sub_icon,
  },

  {
    title: (
      <>
        Quality Assurance<br /> Testing
      </>
    ),
    description:
    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis, consequuntur nam fugit accusamus ea doloremque veritatis excepturi harum aliquid illum quibusdam deleniti ipsam facere voluptas autem facilis architecto corporis. Consequatur!",
    image: img8,
    icon: sub_icon,
  },

  {
    title: (
      <>
        Quality Assurance<br /> Testing
      </>
    ),
    description:
    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis, consequuntur nam fugit accusamus ea doloremque veritatis excepturi harum aliquid illum quibusdam deleniti ipsam facere voluptas autem facilis architecto corporis. Consequatur!",
    image: img9,
    icon: sub_icon,
  },

  {
    title: (
      <>
        Quality Assurance<br /> Testing
      </>
    ),
    description:
    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis, consequuntur nam fugit accusamus ea doloremque veritatis excepturi harum aliquid illum quibusdam deleniti ipsam facere voluptas autem facilis architecto corporis. Consequatur!",
    image: img10,
    icon: sub_icon,
  },

];
