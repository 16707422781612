import { ArrowForward } from "@mui/icons-material"
import Blog1 from "../../assets/images/Blogs/another.png"

import Blog2 from "../../assets/images/Blogs/another2.png"
import Blog3 from "../../assets/images/Blogs/another3.png"
import { motion } from "framer-motion";
import right_down from "../../assets/images/BlogDeatils/right-drow.svg";
import { Link } from "react-router-dom";

export default function OtherBlogs() {
    const blogs = [
        {
            image:
                Blog1,
            category: "Mobile App",
            date: "January 4, 2025",
            title: "Custom Mobile App: Why & How?",
            description: "Discover why your business needs a custom mobile app and how to build one efficiently.",
            slug: "custom-mobile-app-for-your-business-why-how-to-build-one",
        },
        {
            image:
                Blog3,
            category: "AI & Business",
            date: "January 23, 2025",
            title: "AI & Automation: Boost Your Business",
            description: "Learn how AI and automation can streamline operations, enhance efficiency, and drive growth.",
            slug: "future-of-ecom-how-ai-and-automation-are-transforming-online-store",
        },
        {
            image:
                Blog2,
            category: "Web Development",
            date: "February 10, 2025",
            title: "Web Apps: Powering Business Growth",
            description: "Discover how a custom web app can improve efficiency, enhance user experience, and drive business success.",
            slug: "why-custom-web-development-is-a-game-changer-for-your-business-in-2025",
        },
    ]

    return (
        <motion.section className="pt-20 px-4 blog max-w-[1400px] mx-auto border-t border-[#E1E4EB]"   >
            <div className="max-w-7xl mx-auto ">
                <motion.div className="flex justify-between items-center mb-6" initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true, amount: 0.2 }}
                    transition={{ duration: 0.5 }}>
                    <h2 className="text-3xl font-medium text-[#0C0C0C] inter">Other Blogs</h2>
                    <span className="text-lg text-[#262626] cursor-pointer font-semibold flex items-center">
                        All blogs
                        <img src={right_down} alt="Right Arrow" className="ml-2 w-4 h-4" />
                    </span>
                </motion.div>

                <div className="relative">





                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-12">


                        {blogs.map((blog, index) => (
                            <motion.article
                                key={index}
                                className=" rounded-xl overflow-hidden transition-all duration-300 ease-in-out bg-gradient box-shadow-blog cursor-pointer hover:shadow-xl hover:scale-105 hover:bg-[#f9fafb]"
                                initial={{ opacity: 0, y: 50 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                viewport={{ once: true, amount: 0.2 }}
                                transition={{ duration: 0.5 }}
                            >

                                <Link 
to={ `/blogs/${blog.slug}` }>
                                {/* Blog Image */}
                                <div className=" overflow-hidden p-1">
                                    <img
                                        src={blog.image || "/placeholder.svg"}
                                        alt={blog.title}
                                        className="w-full h-full object-cover "
                                    />
                                </div>

                                {/* Blog Content */}
                                <div className="p-6">
                                    <div className="flex items-center mb-4 gap-2">
                                        <span className="text-sm font-medium text-[#3D3D3D] bg-[#F2F4F6] px-3 py-1 rounded-full border border-[#E1E4EB]">
                                            {blog.category}
                                        </span>
                                        <span className="text-sm font-medium text-[#3D3D3D] bg-[#F2F4F6] px-3 py-1 rounded-full border border-[#E1E4EB]">
                                            {blog.date}
                                        </span>
                                    </div>

                                    <h3 className="font-semibold text-xl mb-2 text-gray-900 line-clamp-2 inter">{blog.title}</h3>
                                    <p className="text-gray-600 line-clamp-2 inter">{blog.description}</p>
                                </div>
                                </Link>
                            </motion.article>
                        ))}
                    </div>



                </div>


                {/* <div className="text-center">


          <button
            className=" text-white px-6 py-3 rounded-full  font-semibold consultation-btn"

          >
            View All Blogs
          </button>
        </div> */}
            </div>
        </motion.section>
    )
}

