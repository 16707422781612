
import ios from "../assets/images/TechStack/ios.svg.png"

import angular from "../assets/images/TechStack/fi_15484402.png"

import flutter from "../assets/images/TechStack/flutter.svg fill.png"

import react from "../assets/images/TechStack/react-native.svg.png"

import svg_android from "../assets/images/svg_android.svg"
import dart from "../assets/images/TechStack/dart_png.png"

import openai from "../assets/images/TechStack/openai.png"













export const TechMobile = [
    // Row 1
    {
        name: "Android",
        logo: svg_android,
    },
    {
        name: "iOS",
        logo: ios,
    },
    {
        name: "Flutter",
        logo: flutter,
    },
    // {
    //     name: "React Native",
    //     logo: react,
    // },
    // {
    //     name: "Angular",
    //     logo: angular,
    // },
    // {
    //     name: "React",
    //     logo: react,
    // },

    {
        name: "Dart",
        logo: dart,
    },


    // {
    //     name: "Open AI",
    //     logo: openai,
    // },




]


