import parentician from "../../assets/images/BestWork/parentician.png"
import satori from "../../assets/images/BestWork/satori.png"

import shipora from "../../assets/images/BestWork/shipora.png"

import sindhisoulmate from "../../assets/images/BestWork/sindhisoulmate.png"

import sneh from "../../assets/images/BestWork/sneh.png"

import pharma from "../../assets/images/BestWork/pharma.png"
import parentician_logo from "../../assets/images/BestWork/parentician_logo.png"
import pharma_logo from "../../assets/images/BestWork/pharma_logo.png"
import satori_logo from "../../assets/images/BestWork/satori_logo.png"
import shipora_logo from "../../assets/images/BestWork/shipora_logo.png"
import sindhsolumate_logo from "../../assets/images/BestWork/sindhsolumate_logo.png"
import sneh_logo from "../../assets/images/BestWork/sneh_logo.png"
import { motion } from "framer-motion";
import arrow_icon from "../../assets/images/arrow_icon.svg"
import { Link } from "react-router-dom"
import { useNavigate } from "react-router-dom"




export default function WorkCards() {

    const navigate = useNavigate()

    const onNavigate = (slug)=>{
        if (!slug) return;
        navigate(slug)

    }




    const projects = [
        {
            name: "Shipora",
            description: "A platform that facilitates private and e-commerce shipments, along with a marketplace for Indian vendors targeting the Canadian market.",
            image: shipora,
            logo: shipora_logo,
            slug:"/projects/shipora"
        },
        {
            name: "Parentician",
            description: "A startup that supports modern parents with digital books, PDF checklists, webinars, doctor consultations, and informative blogs.",
            image:
                parentician,
            logo: parentician_logo,
              slug:"/projects/parentician"
        },
        // {
        //     name: "Pharma Ray",
        //     description: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. A, ab nemo! Expedita!",
        //     image:
        //         pharma,
        //     logo: pharma_logo,
        // },
        {
            name: "Sindhi Soulmate",
            description: "A matrimonial platform designed for the Sindhi community, offering advanced matchmaking features.",
            image: sindhisoulmate,
            logo: sindhsolumate_logo,
                    slug:"/projects/sindhi-soulmate"
        },
        // {
        //     name: "Satori",
        //     description: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. A, ab nemo! Expedita!",
        //     image:
        //         satori,
        //     logo: satori_logo,
        // },
        // {
        //     name: "Sneh",
        //     description: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. A, ab nemo! Expedita!",
        //     image:
        //         sneh,
        //     logo: sneh_logo,
        // },
    ]

    return (
        <motion.section className="pt-16 px-4  bestwork" initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true, amount: 0.2 }}
            transition={{ duration: 0.5 }} >

            <div className="relative max-w-7xl mx-auto">

                <div className="flex  items-center mb-8">
                    <h2 className="text-3xl font-normal text-[black] fightree">Check out other works</h2>

                </div>



                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                    {projects.map((project, index) => (

 
                            <div
                                key={index}
                                className="group rounded-2xl overflow-hidden bg-white hover:shadow-xl transition-shadow duration-300 cursor-pointer relative"
                                onClick={() => onNavigate(project.slug)} // ✅ Correct way
                            >


                                {/* Project Image */}
                                <div className="relative aspect-[4/3] overflow-hidden">
                                    <img
                                        src={project.image || "/placeholder.svg"}
                                        alt={`${project.name} project screenshot`}
                                        className="w-full h-full object-cover transform group-hover:scale-105 transition-transform duration-300"
                                    />
                                </div>

                                {/* Project Info */}
                                {/* <div className="p-6">
            <div className="flex items-start gap-3 mb-3">

                <div className="w-10 h-10 rounded-[10px] overflow-hidden flex-shrink-0 shadow-[0px_0px_1.19px_0px_#00000040] flex items-center justify-center">
                    <img
                        src={project.logo || "/placeholder.svg"}
                        alt={`${project.name} logo`}
                        className="max-w-full max-h-full object-contain"
                    />
                </div>

                <div className="flex flex-col">
                    <h3 className="font-semibold text-base text-[#434343] fightree">{project.name}</h3>
                    <p className="text-[#8A8A8A] text-sm fightree">{project.description}</p>
                </div>
            </div>
        </div> */}

                                <div className="p-6">
                                    <div className="flex items-start gap-3 mb-3">
                                        {/* Separate div for the image (not centered) */}
                                        <div className="w-10 h-10 rounded-[10px] overflow-hidden flex-shrink-0 shadow-[0px_0px_1.19px_0px_#00000040] flex items-center justify-center">
                                            <img
                                                src={project.logo || "/placeholder.svg"}
                                                alt={`${project.name} logo`}
                                                className="max-w-full max-h-full object-contain"
                                            />
                                        </div>

                                        {/* Separate div for the text (h3 and paragraph) */}
                                        <div className="flex flex-col">
                                            <h3 className="font-semibold text-base text-[#434343] fightree">{project.name}</h3>
                                            <p className="text-[#8A8A8A] text-sm fightree pr-8">{project.description}</p>
                                        </div>

                                        {/* Arrow icon centered */}
                                        {/* <div className="flex items-center justify-center ml-auto w-11 h-16" >
                <img
                    src={arrow_icon}
                    alt="Arrow Icon"
                    // className="w-7 h-16"
                    // className=" rounded-full bg-white border border-[#F7F7F7] p-2"
                    className="bg-white  "
                />
            </div> */}

                                        <div className="absolute bottom-12 right-6  p-2 rounded-full transition-all duration-300 ease-in-out cursor-pointer hover:shadow-xl hover:scale-125 hover:bg-[#f9fafb] box-shadow-blog ">
                                            <img src={arrow_icon} alt="arrow icon" className="w-2.5 h-2.5" />
                                        </div>
                                    </div>
                                </div>

                                {/* <div className="absolute bottom-12 right-5  p-2 rounded-full transition-all duration-300 ease-in-out cursor-pointer hover:shadow-xl hover:scale-125 hover:bg-[#f9fafb] box-shadow-blog ">
        <img src={arrow_icon} alt="arrow icon" className="w-3 h-3" />
    </div> */}

                            </div>
                  
                    ))}
                </div>

            </div>
        </motion.section>
    )
}

