import React from 'react'
import { useEffect, useRef } from 'react'
import custom_ctm from "../../assets/images/Services/CustomProduct/custom_ctm.png"
import { ArrowForward } from "@mui/icons-material"
import satori_logo from "../../assets/images/BestWork/satori_logo.png"
import satori_img from "../../assets/images/BestWork/satori.png"
import shipora_logo from "../../assets/images/BestWork/shipora_logo.png"
import shipora_img from "../../assets/images/BestWork/shipora.png"
import parentician_logo from "../../assets/images/BestWork/parentician_logo.png"
import parentician_img from "../../assets/images/BestWork/parentician.png"
import sindhi_logo from "../../assets/images/BestWork/sindhsolumate_logo.png"
import sindhi_img from "../../assets/images/BestWork/sindhisoulmate.png"
import { motion } from 'framer-motion';
import { useMediaQuery } from '@mui/material'
import "./TechServices.css"

import { technologies } from '../../data/techStackData'
import { techstackai } from '../../data/techStackAI'
import { techWeb } from '../../data/techStackWeb'
import { TechMobile } from '../../data/techStackMobile'
import { techCms } from '../../data/techStackCMS'













const TechServices = () => {
    const isDesktop = useMediaQuery('(min-width:1024px)');



    const apps = [
        {
            img: satori_img,
            logo: satori_logo,
            title: "Satori",
            description: "Lorem ipsum dolor sit amet consectetur."
        },
        {
            img: shipora_img,
            logo: shipora_logo,
            title: "Shipora",
            description: "Lorem ipsum dolor sit amet consectetur."
        },

    ];


    const apps2 = [
        {
            img: parentician_img,
            logo: parentician_logo,
            title: "Parentician",
            description: "Lorem ipsum dolor sit amet consectetur."
        },
        {
            img: sindhi_img,
            logo: sindhi_logo,
            title: "Sindhi Soulmate",
            description: "Lorem ipsum dolor sit amet consectetur."
        },

    ];

    const trackRef = useRef(null);
    const trackRef2 = useRef(null);


    useEffect(() => {
        const track = trackRef.current;
        if (track && isDesktop) {
            // Duplicate items for smooth infinite scrolling
            track.innerHTML += track.innerHTML;
        }
    }, []);

    useEffect(() => {
        const track = trackRef2.current;
        if (track && isDesktop) {
            // Duplicate items for smooth infinite scrolling
            track.innerHTML += track.innerHTML;
        }
    }, []);




    return (

        <section className="relative py-0 sm:py-16 px-4 about-comp -z-10 tech-service">




            <div className="max-w-7xl mx-auto">





                <motion.div
                    className="relative grid grid-cols-1 lg:grid-cols-2 gap-0 lg:gap-8 items-center justify-center 
 overflow-hidden"

                    {...(isDesktop
                        ? {
                            initial: { opacity: 0, y: 50 },
                            whileInView: { opacity: 1, y: 0 },
                            viewport: { once: true, amount: 0.5 },
                            transition: { duration: 0.7 }
                        }
                        : {})}
                >


                    <div className="space-y-8 flex flex-col h-full p-6 justify-center relative z-[1] ">

                        <div className="space-y-10">

                            <span className="font-medium text-[#333333]  px-4 py-2   rounded-full border border-[#EDEFF3] bg-[#F8F9FA] ">Our Services</span>




                            <h2 className="text-2xl md:text-4xl font-bold lg-text text-[#0C0C0C]">
                                Offering Top-Notch IT Solutions To Your Needs

                            </h2>
                            <p className="text-[#666666] text-lg">
                                Offering the most recent modern technology to grow your business exponentially equipped with top-quality technology that meets your desires.

                            </p>



                        </div>
                    </div>


                    <div className="space-y-8 flex flex-row h-full  px-6 justify-center items-center ">


                        <div className="grid grid-cols-2 sm:grid-cols-3 gap-12 srvc-shadow ">




                            <div className="srvc-card-slider">
                                <div className="space-y-6 srvc-card-track" >
                                    {/* {apps.map((app, index) => (
                                        <div key={index}>
                                            <div className="group rounded-2xl overflow-hidden bg-white cursor-pointer relative ct-card">
                                                <div className="relative aspect-[4/3] overflow-hidden">
                                                    <img
                                                        src={app.img || "/placeholder.svg"}
                                                        className="w-full h-auto object-cover"
                                                    />
                                                </div>

                                                <div className="p-2">
                                                    <div className="flex items-start gap-3 mb-3">
                                                        <div className="w-10 h-10 rounded-[10px] overflow-hidden flex-shrink-0 shadow-[0px_0px_1.19px_0px_#00000040] flex items-center justify-center">
                                                            <img
                                                                src={app.logo || "/placeholder.svg"}
                                                                className="max-w-full max-h-full object-contain"
                                                            />
                                                        </div>

                                                        <div className="flex flex-col">
                                                            <h3 className="font-semibold text-base text-[#434343] fightree">{app.title}</h3>
                                                            <p className="text-[#8A8A8A] text-sm fightree">{app.description}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))} */}

                                    {techWeb?.map((tech, index) => (
                                        <div
                                            key={index}
                                            className="flex flex-col cursor-pointer items-center justify-center group bg-[#F9FAFB]  rounded-3xl p-4 transition-colors duration-300 hover:bg-[#22989F] ct-card border border-[#E5E7EB]"
                                        >
                                            <div className="w-20 h-20 mb-3 flex items-center justify-center transition-transform duration-300 hover:-translate-y-2  ">
                                                <img
                                                    src={tech.logo || "/placeholder.svg"}
                                                    alt={`${tech.name} logo`}
                                                    // className="object-contain group-hover:filter group-hover:brightness-0 group-hover:invert"
                                                    className={`object-contain ${["Webflow", "Node Js"].includes(tech.name)
                                                        ? "" // No hover effect for these
                                                        : "w-14"
                                                        } 
                                
                                
                                
                                ${["Sanity", "Angular", "PostgreSQL"].includes(tech.name)
                                                            ? "" // No hover effect for these
                                                            : "group-hover:filter group-hover:brightness-0 group-hover:invert"
                                                        }`}
                                                />
                                            </div>
                                            <span className="text-xl text-[#3C4858] text-center font-600 group-hover:text-[#FFFFFFCC] transition-colors duration-300">{tech.name}</span>
                                        </div>
                                    ))}
                                </div>
                            </div>




                            <div className='srvc-card-slider-2'>

                                <div className="space-y-6 srvc-card-track-2" >
                                    {/* {apps2.map((app, index) => (
                                        <div key={index}>
                                            <div className="group rounded-2xl overflow-hidden bg-white cursor-pointer relative ct-card-2">
                                                <div className="relative aspect-[4/3] overflow-hidden">
                                                    <img
                                                        src={app.img || "/placeholder.svg"}
                                                        className="w-full h-auto object-cover"
                                                    />
                                                </div>

                                                <div className="p-2">
                                                    <div className="flex items-start gap-3 mb-3">
                                                        <div className="w-10 h-10 rounded-[10px] overflow-hidden flex-shrink-0 shadow-[0px_0px_1.19px_0px_#00000040] flex items-center justify-center">
                                                            <img
                                                                src={app.logo || "/placeholder.svg"}
                                                                className="max-w-full max-h-full object-contain"
                                                            />
                                                        </div>

                                                        <div className="flex flex-col">
                                                            <h3 className="font-semibold text-base text-[#434343] fightree">{app.title}</h3>
                                                            <p className="text-[#8A8A8A] text-sm fightree">{app.description}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))} */}

                                    {techstackai?.map((tech, index) => (
                                        <div
                                            key={index}
                                            className="flex flex-col cursor-pointer items-center justify-center group bg-[#F9FAFB]  rounded-3xl p-4 transition-colors duration-300 hover:bg-[#22989F] ct-card-2 border border-[#E5E7EB]"
                                        >
                                            <div className="w-20 h-20 mb-3 flex items-center justify-center transition-transform duration-300 hover:-translate-y-2  ">
                                                <img
                                                    src={tech.logo || "/placeholder.svg"}
                                                    alt={`${tech.name} logo`}
                                                    // className="object-contain group-hover:filter group-hover:brightness-0 group-hover:invert"
                                                    className={`object-contain ${["Webflow", "Node Js"].includes(tech.name)
                                                        ? "" // No hover effect for these
                                                        : "w-14"
                                                        } 
                                
                                
                                
                                ${["Sanity", "Angular", "PostgreSQL"].includes(tech.name)
                                                            ? "" // No hover effect for these
                                                            : "group-hover:filter group-hover:brightness-0 group-hover:invert"
                                                        }`}
                                                />
                                            </div>
                                            <span className="text-xl text-[#3C4858] text-center font-600 group-hover:text-[#FFFFFFCC] transition-colors duration-300">{tech.name}</span>
                                        </div>
                                    ))}
                                </div>



                            </div>

                            <div className="srvc-card-slider hidden sm:block">
                                <div className="space-y-6 srvc-card-track" >
                                    {/* {apps.map((app, index) => (
                                        <div key={index}>
                                            <div className="group rounded-2xl overflow-hidden bg-white cursor-pointer relative ct-card">
                                                <div className="relative aspect-[4/3] overflow-hidden">
                                                    <img
                                                        src={app.img || "/placeholder.svg"}
                                                        className="w-full h-auto object-cover"
                                                    />
                                                </div>

                                                <div className="p-2">
                                                    <div className="flex items-start gap-3 mb-3">
                                                        <div className="w-10 h-10 rounded-[10px] overflow-hidden flex-shrink-0 shadow-[0px_0px_1.19px_0px_#00000040] flex items-center justify-center">
                                                            <img
                                                                src={app.logo || "/placeholder.svg"}
                                                                className="max-w-full max-h-full object-contain"
                                                            />
                                                        </div>

                                                        <div className="flex flex-col">
                                                            <h3 className="font-semibold text-base text-[#434343] fightree">{app.title}</h3>
                                                            <p className="text-[#8A8A8A] text-sm fightree">{app.description}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))} */}

                                    {techCms?.map((tech, index) => (
                                        <div
                                            key={index}
                                            className="flex flex-col cursor-pointer items-center justify-center group bg-[#F9FAFB]  rounded-3xl p-4 transition-colors duration-300 hover:bg-[#22989F] ct-card border border-[#E5E7EB]"
                                        >
                                            <div className="w-20 h-20 mb-3 flex items-center justify-center transition-transform duration-300 hover:-translate-y-2  ">
                                                <img
                                                    src={tech.logo || "/placeholder.svg"}
                                                    alt={`${tech.name} logo`}
                                                    // className="object-contain group-hover:filter group-hover:brightness-0 group-hover:invert"
                                                    className={`object-contain ${["Webflow", "Node Js"].includes(tech.name)
                                                        ? "" // No hover effect for these
                                                        : "w-14"
                                                        } 
                                
                                
                                
                                ${["Sanity", "Angular", "PostgreSQL"].includes(tech.name)
                                                            ? "" // No hover effect for these
                                                            : "group-hover:filter group-hover:brightness-0 group-hover:invert"
                                                        }`}
                                                />
                                            </div>
                                            <span className="text-xl text-[#3C4858] text-center font-600 group-hover:text-[#FFFFFFCC] transition-colors duration-300">{tech.name}</span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>




                </motion.div>
            </div>
        </section>

    )
}

export default TechServices
