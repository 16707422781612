import PlayCircleIcon from "@mui/icons-material/PlayCircle"
import clients from "../assets/images/clients1.png"
import clients2 from "../assets/images/clients2.png"
import clients3 from "../assets/images/clients3.png"
import bgimage from "../assets/images/Vector.webp"
import VideoCard from "./VideoCard/VideoCard"
import left from "../assets/images/left-overlay.png"
import right from "../assets/images/right-overlay.png"
import { useState } from "react"
import { useEffect } from "react"
import video_img from "../assets/images/video_img.webp"
// import { Typed } from "react-typed"
import Typed from "typed.js";
import { useRef } from "react"
import { Link } from "react-router-dom"
import { ChevronRight } from "@mui/icons-material";
export function HeroSection({ isOpen }) {
    const fullText = "Streamlining Your Businesses With Web and App Development Services";
    const [displayedText, setDisplayedText] = useState(""); 
    const typingSpeed = 70; 
    const images = [clients, clients2, clients3]; 

    useEffect(() => {
        let currentIndex = 0;

        const type = () => {
            if (currentIndex < fullText.length) {
                setDisplayedText(fullText.slice(0, currentIndex + 1)); // Update text up to the current index
                currentIndex++;
                setTimeout(type, typingSpeed);
            }
        };

        type();
    }, []);

    const [animate, setAnimate] = useState(false);

    useEffect(() => {
        // Trigger animation on mount
        setAnimate(true);

        // Optional: Remove animation class after it's done
        const timer = setTimeout(() => setAnimate(false), 1000); // Animation duration is 1s
        return () => clearTimeout(timer); // Clean up the timer on unmount
    }, []);

    const typedElement = useRef(null); // Reference to the span element
    const typedInstance = useRef(null); // Reference to the Typed.js instance

    useEffect(() => {
        // Initialize Typed.js
        typedInstance.current = new Typed(typedElement.current, {
            strings: ["With Web and App Development Service"], // The text to animate
            typeSpeed: 50, // Typing speed (ms per character)
            backSpeed: 0, // No backspacing
            showCursor: false, // Hide the blinking cursor
        });

        return () => {
            // Destroy the Typed.js instance on component unmount
            typedInstance.current.destroy();
        };
    }, []);


    return (
        <section className="container mx-auto px-4 py-12 text-center md:py-20 relative  " >
            {/* 
            <div
                className="absolute left-0 top-0 h-full w-1/3 bg-no-repeat bg-cover bg-left pointer-events-none"
                style={{
                    backgroundImage: `url(${left})`,
                }}
            ></div>

      
            <div
                className="absolute right-0 top-0 h-full w-1/3 bg-no-repeat bg-cover bg-right pointer-events-none"
                style={{
                    backgroundImage: `url(${right})`,
                }}
            ></div> */}
            <div
                className={`flex justify-center mb-10 zoom-in bg-white rounded-full shadow-[0px_2px_2px_0px_#00000005,0px_10px_41px_0px_#0000000A] ${isOpen ? "relative -z-10 pointer-events-none" : ""
                }`}
                style={{
                    alignItems: "center",
                    display: "inline-flex",
                    padding: "10px",
                }}
            >

                <div className="flex -space-x-2">
                    {images.map((clientImage, index) => (
                        <div
                            key={index}
                            className="relative w-6 h-6  sm:w-8 sm:h-8 md:w-8 md:h-8 rounded-full border-2 border-white overflow-hidden"
                        >
                            <img src={clientImage} alt={`Client ${index + 1}`} className="w-full h-full object-cover" />
                        </div>
                    ))}
                </div>
                <span className="ml-2 font-medium text-sm sm:text-base" style={{ color: "#333333" }}>Trusted by Businesses Worldwide </span>
            </div>


            <div className="animation" >
                {/* <h1 className="mx-auto max-w-3xl text-3xl md:text-4xl lg:text-5xl xl:text-6xl tracking-tight line-height-lg man-transition " style={{ fontWeight: "600", color: "#191919", overflow: "hidden", wordBreak: "break-word", whiteSpace: "normal" }}>
                
                    Streamlining Your Businesses With Web and App Development Services

                </h1> */}
                {/* <h1
                    className="mx-auto max-w-3xl text-3xl md:text-4xl lg:text-5xl xl:text-6xl tracking-tight line-height-lg man-transition"
                    style={{
                        fontWeight: "600",
                        color: "#191919",
                        overflow: "hidden",
                        wordBreak: "break-word",
                        whiteSpace: "normal",
                    }}
                >
                    Streamlining Your Businesses{" "}
                    <Typed
                        strings={["With Web and App Development Services"]}
                        typeSpeed={50} // Speed of typing (in ms)
                        backSpeed={0} // No backspacing
                        showCursor={false} // Hide the blinking cursor
                    />
                </h1> */}



                <h1
                    className={`mx-auto max-w-5xl text-3xl md:text-4xl lg:text-5xl xl:text-6xl tracking-tight line-height-lg man-transition ${isOpen ? "relative -z-10 pointer-events-none" : ""
                        }`}
                    style={{
                        fontWeight: "600",
                        color: "#191919",
                        overflow: "hidden",
                        wordBreak: "break-word",
                        whiteSpace: "normal",
                        position:"relative",
                        zIndex:"999",
                    }}
                >
                    Streamlining Your Business{" "}
                    <span ref={typedElement}></span>
                </h1>
                {/* <h1 className="mx-auto max-w-3xl text-3xl md:text-4xl lg:text-5xl xl:text-6xl tracking-tight line-height-lg man-transition " style={{ fontWeight: "600", color: "#191919" }}>
                    Streamlining Your Businesses  With Web and App Development Services
                </h1> */}

                <div style={{
                    backgroundImage: `url(${bgimage})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",


                }}
                
                role="presentation" 
                
                loading="lazy">
                    <p className={`mx-auto mt-6 max-w-2xl text-size md:text-base ${isOpen ? "relative -z-10 pointer-events-none" : ""
                        }`} style={{ fontWeight: "500", color: "#4D4D4D" }}>
        We make a difference to be the leading IT company in Ahmedabad, and that is to make the best product in the minimal time, which is beyond the expectations of our clients. Let’s collaborate and launch your idea with the help of an expert team

                    </p>

                    <Link to={"/consult-us"}>

                    <button
                        // className=" text-white px-6 py-2 rounded-full text-sm font-semibold consultation-btn mt-8"
                        className={`text-white px-6 py-2 rounded-full text-sm font-semibold consultation-btn mt-8 ${animate ? "animate__animated   animate__pulse" : ""
                            } ${isOpen ? "relative -z-10 pointer-events-none" : ""
                            }`}



                        style={{ height: "44px" }}
                    >
                        FREE CONSULTATION
                    </button>
                    </Link>

                </div>
            </div>

            {/* <PlayCircleIcon className="h-8 w-8 md:h-12 md:w-12 text-[#37BFE7]" /> */}

            {/* <button className="mt-8 bg-gradient-to-r from-[#37BFE7] to-[#5B8DEF] text-white px-6 py-2 rounded-md hover:opacity-90">
        CONSULTATION
      </button> */}
            <div className="relative mx-auto mt-7 sm:mt-16 max-w-5xl overflow-hidden rounded-2xl">
                {/* <div className="aspect-[16/9] bg-gray-100">
          <img
            src="https://hebbkx1anhila5yf.public.blob.vercel-storage.com/home-web-lxnLceZ9VhIkq0pUl8qk6DIHUXxBOM.png"
            alt="Business meeting"
            className="h-full w-full object-cover"
          />
          <div className="absolute inset-0 flex items-center justify-center">
            <div className="rounded-full bg-white/90 p-2 md:p-4">
              <PlayCircleIcon className="h-8 w-8 md:h-12 md:w-12 text-[#37BFE7]" />
            </div>
          </div>
        </div> */}
            </div>

            <VideoCard
                poster={video_img}
                videoId="dQw4w9WgXcQ"
            />
        </section>
    )
}

