import { Description, AccessTime } from "@mui/icons-material"
// import main_img from "../../assets/images/MobileDevPage/About/payment.svg"
import main_img from "../../assets/images/MobileDevPage/gif.gif"

import "./MobileDevAbout.css"
import { motion } from "framer-motion";


export default function MobileDevAbout() {
    const features = [
        {
            icon: <Description className="text-emerald-600 w-6 h-6" />,
            title: "Our Mission",
            description: "Connect tools for unified workflow efficiency.",
        },
        {
            icon: <AccessTime className="text-emerald-600 w-6 h-6" />,
            title: "Our Vision",
            description: "User-friendly interface boosts team productivity.",
        },
    ]

    return (
        <section className="py-16 px-4 mobiledevabout">
            <div className="max-w-7xl mx-auto">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">

                    <div className="space-y-8">

                        <motion.div className="inline-flex items-center gap-4  " initial={{ opacity: 0, y: 50 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            viewport={{ once: true, amount: 0.2 }}
                            transition={{ duration: 0.5 }}  >
                            <span className="bg-gradient-to-b from-[#25B57E] to-[#2BAAE2] text-transparent bg-clip-text font-bold text-base">
                                ABOUT US
                            </span>

                            <div className="w-16 h-[2px] bg-[#1DBB91]"></div>
                        </motion.div>


                        <motion.div className="space-y-6" initial={{ opacity: 0, y: 50 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            viewport={{ once: true, amount: 0.2 }}
                            transition={{ duration: 0.5 }}  >
                            <h2 className="text-3xl md:text-4xl font-bold leading-tight  lg-text text-[#0A0322]">Innovating the Mobile Experience</h2>
                            <p className="text-[#4B4B57] text-base ">
                                Tech Rabbit is a leading mobile app development company dedicated to building robust, user-friendly, and scalable mobile applications. Our team of expert developers, designers, and strategists work closely with businesses to bring their ideas to life with technology that makes an impact.
                            </p>
                        </motion.div>


                        <motion.div className="flex gap-10 justify-start" initial={{ opacity: 0, y: 50 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            viewport={{ once: true, amount: 0.2 }}
                            transition={{ duration: 0.5 }} >
                            {features.map((feature, index) => (
                                <div
                                    key={index}


                                >

                                    <h3 className="font-bold text-3xl mb-2 text-[#0F0F0F] ">117+</h3>
                                    <p className="text-[#4B4B57]  text-base">Lorem ipsum </p>
                                </div>
                            ))}
                        </motion.div>
                    </div>


                    <motion.div className="bg-[#F9FAFB] rounded-3xl p-8 " initial={{ opacity: 0, y: 50 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true, amount: 0.2 }}
                        transition={{ duration: 0.5 }} >
                        <img
                            src={main_img}
                            alt="Team collaboration illustratio "
                            className="w-full h-auto rounded-2xl"
                        />
                    </motion.div>
                </div>
            </div>
        </section>
    )
}

