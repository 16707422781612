import React from 'react';
import sub_icon from "../../assets/images/subscribe_icon.png";
import dev_img from "../../assets/images/Developers.png";
import "./GallerComp.css"

import { galleryData } from '../../data/galleryData';
import overlay_blog from "../../assets/images/blog-overlay.svg"
import GalleryCard from './GalleryCard';
import { useEffect } from 'react';
import { useState } from 'react';
import { useRef } from 'react';
const { ScrollObserver, valueAtPercentage } = window.aat;


export default function GalleryComp() {

    // const cardsContainerRef = useRef(null);

    // useEffect(() => {
    //     const cardsContainer = document.querySelector('.cards')
    //     const cards = document.querySelectorAll('.card')
    //     cardsContainer.style.setProperty('--cards-count', cards.length)
    //     cardsContainer.style.setProperty(
    //         '--card-height',
    //         `${cards[0].clientHeight}px`
    //     )
    //     Array.from(cards).forEach((card, index) => {
    //         const offsetTop = 20 + index * 20
    //         card.style.paddingTop = `${offsetTop}px`
    //         if (index === cards.length - 1) {
    //             return
    //         }
    //         const toScale = 1 - (cards.length - 1 - index) * 0.1
    //         const nextCard = cards[index + 1]
    //         const cardInner = card.querySelector('.card__inner')
    //         ScrollObserver.Element(nextCard, {
    //             offsetTop,
    //             offsetBottom: window.innerHeight - card.clientHeight
    //         }).onScroll(({ percentageY }) => {
    //             cardInner.style.scale = valueAtPercentage({
    //                 from: 1,
    //                 to: toScale,
    //                 percentage: percentageY
    //             })
    //             cardInner.style.filter = `brightness(${valueAtPercentage({
    //                 from: 1,
    //                 to: 0.6,
    //                 percentage: percentageY
    //             })})`
    //         })
    //     })
    // }, []);
    return (
        <div className="relative min-h-screen developer-section-gradient px-4 py-12 md:px-6 lg:px-8 gl-section">
            <div className="relative z-10 mx-auto max-w-6xl text-center">
                {/* <h1 className="mb-4 text-3xl   md:text-4xl lg:text-5xl gradient-text" >
                    Top Mobile Application Development
                    <br />
                    Company In India
                </h1>
                <p className="mb-4 text-lg text-white">Offering the most recent modern technology to grow your business</p>
                <p className="mb-12 text-xl text-white fightree">bite-sized design tasks.</p> */}

                <div className="space-y-8 cards" >

                    {galleryData.map((card, index) => (
                        <GalleryCard
                            key={index} // Always use a unique key when mapping
                            title={card.title}
                            description={card.description}
                            image={card.image}
                            icon={card.icon}
                            index={index}
                            totalCards={galleryData.length}
                        />
                    ))}

                </div>
            </div>


        </div>
    );
}
