import React, { useEffect, useRef } from "react";
// import "./JumpStackCard.css";

const JumpStackCard = () => {
  const stackRef = useRef(null);

  useEffect(() => {
    let stack = stackRef.current;
    if (!stack) return;

    // Reverse the order of children initially
    [...stack.children].reverse().forEach((i) => stack.append(i));

    // Swap function
    function swap(e) {
      let card = stack.querySelector(".card:last-child");
      if (e.target !== card) return;
      card.style.animation = "swap 700ms forwards";

      setTimeout(() => {
        card.style.animation = "";
        stack.prepend(card);
      }, 700);
    }

    stack.addEventListener("click", swap);

    // Cleanup event listener on unmount
    return () => {
      stack.removeEventListener("click", swap);
    };
  }, []);

  return (
    <div className="stack" ref={stackRef}>
      <div className="card">1</div>
      <div className="card">2</div>
      <div className="card">3</div>
      <div className="card">4</div>
      <div className="card">5</div>
    </div>
  );
};

export default JumpStackCard;
