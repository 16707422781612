import React from 'react'
import { useEffect, useRef } from 'react'
import custom_ctm from "../../../assets/images/Services/CustomProduct/custom_ctm.png"
import { ArrowForward } from "@mui/icons-material"
import satori_logo from "../../../assets/images/BestWork/satori_logo.png"
import satori_img from "../../../assets/images/BestWork/satori.png"
import shipora_logo from "../../../assets/images/BestWork/shipora_logo.png"
import shipora_img from "../../../assets/images/BestWork/shipora.png"
import parentician_logo from "../../../assets/images/BestWork/parentician_logo.png"
import parentician_img from "../../../assets/images/BestWork/parentician.png"
import sindhi_logo from "../../../assets/images/BestWork/sindhsolumate_logo.png"
import sindhi_img from "../../../assets/images/BestWork/sindhisoulmate.png"
import { motion } from 'framer-motion';
import { useMediaQuery } from '@mui/material'
import { useNavigate } from 'react-router-dom'
const CustomCTM = () => {
    const isDesktop = useMediaQuery('(min-width:1024px)');

    const apps = [
        {
            img: satori_img,
            logo: satori_logo,
            title: "Satori",
            description: "Lorem ipsum dolor sit amet consectetur."
        },
        {
            img: shipora_img,
            logo: shipora_logo,
            title: "Shipora",
            description: "Lorem ipsum dolor sit amet consectetur."
        },

    ];


    const apps2 = [
        {
            img: parentician_img,
            logo: parentician_logo,
            title: "Parentician",
            description: "Lorem ipsum dolor sit amet consectetur."
        },
        {
            img: sindhi_img,
            logo: sindhi_logo,
            title: "Sindhi Soulmate",
            description: "Lorem ipsum dolor sit amet consectetur."
        },

    ];

    const trackRef = useRef(null);
    const trackRef2 = useRef(null);


    useEffect(() => {
        const track = trackRef.current;
        if (track && isDesktop) {
            // Duplicate items for smooth infinite scrolling
            track.innerHTML += track.innerHTML;
        }
    }, []);

    useEffect(() => {
        const track = trackRef2.current;
        if (track && isDesktop) {
            // Duplicate items for smooth infinite scrolling
            track.innerHTML += track.innerHTML;
        }
    }, []);

    const navigate = useNavigate()



    return (

        <section className="relative py-16 px-4 about-comp -z-10 customCTM">




            <div className="max-w-7xl mx-auto">



                <motion.div
                    className="relative grid grid-cols-1 lg:grid-cols-[3fr_2fr] gap-0 lg:gap-8 items-center justify-center 
    border-[#F2F3F8] border-4 rounded-3xl overflow-hidden"

                    {...(isDesktop
                        ? {
                            initial: { opacity: 0, y: 50 },
                            whileInView: { opacity: 1, y: 0 },
                            viewport: { once: true, amount: 0.5 },
                            transition: { duration: 0.7 }
                        }
                        : {})}
                >


                    {isDesktop &&

                        <motion.svg
                            className="absolute right-[-88px] top-[-35px] w-[20%] h-auto z-[-1] pointer-events-none"
                            style={{transform:"rotate(26deg)"}}
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 489 429"
                            initial={{ strokeDashoffset: 1 }}
                            whileInView={{
                                strokeDashoffset: 0,
                            }}
                            transition={{
                                strokeDashoffset: { duration: 3, ease: "easeInOut" },
                            }}
                        >
                            <defs>
                                <linearGradient id="gradient1" x1="0%" y1="0%" x2="100%" y2="100%">
                                    <stop offset="0%" stopColor="#2BAAE2" />
                                    <stop offset="100%" stopColor="#24B574" />
                                </linearGradient>
                            </defs>
                            <path
                                d="M472.903 83.8C417.302 35.747 321.102 -11.368 240.895 35.459C111.796 110.834 194.484 225.891 258.778 246.414C314.373 264.161 343.363 199.518 243.126 162.942C177.606 139.035 86.553 147.243 51.352 198.732C3.224 269.132 72.078 316.981 104.714 324.385C158.609 336.612 163.716 281.692 101.748 273.198C38.974 264.593 0.97 303.747 21.932 412.542"
                                stroke="#25B47ED4"
                                strokeWidth="32"
                                strokeLinecap="round"
                                fill="transparent"
                                opacity="1"
                                pathLength="1"
                                strokeDasharray="1 1"
                            />
                        </motion.svg>

                    }

                  


                    <div className="space-y-8 grid grid-cols-1  h-full p-6">


                        <div className="grid grid-cols-1 md:grid-cols-2 gap-12">




                            <div className="ct-card-slider">
                                <div className="space-y-6 ct-card-track" ref={isDesktop ? trackRef : null}>
                                    {apps.map((app, index) => (
                                        <div key={index}>
                                            <div className="group rounded-2xl overflow-hidden bg-white cursor-pointer relative ct-card">
                                                <div className="relative aspect-[4/3] overflow-hidden">
                                                    <img
                                                        src={app.img || "/placeholder.svg"}
                                                        className="w-full h-auto object-cover"
                                                    />
                                                </div>

                                                <div className="p-2">
                                                    <div className="flex items-start gap-3 mb-3">
                                                        <div className="w-10 h-10 rounded-[10px] overflow-hidden flex-shrink-0 shadow-[0px_0px_1.19px_0px_#00000040] flex items-center justify-center">
                                                            <img
                                                                src={app.logo || "/placeholder.svg"}
                                                                className="max-w-full max-h-full object-contain"
                                                            />
                                                        </div>

                                                        <div className="flex flex-col">
                                                            <h3 className="font-semibold text-base text-[#434343] fightree">{app.title}</h3>
                                                            <p className="text-[#8A8A8A] text-sm fightree">{app.description}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>




                            <div className='ct-card-slider-2'>

                                <div className="space-y-6 ct-card-track-2" ref={isDesktop ? trackRef2 : null} >
                                    {apps2.map((app, index) => (
                                        <div key={index}>
                                            <div className="group rounded-2xl overflow-hidden bg-white cursor-pointer relative ct-card-2">
                                                <div className="relative aspect-[4/3] overflow-hidden">
                                                    <img
                                                        src={app.img || "/placeholder.svg"}
                                                        className="w-full h-auto object-cover"
                                                    />
                                                </div>

                                                <div className="p-2">
                                                    <div className="flex items-start gap-3 mb-3">
                                                        <div className="w-10 h-10 rounded-[10px] overflow-hidden flex-shrink-0 shadow-[0px_0px_1.19px_0px_#00000040] flex items-center justify-center">
                                                            <img
                                                                src={app.logo || "/placeholder.svg"}
                                                                className="max-w-full max-h-full object-contain"
                                                            />
                                                        </div>

                                                        <div className="flex flex-col">
                                                            <h3 className="font-semibold text-base text-[#434343] fightree">{app.title}</h3>
                                                            <p className="text-[#8A8A8A] text-sm fightree">{app.description}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>


                                                 </div>
                        </div>
                    </div>

                    <div className="space-y-8 flex flex-col h-full p-6 justify-center relative z-[1] ">

                        <div className="space-y-10">
                            <h2 className="text-2xl md:text-4xl font-bold lg-text text-[#2C2C2C]">
                                Integrated with <span className="text-[#1DBB91]">best apps web.</span>
                            </h2>
                            <p className="text-[#2C2C2C] text-base">
                                Experience effortless workflow integration as the best apps seamlessly connect with our productivity
                                calendar. Tasks, meetings, and productivity are managed efficiently, maximizing your potential with
                                superior integrations.
                            </p>
                            
                            <button className="inline-flex items-center gap-2 px-6 py-3 text-white rounded-2xl text-base bg-gradient-to-r from-[#24B574] to-[#2AABD8]" onClick={()=>{navigate("/consult-us")}}>
                                Learn More
                                <ArrowForward className="w-5 h-5" />
                            </button>


                        </div>
                    </div>


                </motion.div>
            </div>
        </section>

    )
}

export default CustomCTM
