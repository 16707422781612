

import { useState } from "react"
import { RadioButtonUnchecked, RadioButtonChecked } from "@mui/icons-material"
import mobile_in from "../../assets/images/MobileDevPage/mobile_in.png"
import "./MobileDevLast.css"
import mobileframe from "../../assets/images/MobileDevPage/mobile_frame.png"
import inside_frame from "../../assets/images/test4.webp"
import slide1 from "../../assets/images/MobileDevPage/slide3.webp"
import slide2 from "../../assets/images/MobileDevPage/slide2.webp"
import slide3 from "../../assets/images/MobileDevPage/slide5.webp"
import slide4 from "../../assets/images/MobileDevPage/slide4.webp"
import slide5 from "../../assets/images/MobileDevPage/slide1.webp"
import slide6 from "../../assets/images/MobileDevPage/slide6.webp"
import border_img from "../../assets/images/MobileDevPage/Border.png"
import { motion, AnimatePresence } from "framer-motion";
import img1 from "../../assets/images/MobileDevPage/LastSec/img1.svg"
import img2 from "../../assets/images/MobileDevPage/LastSec/img2.svg"
import img3 from "../../assets/images/MobileDevPage/LastSec/img3.svg"
import img4 from "../../assets/images/MobileDevPage/LastSec/img4.svg"
import img5 from "../../assets/images/MobileDevPage/LastSec/img5.svg"
import img6 from "../../assets/images/MobileDevPage/LastSec/img6.svg"



export default function MobileDevLast() {
    const [selectedFeature, setSelectedFeature] = useState("expert")



    const features = {
        left: [
            {
                id: "expert",
                title: "Expert Team of App Developers",
                description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore, omnis?",
            },
            {
                id: "quality",
                title: "Quality Assurance",
                description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore, omnis?",
            },
            {
                id: "nda",
                title: "Strict NDA Sign",
                description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore, omnis?",
            },
        ],
        right: [
            {
                id: "security",
                title: "Security and Transparency",
                description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore, omnis?",
            },
            {
                id: "turnaround",
                title: "Quick Turnaround Time",
                description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore, omnis?",
            },
            {
                id: "support",
                title: "Continuous Support",
                description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore, omnis?",
            },
        ],
    }

    const detailedFeatures = [
        {
            icon: img1,
            title: "Expert Team of App Developers",
            description:
                "Our team of expert app developers brings years of experience and a deep understanding of the latest trends and technologies. We are dedicated to delivering high-quality, innovative solutions tailored to meet your specific needs and goals.",
        },
        {
            icon: img2,
            title: "Security and Transparency",
            description:
                "We prioritize the security of your data and ensure complete transparency throughout the development process. Our strict protocols and clear communication give you peace of mind, knowing that your project is in safe hands.",
        },
        {
            icon: img3,
            title: "Quality Assurance",
            description:
                "We follow rigorous quality assurance processes to ensure that every aspect of your application works flawlessly. Our focus on testing and optimization guarantees a smooth user experience and top-notch performance.",
        },
        {
            icon: img4,
            title: "Strict NDA Sign",
            description:
                "We take confidentiality seriously. By signing a strict Non-Disclosure Agreement (NDA), we protect your intellectual property and ensure that your ideas and project details remain secure and private.",
        },
        {
            icon: img5,
            title: "Continuous Support",
            description:
                "Our commitment to your project doesn’t end at launch. We offer continuous support to address any issues, provide updates, and make improvements, ensuring your app stays relevant and functional.",
        },
        {
            icon: img6,
            title: "Quick Turnaround Time",
            description:
                "We understand the importance of meeting deadlines. Our team works efficiently to provide a quick turnaround time, delivering high-quality solutions within your expected timeline.",
        },
    ];


    // Mock screen content for each feature
    const screenContent = {
        expert: slide1,
        quality: slide2,
        nda: slide3,
        security: slide4,
        turnaround: slide5,
        support: slide6,
    }

    const FeatureItem = ({ feature, side }) => (
        <div
            className={`flex gap-4 cursor-pointer group ${side === "right" ? "flex-row-reverse text-right md:text-left md:flex-row" : "flex-row-reverse text-right"}`}
            onClick={() => setSelectedFeature(feature.id)}
        >
            <div className="flex-shrink-0 mt-2">
                {selectedFeature === feature.id ? (
                    <RadioButtonChecked className="text-emerald-500 w-6 h-6" />
                ) : (
                    <RadioButtonUnchecked className="text-gray-300 w-6 h-6 group-hover:text-emerald-300" />
                )}
            </div>
            <div>
                <h3 className="font-bold mb-2 text-[#0F0F0F] text-lg">{feature.title}</h3>
                <p className="text-[#4B4B57] text-base">{feature.description}</p>
            </div>
        </div>


    )

    return (
        <section className="pt-16 pb-10 sm:pt-16 sm:pb-16 px-4 mobiledevlast relative">


            <div className="max-w-7xl mx-auto">
                {/* Header */}
                {/* <div className="text-center mb-16">
                    <span className="text-emerald-500 font-medium">LOREM</span>
                    <h1 className="text-3xl md:text-4xl font-bold mt-4 mb-6">
                        Why TechRabbit™ As Your Trusted Mobile
                        <br />
                        Application Development Company?
                    </h1>
                    <p className="text-gray-600 max-w-3xl mx-auto">
                        You can rely on our expertise in cutting-edge technology and app development methodologies to revolutionize
                        your mobile applications and gain unmatched advantages for your business.
                    </p>
                </div> */}


                <motion.div className="text-center mb-14 relative" initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true, amount: 0.2 }}
                    transition={{ duration: 0.5 }}
                >

                    {/* <h2 className="text-3xl md:text-5xl font-medium text-[#020710] mb-4  lg-text max-w-4xl mx-auto"></h2> */}

                    <div className="inline-flex items-center gap-4  mb-4 " initial={{ opacity: 0, y: 50 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true, amount: 0.2 }}
                        transition={{ duration: 0.5 }}  >
                        <span className="bg-gradient-to-b from-[#25B57E] to-[#2BAAE2] text-transparent bg-clip-text font-bold text-base">
                            LOREM
                        </span>

                        <div className="w-16 h-[2px] bg-[#1DBB91]"></div>
                    </div>
                    <h2 className="text-3xl md:text-4xl font-bold text-[#0F0F0F] mb-4   max-w-4xl mx-auto lg-text">  Why Tech Rabbit ™ As Your Trusted Mobile Application Development Company?
                    </h2>
                    <p className="text-[#4B4B57] max-w-3xl text-lg mx-auto">
                        You can rely on our expertise in cutting-edge technology and app development methodologies to revolutionize your mobile applications and gain unmatched advantages for your business.

                    </p>
                </motion.div>


                <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-6">


                    {detailedFeatures.map((feature, index) => (
                        <div key={index} className="bg-white rounded-2xl p-6 border border-[#F1F1F1] shadow-[2px_4px_30px_0px_#0000000D] transition-all duration-200 
                    hover:bg-gradient-to-b from-[#1DBB91] to-[#2BAAE2] group cursor-pointer">
                            <div className="flex flex-col gap-4">
                                <div className="flex items-center gap-2">
                                    <img src={feature.icon} alt={feature.title} className="object-contain bg-[#F2F2F2] p-2 rounded-xl w-14" />
                                    <h3 className="font-semibold text-[#071B2F] text-lg md:text-2xl transition-colors duration-200 group-hover:text-white">
                                        {feature.title}
                                    </h3>
                                </div>
                                <p className="text-[#425067] text-sm transition-colors duration-200 group-hover:text-white" style={{ fontSize: "15.51px", lineHeight: "23.26px" }}>
                                    {feature.description}
                                </p>
                            </div>
                        </div>


                    ))}

                </div>

                {/* Main Content */}
                {/* <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 items-center relative">

       

                    <img
                        src={border_img}
                        alt="Main Shadow"
                        className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full max-w-[500px] hidden lg:block"
                    />

                    <motion.div className="space-y-12" initial={{ opacity: 0, y: 50 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true, amount: 0.2 }}
                        transition={{ duration: 0.5 }}
                    >
                        {features.left.map((feature) => (
                            <FeatureItem key={feature.id} feature={feature} side="left" />
                        ))}
                    </motion.div>

                    <div className="relative mx-auto w-[300px] h-[620px]">

                        <img
                            src={screenContent[selectedFeature] || "/placeholder.svg"}
                            alt="App Interface"
                            className="absolute inset-0 w-[100%] h-full object-cover left-1/2 transform -translate-x-1/2 rounded-[50px]"
                        />



                        <img
                            src={mobileframe}
                            alt="Mobile Frame"
                            className="absolute inset-0 w-full h-full object-cover pointer-events-none"
                        />
                    </div>





   
                    <motion.div className="space-y-12" initial={{ opacity: 0, y: 50 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true, amount: 0.2 }}
                        transition={{ duration: 0.5 }}
                    >
                        {features.right.map((feature) => (
                            <FeatureItem key={feature.id} feature={feature} side="right" />
                        ))}
                    </motion.div>
                </div> */}
            </div>
        </section>
    )
}




