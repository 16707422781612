import React from 'react'
import "./CareerOpen.css"
import location_img from "../../assets/images/CareerPage/location.svg"
import exp_img from "../../assets/images/CareerPage/exp_img.svg"
import full_time from "../../assets/images/CareerPage/full_time.svg"
import { Link } from 'react-router-dom'
import arrow_img from "../../assets/images/CareerPage/view_arrow.svg"
import { useMediaQuery } from "@mui/material";

const CareerOpen = () => {

    const isDesktop = useMediaQuery('(min-width:1024px)');

    const jobListings = [
        // { title: "Marketing Manager", category: "Marketing", categoryColor: "bg-[#DCFFF5] text-[#1BA97F]" },
        // { title: "Lead UI Designer", category: "Marketing", categoryColor: "bg-[#E6F0FF] text-[#0B5CD5]" },
        // { title: "Social Media Manager", category: "Marketing", categoryColor: "bg-[#FFEAD8] text-[#D56A11]" },
        // { title: "Marketing Executive", category: "Marketing", categoryColor: "bg-[#DCFFF5] text-[#1BA97F]" },
        // { title: "UX Designer", category: "Marketing", categoryColor: "bg-[#E6F0FF] text-[#0B5CD5]" },
        { title: "QA Engineer", category: "Quality Assurance", categoryColor: "bg-[#FFF5D1] text-[#C48C00]" },
        // { title: "QA Engineer", category: "Quality Assurance", categoryColor: "bg-[#FFF5D1] text-[#C48C00]" },
        // { title: "QA Engineer", category: "Quality Assurance", categoryColor: "bg-[#FFF5D1] text-[#C48C00]" },
        // { title: "QA Engineer", category: "Quality Assurance", categoryColor: "bg-[#FFF5D1] text-[#C48C00]" },

        
        
    ];
    return (
        <section className='px-4 py-16 careeropen relative'>


            {/* {isDesktop ? <svg xmlns="http://www.w3.org/2000/svg" width="20%" height="auto" viewBox="0 0 500 351"
                className="absolute  right-[0] top-[0]"
            >
                <path
                    d="M 486.864 13.61 C 390.545 194.927 248.175 159.17 204.087 125.947 C 158.968 91.947 218.97 66.259 287.488 106.239 C 356.006 146.219 355.756 187.164 337.472 207.033 C 288.765 259.964 159.719 192.438 143.713 210.412 C 138.097 216.718 190.328 248.139 220.094 267.002 C 157.192 262.78 57.785 292.624 13.136 337.39"
                    stroke="url(#gradient1)"
                    stroke-width="26.27"
                    stroke-linejoin="round"
                    stroke-linecap="round"
                    fill="transparent"
                    opacity="1"
                    pathLength="1"
                    stroke-dashoffset="1"
                    stroke-dasharray="1px 1px">




                    <animate
                        attributeName="stroke-dashoffset"
                        from="1"
                        to="0"
                        dur="3s"
                        fill="freeze" />
                </path>
                <defs>

                    <linearGradient id="gradient1" x1="100%" y1="0%" x2="100%" y2="100%">
                        <stop offset="0%" stop-color="#2BAAE2" />
                        <stop offset="100%" stop-color="#24B574" />
                    </linearGradient>
                </defs>
            </svg> : null} */}

            <div className='max-w-7xl mx-auto'>


                <div className="text-center mb-8 sm:mb-16">

                    <h2 className="text-3xl md:text-5xl font-semibold mt-2 mb-4 lg-text text-[#111111]">Open positions</h2>

                </div>

                <div className="space-y-6">
                    {jobListings.map((job, index) => (
                        <div key={index} className="flex flex-col md:flex-row justify-between items-start md:items-center py-4 border-b border-gray-200">
                            {/* Job Info */}
                            <div>
                                <h3 className="text-lg md:text-xl font-semibold text-[#111111] flex items-center gap-2">
                                    {job.title}
                                    <span className={`px-2 py-1 rounded-full text-xs font-semibold ${job.categoryColor}`}>
                                        {job.category}
                                    </span>
                                </h3>
                                <p className="text-[#3E3E59] text-base md:text-lg mt-2">Collaborate with our development team to ensure top-notch software quality</p>

                                <div className='flex items-center gap-4 '>


                                    <div className="text-[#5F6980] text-xs sm:text-sm flex items-center gap-2 mt-3 font-medium">
                                        <img src={location_img} alt="Location" className="w-3 h-3 sm:w-4 sm:h-4" />
                                        Ahmedabad
                                    </div>

                                    <div className="text-[#5F6980] text-xs sm:text-sm flex items-center gap-2 mt-3 font-medium">
                                        <img src={exp_img} alt="Location" className="w-4 h-4 sm:w-5 sm:h-5" />
                                        2-4 years
                                    </div>

                                    <div className="text-[#5F6980] text-xs sm:text-sm flex items-center gap-2 mt-3 font-medium">
                                        <img src={full_time} alt="Location" className="w-4 h-4 sm:w-5 sm:h-5" />
                                        Full-Time
                                    </div>

                                </div>



                            </div>

                            {/* View Job Link */}
                            <Link to="/job" className="text-[#111111] flex items-center gap-2 mt-3 md:mt-0 text-sm md:text-base font-semibold">
                                View Job
                                <img src={arrow_img} alt="Arrow" className="w-4 h-4" />
                            </Link>
                        </div>
                    ))}
                </div>



            </div>
        </section>
    )
}

export default CareerOpen
