import React, { useState } from "react";
import video_img from "../../assets/images/video_img.png"
import play_btn from "../../assets/images/play-btn.png"
import video_play from "../../assets/images/video_play.png"
import { useRef, useEffect } from "react";
import zIndex from "@mui/material/styles/zIndex";

const VideoCard = ({ poster, videoId }) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [scale, setScale] = useState(1); // State to track the zoom level
    const cardRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            if (!cardRef.current) return;

            const card = cardRef.current;
            const cardRect = card.getBoundingClientRect(); // Get card position relative to viewport
            const windowHeight = window.innerHeight;

            // Calculate percentage of the card visible in the viewport
            const visiblePercentage = Math.max(
                0,
                Math.min(1, (windowHeight - cardRect.top) / windowHeight)
            );

            // Scale the card based on its visibility percentage
            const newScale = 1 + visiblePercentage * 0.2; // Adjust zoom level
            setScale(newScale);
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <div className="max-w-[78%] sm:max-w-[60%] mx-auto rounded-2xl overflow-hidden "  ref={cardRef}
        style={{
            transform: `scale(${scale})`,
            position:"relative",
            zIndex:"999"
        }}>
      
            <div className="relative">
             
                {!isPlaying ? (
                    <div className="relative">
                        <img
                            src={poster}
                            alt="Video Poster"
                            className="w-full  object-cover"
                            loading="lazy" 
                        />
                        {/* Play Button */}
                        <div className="absolute inset-0 flex items-center justify-center ">
                        

                            {/* <img src={video_play} onClick={() => setIsPlaying(true)}   className="cursor-pointer w-6 h-6 sm:w-12 sm:h-12 "/>
                             */}
                            <img src={video_play}   className="cursor-pointer w-6 h-6 sm:w-12 sm:h-12 "/>

                        </div>
                    </div>
                ) : (
    
                    <iframe
                        className="w-full h-80"
                        src={`https://www.youtube.com/embed/${videoId}?autoplay=1`}
                        title="YouTube Video"
                        allow="autoplay; encrypted-media"
                        allowFullScreen
                    ></iframe>
                )}
            </div>
        </div>
    );
};

export default VideoCard;
