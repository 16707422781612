import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import cookies_img from "../assets/images/cookies.svg"
import { motion } from "framer-motion";
import { openCookies, closeCookies } from "../redux/authModalSlice";
import { useSelector } from "react-redux";
const CookieConsent = () => {
    const { showCookies } = useSelector((state) => state.authModal);
    const [showPopup, setShowPopup] = useState(false);
    const dispatch = useDispatch()

    useEffect(() => {
        const cookieConsent = localStorage.getItem("cookieConsent");
        if (!cookieConsent) {
            setTimeout(() => {
               dispatch(openCookies());
            }, 5000);
        }
    }, []);


    const handleAllowCookies = () => {
        localStorage.setItem("cookieConsent", "allowed");
        dispatch(closeCookies())
    };

    const handleRejectCookies = () => {
        localStorage.setItem("cookieConsent", "rejected");
        // setShowPopup(false);
        dispatch(closeCookies())

    };

    return showCookies ? (
        // <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 p-4">
        <div className="fixed bottom-4 right-2 sm:right-4 bg-white p-0 sm:p-2 rounded-lg sm:rounded-2xl shadow-lg z-50 border w-80 sm:w-auto ">
            <motion.div className="bg-white px-2 sm:px-1 py-3 rounded-3xl text-center  max-w-md w-full" initial={{ opacity: 0 }} // Start transparent
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }} >
                <img src={cookies_img} alt="" className="mx-auto w-10 sm:w-12  " />

                <h2 className="text-sm sm:text-lg font-semibold mt-4">We Value Your Privacy</h2>
                <p className="text-[#555555] mt-1 sm:mt-4 text-xs sm:text-sm">
                    We use cookies to enhance your experience, analyze site traffic, and serve personalized content.
                    By clicking 'Allow Cookies,' you consent to our use of cookies. You can manage your preferences
                    or reject non-essential cookies anytime.
                </p>

                <div className="mt-4 sm:mt-4 flex justify-center space-x-4">
                    <button
                        onClick={handleAllowCookies}
                        className=" text-white px-2 py-2 sm:px-2  sm:py-2 rounded-lg text-xs sm:text-sm font-medium  transition consultation-btn"
                    >
                        Allow Cookies
                    </button>
                    <button
                        onClick={handleRejectCookies}
                        className="text-[#3A3A3A] px-2 py-2 sm:px-2  sm:py-2 rounded-lg text-xs sm:text-sm font-medium transition border border-[#E1E4EB] hover:bg-[#F5F5F5]"
                    >
                        Reject Cookies
                    </button>
                </div>
            </motion.div>
        </div>
    ) : null;
};

export default CookieConsent;
