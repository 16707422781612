import "./PrivacyComp.css"

export default function PrivacyComp() {
    return (
        <section className="py-16 px-4 privacy">

            <div className="max-w-6xl mx-auto ">

                <h2 className="text-3xl md:text-4xl font-semibold text-center mb-12 text-[#201E1C] lg-text">Privacy & Policy</h2>


                <div className="space-y-8">

                    <section>
                        <h2 className="text-lg md:text-xl font-semibold mb-4 text-[#1B1B1B]">Acceptance of Terms.</h2>
                        <p className="text-[#5A5857] leading-relaxed text-base md:text-lg ">
                            Welcome to Tech Rabbit, the task management app developed by Tech Rabbit. This Privacy Policy is designed to help you
                            understand how we collect, use, disclose, and safeguard your personal information when you use our Tech Rabbit
                            application and related services. By accessing or using Tech Rabbit, you agree to the terms outlined in this
                            Privacy Policy.
                        </p>
                    </section>


                    <section>
                        <h2 className="text-lg md:text-xl font-semibold mb-4 text-[#1B1B1B]">Information We Collect.ption of Service.</h2>
                        <ol className="list-decimal list-outside ml-4 space-y-3 text-[#5A5857] text-base md:text-lg ">
                            <li>
                                To use Tech Rabbit, you will need to create an account. We collect and store your email address, username, and
                                password securely.
                            </li>
                            <li>
                                Tech Rabbit collects information related to the tasks and projects you create, including task names,
                                descriptions, due dates, and associated files.
                            </li>
                            <li>
                                We collect information about your interactions with Tech Rabbit, such as the features you use, the frequency of
                                use, and any errors encountered.
                            </li>
                            <li>
                                We may collect information about the device you use to access Tech Rabbit, including device type, operating
                                system, and device identifiers.
                            </li>
                        </ol>
                    </section>

                    <section>
                        <h2 className="text-lg md:text-xl font-semibold mb-4 text-[#1B1B1B]">How We Use Your Information.</h2>
                        <ol className="list-decimal list-outside ml-4 space-y-3 text-[#5A5857] text-base md:text-lg ">
                            <li>
                                Your task and project information is used to provide the core functionality of Tech Rabbit, helping you organize
                                and manage your tasks effectively.
                            </li>
                            <li>
                                We use usage data to analyze app performance, identify areas for improvement, and enhance user experience.
                            </li>
                            <li>
                                Your account information may be used to provide customer support and address any inquiries or issues you
                                may have.
                            </li>
                        </ol>
                    </section>

                    <section>
                        <h2 className="text-lg md:text-xl font-semibold mb-4 text-[#1B1B1B]">Information Sharing and Disclosure.</h2>
                        <ol className="list-decimal list-outside ml-4 space-y-3 text-[#5A5857] text-base md:text-lg ">
                            <li>
                                We may engage third-party service providers to assist with app development, analytics, and customer
                                support. These providers are bound by confidentiality and data protection agreements.
                            </li>
                            <li>We may disclose your information if required by law or in response to a valid legal request.</li>
                        </ol>
                    </section>

                    <section>
                        <h2 className="text-xl font-semibold mb-4">Security.</h2>
                        <p className="text-[#5A5857] text-base md:text-lg  leading-relaxed">
                            You can manage your account settings and preferences within Tech Rabbit. You may choose to delete your account,
                            which will permanently remove your data from our servers.
                        </p>
                    </section>

                    <section>
                        <h2 className="text-lg md:text-xl font-semibold mb-4 text-[#1B1B1B]">Your Choices.</h2>
                        <p className="text-[#5A5857] text-base md:text-lg  leading-relaxed">
                            The App and its original content, features, and functionality are owned by Tech Rabbit and are protected by
                            international copyright, trademark, patent, trade secret, and other intellectual property or proprietary
                            rights laws.
                        </p>
                        <p className="text-[#5A5857] text-base md:text-lg  leading-relaxed mt-3">
                            You may not reproduce, modify, distribute, or otherwise use any part of the App without the express written
                            consent of Tech Rabbit.
                        </p>
                    </section>


                    <section>
                        <h2 className="text-lg md:text-xl font-semibold mb-4 text-[#1B1B1B]">Updates to this Privacy Policy.</h2>
                        <p className="text-[#5A5857] text-base md:text-lg leading-relaxed">
                            We may update this Privacy Policy from time to time. Any changes will be communicated through the app or via
                            email. Continued use of Tech Rabbit after such changes constitutes your acceptance of the revised Privacy Policy.
                        </p>
                    </section>


                    <section>
                        <p className="text-[#5A5857] text-base md:text-lg  leading-relaxed">
                            By using the Tech Rabbit Task Management App, you acknowledge that you have read, understood, and agree to be
                            bound by these Terms and Conditions.
                        </p>
                    </section>
                </div>
            </div>
        </section>
    )
}

