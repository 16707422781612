import { useState , useEffect} from "react"
import { useLocation } from "react-router-dom"
import {
    Person,
    Schedule,
    Code,
    CheckCircle,
    ArrowForward,
    SmartToy,
    PhoneAndroid,
    Web,
    Palette,
    Storage,
    BugReport,
} from "@mui/icons-material"
import step1 from "../../assets/images/ConsultForm/select_services.png"
import step2 from "../../assets/images/ConsultForm/personal_details.png"
import step3 from "../../assets/images/ConsultForm/select_timeline.png"
import service1 from "../../assets/images/ConsultForm/service1.png"
import service2 from "../../assets/images/ConsultForm/service2.png"
import service3 from "../../assets/images/ConsultForm/service3.png"
import service4 from "../../assets/images/ConsultForm/service4.png"
import service5 from "../../assets/images/ConsultForm/service5.png"
import service6 from "../../assets/images/ConsultForm/service6.png"
import service7 from "../../assets/images/ConsultForm/service7.png"
import leftbottom from "../../assets/images/ConsultForm/logoshadow.png"
import { img } from "framer-motion/client"
import arrow from "../../assets/images/ConsultForm/right_arrow.png"
import selected from "../../assets/images/ConsultForm/selected.png"
import drop_icon from "../../assets/images/ConsultForm/dropdown_icon.png"
// import countryData from "world-countries";
import google_search from "../../assets/images/ConsultForm/google_search.svg"
import social_media from "../../assets/images/ConsultForm/social_media.svg"
import referral from "../../assets/images/ConsultForm/referal.svg"
import CountryFlag from "react-country-flag";
import { useNavigate } from "react-router-dom"
import "./Consult.css"
import countries from "i18n-iso-countries";
import enLocale from "i18n-iso-countries/langs/en.json";
import { getCountryCallingCode } from "libphonenumber-js"; 
import step2_active from "../../assets/images/ConsultForm/step2_active.png"
// countries.registerLocale(enLocale);
// const countryList = countries.getNames("en");

// const countryArray = Object.entries(countryList).map(([code, name]) => ({
//     code,
//     name,
// }));


countries.registerLocale(enLocale);
const countryList = countries.getNames("en");

// Filter out unsupported countries
const countryArray = Object.entries(countryList)
    .map(([code, name]) => {
        try {
            return {
                code,
                name,
                dialCode: `+${getCountryCallingCode(code)}`, // Get dial code
            };
        } catch (error) {
            return null; // Skip unsupported countries
        }
    })
    .filter(Boolean); // Remove null values
export default function PersonalDetails() {
    const navigate = useNavigate()
    const [currentStep, setCurrentStep] = useState(2)
    const [selectedServices, setSelectedServices] = useState(null)
    const [projectDescription, setProjectDescription] = useState("")
    const [isReferenceUrlEnabled, setIsReferenceUrlEnabled] = useState(false);
    const [referenceUrl, setReferenceUrl] = useState("");

    const steps = [
        {
            number: 1,
            title: "Select Services",
            icon: <Code className="w-6 h-6" />,
            activeImg: step1
            , inactiveImg: step1
        },
        {
            number: 2,
            title: "Personal Details",
            icon: <Person className="w-6 h-6" />,
            activeImg: step2_active
            , inactiveImg: step2
        },
        {
            number: 3,
            title: "Select Timeline",
            icon: <Schedule className="w-6 h-6" />,
            activeImg: step3
            , inactiveImg: step3
        },
    ]

    const services = [
        {
            id: "Google",
            title: "Google ",
            icon: <Code className="w-6 h-6" />,
            img: google_search
        },
        {
            id: "Social Media",
            title: "Social Media",
            icon: <SmartToy className="w-6 h-6" />,
            img: social_media

        },
        {
            id: "Referral",
            title: "Referral",
            icon: <PhoneAndroid className="w-6 h-6" />,
            img: referral

        },
        {
            id: "Other",
            title: "Other",
            icon: <Web className="w-6 h-6" />,
            // img: service4

        },

    ]

    const handleServiceToggle = (id) => {
        setSelectedServices(id); // Set the clicked service as the only selected one
    };

    const [fullNameError, setFullNameError] = useState("");
    const [emailError, setEmailError] = useState("");
    const [phoneError, setPhoneError] = useState("");
    // const handleContinue = () => {
    //     let isValid = true;

    //     // Check if Full Name is empty
    //     if (!fullName.trim()) {
    //         setFullNameError("Full Name is required.");
    //         isValid = false;
    //     } else {
    //         setFullNameError("");
    //     }

    //     // Check if Email is empty or invalid
    //     const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    //     if (!email.trim()) {
    //         setEmailError("Email is required.");
    //         isValid = false;
    //     } else if (!emailPattern.test(email)) {
    //         setEmailError("Please enter a valid email address.");
    //         isValid = false;
    //     } else {
    //         setEmailError("");
    //     }

    //     // Check if Phone Number is empty or not exactly 10 digits
    //     if (!phoneNumber.trim()) {
    //         setPhoneError("Phone number is required.");
    //         isValid = false;
    //     } else if (!/^\d{10}$/.test(phoneNumber)) {
    //         setPhoneError("Phone number must be exactly 10 digits.");
    //         isValid = false;
    //     } else {
    //         setPhoneError("");
    //     }

    //     // Proceed only if all validations pass
    //     if (isValid) {
    //         navigate("/select-timeline");
    //     }
    // };

    const handleContinue = () => {
        let errors = {};
        let isValid = true;
    
        // Validate Full Name
        if (!fullName.trim()) {
            errors.fullName = "Full Name is required.";
            isValid = false;
        }
    
        // Validate Email Format
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!email.trim()) {
            errors.email = "Email is required.";
            isValid = false;
        } else if (!emailPattern.test(email)) {
            errors.email = "Please enter a valid email address.";
            isValid = false;
        }
    
        // Validate Phone Number
        if (!phoneNumber.trim()) {
            errors.phoneNumber = "Phone number is required.";
            isValid = false;
        } else if (!/^\d{10}$/.test(phoneNumber)) {
            errors.phoneNumber = "Phone number must be exactly 10 digits.";
            isValid = false;
        }
    
        // Validate Country Selection
        if (!selectedCountry.code || !selectedCountry.name) {
            errors.selectedCountry = "Please select a country.";
            isValid = false;
        }
    
        // Set Errors
        setFullNameError(errors.fullName || "");
        setEmailError(errors.email || "");
        setPhoneError(errors.phoneNumber || "");
    
        if (!isValid) return;
    
        // Store form data in localStorage

        localStorage.setItem("WhereTheyHear", JSON.stringify(selectedServices));
        localStorage.setItem(
            "step2",
            JSON.stringify({
                fullName,
                email,
                phoneNumber,
                country: {
                    name: selectedCountry.name,
                    code: selectedCountry.dialCode,
                },
            })
        );
    
        // Navigate to the next step
        navigate("/select-timeline");
    };
    


    // const [selectedCountry, setSelectedCountry] = useState({ code: "IN", name: "India" });

    // const handleCountryChange = (e) => {
    //     const selected = countryArray.find((c) => c.code === e.target.value);
    //     if (selected) setSelectedCountry(selected);
    // };
    const [selectedCountry, setSelectedCountry] = useState(countryArray.find(c => c.code === "IN") || countryArray[0]);

    // console.log("Seelcted",selectedCountry)

    const handleCountryChange = (e) => {
        const selected = countryArray.find((c) => c.code === e.target.value);
        if (selected) setSelectedCountry(selected);
    };

    const [fullName, setFullName] = useState("");

    const handleChange = (e) => {
        const value = e.target.value;

        // Allow only letters (A-Z, a-z) and spaces
        if (/^[A-Za-z\s]*$/.test(value)) {
            setFullName(value);
        }
    };

    const [phoneNumber, setPhoneNumber] = useState("");

    const handlePhoneChange = (e) => {
        const value = e.target.value;

        // Allow only digits and limit to 10 characters
        if (/^\d{0,10}$/.test(value)) {
            setPhoneNumber(value);
        }
    };



    const [email, setEmail] = useState("");
    const [error, setError] = useState("");

    const handleEmailChange = (e) => {
        const value = e.target.value;
        setEmail(value);

        // Email validation regex
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (value && !emailPattern.test(value)) {
            setError("Please enter a valid email address.");
        } else {
            setError("");
        }
    };

   
    useEffect(() => {
        const savedData = JSON.parse(localStorage.getItem("step2"));
        if (savedData) {
            setFullName(savedData.fullName || "");
            setEmail(savedData.email || "");
            setPhoneNumber(savedData.phoneNumber || "");
            setSelectedCountry({
                name: savedData.country?.name || "",
                code: savedData.country?.code || "",
            });
        }
    }, []);

    

    return (

        

        <section className="py-12 px-4 relative consult">


            <img
                src={leftbottom}
                alt="Overlay"
                className="absolute left-0 bottom-0 w-36 sm:w-44 md:w-60 lg:w-96 "
            />
            <div className="max-w-4xl mx-auto ">

                {/* Progress Steps */}
                <div className="flex items-center justify-center mb-12">
                    {steps.map((step, index) => (
                        <div key={step.number} className="flex items-center">


                            <div className="flex flex-col items-center">

                                <div
                                    className={`flex items-center justify-center rounded-full border-2  p-1 md:p-2
          ${currentStep >= step.number ? "border-grad text-emerald-500" : "border-[#A1AEBE] text-gray-400"}`}


                                >

                                    <img
                                        src={currentStep >= step.number ? step.activeImg : step.inactiveImg}
                                        alt=""
                                        className="w-6 md:w-8"
                                    />
                                </div>

                                <p
                                    className={`text-sm sm:text-lg md:text-xl font-semibold mt-2  text-center
    ${currentStep >= step.number
                                            ? "bg-gradient-to-b from-[#24B574] to-[#2BAAE2] bg-clip-text text-transparent"
                                            : "text-gray-400"
                                        }`}
                                >
                                    {step.title}
                                </p>

                            </div>


                            {index < steps.length - 1 && (
                                <div
                                    className={`w-16 sm:w-24 h-0.5 mx-1 sm:mx-4 
          ${currentStep > step.number ? "bg-emerald-500" : "bg-[#A1AEBE]"}`}
                                />
                            )}
                        </div>
                    ))}

                </div>

   
                <div className="bg-white rounded-2xl p-8 sm:p-14 border-2 border-[#EBEDEF] relative">
                    {currentStep === 2 && (
                        <div className="space-y-9">



                            <div>
                                <label className="block text-base md:text-lg font-medium text-[#636363] mb-3">Full Name <span className="">*</span></label>
                                <input
                                    type="text"
                                    name="fullName"
                                    value={fullName}
                                    onChange={handleChange}
                                    placeholder="Enter your full name"
                                    className="w-full p-4 rounded-xl border border-[#8888884F] focus:outline-none focus:ring-2 focus:ring-emerald-500"
                                />
                                {fullNameError && <p className="text-red-500 text-sm mt-2">{fullNameError}</p>}
                            </div>

                    
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                              
                                <div className="relative">
                                    <label className="block text-base md:text-lg font-medium text-[#636363] mb-3">Country<span className="">*</span></label>
                                    <div className="relative">
                                      
                                        <select
                                            className="w-full p-4 rounded-xl border border-[#8888884F] appearance-none focus:outline-none focus:ring-2 focus:ring-emerald-500"
                                            onChange={handleCountryChange}
                                            value={selectedCountry.code}
                                        >
                                            {countryArray.map((country) => (
                                                <option key={country.code} value={country.code}>
                                                    {country.name}
                                                </option>
                                            ))}
                                        </select>

                                        <img
                                            src={drop_icon}
                                            alt="Dropdown Icon"
                                            className="absolute right-4 top-1/2 transform -translate-y-1/2  pointer-events-none"
                                        />
                                    </div>
                                </div>


      
                                <div>
                                    <label htmlFor="phone-number" className="block text-base md:text-lg font-medium text-[#636363] mb-3">
                                        Phone Number<span className="">*</span>
                                    </label>
                                    <div className="flex items-center border border-[#8888884F] rounded-xl px-4">


                                        <CountryFlag
                                            countryCode={selectedCountry.code}
                                            svg
                                            style={{ width: "1.7em", height: "1.7em", marginRight: "8px", borderRadius: "7px" }}
                                        />
                                        <span className="text-[#636363] font-medium pr-4">{selectedCountry.dialCode}</span>
                                        <div className="h-6 w-[1px] bg-[#8888884F]"></div>
                                        <input
                                            type="tel"
                                            id="phone-number"
                                            value={phoneNumber}
                                            onChange={handlePhoneChange}
                                            placeholder="Enter phone number"
                                            className="w-full p-4 focus:outline-none pl-4"
                                        />
                                    </div>

                                    {phoneError && <p className="text-red-500 text-sm mt-2">{phoneError}</p>}
                                </div>





                            </div>


                            <div>
                                <label className="block text-base md:text-lg font-medium text-[#636363] mb-3">Email ID<span className="">*</span></label>
                                <input
                                     name='email'
                                    type="email"
                                    value={email}
                                    //   autoComplete="email"
                                    onChange={handleEmailChange}
                                    placeholder="Enter your email address"
                                    className="w-full p-4 rounded-xl border border-[#8888884F] focus:outline-none focus:ring-2 focus:ring-emerald-500"
                                />
                           
                                {emailError && <p className="text-red-500 text-sm mt-2">{emailError}</p>}
                            </div>


                            <div>
                                <label className="block text-base md:text-lg font-medium text-[#636363] mb-3">Message (Optional)</label>
                                <textarea
                                    placeholder="Write your message here"
                                    className="w-full p-4 rounded-xl border border-[#8888884F]  focus:outline-none focus:ring-2 focus:ring-emerald-500"
                                    rows="4"
                                ></textarea>
                            </div>



                            <div className="flex items-center gap-2">
                                <input
                                    type="checkbox"
                                    id="referenceUrlCheckbox"
                                    checked={isReferenceUrlEnabled}
                                    onChange={() => setIsReferenceUrlEnabled(!isReferenceUrlEnabled)}

                                    className="w-5 h-5 text-emerald-500 border-[#8888884F] rounded focus:ring-emerald-500 border"
                                />
                                <label htmlFor="referenceUrlCheckbox" className="text-sm md:text-base text-[#434343] cursor-pointer font-medium">
                                    How did you hear about us? (Optional)
                                </label>
                            </div>

                            {isReferenceUrlEnabled &&

                                <div className="flex flex-wrap justify-start gap-4 sm:gap-6">
                                    {services.map((service) => (
                                        <button
                                            key={service.id}
                                            onClick={() => handleServiceToggle(service.id)}
                                            className={`relative flex items-center gap-2 px-3 py-2 sm:px-4 sm:py-2 rounded-full border transition-colors border-[#E9EBED]`}
                                        >

                                            {service?.img &&
                                                <div className="text-emerald-500">
                                                    <img src={service?.img} alt="" className="w-4 h-4 sm:w-6 sm:h-6 " />
                                                </div>}
                                            <span className="text-xs sm:text-base md:text-lg font-medium text-[#757D84]">{service.title}</span>

                                            <div className="border border-[#E9EBED] rounded-full " style={{ padding: "1px" }}>

                                                <div
                                                    className={`w-4 h-4 sm:w-5 sm:h-5 md:w-7 md:h-7  rounded-full   ml-auto flex items-center justify-center transition-all
    ${selectedServices === service?.id
                                                            ? "border-green-500 bg-gradient-to-b from-[#24B574] to-[#2BAAE2]"
                                                            : "bg-transparent"}`}
                                                >
                                                    {selectedServices === service?.id && (

                                                        <img src={selected} alt="Selected" className="w-2 h-2 md:w-3 md:h-3" />
                                                    )}
                                                </div>
                                            </div>

                                        </button>
                                    ))}
                                </div>}
                       

                            <div className="flex justify-end">
                                <button
                                    onClick={handleContinue}
                                
                                    className="flex items-center gap-2 text-sm px-3 py-2 sm:text-base sm:px-5 sm:py-2 md:px-6 md:py-3 md:text-lg
             bg-gradient-to-r from-[#25B57E] to-[#2BAAE2] 
             text-white rounded-xl transition-all 
             hover:opacity-90 disabled:opacity-50 disabled:cursor-not-allowed"
                                >
                                    Continue
                             
                                    <img src={arrow} alt="" className="w-4 sm:w-5 md:w-6" />
                                </button>

                            </div>
                        </div>
                    )}
                </div>
            </div>

        </section>
    )
}

