// import TensorFlow from "../assets/images/Services/AIDev/tensorflow.png"
// import Keras from "../assets/images/Services/AIDev/keras.png"
// import SciKIT from "../assets/images/Services/AIDev/scikit.png"
// import Pytoarch from "../assets/images/Services/AIDev/pytoarch.png"
// import Spacy from "../assets/images/Services/AIDev/spancy.png"
// import OpenCv from "../assets/images/Services/AIDev/opencv.png"
// import google_cloud_ai from "../assets/images/Services/AIDev/google_clod.png"
// import Azure from "../assets/images/Services/AIDev/azure.png"
// import yolo from "../assets/images/Services/AIDev/yolo.png"


import tensorflow from "../assets/images/TechStack/icons8-tensorflow 1.png"
import keras from "../assets/images/TechStack/keras.png"
import scikit_learn from "../assets/images/TechStack/scikit-learn 1.png"
import pytoarch from "../assets/images/TechStack/icons8-pytorch 1.png"
import spacy from "../assets/images/TechStack/spaCy-01 1.png"
import opencv from "../assets/images/TechStack/opencv.png"
import yolo from "../assets/images/TechStack/icons8-yolo 1.png"
import azure from "../assets/images/TechStack/azure-icon 1.png"
import google_cloud from "../assets/images/TechStack/google-cloud-3 1.png"
import scikit_hover from "../assets/images/TechStack/scikit-hover.png"
import azure_hover from "../assets/images/TechStack/azure-hover.png"











export const techstackai = [
    // Row 1
    {
        name: "TensorFlow",
        logo: tensorflow,
    },

    {
        name: "Keras",
        logo: keras,
    },

    {
        name: "Scikit-Learn",
        logo: scikit_learn,
        hover:scikit_hover,
    },


    {
        name: "PyTorch",
        logo: pytoarch,
    },


    {
        name: "SpaCy",
        logo: spacy,
    },


    {
        name: "OpenCV",
        logo: opencv,
    },


    {
        name: "YOLO",
        logo: yolo,
    },


    {
        name: "Google Cloud AI",
        logo: google_cloud,
    },

    {
        name: "Azure",
        logo: azure,
        hover:azure_hover
    },

]