import React from 'react';
import "./BreadCrumbBlog.css";
import overlay_blog from "../../assets/images/blog-overlay.svg"

const BreadCrumbBlog = () => {
    return (

        <>
            {/* <section className="py-14 px-4 text-white text-center breadcrumb ">
            <div className="container mx-auto gradient-header rounded-2xl py-20 min-h-[550px] relative " style={{ borderRadius: "48px" }}>
                <h2 className="text-3xl sm:text-4xl md:text-5xl  font-medium text-white mb-6 poppins lg-text">
                    Our Blog
                </h2>

                <h2 className="text-2xl sm:text-4xl  font-medium text-white mb-6 poppins"> News, insights and more</h2>
                <p className="text-white max-w-3xl text-base md:text-lg md:text-xl mx-auto manrope">
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit. A, ab nemo! Expedita! Lorem ipsum dolor sit amet
                </p>
            </div>

 
            <img src={overlay_blog} alt="Blog Overlay" className="absolute inset-0 w-full h-full object-cover opacity-100 overlay-animation-breadcrumb" />
        </section> */}


            <section className="py-14 px-4 text-white text-center breadcrumb relative overflow-hidden   -z-10 pointer-events-none" >
                <div className="container mx-auto gradient-header rounded-2xl py-20 min-h-[500px] sm:min-h-[550px] px-4" style={{ borderRadius: "48px" }}>
                    <h2 className="text-3xl sm:text-4xl md:text-5xl font-medium text-white mb-6 poppins lg-text">
                        Our Blog
                    </h2>

                    <h2 className="text-2xl sm:text-4xl font-medium text-white mb-6 poppins">News, insights and more</h2>
                    <p className="text-white max-w-3xl text-base  md:text-xl mx-auto manrope">
                    Stay updated with the latest news, insights, and trends in the industry. Explore our articles to learn more!
                    </p>
                </div>

                <img src={overlay_blog} alt="Blog Overlay" className="absolute inset-0 w-full h-full object-cover opacity-100 overlay-animation-breadcrumb" />
            </section>

        </>

    );
};

export default BreadCrumbBlog;
