

import { motion } from "framer-motion";
import { useState, useEffect } from "react"
import { Link } from "react-router-dom"

import android from "../../assets/images/MobileDevPage/Feature/android.svg"
import cross from "../../assets/images/MobileDevPage/Feature/cross.svg"
import ios from "../../assets/images/MobileDevPage/Feature/ios.svg"
import wearable from "../../assets/images/MobileDevPage/Feature/wearable.svg"
import web_app from "../../assets/images/MobileDevPage/Feature/web_app.svg"
import land1 from "../../assets/images/MobileDevPage/Feature/land1.png"





// import land1 from "../../assets/images/landing_1.webp"
import land2 from "../../assets/images/MobileDevPage/Feature/land2.png"
import land3 from "../../assets/images/MobileDevPage/Feature/land3.png"
import land4 from "../../assets/images/MobileDevPage/Feature/land4.png"
import land5 from "../../assets/images/MobileDevPage/Feature/land5.png"


import left_shadow from "../../assets/images/MobileDevPage/Feature/left_shadow.svg"

export default function MobileDevFeatures() {


    const features = [
        {
            image: android,
            title: "Android App Development",
            description: " Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ullam magni inventore quod quibusdam!"
            ,
            // main_img: svg7
            main_img: land1

        },
        {
            image: ios,
            title: "iOS App Development",
            description: " Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ullam magni inventore quod quibusdam!"
            ,
            main_img: land2
        },
        {
            image: cross,
            title: "Cross-Platform App Development",
            description: " Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ullam magni inventore quod quibusdam!"
            ,
            main_img: land3
        },
        {
            image: wearable,
            title: "Wearable App Development",
            description: " Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ullam magni inventore quod quibusdam!"
            ,
            main_img: land4
        },
        {
            image: web_app,
            title: "Web Application Development",
            description: " Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ullam magni inventore quod quibusdam!"
            ,
            main_img: land5
        },

        // {
        //     image: deployment,
        //     title: "Deployment",
        //     description: "Seamlessly launching your product for a smooth and impactful go-live.",
        //     main_img: land6
        // },

        // {
        //     image: support,
        //     title: "Support & Maintenance",
        //     description: "Continuous enhancements and round-the-clock support to keep you ahead.",
        //     main_img: land7
        // },
    ]

    const [selectedFeature, setSelectedFeature] = useState(features[0]);
    let index = 0;
    // const handleFeatureClick = (feature) => {
    //     setSelectedFeature(feature);
    // };
    useEffect(() => {
        const interval = setInterval(() => {
            index = (index + 1) % features.length;
            setSelectedFeature(features[index]);
        }, 3000); // Change every 3 seconds

        return () => clearInterval(interval); // Cleanup on unmount
    }, []);
    return (
        <section className="py-16 px-4 relative">
            <img
                src={left_shadow}
                alt="Left Shadow"
                className="absolute -top-14 left-0  w-auto  z-0"
            />


            <motion.div className="max-w-7xl mx-auto   mobile-tech" initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true, amount: 0.2 }}
                transition={{ duration: 0.5 }} >
                {/* 
                <h2 className=" pb-8 text-left text-3xl   md:text-4xl lg:text-5xl md-padding lg-text-workflow inter" style={{ color: "#111111", fontWeight: "600" }}>
                    From Idea to Impact – A Seamless Journey of Innovation.

                </h2> */}

                <motion.div className="text-center mb-14 max-w-7xl mx-auto relative" initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true, amount: 0.2 }}
                    transition={{ duration: 0.5 }}
                >

                    {/* <h2 className="text-3xl md:text-5xl font-medium text-[#020710] mb-4  lg-text max-w-4xl mx-auto"></h2> */}

                    <motion.div className="inline-flex items-center gap-4  mb-4 " initial={{ opacity: 0, y: 50 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true, amount: 0.2 }}
                        transition={{ duration: 0.5 }}  >
                        <span className="bg-gradient-to-b from-[#25B57E] to-[#2BAAE2] text-transparent bg-clip-text font-bold text-base">
                            MINTRO FEATURES
                        </span>

                        <div className="w-16 h-[2px] bg-[#1DBB91]"></div>
                    </motion.div>
                    <h2 className="text-3xl md:text-4xl font-bold text-[#0F0F0F] mb-4   max-w-4xl mx-auto lg-text">  Designed to Empower Your Journey</h2>
                    <p className="text-[#4B4B57] max-w-xl text-lg mx-auto">
                        Empowering you to take charge of your financial future with intuitive tools and personalized insights.
                    </p>
                </motion.div>

                <motion.div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center  " initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true, amount: 0.2 }}
                    transition={{ duration: 0.5 }}
                >
                    <div className="flex flex-col h-full order-last lg:order-first  p-0 border-2 border-dotted border-[#0000001A] relative">
                        <div className="">
                            {features.map((feature, index) => (
                                <div
                                    key={index}
                                    // onClick={() => handleFeatureClick(feature)}
                                    className={`flex items-center space-x-4 px-5 py-7 cursor-pointer transition-all duration-300 ease-in-out transform border-b-2 border-dotted border-[#0000001A] last:border-none ${selectedFeature?.image === feature?.image && "bg-[#F9FAFB] "} `}
                                >

                                    {/* ${selectedFeature?.image === feature?.image && "bg-[#EDF2FA] shadow-[0px_0px_24px_rgba(0,0,0,0.05)] scale-105 p-4 "} */}
                                    <div className="p-3 bg-[#F9FAFB] rounded-lg">
                                        <img src={feature.image} alt={feature.title} className=" object-contain w-9" />
                                    </div>
                                    <div>
                                        <h3 className="text-lg md:text-xl font-medium text-[#0F0F0F]">{feature.title}</h3>
                                        <p className="text-[#4B4B57] mt-3 text-sm md:text-base">{feature.description}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>





                    <div className="flex flex-col order-first lg:order-last">


                        <div className="flex-1 overflow-hidden rounded-3xl bg-[#F7F7F7] p-5 md:p-8 ">
                            <div className="relative  flex justify-center items-center ">
                                <img
                                    src={selectedFeature?.main_img}
                                    alt={selectedFeature?.title}
                                    className="object-cover w-full h-full  max-w-full rounded-2xl max-h-[6  00px]"
                                />
                            </div>
                        </div>

                    </div>
                </motion.div>
            </motion.div>

        </section>

    )
}