// import { createSlice } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";


// const loadSignupData = () => {
//     const data = localStorage.getItem("signupData");
//     return data ? JSON.parse(data) : { fullName: "", mobileNumber: "", countryCode: "+91", email: "" };
// };






const initialState = {
  
    showConsultation: false,
    showCookies:false,

};



const authModalSlice = createSlice({
    name: "authModal",
    initialState,
    reducers: {
        openConsultation: (state) => {
            state.showConsultation = true;
       
        },
      
        closeConsultation: (state) => {
            state.showConsultation = false;
      
        },

        openCookies: (state) => {
            state.showCookies = true;
       
        },
      
        closeCookies: (state) => {
            state.showCookies = false;
      
        },

    
    },
});

export const { openConsultation , closeConsultation, openCookies, closeCookies} = authModalSlice.actions;
export default authModalSlice.reducer;
