
import emailjs from "@emailjs/browser";
import { useState } from "react"
import { ArrowForward, CloudUpload } from "@mui/icons-material"
// import ind_flag from "../../assets/images/CareerPage/ind_flag.svg'"
import ind from "../../assets/images/CareerPage/ind_flag.svg"
import dropdown_icon from "../../assets/images/CareerPage/dropdown.svg"
import "./ApplyJobForm.css"
import apply_arrow from "../../assets/images/CareerPage/apply_Arrow.svg"
import { Link } from "react-router-dom"
export default function JobApplicationForm() {
    const [formData, setFormData] = useState({
        fullName: "",
        email: "",
        phone: "",
        experience: "",
        message: "",
        resume: null,
        fileName: ""
    })
    const [fileError, setFileError] = useState("");
    const [errors, setErrors] = useState({})

    // const handleInputChange = (e) => {
    //     const { name, value, type, checked, files } = e.target;

    //     if (name === "resume") {
    //         const file = files[0];


    //         const allowedFormats = ["application/pdf", "application/msword",
    //             "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    //             "application/rtf", "application/vnd.oasis.opendocument.text"];


    //         if (!allowedFormats.includes(file.type)) {
    //             setFileError("Invalid file type. Allowed types: .doc, .docx, .pdf, .rtf, .odt");
    //             return;
    //         }

    //         if (file.size > 10 * 1024 * 1024) {
    //             setFileError("File size exceeds 10MB.");
    //             return;
    //         }

    //         setFormData((prev) => ({
    //             ...prev,
    //             resume: file,
    //         }));
    //         setFileError(""); 
    //     }


    //     if (name === "phone") {
    //         const numericValue = value.replace(/\D/g, ""); 
    //         if (numericValue.length <= 10) {
    //             setFormData((prev) => ({
    //                 ...prev,
    //                 [name]: numericValue, 
    //             }));
    //         }
    //     } else {

    //         setFormData((prev) => ({
    //             ...prev,
    //             [name]: type === "checkbox" ? checked : type === "file" ? files[0] : value,
    //         }));
    //     }


    //     if (errors[name]) {
    //         setErrors((prev) => ({ ...prev, [name]: "" }));
    //     }
    // };

    // const handleInputChange = (e) => {
    //     const { name, value, type, checked, files } = e.target;

    //     if (name === "resume") {
    //         const file = files[0];

    //         if (!file) return;

    //         const allowedFormats = [
    //             "application/pdf",
    //             "application/msword",
    //             "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    //             "application/rtf",
    //             "application/vnd.oasis.opendocument.text"
    //         ];

    //         if (!allowedFormats.includes(file.type)) {
    //             setFileError("Invalid file type. Allowed types: .doc, .docx, .pdf, .rtf, .odt");
    //             return;
    //         }

    //         if (file.size > 10 * 1024 * 1024) {
    //             setFileError("File size exceeds 10MB.");
    //             return;
    //         }

    //         const reader = new FileReader();
    //         reader.readAsDataURL(file);
    //         reader.onload = () => {
    //             const base64String = reader.result.split(",")[1]; 
    //             setFormData((prev) => ({
    //                 ...prev,
    //                 resume: base64String,
    //                 fileName: file.name,
    //             }));
    //         };

    //         setFileError("");
    //     }

    //     if (name === "phone") {
    //         const numericValue = value.replace(/\D/g, "");
    //         if (numericValue.length <= 10) {
    //             setFormData((prev) => ({
    //                 ...prev,
    //                 [name]: numericValue,
    //             }));
    //         }
    //     } else {
    //         setFormData((prev) => ({
    //             ...prev,
    //             [name]: type === "checkbox" ? checked : value,
    //         }));
    //     }

    //     if (errors[name]) {
    //         setErrors((prev) => ({ ...prev, [name]: "" }));
    //     }
    // };

    const handleInputChange = (e) => {
        const { name, value, type, checked, files } = e.target;
    
        if (name === "resume") {
            const file = files[0];
    
            if (!file) return;
    
            const allowedFormats = [
                "application/pdf",
                "application/msword",
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                "application/rtf",
                "application/vnd.oasis.opendocument.text"
            ];
    
            if (!allowedFormats.includes(file.type)) {
                setFileError("Invalid file type. Allowed types: .doc, .docx, .pdf, .rtf, .odt");
                return;
            }
    
            if (file.size > 10 * 1024 * 1024) {
                setFileError("File size exceeds 10MB.");
                return;
            }
    
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                setFormData((prev) => ({
                    ...prev,
                    resume: {
                        base64: reader.result.split(",")[1], // Store Base64 data
                        fileName: file.name,  
                    },
                }));
            };
    
            setFileError(""); 
        } else if (name === "phone") {
            const numericValue = value.replace(/\D/g, ""); 
            if (numericValue.length <= 10) {
                setFormData((prev) => ({
                    ...prev,
                    [name]: numericValue, 
                }));
            }
        } else {
            setFormData((prev) => ({
                ...prev,
                [name]: type === "checkbox" ? checked : value,
            }));
        }
    
        if (errors[name]) {
            setErrors((prev) => ({ ...prev, [name]: "" }));
        }
    };
    

    const validateForm = () => {
        const newErrors = {}
        if (!formData.fullName) newErrors.fullName = "Full name is required"
        if (!formData.email) newErrors.email = "Email is required"
        else if (!/\S+@\S+\.\S+/.test(formData.email)) newErrors.email = "Email is invalid"
        if (!formData.phone) newErrors.phone = "Phone number is required"
        if (!formData.experience) newErrors.experience = "Experience is required"
        if (!formData.consent) newErrors.consent = "You must accept the privacy policy"
        return newErrors
    }


    // const handleSubmit = (e) => {
    //     e.preventDefault()
    //     const newErrors = validateForm()
    //     if (Object.keys(newErrors).length === 0) {
    //         // Handle form submission
    //         console.log("Form submitted:", formData)
    //     } else {
    //         setErrors(newErrors)
    //     }
    // }

    // const handleSubmit = async (e) => {
    //     e.preventDefault();

    //     const newErrors = validateForm();
    //     if (Object.keys(newErrors).length === 0) {
    //         if (!formData.resume) {
    //             setFileError("Please upload a resume.");
    //             return;
    //         }

    //         try {
    //             const response = await fetch("https://script.google.com/macros/s/AKfycbzcpvZZA0qrNIAQ0ZIP5_QCuIId8LAgDf64xgqJvLfC4nuBElckVGF-Eiia0w1pOL16/exec", {
    //                 method: "POST",
    //                 headers: { "Content-Type": "application/json" },
    //                 body: JSON.stringify(formData),
    //             });

    //             const result = await response.json();
    //             if (result.status === "success") {
    //                 alert("Resume submitted successfully!");
    //                 setFormData({
    //                     fullName: "",
    //                     email: "",
    //                     phone: "",
    //                     experience: "",
    //                     message: "",
    //                     resume: null,
    //                     fileName: "",
    //                 });
    //             } else {
    //                 alert("Error submitting resume: " + result.message);
    //             }
    //         } catch (error) {
    //             alert("Submission failed. Please try again.");
    //         }
    //     } else {
    //         setErrors(newErrors);
    //     }
    // };

    // const handleSubmit = async (e) => {
    //     e.preventDefault();
    
    //     const newErrors = validateForm();
    //     if (Object.keys(newErrors).length > 0) {
    //         setErrors(newErrors);
    //         return;
    //     }
    
    //     const formDataToSend = {
    //         fullName: formData.fullName,
    //         email: formData.email,
    //         phone: formData.phone,
    //         experience: formData.experience,
    //         message: formData.message || "No message provided",
    //         attachment: formData.resume, // Base64 encoded file
    //         fileName: formData.fileName,
    //     };
    
    //     try {
    //         const response = await fetch(
    //             "https://script.google.com/macros/s/AKfycbwkkApOcTs5S3VoQkquAORQcpnqr2uPnBE_LkN4Mn-IjsAzgQ0v5LEwNkJekM99wot5/exec",
    //             {
    //                 method: "POST",
    //                 headers: {
    //                     "Content-Type": "application/json",
    //                 },
    //                 body: JSON.stringify(formDataToSend),
    //                 mode: "no-cors", 
    //             }
    //         );
    
    //         console.log("Form submitted successfully!");
    //         console.log(response)
    //     } catch (error) {
    //         console.error("Error submitting form:", error);
    //     }
    // };

const handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = validateForm();
    if (Object.keys(newErrors).length > 0) {
        setErrors(newErrors);
        return;
    }

    // Prepare data for EmailJS
    const emailData = {
        fullName: formData.fullName,
        email: formData.email,
        phone: formData.phone,
        experience: formData.experience,
        message: formData.message,
        resume: formData.resume.base64, // Sending Base64 string
        fileName: formData.resume.fileName, // File name
    };

    try {
        const response = await emailjs.send(
            "service_od4c70r",
            "template_dym8i6c",
            emailData,
            "l-GWr-2s6wle6Qh11"
        );
        alert("Email Sent Successfully!");
        console.log("SUCCESS!", response.status, response.text);

        // Reset form after successful submission
        setFormData({
            fullName: "",
            email: "",
            phone: "",
            experience: "",
            message: "",
            resume: null,
            fileName: "",
        });
    } catch (error) {
        alert("Email Sending Failed!");
        console.error("FAILED...", error);
    }
};
    


    const experienceOptions = ["0-1 years", "1-3 years", "3-5 years", "5-10 years", "10+ years"]

    return (

        <section className="px-4 pb-16 jobform">
            <div className="max-w-5xl mx-auto ">
                <h1 className="text-3xl md:text-4xl font-bold mb-8 text-[#333333] lg-text">Apply for this job</h1>

                <form onSubmit={handleSubmit} className="space-y-8 border-[#EBEDEF] border-2 px-7 py-7 md:px-14 md:py-10 lg:px-24 lg:py-14 rounded-3xl ">

                    {/* <div className="border-2 border-dashed border-[#DADADA] rounded-xl p-10 bg-[#F8F8F9]">
                        <input
                            type="file"
                            id="resume"
                            name="resume"
                            accept=".doc,.docx,.pdf,.rtf,.odt"
                            onChange={handleInputChange}
                            className="hidden"
                        />
                        <label htmlFor="resume" className="flex flex-col items-center justify-center cursor-pointer">
                            <span className="text-[#5FACEB] text-sm sm:text-base">Upload resume</span>
                            <span className="text-sm sm:text-base text-[#868E96] text-center mt-2">
                                This will auto-fill the fields below. 10MB max file size (Allowed file types: .doc, .pdf, .docx, .rtf, .odt).
                            </span>
                        </label>

                     
                        {formData.resume && (
                            <p className="mt-4 text-[#5FACEB] text-sm">{formData.resume.name}</p>
                        )}

                  
                        {fileError && <p className="text-red-500 text-sm mt-2">{fileError}</p>}
                    </div> */}

                    <div className="border-2 border-dashed border-[#DADADA] rounded-xl p-10 bg-[#F8F8F9]">
                        <input
                            type="file"
                            id="resume"
                            name="resume"
                            accept=".doc,.docx,.pdf,.rtf,.odt"
                            onChange={handleInputChange}
                            className="hidden"
                        />
                        <label htmlFor="resume" className="flex flex-col items-center justify-center cursor-pointer">
                            <span className="text-[#5FACEB] text-sm sm:text-base">Upload resume</span>
                            <span className="text-sm sm:text-base text-[#868E96] text-center mt-2">
                                This will auto-fill the fields below. 10MB max file size (Allowed file types: .doc, .pdf, .docx, .rtf, .odt).
                            </span>
                        </label>

                        {formData.resume?.fileName && (
                            <p className="mt-4 text-[#5FACEB] text-sm">{formData.resume.fileName}</p>
                        )}

                        {fileError && <p className="text-red-500 text-sm mt-2">{fileError}</p>}
                    </div>


                    {/* Full Name */}
                    <div>
                        <label className="block text-lg md:text-xl lg:text-2xl font-medium mb-4 text-[#636363]">
                            Full Name<span >*</span>
                        </label>
                        <input
                            type="text"
                            name="fullName"
                            value={formData.fullName}
                            onChange={handleInputChange}
                            className={`w-full px-4 py-4 rounded-xl border ${errors.fullName ? "border-red-500" : "border-[#8888884F]"
                                } focus:outline-none focus:ring-2 focus:ring-emerald-500`}
                            placeholder="Jane Smith"
                        />
                        {errors.fullName && <p className="text-red-500 text-sm mt-1">{errors.fullName}</p>}
                    </div>

                    {/* Email */}
                    <div>
                        <label className="block text-lg md:text-xl lg:text-2xl font-medium mb-4 text-[#636363]">
                            Email id<span >*</span>
                        </label>
                        <input
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleInputChange}
                            className={`w-full px-4 py-4 rounded-xl border ${errors.email ? "border-red-500" : "border-[#8888884F]"
                                } focus:outline-none focus:ring-2 focus:ring-emerald-500`}
                            placeholder="support@yourapp.com"
                        />
                        {errors.email && <p className="text-red-500 text-sm mt-1">{errors.email}</p>}
                    </div>

                    {/* Phone and Experience */}
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        {/* Phone Number */}
                        <div>
                            <label className="block text-lg md:text-xl lg:text-2xl font-medium mb-4 text-[#636363]">Phone Number <span >*</span></label>
                            <div className="flex">
                                <div className="flex items-center px-3 border border-r-0 border-[#8888884F] rounded-l-xl ">
                                    <img src={ind} alt="IN" className="w-6 h-6 sm:w-8 sm:h-8 rounded-sm" />
                                    <span className="ml-2 text-gray-500">+91</span>
                                </div>
                                <input
                                    type="tel"
                                    name="phone"
                                    value={formData.phone}
                                    onChange={handleInputChange}
                                    className="flex-1 px-4 py-4 rounded-r-xl border border-[#8888884F] focus:outline-none focus:ring-2 focus:ring-emerald-500"
                                    placeholder="989898989"
                                />

                            </div>

                            {errors.phone && <p className="text-red-500 text-sm mt-1">{errors.phone}</p>}
                        </div>

                        {/* Experience */}
                        <div>
                            <label className="block text-lg md:text-xl lg:text-2xl font-medium mb-4 text-[#636363]">
                                Experience<span >*</span>
                            </label>


                            <div className="relative w-full">
                                {/* Dropdown Select */}
                                <select
                                    name="experience"
                                    value={formData.experience}
                                    onChange={handleInputChange}
                                    className={`w-full px-4 py-[17px] rounded-xl border appearance-none 
            ${errors.experience ? "border-red-500" : "border-[#8888884F]"} 
            focus:outline-none focus:ring-2 focus:ring-emerald-500`}
                                >
                                    <option value="">Select experience</option>
                                    {experienceOptions.map((option) => (
                                        <option key={option} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </select>

                                {/* Dropdown Icon */}
                                <img
                                    src={dropdown_icon}
                                    alt="Dropdown Icon"
                                    className="absolute right-4 top-1/2 transform -translate-y-1/2 w-4 h-4 pointer-events-none"
                                />
                            </div>

                            {errors.experience && <p className="text-red-500 text-sm mt-1">{errors.experience}</p>}
                        </div>
                    </div>

                    {/* Message */}
                    <div>
                        <label className="block text-lg md:text-xl lg:text-2xl font-medium mb-4 text-[#636363]">Message (Optional)</label>
                        <textarea
                            name="message"
                            value={formData.message}
                            onChange={handleInputChange}
                            rows={5}
                            className="w-full px-4 py-4 rounded-xl border border-[#8888884F] focus:outline-none focus:ring-2 focus:ring-emerald-500"
                            placeholder="Enter your message"
                        />
                    </div>

                    {/* Privacy Policy Consent */}
                    <div>
                        <label className="flex items-start gap-2 cursor-pointer">
                            <input
                                type="checkbox"
                                name="consent"
                                checked={formData.consent}
                                onChange={handleInputChange}
                                className="mt-2"
                            />
                            <span className="text-base md:text-lg text-[#333333]">
                                By applying, you hereby accept the data processing terms under the{" "}
                                <Link to="/privacy-policy" className="text-emerald-500 hover:underline">
                                    Privacy Policy
                                </Link>{" "}
                                and give consent to processing of the data as part of this job application.
                            </span>
                        </label>
                        {errors.consent && <p className="text-red-500 text-sm mt-1">{errors.consent}</p>}
                    </div>

                    {/* Submit Button */}
                    <button
                        type="submit"
                        className="flex items-center font-medium gap-2 px-4 py-3 md:px-6 md:py-3 text-white rounded-xl text-base md:text-lg bg-gradient-to-r from-emerald-500 to-cyan-500 hover:from-emerald-600 hover:to-cyan-600 transition-colors"
                    >
                        Apply Now
                        {/* <ArrowForward className="w-5 h-5" /> */}
                        <img src={apply_arrow} alt="" className="w-4 h-4 md:w-5 md:h-5 " />
                    </button>
                </form>
            </div>
        </section>
    )
}

