import { Speed, Group, BarChart, Rocket, Timeline, Assessment } from "@mui/icons-material"
import { useState } from "react"
import native_mobile from "../../../assets/images/Services/MobileApp/native_mmobile.svg"
import app_maintain from "../../../assets/images/Services/MobileApp/app_updates.svg"
import cross_plat from "../../../assets/images/Services/MobileApp/cross.svg"
import app_int from "../../../assets/images/Services/MobileApp/app_int.svg"
import app_design from "../../../assets/images/Services/MobileApp/app.svg"
import scalable from "../../../assets/images/Services/MobileApp/scalable.svg"
// import native_mobile from"../../../assets/images/Services/MobileApp/native_mmobile.svg"
import { motion } from "framer-motion"
import mobile_img from "../../../assets/images/Services/Quality Assurance/mobile_trans.png"
import mobile_bg from "../../../assets/images/Services/Quality Assurance/mobile_bg.png";


import img1 from "../../../assets/images/Services/Quality Assurance/mannual.svg"
import img2 from "../../../assets/images/Services/Quality Assurance/security.svg"
import img3 from "../../../assets/images/Services/Quality Assurance/automated_testing.svg"
import img4 from "../../../assets/images/Services/Quality Assurance/mobile_app.svg"
import img5 from "../../../assets/images/Services/Quality Assurance/performance.svg"
import img6 from "../../../assets/images/Services/Quality Assurance/api_test.svg"


import img1_active from "../../../assets/images/Services/Quality Assurance/mannual_active.svg"
import img2_active from "../../../assets/images/Services/Quality Assurance/security_active.svg"
import img3_active from "../../../assets/images/Services/Quality Assurance/auto_active.svg"
import img4_active from "../../../assets/images/Services/Quality Assurance/mobile_Active.svg"
import img5_active from "../../../assets/images/Services/Quality Assurance/performance_active.svg"
import img6_active from "../../../assets/images/Services/Quality Assurance/api_test_active.svg"

import slide1 from "../../../assets/images/Services/Quality Assurance/slide1.png"
import slide2 from "../../../assets/images/Services/Quality Assurance/slide2.png"
import slide3 from "../../../assets/images/Services/Quality Assurance/slide3.png"
import slide4 from "../../../assets/images/Services/Quality Assurance/slide4.png"
import slide5 from "../../../assets/images/Services/Quality Assurance/slide5.png"
import slide6 from "../../../assets/images/Services/Quality Assurance/slide6.png"




export default function TestingType() {


    const mobileBackgrounds = [
      slide1,
      slide2,
      slide3,
      slide4,
      slide5,
      slide6,

    ];
    const [selectedIndex, setSelectedIndex] = useState(0);
    const topFeatures = [
        "Scalability to meet evolving project needs.",
        "Enhanced productivity through workflows.",
        "Improved collaboration for teamwork.",
        "Clear accountability with defined roles.",
    ]

    const advantages = [
        {
            icon: img1,
            title: "Manual Testing",
            description: "Exploratory Testing, Functional Testing, Regression Testing, Usability Testing",
            active:img1_active
        },
        {
            icon: img2,
            title: "Security Testing",
            description: "Vulnerability Assessment, Penetration Testing, Compliance Testing",
            active:img2_active

        },
        {
            icon: img3,
            title: "Automated Testing",
            description: "Automation Strategy, Script Development, Continuous Integration Testing",
            active:img3_active

        },
        {
            icon: img4,
            title: "Mobile App Testing",
            description: " Cross-Platform Testing, App Compatibility Testing, Mobile Usability Testing",
            active:img4_active

        },
        {
            icon: img5,
            title: "Performance & Load Testing",
            description: "Stress Testing, Scalability Testing, Load Testing",
            active:img5_active

        },
        {
            icon: img6,
            title: "API Testing",
            description: "Functional Testing, Security Testing, Performance Testing",
            active:img6_active

        },
    ]

    return (
        <section className="pt-16 pb-0 sm:pt-16 sm:pb-16 px-4 testtype">
            <div className="max-w-7xl mx-auto">

                <motion.div className="text-center  mb-10 sm:mb-20" initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true, amount: 0.2 }}
                    transition={{ duration: 0.5 }}>
                    <h2 className="text-3xl font-semibold mb-6 lg-text text-[#0C0C0C] ">Lorem ipsum dolor sit amet ipsum</h2>
                    <p className="text-[#666666] max-w-3xl mx-auto" style={{ fontSize: "17px" }}>
                        Lorem ipsum dolor sit amet consectetur, adipisicing elit. A, ab nemo! Expedita!Lorem ipsum dolor sit amet consectetur
                    </p>
                </motion.div>


                <div className="relative">


                    <div className="hidden md:block absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-[290px]">
                        <div className="aspect-[9/19.5] overflow-hidden relative">
                 
                            <img   src={mobileBackgrounds[selectedIndex]}  alt="Mobile Background" className="absolute inset-0 w-full h-full object-contain " style={{borderRadius:"50px"}} />

                            <img src={mobile_img} alt="Mobile Content" className="relative w-full h-full" />
                        </div>
                    </div>


                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6 ">
                        {advantages.map((advantage, index) => (
                            <motion.div
                                key={index}
                                initial={{ opacity: 0, y: 50 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                viewport={{ once: true, amount: 0.2 }}
                                transition={{ duration: 0.5 }}
                                onClick={() => setSelectedIndex(index)}
                                className={`p-6 cursor-pointer rounded-2xl border border-[#EDEFF3] drop-shadow-custom
                               ${index % 2 === 0 ? "md:mr-[155px] lg:mr-[190px]" : "md:ml-[155px] lg:ml-[190px]"}
                               ${selectedIndex === index ? "bg-[#1DBB91] text-white" : "bg-[#FEFEFE] text-[#071B2F]"}`}
                                style={{
                                    filter: "drop-shadow(0px 0px 30px #0000000D)",
                                }}
                            >
                                {/* Row for Icon and Title */}
                                <div className="flex items-center mb-4">
                                    <div className={` ${selectedIndex === index ? "bg-white " : "bg-[#F2F2F2]"} p-3 rounded-xl flex items-center justify-center w-16 h-16`}>

                                        {selectedIndex === index ? <img src={advantage?.active} alt="" className="w-8 h-8" /> :  <img src={advantage?.icon} alt="" className="w-8 h-8" /> }

                                        
                                       
                                    </div>
                                </div>


                                <h3 className={`text-lg md:text-xl font-semibold mb-3 
                               ${selectedIndex === index ? "text-white" : "text-[#071B2F]"}`}>
                                    {advantage.title}
                                </h3>

                                {/* Description */}
                                <p className={`text-base md:text-lg 
                               ${selectedIndex === index ? "text-white" : "text-[#494949]"}`}>
                                    {advantage.description}
                                </p>
                            </motion.div>

                        ))}
                    </div>
                </div>
            </div>
        </section>
    )
}

