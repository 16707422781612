import React from 'react'
import { motion } from 'framer-motion'
import "./WebDevComp.css"
import begin_img from "../../../assets/images/Services/AIDev/begin_img.png"
import react_img from "../../../assets/images/Services/WebApp/react.svg"
import angular_img from "../../../assets/images/Services/WebApp/angular.svg"
import nodejs_img from "../../../assets/images/Services/WebApp/nodejs.svg"
import { img } from 'framer-motion/client'
import cursor_img from "../../../assets/images/Services/WebApp/cursor.svg"
import custom_web_Dev from "../../../assets/images/Services/WebApp/cms_Section.png"
import starpi from "../../../assets/images/Services/WebApp/strapi.png"
import medula from "../../../assets/images/Services/WebApp/medula.png"




const KeyFrameWorks = [
    {


        text: "React.js  Dynamic, responsive user interfaces with fast load times.",
        img: react_img,
    },
    {

        text: `Angular A complete, scalable framework for enterprise-level apps.`,
        img: angular_img,
    },
    {

        text: "Node.js & Express Backend solutions for fast, scalable, and real-time applications.",
        img: nodejs_img,
    },

]


const CmsSection = () => {
    return (

        <section className="relative py-16 px-4 ">


            <div className="max-w-7xl mx-auto">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center p-0 lg:p-4 framework">



                    <motion.div className="flex flex-col h-full justify-center" initial={{ opacity: 0, y: 50 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true, amount: 0.2 }}
                        transition={{ duration: 0.5 }}>
                        <div className="space-y-7">

                            <span className="font-medium text-[#333333]  px-3 py-2  gap-1  rounded-full border border-[#EDEFF3] bg-[#F8F9FA] text-sm flex" style={{ display: "inline-flex" }}>

                                <img src={cursor_img} alt="" className="w-5 h-5" />

                                <span >Free Trial</span>

                            </span>


                            <h3 className="text-2xl md:text-4xl font-medium text-[#0C0C0C] lg-text">
                                No-Code & <span className='text-[#25B57E]'>  Headless CMS  </span>   Development
                            </h3>


                            <p className=" text-[#666666] text-base" >
                                For businesses that need to launch quickly without sacrificing flexibility or performance, we leverage powerful no-code tools and headless CMS platforms. Whether you're looking to create an elegant website or a fully functional e-commerce store, we use no-code tools to build highly customizable websites and applications, with a focus on ease of use and future scalability.
                            </p>

                            {/* <div className="space-y-4">
                                {keyFeatures.map((feature, index) => (
                                    <div key={index} className="flex items-center gap-3 text-gray-700 rounded-full border border-[#E6E9EE] p-2" style={{ display: "inline-flex" }}>

                                        <img src={verify_icon} alt="" className="w-5 h-5" />
                                        <span style={{ fontSize: "15.51px", lineHeight: '23.26px', color: "#333333" }}>{feature.text}</span>
                                    </div>
                                ))}
                            </div> */}
                            {/* 
                            <div className="space-y-4">

                                {KeyFrameWorks.map((feature, index) => (

                                    <div className="flex items-center gap-3 text-[#3D3D3D] rounded-full border border[#E6E9EE] p-2 font-medium text-sm" style={{ display: "inline-flex" }}>

                                        <img src={feature?.img} alt="" className="w-5 h-5" />

                                        <span >{feature?.text}</span>
                                    </div>

                                ))}

                            </div> */}

                            {/* <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3  gap-8 justify-items-center " initial={{ opacity: 0, y: 50 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                viewport={{ once: true, amount: 0.2 }}
                                transition={{ duration: 0.5 }}
                            >
                                {displayedIndustries?.map((tech, index) => (
                                    <motion.div
                                        key={index}
                                        className="flex flex-col cursor-pointer items-start  group bg-white  rounded-3xl p-5 w-full border border-[#E6E9EE]"
                                        initial={{ opacity: 0, y: 50 }}
                                        whileInView={{ opacity: 1, y: 0 }}
                                        viewport={{ once: true, amount: 0.2 }}
                                        transition={{ duration: 0.5 }}
                                    >
                                        <div className="mb-3 flex  items-center flex-row space-x-3">
                                            <img
                                                src={tech?.logo}
                                                alt={`${tech.name} logo`}
                                                className="object-contain w-9 h-9"
                                            />
                                            <span className="text-lg text-[#262626] font-medium">{tech.name}</span>
                                        </div>
                                        <p className="  text-[#666666] mt-2 px-3" style={{ fontSize: "15.41px", lineHeight: "23.11px" }}>{tech.description}</p>

                                    </motion.div>
                                ))}


                            </div> */}


                            <div className="grid grid-cols-1 sm:grid-cols-2  gap-8 justify-items-center " initial={{ opacity: 0, y: 50 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                viewport={{ once: true, amount: 0.2 }}
                                transition={{ duration: 0.5 }}
                            >

                                <motion.div

                                    className="flex flex-col cursor-pointer items-start  group bg-white  rounded-xl  p-4 w-full border border-[#E6E9EE]"
                                    initial={{ opacity: 0, y: 50 }}
                                    whileInView={{ opacity: 1, y: 0 }}
                                    viewport={{ once: true, amount: 0.2 }}
                                    transition={{ duration: 0.5 }}
                                >
                                    <div className="mb-2 flex  items-center flex-row space-x-3">
                                        <img
                                            src={starpi}
                                            // alt={`${tech.name} logo`}
                                            className="object-contain "
                                        />
                                        <span className=" text-[#262626] font-medium text-sm">Strapi & Sanity (Headless CMS)</span>
                                    </div>
                                    <p className="  text-[#666666] mt-1  text-xs" style={{ fontSize: "15.41px", lineHeight: "23.11px" }}>Boost teamwork and productivity with instant communication and seamless file sharing in SAP.</p>

                                </motion.div>

                                <motion.div

                                    className="flex flex-col cursor-pointer items-start  group bg-white  rounded-xl  p-4 w-full border border-[#E6E9EE]"
                                    initial={{ opacity: 0, y: 50 }}
                                    whileInView={{ opacity: 1, y: 0 }}
                                    viewport={{ once: true, amount: 0.2 }}
                                    transition={{ duration: 0.5 }}
                                >
                                    <div className="mb-2 flex  items-center flex-row space-x-3">
                                        <img
                                            src={medula}
                                            // alt={`${tech.name} logo`}
                                            className="object-contain "
                                        />
                                        <span className=" text-[#262626] font-medium text-sm">Medusa.js (E-commerce)</span>
                                    </div>
                                    <p className="  text-[#666666] mt-1  text-xs" style={{ fontSize: "15.41px", lineHeight: "23.11px" }}>Easily assign, track, and prioritize tasks to keep projects on schedule and teams aligned.</p>

                                </motion.div>



                                {/* <motion.div

                                    className="flex flex-col cursor-pointer items-start  group bg-white  rounded-3xl p-5 w-full border border-[#E6E9EE]"
                                    initial={{ opacity: 0, y: 50 }}
                                    whileInView={{ opacity: 1, y: 0 }}
                                    viewport={{ once: true, amount: 0.2 }}
                                    transition={{ duration: 0.5 }}
                                >
                                    <div className="mb-3 flex  items-center flex-row space-x-3">
                                        <img
                                            src={starpi}
                                            // alt={`${tech.name} logo`}
                                            className="object-contain"
                                        />
                                        <span className="text-lg text-[#262626] font-medium">Strapi & Sanity (Headless CMS)</span>
                                    </div>
                                    <p className="  text-[#666666] mt-2 px-3" style={{ fontSize: "15.41px", lineHeight: "23.11px" }}>Enhance teamwork and productivity with instant
                                        communication and file sharing within SAP's
                                        collaborative environment.</p>

                                </motion.div> */}



                            </div>




                        </div>
                    </motion.div>


                    <motion.div
                        className="space-y-8 flex flex-col h-full relative group  justify-center"
                        initial={{ opacity: 0, y: 50 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true, amount: 0.2 }}
                        transition={{ duration: 0.5 }}
                    >

                        <img src={custom_web_Dev} alt="" />



                    </motion.div>

                </div>
            </div>
        </section>
    )
}

export default CmsSection
