// src/cardData.js

// import dev_img from "../assets/images/Developers.png";
// import ai_icon from "../assets/images/subscribe_icon.png";
import dev_img from "../assets/images/Developers.webp"
// import ai_icon from "../assets/images/subscribe_icon.png";
import cms from "../assets/images/cms.webp";
import mobile_app from "../assets/images/mobile_app.webp";
import quality from "../assets/images/quality.webp";
import ui_ux from "../assets/images/ui-ux.webp";
import web_app from "../assets/images/web_app.webp";
import ai_dev from "../assets/images/ai_Dev.png";
import ai_icon from "../assets/images/DevSection/ai_dev.svg"
import mobile_icon from "../assets/images/DevSection/mobile_app.svg"
import web_icon from "../assets/images/DevSection/web_app.svg"
import ui_icon from "../assets/images/DevSection/ui_ux.svg"
import cms_icon from "../assets/images/DevSection/sms_dev.svg"
import quality_icon from "../assets/images/DevSection/quality_testing.svg"
import custom_product_icon from "../assets/images/DevSection/custom_product.svg"






export const cardData = [

  {
    title: (
      <>
 AI-Enabled Solutions
      </>
    ),
    description:
      "We develop AI-driven solutions that enhance decision-making, automate processes, and provide personalized user experiences using machine learning and natural language processing technologies.",
    image: ai_dev,
    icon: ai_icon,
  },

  {
    title: (
      <>
   SaaS Product Development
      </>
    ),
    description:
      "We specialize in building custom mobile and web applications tailored to your business needs, ensuring scalability, security, and a user-friendly experience from start to finish.",
    image: dev_img,
    icon: custom_product_icon,
  },
  {
    title: (
      <>
        Mobile App <br /> Development
      </>
    ),
    description:
      "We create high-performance, user-friendly mobile apps for iOS and Android that are secure, scalable, and optimized for an exceptional user experience.",
    image: mobile_app,
    icon: mobile_icon,
  },

  {
    title: (
      <>
        Web App  <br /> Development
      </>
    ),
    description:
      "We build responsive, scalable web applications that are secure, easy to use, and seamlessly integrate with your existing systems to enhance your business operations.",
    image: web_app,
    icon: web_icon,
  },

  {
    title: (
      <>
        UI/UX <br /> Design
      </>
    ),
    description:
      "Our design team focuses on creating visually appealing, intuitive interfaces that deliver seamless user experiences and engage users at every touchpoint.",
    image: ui_ux,
    icon: ui_icon,
  },

  {
    title: (
      <>
        CMS <br /> Development
      </>
    ),
    description:
      "We provide flexible and scalable CMS solutions that enable easy content management, empowering teams to create, edit, and update content efficiently.",
    image: cms,
    icon: cms_icon,
  },

  {
    title: (
      <>
        Quality Assurance<br /> Testing
      </>
    ),
    description:
      "Our QA team performs thorough testing to ensure your application is bug-free, high-performing, and delivers a seamless, reliable user experience.",
    image: quality,
    icon: quality_icon,
  },

];
