
import shopify from "../assets/images/TechStack/shopify-svgrepo-com 1.png"

import webflow from "../assets/images/TechStack/webflow-svgrepo-com 1.png"
import woocommerce from "../assets/images/TechStack/woocommerce-icon-svgrepo-com 1.png"
import wordpress from "../assets/images/TechStack/wordpress-svgrepo-com 1.png"

// import svg_android from "../../assets/images/svg_android.svg"
// import svg_sanity from "../../assets/images/sanity_svg.svg"

import elementor from "../assets/images/TechStack/elementor.png"
import elementor_hover from "../assets/images/TechStack/element_hover_2.png"















export const techCms = [

    {
        name: "Elementor",
        logo: elementor,
        hover:elementor_hover
    },

    


    {
        name: "WooCommerce",
        logo: woocommerce,
        
    },
    {
        name: "Shopify",
        logo: shopify,
    },
    {
        name: "WordPress",
        logo: wordpress,
    },

    {
        name: "Webflow",
        logo: webflow,
    },







]


