

import { useState } from "react"
import { useEffect } from "react"


// import { Typed } from "react-typed"
import Typed from "typed.js";
import { useRef } from "react"
import Partners from "../Partners";
import "./ServiceHero.css"
import ServiceSlider from "../ServiceSlider/ServiceSlider";
import { Link } from "react-router-dom";
export function ServiceHero({ isOpen }) {
    const fullText = "Streamlining Your Businesses With Web and App Development Services";
    const [displayedText, setDisplayedText] = useState("");
    const typingSpeed = 70;


    useEffect(() => {
        let currentIndex = 0;

        const type = () => {
            if (currentIndex < fullText.length) {
                setDisplayedText(fullText?.slice(0, currentIndex + 1)); // Update text up to the current index
                currentIndex++;
                setTimeout(type, typingSpeed);
            }
        };

        type();
    }, []);

    const [animate, setAnimate] = useState(false);

    useEffect(() => {
        // Trigger animation on mount
        setAnimate(true);

        // Optional: Remove animation class after it's done
        const timer = setTimeout(() => setAnimate(false), 1000); // Animation duration is 1s
        return () => clearTimeout(timer); // Clean up the timer on unmount
    }, []);

    const typedElement = useRef(null); // Reference to the span element
    const typedInstance = useRef(null); // Reference to the Typed.js instance

    useEffect(() => {
        // Initialize Typed.js
        typedInstance.current = new Typed(typedElement.current, {
            strings: ["Future-Ready Tech Solutions"], // The text to animate
            typeSpeed: 50, // Typing speed (ms per character)
            backSpeed: 0, // No backspacing
            showCursor: false, // Hide the blinking cursor
        });

        return () => {
            // Destroy the Typed.js instance on component unmount
            typedInstance.current.destroy();
        };
    }, []);


    return (
        <section className="container mx-auto px-4 pb-0 pt-12 md:pb-0 text-center md:py-20 md:pt-0 relative padtop  " style={{paddingTop:"2rem"}}>

           


            <div className="animation" >

                <h1
                    className={`mx-auto max-w-5xl text-3xl md:text-4xl lg:text-5xl xl:text-6xl tracking-tight line-height-lg man-transition ${isOpen ? "relative -z-10 pointer-events-none" : ""
                        }`}
                    style={{
                        fontWeight: "600",
                        color: "#191919",
                        overflow: "hidden",
                        wordBreak: "break-word",
                        whiteSpace: "normal",
                    }}
                >
                 Smart, Scalable, and{" "}
                    <span ref={typedElement}></span>
                </h1>


                <div>
                    <p className={`mx-auto mt-6 max-w-2xl text-size md:text-base ${isOpen ? "relative -z-10 pointer-events-none" : ""
                        }`} style={{ fontWeight: "500", color: "#4D4D4D" }}>
             From AI-driven automation to seamless web and app development, we craft tailored solutions that enhance efficiency, elevate user experience, and drive growth. Our expertise ensures scalable, high-performing, and future-ready digital products. 
                    </p>

                    <Link to={"/consult-us"}>

                    <button

                        className={`text-white px-6 py-2 rounded-full text-base font-semibold consultation-btn mt-8 ${animate ? "animate__animated   animate__pulse" : ""
                            } ${isOpen ? "relative -z-10 pointer-events-none" : ""
                            }`}

                        style={{ height: "44px" }}
                    >
                        Get Started For Free
                    </button></Link>

                </div>

                <ServiceSlider/>
            </div>

            {/* <svg
                xmlns="http://www.w3.org/2000/svg"
                width="100%"
                height="100%"
                viewBox="0 0 489 429"
            >
                <path
                    d="M 472.903 83.8 
           C 417.302 35.747 321.102 -11.368 240.895 35.459 
           C 111.796 110.834 194.484 225.891 258.778 246.414 
           C 314.373 264.161 343.363 199.518 243.126 162.942 
           C 177.606 139.035 86.553 147.243 51.352 198.732 
           C 3.224 269.132 72.078 316.981 104.714 324.385 
           C 158.609 336.612 163.716 281.692 101.748 273.198 
           C 38.974 264.593 0.97 303.747 21.932 412.542"
                    stroke="rgb(146, 137, 198)"
                    stroke-width="32"
                    stroke-linecap="round"
                    fill="none"
                    opacity="1"
                    pathLength="1"
                    stroke-dashoffset="0"
                    stroke-dasharray="1 1"
                />
            </svg> */}











            {/* <Partners/> */}




        </section>
    )
}

