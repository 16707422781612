import React from 'react'
import "./ProjectDetails.css"
import { motion } from "framer-motion";
const CommonDiv = () => {
    return (
        <motion.section className="relative py-12  common" initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true, amount: 0.2 }}
            transition={{ duration: 0.5 }}>
            <div className="max-w-3xl mx-auto flex flex-col items-start text-left space-y-4">
                <h3 className="text-2xl md:text-4xl  text-[#292929] lg-text">
                    An outline of our Design Workflow <br />from start to finish
                </h3>
                <span className="text-[#676767] text-lg">
                    Lorem ipsum dolor, sit amet consectetur adipisicing elit. Provident in doloremque officia dignissimos deleniti inventore
                    porro possimus, suscipit natus. Commodi iure numquam facilis itaque?
                </span>
            </div>
        </motion.section>


    )
}

export default CommonDiv
