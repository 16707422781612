import { useState } from "react"
import { motion } from "framer-motion";

import bg_image from "../../assets/images/Blogs/ctm-bg.svg"
import bg from "../../assets/images/Blogs/bg-image.svg"
import arrow from "../../assets/images/Blogs/arrow.svg"
import left_shadow from "../../assets/images/Blogs/shadow-left.svg"
import right_shadow from "../../assets/images/Blogs/shadow-right.svg"
import leftvector from "../../assets/images/MobileDevPage/left_vector.svg"
import rightvector from "../../assets/images/MobileDevPage/right_vector.svg"

import top_left from "../../assets/images/BlogDeatils/tleft.png"
import top_right from "../../assets/images/BlogDeatils/tright.png"
import bottom_left from "../../assets/images/BlogDeatils/bleft.png"
import bottom_right from "../../assets/images/BlogDeatils/bright.png"
import { useNavigate } from "react-router-dom";
import pointer_arrow from "../../assets/images/MobileDevPage/HeroSection/pointer_arrow.svg"
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { openConsultation } from "../../redux/authModalSlice";


export default function MobileDevCTM() {
const dispatch = useDispatch()
    const navigate = useNavigate()
    return (


        <motion.section className="pt-0 pb-4 sm:pt-10 sm:pb-10 px-4 ctm" initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true, amount: 0.2 }}
            transition={{ duration: 0.5 }}>
            <div className="max-w-7xl mx-auto  p-1 relative ">

                <div className="absolute left-[10%] top-[30%] z-[1] floating-left">
                    <img src={leftvector} alt="" className="w-14 sm:w-auto" />
                </div>

                <div className="absolute right-[10%] top-[20%] z-[1] floating-right">
                    <img src={rightvector} alt="" className="w-14 sm:w-auto" />
                </div>


                <div className="relative h-auto px-4 py-14 flex flex-col items-center justify-center text-center text-black 
                bg-gradient-to-b from-[#29ADC7B8] to-[#25B480B8] bg-no-repeat rounded-3xl "

                    style={{
                        // backgroundImage: `url(${left_shadow}), url(${right_shadow})`,
                        // backgroundSize: 'auto, auto',
                        // backgroundPosition: 'left top, right top'
                    }}>

                
                    <p className="text-center text-[#FFFFFF] mb-6 border border-[#F3F3F1] rounded-full px-4 py-1 text-sm sm:text-base">
                        Elevate Your Business
                    </p>

                    <h2 className="text-lg sm:text-3xl md:text-4xl font-semibold mb-6 max-w-4xl md:max-w-3xl lg:max-w-2xl lg-ctm text-[#FFFFFF] relative z-20">
                        Seamless Connections with Your Favorite Apps
                    </h2>

                    <p className="text-sm sm:text-lg md:text-lg text-black-200 mb-8 max-w-2xl lg:max-w-lg text-[#FFFFFF] font-normal relative z-20">
                        Empowering you to take charge of your financial future with intuitive tools and personalized insights.
                    </p>



             

                    <button onClick={()=>dispatch(openConsultation())} className="text-white px-3 py-2 sm:px-3 sm:py-3 md:px-6 md:py-3 rounded-full text-xs sm:text-base font-medium consultation-btn  inline-flex items-center gap-2 border border-[#FFFFFF]">
                        Get a Free Consultation
                        <img src={pointer_arrow} alt="Arrow" className="border border-[#FFFFFF8F] rounded-full p-1 w-6 lg:w-auto" />
                    </button>
                


                </div>
            </div>


            <motion.div className="max-w-7xl mx-auto rounded-[2.8125rem] shadow-[0px_0px_4px_0px_#00000040] p-1 relative hidden" initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true, amount: 0.2 }}
                transition={{ duration: 0.5 }}>
      
                <div className="absolute left-[10%] top-[30%] z-[0] floating-left">
                    <img src={leftvector} alt="" className="w-14" />
                </div>

                <div className="absolute right-[10%] top-[20%] z-[0] floating-right">
                    <img src={rightvector} alt="" className="w-14" />
                </div>


                <div className="relative h-auto min-h-[450px] max-h-[590px] px-4 py-10 flex flex-col items-center justify-center text-center text-black rounded-[2.8125rem] border border-[#F3F3F1] bg-no-repeat bg-left-top bg-right-top"
                    style={{
                        backgroundImage: `url(${top_left}), url(${top_right}),url(${bottom_left}),url(${bottom_right})`,
                        backgroundSize: 'auto, auto, auto, auto',
                        backgroundPosition: 'left top, right top, left bottom, right bottom'
                    }}>

                    {/* Text Content */}
                    <p className="text-center text-[#37312F] mb-6 border border-[#F3F3F1] rounded-full px-4 py-1 text-sm">
                        Elevate Your Business
                    </p>

                    <h2 className="text-lg md:text-2xl font-semibold mb-6 max-w-4xl manrope  urbanist">
                        Transform Ideas into Powerful Digital Solutions
                    </h2>

                    <p className=" text-sm text-black-200 mb-8 max-w-2xl inter font-normal" >
                        Build smarter, faster, and more innovative digital products with our expert services.

                    </p>


                    <button
                        className=" text-white px-4 py-2 rounded-full text-sm md:text-base font-semibold consultation-btn"
                        onClick={() => navigate("/consult-us")}

                    >
                        Get Started
                    </button>

                </div>
            </motion.div>
        </motion.section>







    )
}


