import { LinkedIn, Instagram, Facebook } from "@mui/icons-material";
import footer_logo from "../../assets/images/MobileDevPage/tr_logo.svg"
import insta_logo from "../../assets/images/MobileDevPage/insta_icon.svg"
import link_icon from "../../assets/images/MobileDevPage/link-icon.svg"

import { Link } from "react-router-dom";

const MobileDevFooter = () => {
  return (
    <footer className="w-full bg-white py-6 px-4">
      <div className="max-w-7xl mx-auto">
        {/* Top Row: Logo & Social Media */}
        <div className="flex flex-col lg:flex-row items-center justify-between pb-4">
          {/* Logo */}

          <Link to={"/"}>
          <img src={footer_logo} alt="Tech Rabbit Logo" className="w-40 sm:w-52" />
          </Link>
          {/* Social Media Icons */}
          {/* <div className="flex space-x-3 mt-4 lg:mt-0">
              <div className="p-2 bg-white rounded-full shadow-md hover:shadow-lg transition">
                <LinkedIn className="text-[#22989F]" fontSize="small" />

              </div>
              <div className="p-2 bg-white rounded-full shadow-md hover:shadow-lg transition">
                <Instagram className="text-[#22989F]" fontSize="small" />


              </div>
           
            </div> */}

          <div className="flex space-x-1 mt-4 lg:mt-0">
            <a href="https://in.linkedin.com/company/techrabbitindia"
              target="_blank"
              rel="noopener noreferrer"
              className="p-2">
              <img src={link_icon} alt="" className="w-14 hover:scale-110 transition-transform duration-200 ease-in-out cursor-pointer" />


            </a>
            <a href="https://www.instagram.com/techrabbitindia/"
              target="_blank"
              rel="noopener noreferrer"
              className="p-2">
              <img src={insta_logo} alt="" className="w-14 hover:scale-110 transition-transform duration-200 ease-in-out cursor-pointer" />


            </a>

          </div>

          {/* 
          <a
            href="https://in.linkedin.com/company/techrabbitindia"
            target="_blank"
            rel="noopener noreferrer"
            className="rounded-full bg-white p-3 hover:scale-110 hover:shadow-lg transition-transform duration-200 ease-in-out cursor-pointer"
          >
            <img src={svg4} alt="LinkedIn Icon" className="w-5 h-5" />
          </a>

          <a
            href="https://www.instagram.com/techrabbitindia/"
            target="_blank"
            rel="noopener noreferrer"
            className="rounded-full bg-white p-3 hover:scale-110 hover:shadow-lg transition-transform duration-200 ease-in-out"
          >
            <img src={svg1} alt="Instagram" className="w-5 h-5" />
          </a> */}
        </div>

        {/* Border */}
        <hr className="border-t border-[#E7E7E7]" />

        {/* Bottom Row: Copyright & Links */}
        <div className="flex flex-col lg:flex-row items-center justify-between pt-4 text-[#434343] font-medium">
          {/* Copyright */}
          <p>
            Copyright © 2025 <Link className="underline" to={"/"}>TechRabbit</Link>
          </p>

          {/* Privacy & Terms Links */}
          <div className="flex space-x-6 mt-2 lg:mt-0 text-[#434343] font-medium">
            <Link to="/privacy-policy" className="underline">
              Privacy Policy
            </Link>
            <Link to="/terms-conditions" className="underline">
              Terms & Conditions
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default MobileDevFooter;
