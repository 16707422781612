// import { ArrowForward } from "@mui/icons-material"
import Blog1 from "../../assets/images/Blogs/another.png"
import "./BlogSection.css"
import Blog2 from "../../assets/images/Blogs/another2.png"
import Blog3 from "../../assets/images/Blogs/another3.png"
import { motion } from "framer-motion";
import arrow_icon from "../../assets/images/arrow_icon.svg"
import { Link } from "react-router-dom";
import blogData from "../../data/blogData.json"
import blogWeb from "../../data/blogWeb.json"

import blogAI from "../../data/blogAI.json"


export default function BlogSection() {
  const blogs = [
    {
      image:
        Blog1,
      category: "Mobile App",
      date: "January 4, 2025",
      title: "Custom Mobile App: Why & How?",
      description: "Discover why your business needs a custom mobile app and how to build one efficiently.",
      slug: "custom-mobile-app-for-your-business-why-how-to-build-one",
    },
    {
      image:
        Blog3,
      category: "AI & Business",
      date: "January 23, 2025",
      title: "AI & Automation: Boost Your Business",
      description: "Learn how AI and automation can streamline operations, enhance efficiency, and drive growth.",
      slug: "future-of-ecom-how-ai-and-automation-are-transforming-online-store",
    },
    {
      image:
        Blog2,
      category: "Web Development",
      date: "February 10, 2025",
      title: "Web Apps: Powering Business Growth",
      description: "Discover how a custom web app can improve efficiency, enhance user experience, and drive business success.",
      slug: "why-custom-web-development-is-a-game-changer-for-your-business-in-2025",
    },
  ]

  return (
    <section className="pt-16 sm:pt-16 px-4 blog"   >
      <div className="max-w-7xl mx-auto">
        {/* Header */}
        <motion.div className="text-center mb-12 margin-b-20" initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true, amount: 0.2 }}
          transition={{ duration: 0.5 }}>
          <h2 className="text-3xl md:text-5xl font-medium text-[#212A40] mb-4 poppins lg-text">Our Blogs</h2>
          <p className="text-[#485E79] max-w-2xl text-lg mx-auto">
            Stay updated with the latest trends, expert tips, and tech breakthroughs!

          </p>



        </motion.div>

        {/* <div className="relative text-center mb-12">
            <h2 className="text-3xl md:text-5xl font-medium text-[#212A40] mb-4 poppins lg-text">Our Blog</h2>
            <p className="text-[#485E79] max-w-2xl text-lg mx-auto">
              Lorem ipsum dolor sit amet consectetur, adipisicing elit. A, ab nemo! Expedita! Lorem ipsum dolor sit amet
            </p>

            <div className="absolute right-0 top-1/2 transform -translate-y-1/2"> 
              <button className="text-white px-6 py-3 rounded-full font-semibold consultation-btn">
                View All Blogs
              </button>
            </div>
          </div> */}

        {/* <div className="text-center">


          <button
            className=" text-white px-6 py-3 rounded-full  font-semibold consultation-btn"

          >
            View All Blogs
          </button>
        </div> */}

        {/* Blog Grid */}

        <motion.div className="relative" >

          <motion.div className="absolute top-[-4.0625rem] right-0" initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true, amount: 0.2 }}
            transition={{ duration: 0.5 }}>
            <Link to="Blogs">
              <button className="text-[#3D3D3D] px-6 py-3 rounded-full font-semibold bg-[#EFF1F4] view-all transition duration-200 ease-in-out hover:bg-[#D9DEE3] hover:text-[#2C2C2C]">
                View All Blogs
              </button>
            </Link>
          </motion.div>



          {/* <motion.div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-12" > */}
          <motion.div
            className={`grid gap-8 mb-12 grid-cols-1 md:grid-cols-2 lg:grid-cols-3
              }`}>


            {blogs.map((blog, index) => (
              <Link  to={ `/blogs/${blog.slug}` }>


                {/* <motion.article
                  key={index}
                  className="relative rounded-xl overflow-hidden bg-gradient box-shadow-blog  cursor-pointer duration-300   hover:shadow-xl hover:scale-105 hover:bg-[#f9fafb] "
                  initial={{ opacity: 0, y: 50 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true, amount: 0.2 }}
                  transition={{ duration: 0.5 }}
                > */}

                <motion.article
                  key={index}
                  className={`relative rounded-xl overflow-hidden bg-gradient box-shadow-blog cursor-pointer duration-300 hover:shadow-xl hover:scale-105 hover:bg-[#f9fafb] ${blogs.length === 1 ? "max-w-[400px] w-full" : "" // Limit width when only 1 blog
                    }`}
                  initial={{ opacity: 0, y: 50 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true, amount: 0.2 }}
                  transition={{ duration: 0.5 }}
                >

                  <div className=" overflow-hidden p-1">
                    <img
                      src={blog.image || "/placeholder.svg"}
                      alt={blog.title}
                      className="w-full h-full object-cover "
                    />
                  </div>


                  <div className="p-6">
                    <div className="flex items-center mb-4 gap-2">
                      <span className="text-xs sm:text-sm font-medium text-[#3D3D3D] bg-[#F2F4F6] px-3 py-1 rounded-full border border-[#E1E4EB]">
                        {blog.category}
                      </span>
                      <span className="text-xs sm:text-sm font-medium text-[#3D3D3D] bg-[#F2F4F6] px-3 py-1 rounded-full border border-[#E1E4EB]">
                        {blog.date}
                      </span>
                    </div>

                    <h3 className="font-semibold text-xl mb-2 text-gray-900 line-clamp-2 inter">{blog.title}</h3>
                    <p className="text-gray-600 line-clamp-2 inter pr-8" >{blog.description}</p>
                  </div>

                  <div className="absolute bottom-6 right-6  p-2 rounded-full transition-all duration-300 ease-in-out cursor-pointer hover:shadow-xl hover:scale-125 hover:bg-[#f9fafb] box-shadow-blog ">
                  
                      <img src={arrow_icon} alt="arrow icon" className="w-3 h-3" />
               
                  </div>
                </motion.article>
              </Link>
            ))}
          </motion.div>





        </motion.div>


      </div>
    </section>
  )
}

