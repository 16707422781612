import React from "react";
import { useState, useEffect } from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer/Footer";
import CTMSection from "../../components/CTMSection/CTMSection";
import TestimonialsSection from "../../components/Testimonials/Testimonials";
import Banner from "../../components/Services/CustomProductDev/Banner";
import TechStackAlt from "../../components/TechStackAlt/TechStackAlt";
import "./MobileAppDev.css"
// import mobile_Dev from "../../assets/images/Services/MobileApp/mobile_dev.svg"
import mobile_Dev from "../../assets/images/Services/mobile-app.png"

import BreadCrumbService from "../../components/BreadCrumbService/BreadCrumbService";
import MobileBegin from "../../components/Services/MobileAppDev/MobileBegin";
import OurMobileAppDev from "../../components/Services/MobileAppDev/OurMobileAppDev";
import WhyChooseTR from "../../components/Services/MobileAppDev/WhyChooseTR";
import MobileAppProcess from "../../components/Services/MobileAppDev/MobileAppProcess";
import OtherServices from "../../components/Services/CustomProductDev/OtherServices";
import { TechMobile } from "../../data/techStackMobile";
import { motion } from "framer-motion";
import Loader from "../../components/Loader1/Loader";



function MobileAppDev() {

    
    const data = [
        {
          title: "Mobile App Development",
          description: "Create high-performance mobile apps with seamless design and functionality. We build innovative, user-friendly solutions for iOS and Android to elevate your business!",





          img: mobile_Dev, 
        },

    ]


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const [isOpen, setIsOpen] = useState(false)

    const [loading, setLoading] = useState(true); 


    useEffect(() => {
       const handleLoad = () => {
         setTimeout(() => setLoading(false), 1500); 
       };
     
       if (document.readyState === "complete") {
         setTimeout(() => setLoading(false), 1500);
       } else {
         window.addEventListener("load", handleLoad);
       }
     
       return () => window.removeEventListener("load", handleLoad);
     }, []);
     
 
     
     if (loading) {
       return <Loader />;
     }


    return (



        <div className="relative flex min-h-screen py-10 flex-col bg-white">

            <main className="flex-1 relative " style={{ zIndex: "999" }}>

                <div className="w-full relative">
                    <Navbar isOpen={isOpen} setIsOpen={setIsOpen} />




                </div>


                {/* <Banner /> */}
                <BreadCrumbService data={data}/>
                <MobileBegin/>
                <OurMobileAppDev/>






                <TechStackAlt technologies={TechMobile}/>
                <WhyChooseTR/>
                <MobileAppProcess/>
                <OtherServices currentService="Mobile App Development"/>

                <TestimonialsSection />


                <CTMSection />











                <Footer />


            </main>
        </div>


    );
}

export default MobileAppDev;
