import React from 'react'
import { motion } from 'framer-motion'
import "./WebDevComp.css"
import begin_img from "../../../assets/images/Services/AIDev/begin_img.png"
import react_img from "../../../assets/images/Services/WebApp/react.svg"
import angular_img from "../../../assets/images/Services/WebApp/angular.svg"
import nodejs_img from "../../../assets/images/Services/WebApp/nodejs.svg"
import { img } from 'framer-motion/client'
import cursor_img from "../../../assets/images/Services/WebApp/cursor.svg"
import custom_web_Dev from "../../../assets/images/Services/WebApp/ContentManage.png"
import starpi from "../../../assets/images/Services/WebApp/strapi.png"
import medula from "../../../assets/images/Services/WebApp/medula.png"
import correct_Arrow from "../../../assets/images/Services/WebApp/correct_arrow.png"



const KeyFrameWorks = [
    {


        text: "React.js  Dynamic, responsive user interfaces with fast load times.",
        img: react_img,
    },
    {

        text: `Angular A complete, scalable framework for enterprise-level apps.`,
        img: angular_img,
    },
    {

        text: "Node.js & Express Backend solutions for fast, scalable, and real-time applications.",
        img: nodejs_img,
    },

]


const ContentManageSec = () => {
    return (

        <section className="relative py-0 sm:py-16 px-4 ">


            <div className="max-w-7xl mx-auto">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center p-0 lg:p-4 framework">

                <motion.div
                        className="space-y-8 flex flex-col h-full relative group justify-center"
                        initial={{ opacity: 0, y: 50 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true, amount: 0.2 }}
                        transition={{ duration: 0.5 }}
                    >

                        <img src={custom_web_Dev} alt="" />



                    </motion.div>

                    <motion.div className="flex flex-col h-full justify-center" initial={{ opacity: 0, y: 50 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true, amount: 0.2 }}
                        transition={{ duration: 0.5 }}>
                        <div className="space-y-7 lg:space-y-7">

                            <span className="font-semibold text-[#111111]  px-3 py-2  gap-1  rounded-full border border-[#EBEBEB] bg-[white] text-sm flex" style={{ display: "inline-flex" }}>

                                {/* <img src={cursor_img} alt="" className="w-5 h-5" /> */}

                                <span >Get Things Done</span>

                            </span>


                            <h3 className="text-2xl md:text-4xl font-medium text-[#0C0C0C] lg-text">
                                Content <span className='text-[#25B57E]'>          Management  </span>   Systems (CMS)
                            </h3>


                            <p className=" text-[#666666] text-base" >
                                We specialize in CMS development, offering robust solutions for clients who need easy-to-manage websites. Whether you're looking for a simple blog or a full-featured website, our expertise in WordPress/Elementor and Webflow ensures that your site is easy to update and maintain, even without a technical background. If you’re building an online store, we create optimized e-commerce platforms using Shopify that are both user-friendly and scalable.
                            </p>

                            <div className="flex gap-2">
                                <span className=" text-[#111111] px-3 py-2  text-base inline-flex items-center gap-2">
                                               <img src={correct_Arrow} alt="" className="w-5 h-5" />
                                    <span>3K+ dswdw</span>
                                </span>

                                <span className=" text-[#111111] px-3 py-2  text-base inline-flex items-center gap-2">
                                <img src={correct_Arrow} alt="" className="w-5 h-5" />

                                    <span>wwdwd ws wa</span>
                                </span>
                            </div>











                        </div>
                    </motion.div>


                 

                </div>
            </div>
        </section>
    )
}

export default ContentManageSec
