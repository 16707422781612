import React from 'react'

import { motion } from "framer-motion";

import ig1 from "../../../assets/images/Services/MobileApp/step1.png"
import ig2 from "../../../assets/images/Services/MobileApp/step2.png"
import ig3 from "../../../assets/images/Services/MobileApp/step3.png"
import ig4 from "../../../assets/images/Services/MobileApp/step4.png"
import ig5 from "../../../assets/images/Services/MobileApp/step5.png"




const MobileAppProcess = () => {

    const technologies = [
        // Row 1
        {
            name: "Discovery & Planning",
            logo: ig1,
            description: "Understanding your goals and mapping out the app’s features."
        },


        {
            name: "Design",
            logo: ig2,
            description: "Creating a user-friendly design with your input at every stage."

        },




        {
            name: "Development",
            logo: ig3,
            description: "Building a scalable, bug-free app using the latest technologies."

        },


        {
            name: "Testing",
            logo: ig4,
            description: "   Rigorous testing to ensure performance, security, and usability."
        },



        {
            name: "    Deployment ",
            logo: ig2,
            description: " Publish the app on app stores, configure servers, and ensure a smooth rollout."

        },

        {
            name: "Support & Maintenance ",
            logo: ig5,
            description: "Regularly update the app with new features, bug fixes, and security patches."

        },

       


    ]
    return (

        <section className="relative py-0 sm:py-16 px-4 whyTR">


            <div className="max-w-7xl mx-auto">

                <motion.div className="text-center mb-12 max-w-5xl mx-auto" initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true, amount: 0.2 }}
                    transition={{ duration: 0.5 }}
                >
                    <h2 className="text-3xl md:text-5xl font-semibold text-[#020710] mb-4 poppins lg-text max-w-2xl mx-auto">
                    Our Proven Mobile <span className="text-[#24B574]">App Development</span>  Process  </h2>
                    <p className="text-[#485E79] max-w-2xl text-lg mx-auto">
                        Lorem ipsum dolor sit amet consectetur, adipisicing elit A, ab nemo! Expedita!Lorem ipsum dolor sit amet consectetur
                    </p>
                </motion.div>
















                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 justify-items-center " initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true, amount: 0.2 }}
                    transition={{ duration: 0.5 }}
                >

                    {/* relative rounded-xl overflow-hidden bg-gradient box-shadow-blog cursor-pointer  */}
                    {technologies.map((tech, index) => (
                        <motion.div
                            key={index}
                            className=" cursor-pointer   bg-white  rounded-3xl p-5  border border-[#F2F3F8] w-full"
                            initial={{ opacity: 0, y: 50 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            viewport={{ once: true, amount: 0.2 }}
                            transition={{ duration: 0.5 }}
                        >

                            <div className='py-4 '>

                                <p className="text-lg md:text-2xl text-[#171717] text-center font-medium">{tech.name}</p>
                                <p className="text-center  text-[#7A8089] my-3" style={{fontSize:"15px"}}>{tech.description}</p>

                            </div>
                            <div className="overflow-hidden p-1 ">
                                <img
                                    src={tech.logo || "/placeholder.svg"}
                                    alt={`${tech.name} logo`}
                                    // className="object-contain group-hover:filter group-hover:brightness-0 group-hover:invert"
                                    className={`w-full h-full object-cover 
                                
                                
                                
                               `}
                                />
                            </div>

                        </motion.div>
                    ))}


                </div>





            </div>
        </section>
    )
}

export default MobileAppProcess
