import { Timeline, Assessment, Dashboard, Extension, ContactMail } from "@mui/icons-material"
import dashboards from "../../assets/images/dashboards.png"
import analytics from "../../assets/images/analkytics.png"
import conatct_management from "../../assets/images/conatct_management.png"
import integrations from "../../assets/images/integrations.png"
import workflow from "../../assets/images/workflow.png"
import map from "../../assets/images/map.png"

import notification_svg from "../../assets/images/notification_img.svg"
import logistics from "../../assets/images/Workflow/Logistics.svg"
import "./Workflow.css"
import { motion } from "framer-motion";
import { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import ui_ux from "../../assets/images/Workflow/ui-ux.svg"
import prototype from "../../assets/images/Workflow/prototype.svg"
import dev from "../../assets/images/Workflow/dev.svg"
import quality from "../../assets/images/Workflow/quality.svg"
import deployment from "../../assets/images/Workflow/deplyment.svg"
import support from "../../assets/images/Workflow/support.svg"
import ui_ux_img from "../../assets/images/Workflow/ui_ux_15.jpg"
import ui_ux_svg from "../../assets/images/Workflow/wi_ux_svg.svg"
import svg2 from "../../assets/images/Workflow/svg2.svg"
import svg3 from "../../assets/images/Workflow/svg3.svg"
import svg4 from "../../assets/images/Workflow/svg4.svg"
import svg5 from "../../assets/images/Workflow/svg5.svg"
import svg6 from "../../assets/images/Workflow/svg6.svg"
import svg7 from "../../assets/images/Workflow/svg7.svg"

// import svg7 from "../../assets/images/Workflow/svg2.svg"
// import svg8 from "../../assets/images/Workflow/svg2.svg"

import land1 from "../../assets/images/landing_1.webp"
import land2 from "../../assets/images/l2.webp"
import land3 from "../../assets/images/l3.webp"
import land4 from "../../assets/images/l4.webp"
import land5 from "../../assets/images/l5.webp"
import land6 from "../../assets/images/l6.webp"
import land7 from "../../assets/images/l7.webp"




export default function WorkflowSection() {
  const notifications = [
    {
      avatar:
        "https://hebbkx1anhila5yf.public.blob.vercel-storage.com/Screenshot%202025-01-27%20114921-EtEOdQfhq4DvArtUaoFKCnpX8DtB5Y.png",
      title: "Congratulations!",
      message: "Your profile has been verified",
      time: "23 min ago",
    },
    {
      avatar:
        "https://hebbkx1anhila5yf.public.blob.vercel-storage.com/Screenshot%202025-01-27%20114921-EtEOdQfhq4DvArtUaoFKCnpX8DtB5Y.png",
      title: "Ronald Richards",
      message: "Added to VIP network activated",
      time: "27 min ago",
    },
    {
      avatar:
        "https://hebbkx1anhila5yf.public.blob.vercel-storage.com/Screenshot%202025-01-27%20114921-EtEOdQfhq4DvArtUaoFKCnpX8DtB5Y.png",
      title: "Annie McCoy",
      message: "Added you to promotions",
      time: "31 min ago",
    },
    {
      avatar:
        "https://hebbkx1anhila5yf.public.blob.vercel-storage.com/Screenshot%202025-01-27%20114921-EtEOdQfhq4DvArtUaoFKCnpX8DtB5Y.png",
      title: "Antoine Black",
      message: "Added you to promotions",
      time: "33 min ago",
    },
    {
      avatar:
        "https://hebbkx1anhila5yf.public.blob.vercel-storage.com/Screenshot%202025-01-27%20114921-EtEOdQfhq4DvArtUaoFKCnpX8DtB5Y.png",
      title: "Darlene Robertson",
      message: "Added you to meeting",
      time: "35 min ago",
    },
  ]

  const features = [
    {
      image: logistics,
      title: "Requirement Gathering",
      description: " Understanding your vision, needs, and goals to design the perfect solution.",
      // main_img: svg7
      main_img: land1

    },
    {
      image: ui_ux,
      title: "UI/UX Design",
      description: "Creating intuitive, stunning, and user-friendly designs that engage and inspire.",
      main_img: land2
    },
    {
      image: prototype,
      title: "Prototype",
      description: "Bringing ideas to life with interactive models for a real-time preview.",
      main_img: land3
    },
    {
      image: dev,
      title: "Development",
      description: "Turning blueprints into reality with advanced technology and flawless coding.",
      main_img: land4
    },
    {
      image: quality,
      title: "Quality Assurance",
      description: "Rigorously testing every detail to ensure peak performance and security.",
      main_img: land5
    },

    {
      image: deployment,
      title: "Deployment",
      description: "Seamlessly launching your product for a smooth and impactful go-live.",
      main_img: land6
    },

    {
      image: support,
      title: "Support & Maintenance",
      description: "Continuous enhancements and round-the-clock support to keep you ahead.",
      main_img: land7
    },
  ]

  const [selectedFeature, setSelectedFeature] = useState(features[0]); // Default to the first feature
  let index = 0;
  // const handleFeatureClick = (feature) => {
  //   setSelectedFeature(feature); // Update the selected feature
  // };

  useEffect(() => {
    const interval = setInterval(() => {
        index = (index + 1) % features.length;
        setSelectedFeature(features[index]);
    }, 3000); // Change every 3 seconds

    return () => clearInterval(interval); // Cleanup on unmount
}, []);

  return (
    <section className="py-0 sm:py-16 px-4">   
      
       <motion.div className="max-w-7xl mx-auto relative  workflow" initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true, amount: 0.2 }}
      transition={{ duration: 0.5 }} >

      <h2 className=" pb-8 text-left text-3xl   md:text-4xl lg:text-5xl md-padding lg-text-workflow inter" style={{ color: "#111111", fontWeight: "600" }}>
      From Idea to Impact – A Seamless Journey of Innovation.

      </h2>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-start  ">
        {/* Left Column */}
        <div className="space-y-8 flex flex-col h-full order-last lg:order-first">
          {/* <h1 className="text-4xl md:text-5xl font-bold leading-tight">
            Designed to simplify your workflow and maximize efficiency
          </h1> */}

          <div className="space-y-3">
            {/* {features.map((feature, index) => (
              <div key={index} className="flex items-start space-x-4">
          
                <div className="p-2 ">
                  <img src={feature.image} alt={feature.title} className="w-8 h-8 object-contain" />
                </div>
                <div>
                  <h3 className="font-600 text-xl inter">{feature.title}</h3>
                  {feature.description && <p className="text-[#666666] mt-1 inter">{feature.description}</p>}
                </div>
              </div>
            ))}  */}

            {features.map((feature, index) => (
              <div
                key={index}
                // onClick={() => handleFeatureClick(feature)}
                className={`flex items-start space-x-4 rounded-lg cursor-pointer transition-all duration-300 ease-in-out transform  ${selectedFeature?.image === feature?.image && "bg-[#EDF2FA] shadow-[0px_0px_24px_rgba(0,0,0,0.05)] scale-105 p-4 "}`}
              >
                <div className="p-2">
                  <img src={feature.image} alt={feature.title} className="w-10 h-10 object-contain" />
                </div>
                <div>
                  <h3 className="text-lg font-600 md:text-xl inter">{feature.title}</h3>
                  {selectedFeature?.image === feature?.image && <p className="text-[#666666] mt-1 inter text-sm md:text-base">{feature.description}</p>}
                </div>
              </div>
            ))}


          </div>
          {/* 
          <button className="bg-blue-500 text-white px-6 py-2 rounded-lg hover:bg-blue-600 transition-colors">
            Learn more features
          </button> */}
          <button className="relative text-black px-6 py-3 rounded-full text-base font-medium mt-8 inter max-w-fit border border-[#E6E9EE] overflow-hidden transition-colors duration-300 ease-out before:absolute before:inset-0 before:bg-[#2D2D2D] before:origin-bottom-left before:scale-x-0 before:scale-y-0 before:rounded-full before:transition-transform before:duration-500 before:ease-out before:delay-75 hover:before:scale-100 hover:text-white">
          <Link to="/services">  <span className="relative z-10">Explore Services</span></Link>
          </button>





        </div>

        {/* Right Column - Notifications Panel */}
        {/* <div className="bg-white rounded-xl shadow-lg p-6 max-w-md mx-auto lg:ml-auto">
          <div className="flex justify-between items-center mb-6">
            <h3 className="font-semibold">Notifications</h3>
            <span className="bg-blue-100 text-blue-800 text-xs px-2 py-1 rounded-full">05</span>
          </div>

          <div className="space-y-4">
            {notifications.map((notification, index) => (
              <div key={index} className="flex items-start space-x-3">
                <img src={notification.avatar || "/placeholder.svg"} alt="" className="w-8 h-8 rounded-full" />
                <div className="flex-1">
                  <div className="flex justify-between items-start">
                    <h4 className="font-medium text-sm">{notification.title}</h4>
                    <span className="text-xs text-gray-500">{notification.time}</span>
                  </div>
                  <p className="text-sm text-gray-600">{notification.message}</p>
                </div>
              </div>
            ))}

            <button className="w-full text-center text-blue-500 text-sm py-2 hover:text-blue-600">
              See All Notifications
            </button>
          </div>
        </div> */}

        {/* <div className="flex-1 overflow-hidden rounded-3xl bg-[#F7F7F7] p-8 md:p-12">

          <div className="">
            <div className="relative">


              <img
                src={notification_svg}
                alt="World Map"
                className=""
              />
            </div>
          </div>
        </div> */}

        <div className="flex flex-col order-first lg:order-last">


          <div className="flex-1 overflow-hidden rounded-3xl bg-[#F7F7F7] p-5 md:p-5 ">
            <div className="relative  flex justify-center items-center ">
              <img
                src={selectedFeature?.main_img}
                alt={selectedFeature?.title}
                className="object-cover w-full h-full  max-w-full rounded-2xl max-h-[6  00px]"
              />
            </div>
          </div>

        </div>
      </div>
    </motion.div>

    </section>

  )
}