import React from 'react'

import { motion } from "framer-motion";
import img1 from "../../../assets/images/Services/UI-UX/img1.png"
import img2 from "../../../assets/images/Services/UI-UX/img2.png"
import img3 from "../../../assets/images/Services/UI-UX/img3.png"



const UIBegin = () => {
    return (
        <section className='relative  py-0 md:py-16 px-4 services-offer'>

            <div className='max-w-7xl mx-auto'>

                <motion.div className="grid lg:grid-cols-2 gap-8" initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true, amount: 0.2 }}


                    transition={{ duration: 0.5 }}>


                    <div className="flex flex-col gap-8">


                        <div className="flex flex-col md:flex-row items-center bg-[#EAEAEA33] border-2 border-[#F2F3F8] rounded-2xl p-6 gap-6 h-full">
                            <div className="md:w-1/2 text-center md:text-left">
                                    <h3 className="text-xl md:text-3xl font-medium mb-4 text-[#171717]">User-Centric Design for Maximum Engagement</h3>
                                <p className="text-sm sm:text-base text-[#171717]">We prioritize usability and accessibility to create designs that users love to interact with.</p>
                            </div>
                            <div className="md:w-1/2 flex justify-center">
                                <img src={img1} alt="First Card" className="rounded-lg" />
                            </div>
                        </div>


                        <div className="flex flex-col md:flex-row-reverse items-center bg-[#EAEAEA33] border-2 border-[#F2F3F8] rounded-2xl p-6 gap-6 h-full" >
                            <div className="md:w-1/2 text-center md:text-left">
                                    <h3 className="text-xl md:text-3xl font-medium mb-4 text-[#171717]">Enhancing Conversions with Data-Driven UI</h3>
                                <p className="text-sm sm:text-base text-[#171717]">Lorem ipsum dolor sit amet  ipsum consectetur, adipisicing consectetur, adipisicing </p>
                            </div>
                            <div className="md:w-1/2 flex justify-center">
                                <img src={img2} alt="Second Card" className="rounded-lg" />
                            </div>
                        </div>

                    </div>


                    <div className="bg-[#EAEAEA33] border-2 border-[#F2F3F8] rounded-2xl  p-6 flex flex-col items-center lg:items-start text-center lg:text-start h-full">
                        <img src={img3} alt="Main Card" className="rounded-lg mb-10 flex self-center" />
                        <h3 className="text-xl sm:text-3xl font-medium mb-2 lg-text text-[#171717]">Designing for Scalability & Future Growth</h3>
                            <p className="text-[#171717] text-sm sm:text-base">Our design approach guarantees flexibility, enabling your digital products to scale smoothly as your business evolves. We create adaptable solutions that grow with your needs, ensuring long-term success!</p>
                    </div>

                </motion.div>


            </div>
        </section>
    )
}

export default UIBegin
