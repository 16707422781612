import React from 'react'
import "./AIServices.css"
import { useState } from 'react'
import img1 from "../../../assets/images/Services/AIDev/healthcare.svg"
import img2 from "../../../assets/images/Services/AIDev/finance.svg"
import img3 from "../../../assets/images/Services/AIDev/manufacture.svg"
import img4 from "../../../assets/images/Services/AIDev/media.svg"
import img5 from "../../../assets/images/Services/AIDev/travel.svg"
import img6 from "../../../assets/images/Services/AIDev/leagl.svg"


import logistics from "../../../assets/images/Services/AIDev/logistics.svg"
import food from "../../../assets/images/Services/AIDev/food.svg"
import real_estate from "../../../assets/images/Services/AIDev/real_estate.svg"
import retail_ecom_img from "../../../assets/images/Services/AIDev/retail_ecom.svg"
import startup from "../../../assets/images/Services/AIDev/startup.svg"
import quick_com from "../../../assets/images/Services/AIDev/quick_com.svg"






import healthcare from "../../../assets/images/Services/AIDev/healthcare.svg"
import { motion } from "framer-motion";
import retail_ecom from "../../../assets/images/Services/retail_ecom.svg"
const IndustriesWeServe = () => {
    const [showAll, setShowAll] = useState(false);
    const technologies = [
        // Row 1
        {
            name: "Healthcare",
            logo: img1,
            description: "Predictive analytics for patient care, medical imaging analysis, personalized treatment plans, drug discovery, healthcare chatbots, and virtual assistants."
        },
        {
            name: "Finance and Banking",
            logo: img2,
            description: "Fraud detection, risk analysis, automated trading, customer support chatbots, personalized financial advising, and predictive analytics."

        },

        {
            name: "Manufacturing",
            logo: img3,
            description: "Predictive maintenance, production optimization, quality control, supply chain management, robotics, and automation of repetitive tasks."

        },
        {
            name: "Media and Advertising",
            logo: img4,
            description: "Targeted advertising, customer segmentation, content generation, social media monitoring, and personalized ad recommendations."

        },

        {
            name: "Travel",
            logo: img5,
            description: "Personalized travel recommendations, smart booking systems, virtual assistants, dynamic pricing strategies, and customer sentiment analysis."

        },

        {
            name: "Legal Services",
            logo: img6,
            description: "Contract review and analysis, legal research automation, e-discovery, predictive analytics for case outcomes, and document management."

        },

        {
            name: "Logistics & Distribution",
            logo: logistics,
            description: "We provide efficient supply chain solutions, ensuring timely delivery and optimized logistics processes to meet customer demand."
        },
        {
            name: "Retail & E-commerce",
            logo: retail_ecom_img,
            description: "Our services support retailers and online businesses with seamless platforms, ensuring smooth transactions, inventory management, and customer experience."

        },

        {
            name: "Food & Restaurant",
            logo: food,
            description: "We assist food businesses with delivery solutions, ordering systems, and inventory management, streamlining their operations for better customer service."

        },
        {
            name: "Real Estate Market",
            logo: real_estate,
            description: "Our solutions help real estate businesses with property listings, customer engagement, and transaction processes, enhancing their market presence."

        },

        {
            name: "Startup Community",
            logo: startup,
            description: "We support startups with innovative solutions, helping them scale by offering cost-effective tools, resources, and a network for growth."

        },

        {
            name: "Quick Commerce",
            logo: quick_com,
            description: "Our solutions cater to fast-paced delivery models, optimizing inventory and supply chains for on-demand service, ensuring rapid delivery to customers."

        },

    ]


    const displayedIndustries = showAll ? technologies : technologies.slice(0, 6);

    return (
        <section className="relative sm:pt-16 sm:pb-16  pt-16 pb-0 px-4 about-comp">


            <div className="max-w-7xl mx-auto">

                <motion.div className="text-center mb-12" initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true, amount: 0.2 }}
                    transition={{ duration: 0.5 }}
                >
                    <h2 className="text-3xl md:text-5xl font-medium text-[#212A40] mb-4 poppins lg-text">  <span className="text-[#24B574]">  Industries  </span> We Serve</h2>
                    <p className="text-[#485E79] max-w-2xl text-lg mx-auto">
                        Lorem ipsum dolor sit amet consectetur, adipisicing elit A, ab nemo! Expedita!Lorem ipsum dolor sit amet consectetur
                    </p>
                </motion.div>

                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3  gap-8 justify-items-center " initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true, amount: 0.2 }}
                    transition={{ duration: 0.5 }}
                >
                    {displayedIndustries?.map((tech, index) => (
                        <motion.div
                            key={index}
                            className="flex flex-col cursor-pointer items-start  group bg-white  rounded-3xl p-5 w-full border border-[#E6E9EE]"
                            initial={{ opacity: 0, y: 50 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            viewport={{ once: true, amount: 0.2 }}
                            transition={{ duration: 0.5 }}
                        >
                            <div className="mb-3 flex  items-center flex-row space-x-3">
                                <img
                                    src={tech?.logo}
                                    alt={`${tech.name} logo`}
                                    className="object-contain w-9 h-9"
                                />
                                <span className="text-lg text-[#262626] font-medium">{tech.name}</span>
                            </div>
                            <p className="  text-[#666666] mt-2 px-3" style={{ fontSize: "15.41px", lineHeight: "23.11px" }}>{tech.description}</p>

                        </motion.div>
                    ))}


                </div>





                {technologies?.length > 6 && (

                    <button className="block mx-auto  relative text-black px-6 py-3 rounded-full text-base font-medium mt-8 inter max-w-fit border border-[#E6E9EE] overflow-hidden transition-colors duration-300 ease-out before:absolute before:inset-0 before:bg-[#2D2D2D] before:origin-bottom-left before:scale-x-0 before:scale-y-0 before:rounded-full before:transition-transform before:duration-500 before:ease-out before:delay-75 hover:before:scale-100 hover:text-white"
                        onClick={() => setShowAll(!showAll)}
                    >
                        <span className="relative z-10">{showAll ? 'Show Less' : 'View All'}</span>
                    </button>

                )}





            </div>
        </section>
    )
}

export default IndustriesWeServe














