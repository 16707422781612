import React from "react";
import { useState, useEffect } from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer/Footer";
import CTMSection from "../../components/CTMSection/CTMSection";
import TestimonialsSection from "../../components/Testimonials/Testimonials";
import Banner from "../../components/Services/CustomProductDev/Banner";
import TechStackAlt from "../../components/TechStackAlt/TechStackAlt";
import "./WebAppDev.css"

import web_app_img from "../../assets/images/Services/WebApp/WebDev.svg"
import BreadCrumbService from "../../components/BreadCrumbService/BreadCrumbService";
import FrameWorks from "../../components/Services/WebDev/FrameWorks";
import CmsSection from "../../components/Services/WebDev/CmsSection";
import StatsSection from "../../components/Services/WebDev/StatsSection";
import ContentManageSec from "../../components/Services/WebDev/ContentManageSec";
import CustomCTM from "../../components/Services/CustomProductDev/CustomCTM";
import OtherServices from "../../components/Services/CustomProductDev/OtherServices";
import SpecialitySec from "../../components/Services/WebDev/SpecialitySec";
import WebDevProcess from "../../components/Services/WebDev/WebDevProcess";

import { techWeb } from "../../data/techStackWeb";
import Loader from "../../components/Loader1/Loader";

function WebAppDev() {

    const data = [
        {
            title: "Web App Development",
            description: "Build powerful, scalable web applications tailored to your business. Our expert team ensures seamless functionality, performance, and user experience. From startups to enterprises, we create solutions that drive success!",
            img: web_app_img,
        },

    ]

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [isOpen, setIsOpen] = useState(false)

    const [loading, setLoading] = useState(true); 


    useEffect(() => {
       const handleLoad = () => {
         setTimeout(() => setLoading(false), 1500); 
       };
     
       if (document.readyState === "complete") {
         setTimeout(() => setLoading(false), 1500);
       } else {
         window.addEventListener("load", handleLoad);
       }
     
       return () => window.removeEventListener("load", handleLoad);
     }, []);
     
 
     
     if (loading) {
       return <Loader />;
     }


    return (



        <div className="relative flex min-h-screen py-10 flex-col bg-white">

            <main className="flex-1 relative " style={{ zIndex: "999" }}>

                <div className="w-full relative">
                    <Navbar isOpen={isOpen} setIsOpen={setIsOpen} />




                </div>


                {/* <Banner/> */}
                <BreadCrumbService data={data} />
                <FrameWorks/>
                <CmsSection/>

                <ContentManageSec/>
                <StatsSection/>




                <TechStackAlt technologies={techWeb} name="web"/>
                <SpecialitySec/>
                <CustomCTM/>
                <WebDevProcess/>
                <OtherServices currentService="Web App Development"/>

                <TestimonialsSection />


                <CTMSection />











                <Footer />


            </main>
        </div>


    );
}

export default WebAppDev;
