import { ArrowForward } from "@mui/icons-material"
import Blog1 from "../../assets/images/Blogs/another.png"
import Blog2 from "../../assets/images/Blogs/another2.png"
import Blog3 from "../../assets/images/Blogs/another3.png"
import { motion } from "framer-motion";
import "./Allblog.css";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import filter from "../../assets/images/BestWork/filter.svg"
import search_icon from "../../assets/images/search_icon.svg"
import { useState, useEffect, useRef } from "react";
// import { motion } from "framer-motion";
import arrow_icon from "../../assets/images/arrow_icon.svg"
import { Pagination } from "@mui/material";
import { Link } from "react-router-dom";
export default function AllBlog() {
    const blogs = [
        {
            image:
                Blog1,
            category: "Mobile App",
            date: "January 4, 2025",
            title: "Custom Mobile App: Why & How?",
            description: "Discover why your business needs a custom mobile app and how to build one efficiently.",
            slug: "custom-mobile-app-for-your-business-why-how-to-build-one",
        },
        {
            image:
                Blog3,
            category: "AI & Business",
            date: "January 23, 2025",
            title: "AI & Automation: Boost Your Business",
            description: "Learn how AI and automation can streamline operations, enhance efficiency, and drive growth.",
            slug: "future-of-ecom-how-ai-and-automation-are-transforming-online-store",
        },
        {
            image:
                Blog2,
            category: "Web Development",
            date: "February 10, 2025",
            title: "Web Apps: Powering Business Growth",
            description: "Discover how a custom web app can improve efficiency, enhance user experience, and drive business success.",
            slug: "why-custom-web-development-is-a-game-changer-for-your-business-in-2025",
        },

  

        // {
        //     image:
        //         Blog1,
        //     category: "Education",
        //     date: "Nov 7, 2024",
        //     title: "Lorem Ipsum Dolor Sit Amet Consectetur,",
        //     description: "Lorem ipsum dolor sit amet consectetur.",
        // },
        // {
        //     image:
        //         Blog2,
        //     category: "Productivity",
        //     date: "Aug 8, 2024",
        //     title: "Lorem Ipsum Dolor Sit Amet Consectetur,",
        //     description: "Lorem ipsum dolor sit amet consectetur.",
        // },
        // {
        //     image:
        //         Blog3,
        //     category: "Productivity",
        //     date: "Jul 31, 2024",
        //     title: "Lorem Ipsum Dolor Sit Amet Consectetur,",
        //     description: "Lorem ipsum dolor sit amet consectetur.",
        // },

        // {
        //     image:
        //         Blog3,
        //     category: "Productivity",
        //     date: "Jul 31, 2024",
        //     title: "Lorem Ipsum Dolor Sit Amet Consectetur,",
        //     description: "Lorem ipsum dolor sit amet consectetur.",
        // },


        // {
        //     image:
        //         Blog3,
        //     category: "Productivity",
        //     date: "Jul 31, 2024",
        //     title: "Lorem Ipsum Dolor Sit Amet Consectetur,",
        //     description: "Lorem ipsum dolor sit amet consectetur.",
        // },


        // {
        //     image:
        //         Blog3,
        //     category: "Productivity",
        //     date: "Jul 31, 2024",
        //     title: "Lorem Ipsum Dolor Sit Amet Consectetur,",
        //     description: "Lorem ipsum dolor sit amet consectetur.",
        // },
    ]

    const [searchQuery, setSearchQuery] = useState(""); // Search input state
    const [isOpen, setIsOpen] = useState(false)
    // const [selected, setSelected] = useState("All blogs")

    const [selectedCategory, setSelectedCategory] = useState("All"); // Default category filter

    const categories = ["All", "Mobile App", "AI & Business", "Web Development"]


    // const [selected, setSelected] = useState("All");
    // const [isOpen, setIsOpen] = useState(false);
    const [page, setPage] = useState(1);
    const blogsPerPage = 6;

    // const handlePageChange = (event, value) => {
    //     setPage(value);
    // };

    const componentRef = useRef(null);

    const handlePageChange = (event, value) => {
        setPage(value);

        // Scroll to the component
        componentRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
    };

    // const paginatedBlogs = blogs.slice((page - 1) * blogsPerPage, page * blogsPerPage);
    const filteredBlogs = blogs.filter((blog) => {
        return (
            (selectedCategory === "All" || blog.category === selectedCategory) &&
            (blog.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
                blog.description.toLowerCase().includes(searchQuery.toLowerCase()))
        );
    });


    const paginatedBlogs = filteredBlogs.slice((page - 1) * blogsPerPage, page * blogsPerPage);






    return (
        <section className=" pb-16 px-4 all_blog " ref={componentRef}  >
            <div className="max-w-7xl mx-auto">





                <div className="relative bg-white px-6 sm:px-8 md:px-10  py-2 sm:py-3 md:py-4  bx-sd min-h-[300px]" style={{ borderRadius: "32px" }}>

                    <motion.div className="w-full py-5 inter"  >
                        <div className="flex flex-col sm:flex-row items-start sm:items-center gap-4 sm:gap-5 justify-between">

                            <div className="relative w-full sm:w-1/3">
                                <input
                                    type="text"
                                    placeholder="Search for Blogs"
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                    className="w-full pl-12 pr-4 py-3 bg-white border border-[#E1E4EB] text-sm text-[#818181] rounded-full focus:outline-none focus:ring-2 focus:ring-[#4A90E2] inter placeholder-[#666666]"
                                />
                                <img
                                    src={search_icon}
                                    alt="Search Icon"
                                    className="absolute left-4 top-1/2 transform -translate-y-1/2 w-5 h-5"
                                />
                            </div>

                            <div className="relative w-full sm:w-auto flex flex-col sm:flex-row items-start sm:items-center gap-3" initial={{ opacity: 0, y: 50 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                viewport={{ once: true, amount: 0.2 }}
                                transition={{ duration: 0.5 }}>
                                {/* Filter Category Label */}
                                <span className="text-base font-medium text-[#8C8C8C] ">{`Filter Category`}</span>

                                <button
                                    onClick={() => setIsOpen(!isOpen)}
                                    className="w-full sm:w-auto flex items-center justify-between gap-2 px-4 py-2 bg-[#F4F5F6] hover:bg-gray-100 rounded-full inter border border-[#DCDCDC] text-sm text-[#818181]"
                                >
                                    <img src={filter} alt="Filter Icon" className="w-5 h-5" />
                                    <span>{selectedCategory}</span>
                                    <KeyboardArrowDownIcon className={`w-5 h-5 transition-transform ${isOpen ? "rotate-180" : ""}`} />
                                </button>

                                {isOpen && (
                                    <div className="absolute right-0 top-full mt-2 w-full sm:w-48 bg-white rounded-lg shadow-lg border border-gray-100 z-10">
                                        {categories.map((category) => (
                                            <button
                                                key={category}
                                                onClick={() => {
                                                    setSelectedCategory(category);
                                                    setIsOpen(false);
                                                }}
                                                className="w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-50 first:rounded-t-lg last:rounded-b-lg"
                                            >
                                                {category}
                                            </button>
                                        ))}
                                    </div>
                                )}
                            </div>


                        </div>
                    </motion.div>



                    {/* <motion.div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-12" >
                        {paginatedBlogs.length > 0 ? (
                            paginatedBlogs.map((blog, index) => (


                                <motion.article key={index} className="rounded-xl overflow-hidden transition-all duration-300 ease-in-out bg-gradient box-shadow-blog cursor-pointer hover:shadow-xl hover:scale-105 hover:bg-[#f9fafb] mt-6 relative" initial={{ opacity: 0, y: 50 }}
                                    whileInView={{ opacity: 1, y: 0 }}
                                    viewport={{ once: true, amount: 0.2 }}
                                    transition={{ duration: 0.5 }}>

                                    <Link to={`/blogs/${blog.slug}`} className="block">
                                        <div className="overflow-hidden p-1">
                                            <img src={blog.image || "/placeholder.svg"} alt={blog.title} className="w-full h-full object-cover" />
                                        </div>
                                        <div className="p-6">
                                            <div className="flex items-center mb-4 gap-2">
                                                <span className="text-xs sm:text-sm font-medium text-[#3D3D3D] bg-[#F2F4F6] px-3 py-1 rounded-full border border-[#E1E4EB]">
                                                    {blog.category}
                                                </span>
                                                <span className="text-xs sm:text-sm font-medium text-[#3D3D3D] bg-[#F2F4F6] px-3 py-1 rounded-full border border-[#E1E4EB]">
                                                    {blog.date}
                                                </span>
                                            </div>
                                            <h3 className="font-semibold text-xl mb-2 text-gray-900 line-clamp-2 inter">{blog.title}</h3>
                                            <p className="text-gray-600 line-clamp-2 inter pr-8">{blog.description}</p>
                                        </div>
                                  
                                    </Link> 
                                </motion.article>

                            ))
                        ) : (
                            <p className="text-center text-gray-500 w-full mx-auto">No blogs found.</p>
                        )}
                    </motion.div> */}



                    {paginatedBlogs.length > 0 ? (
                        <motion.div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-12">
                            {paginatedBlogs.map((blog, index) => (
                                <motion.article
                                    key={index}
                                    className="rounded-xl overflow-hidden transition-all duration-300 ease-in-out bg-gradient box-shadow-blog cursor-pointer hover:shadow-xl hover:scale-105 hover:bg-[#f9fafb] mt-6 relative"
                                    initial={{ opacity: 0, y: 50 }}
                                    whileInView={{ opacity: 1, y: 0 }}
                                    viewport={{ once: true, amount: 0.2 }}
                                    transition={{ duration: 0.5 }}
                                >
                                    <Link to={`/blogs/${blog.slug}`} className="block">
                                        <div className="overflow-hidden p-1">
                                            <img src={blog.image || "/placeholder.svg"} alt={blog.title} className="w-full h-full object-cover" />
                                        </div>
                                        <div className="p-6">
                                            <div className="flex items-center mb-4 gap-2">
                                                <span className="text-xs sm:text-sm font-medium text-[#3D3D3D] bg-[#F2F4F6] px-3 py-1 rounded-full border border-[#E1E4EB]">
                                                    {blog.category}
                                                </span>
                                                <span className="text-xs sm:text-sm font-medium text-[#3D3D3D] bg-[#F2F4F6] px-3 py-1 rounded-full border border-[#E1E4EB]">
                                                    {blog.date}
                                                </span>
                                            </div>
                                            <h3 className="font-semibold text-xl mb-2 text-gray-900 line-clamp-2 inter">{blog.title}</h3>
                                            <p className="text-gray-600 line-clamp-2 inter pr-8">{blog.description}</p>
                                        </div>

                                              {/* 
                                    <div className="absolute bottom-6 right-6  p-2 rounded-full transition-all duration-300 ease-in-out cursor-pointer hover:shadow-xl hover:scale-125 hover:bg-[#f9fafb] box-shadow-blog ">
                                        <img src={arrow_icon} alt="arrow icon" className="w-2.5 h-2.5" />
                                    </div> */}
                                    </Link>
                                </motion.article>
                            ))}
                        </motion.div>
                    ) : (
                        // Display "No blogs found" outside the grid
                        <p className="text-center text-gray-500 w-full mx-auto mt-6">No blogs found.</p>
                    )}











                    {blogs?.length > 6 &&



                        <div className="flex justify-center my-6 inter">
                            <Pagination
                                count={Math.ceil(blogs.length / blogsPerPage)}
                                page={page}
                                onChange={handlePageChange}
                                variant="outlined"
                                shape="rounded"
                                className="inter"
                                sx={{

                                    fontFamily: 'Inter, sans-serif',




                                    "& .MuiPaginationItem-root": {
                                        fontFamily: "Poppins, sans-serif",
                                    },

                                    "& .Mui-selected": {
                                        background: "linear-gradient(180deg, #E9F3FA 0%, #FFFFFF 100%) !important",



                                    },
                                }}
                            />
                        </div>}

                </div>
            </div>
        </section>
    )
}

