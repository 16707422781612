import partner1 from "../../assets/images/Clients/cl_1.svg"
import partner2 from "../../assets/images/Clients/cl_2.svg"
import partner3 from "../../assets/images/Clients/cl_3.svg"
import partner4 from "../../assets/images/Clients/cl_4.svg"
import partner5 from "../../assets/images/Clients/cl_5.svg"
import partner6 from "../../assets/images/Clients/cl_6.svg"
// import partner7 from "../../assets/images/Clients/cl_7.svg"
import partner8 from "../../assets/images/Clients/cl_8.svg"
// import partner9 from "../../assets/images/Clients/cl_9.svg"

import partner10 from "../../assets/images/Clients/cl_10.svg"
import sneh_logo from "../../assets/images/Clients/sneh.png"
import { useRef , useEffect} from "react"
import "./ServiceSlider.css"




export default function ServiceSlider() {
    const trackRef = useRef(null);

    useEffect(() => {
        const track = trackRef.current;
        if (track) {
            // Duplicate items for smooth infinite scrolling
            track.innerHTML += track.innerHTML;
        }
    }, []);

    return (
        <div className="px-8 py-16 services">
            <div className="max-w-xl mx-auto service-slider">
                <div className="grid grid-cols-3 md:grid-cols-3 gap-1 items-center slide-track" ref={trackRef}>
                    <div className="slide h-16 w-16 flex items-center justify-center">
                        <img className="h-full w-full max-w-[100px] max-h-[100px] object-contain" src={partner2} alt="Partner Logo" />
                    </div>
                    <div className="slide h-16 w-16 flex items-center justify-center">
                        <img className="h-full w-full max-w-[100px] max-h-[100px] object-contain" src={partner3} alt="Partner Logo" />
                    </div>
                    <div className="slide h-16 w-16 flex items-center justify-center">
                        <img className="h-full w-full max-w-[100px] max-h-[100px] object-contain" src={partner1} alt="Partner Logo" />
                    </div>
                    <div className="slide h-16 w-16 flex items-center justify-center">
                        <img className="h-full w-full max-w-[100px] max-h-[100px] object-contain" src={sneh_logo} alt="Partner Logo" />
                    </div>
                    <div className="slide h-16 w-16 flex items-center justify-center">
                        <img className="h-full w-full max-w-[100px] max-h-[100px] object-contain" src={partner5} alt="Partner Logo" />
                    </div>
                    <div className="slide h-16 w-16 flex items-center justify-center">
                        <img className="h-full w-full max-w-[100px] max-h-[100px] object-contain" src={partner6} alt="Partner Logo" />
                    </div>
                    {/* <div className="slide h-16 w-16 flex items-center justify-center">
                        <img className="h-full w-full max-w-[100px] max-h-[100px] object-contain" src={partner7} alt="Partner Logo" />
                    </div> */}
                    <div className="slide h-16 w-16 flex items-center justify-center">
                        <img className="h-full w-full max-w-[100px] max-h-[100px] object-contain" src={partner8} alt="Partner Logo" />
                    </div>

                    {/* <div className="slide h-16 w-16 flex items-center justify-center">
                        <img className="h-full w-full max-w-[1100px] max-h-[1100px] object-contain" src={partner9} alt="Partner Logo" />
                    </div> */}

                    {/* <div className="slide h-16 w-16 flex items-center justify-center">
                        <img className="h-full w-full max-w-[190px] max-h-[190px] object-contain" src={partner10} alt="Partner Logo" />
                    </div> */}
                </div>
            </div>
        </div>

    )
}

