import React from 'react'
import { useState, useEffect } from 'react'
import { useRef } from "react"
import { motion } from 'framer-motion'
import wave2 from "../../assets/images/MobileDevPage/wave2.svg"
import wave1 from "../../assets/images/MobileDevPage/wave.svg"
import emailjs from "@emailjs/browser";
import CountryFlag from "react-country-flag";
import countries from "i18n-iso-countries";
import enLocale from "i18n-iso-countries/langs/en.json";
import { getCountryCallingCode } from "libphonenumber-js";
// import thank_you_webm2 from "../../assets/webm/thank-you-2.webm"
import thank_you_webm2 from "../../assets/webm/thank-you-2.webm"
import { Person, Email } from "@mui/icons-material"
import user_img from "../../assets/images/ConsultPopup/user.svg"
import email_img from "../../assets/images/ConsultPopup/email.svg"
import dropdown from "../../assets/images/ConsultPopup/custom_dropdown.svg"
import indian_flag from "../../assets/images/ConsultPopup/indian_flag.svg"
import verify_icon from "../../assets/images/Services/verify_icon.svg"
import consult_img from "../../assets/images/ConsultPopup/get_free.svg"
import { useSelector } from 'react-redux'
import minimize from "../../assets/images/ConsultPopup/minimize.svg"

import { useNavigate } from 'react-router-dom'
countries.registerLocale(enLocale);
const ConsultationPopup = ({ children }) => {
    const navigate = useNavigate()
    const { showCookies } = useSelector((state) => state.authModal);
    const [selectedCountry, setSelectedCountry] = useState("+91");
    const [showButton, setShowButton] = useState(false); // Controls floating button
    const [selectedCountryCode, setSelectedCountryCode] = useState("IN");
    const [emailError, setEmailError] = useState("");
    const [showPopup, setShowPopup] = useState(false);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        const timer = setTimeout(() => {
            setShowButton(true);
        }, 3000);

        return () => clearTimeout(timer);
    }, []);
    const modalRef = useRef(null);
    // useEffect(() => {
    //     const timer = setTimeout(() => {
    //         setShowPopup(true);
    //     }, 1000); 

    //     return () => clearTimeout(timer); 
    // }, []);

    const [formData, setFormData] = useState({
        fullName: "",
        email: "",
        phone: "",

        service: "",
        countrycode: selectedCountry,
    });

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                setShowPopup(!showPopup)
            }
        };

        if (showPopup) {
            document.addEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [showPopup]);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });

        if (e.target.name === "email") {
            const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            if (!emailPattern.test(e.target.value)) {
                setEmailError("Please enter a valid email address");
            } else {
                setEmailError("");
            }
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!formData.email.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)) {
            setEmailError("Please enter a valid email address");
            return;
        }
        setLoading(true);
        emailjs
            .send(
                "service_sgsvjoy",
                "template_3yyqace",
                formData,
                "WRHR_yi9dUTXMXsPb"
            )
            .then((response) => {
                console.log("Success!", response.status, response.text);

                setFormData({
                    fullName: "",
                    email: "",
                    phone: "",

                    service: "",

                    countrycode: selectedCountry,
                });

                navigate("/thank-you")
                // alert("Message sent successfully!");

                // setShowPopup(!showPopup);

                // Clear form fields

                // Hide popup after 3 seconds
                // setTimeout(() => {
                //     setShowPopup(false);
                // }, 3000);
            })
            .catch((err) => {
                console.error("Failed...", err);
                alert("Message failed to send.");
            })

            .finally(() => {
                setLoading(false); 
            });
    };

    const countryList = Object.entries(countries.getNames("en"))
        .map(([countryCode, name]) => {
            try {
                const callingCode = `+${getCountryCallingCode(countryCode)}`;
                return {
                    key: `${callingCode}-${countryCode}`, // Unique key format
                    code: callingCode,
                    name: `${name} (${callingCode})`,
                    countryCode
                };
            } catch (error) {
                // console.warn(`Skipping unknown country: ${countryCode}`);
                return null; // Skip invalid countries
            }
        })
        .filter(Boolean); // Remove null values


    const handleCountryChange = (event) => {
        const selectedCode = event.target.value; // This will be "+91", "+1", etc.
        const country = countryList.find((c) => c.code === selectedCode);

        if (country) {
            setSelectedCountry(country.code); // Update displayed phone code (e.g., "+91")
            setSelectedCountryCode(country.countryCode); // Update flag (e.g., "IN")
        }
    };

    return (

        <>
            <div className='relative'>

                {children}
                {/* 
            <motion.div className="fixed bottom-4 right-4  z-50  p-4 "
                initial={{ x: '100%' }}
                animate={{ x: 0 }}
                transition={{ type: 'spring', stiffness: 100, damping: 25 }}
                ref={modalRef}
            >

                <img src={consult_img} alt="" />



            </motion.div> */}

                {showButton && !showPopup && !showCookies && (

                    <motion.div className="fixed bottom-4 right-4  z-[999]  p-4 "
                        initial={{ x: '100%' }}
                        animate={{ x: 0 }}
                        transition={{ type: 'spring', stiffness: 100, damping: 25 }}
                        ref={modalRef}
                    >

                        <div
                            className='flex gap-2 bg-white items-center border p-2 rounded-full cursor-pointer hover:bg-gray-100 hover:shadow-sm transition duration-300'
                            onClick={() => setShowPopup(!showPopup)}
                        >

                            <span className='font-semibold text-sm md:text-base'>Say, Hello</span>
                            <div className=" consultation-pop px-[1px] pb-[1px]  rounded-full text-center cursor-pointer ">




                                <img src={wave2} alt="" className='md:w-9 w-7  p-2' />
                            </div>

                        </div>

                    </motion.div >)}



                {showPopup && (
                    <motion.div className="fixed bottom-0 right-0 sm:bottom-4 sm:right-4  z-[999]  p-4 "
                        initial={{ x: '100%' }}
                        animate={{ x: 0 }}
                        transition={{ type: 'spring', stiffness: 100, damping: 25 }}
                        ref={modalRef}
                    >
                        <div className="relative consultation-pop px-[1px] pb-[1px]  rounded-3xl text-center  max-w-[290px] sm:max-w-[400px] w-full">

                            <div className='relative'>
                                <img
                                    src={minimize}
                                    onClick={() => setShowPopup(!showPopup)}
                                    alt=""
                                    className="absolute w-5 h-5 rounded-full top-1/2 right-2 transform -translate-y-1/2 cursor-pointer p-1 border transition-all duration-300 
             hover:bg-gray-700 hover:scale-110 active:bg-gray-900 active:scale-90"
                                />



                                {/* <button
                                className="absolute top-2 right-2 px-3 py-1 bg-red-500 text-white rounded-full transition-transform hover:translate-x-1 hover:translate-y-1"
                                onClick={() => setShowPopup(!showPopup)}
                            >
                                Close
                            </button> */}

                                <h2 className='text-white text-sm sm:text-base md:text-lg font-semibold py-3'>Get Free Consultation </h2>
                            </div>

                            <div className='bg-[#F3FFFA] flex flex-col space-y-1 sm:space-y-2 py-1 sm:py-2 border-b border-[#E4E4E4]'>

                                <h2 className='text-[#1B1B1B] text-base sm:text-lg md:text-xl font-medium'>Ready to Build Your Project? </h2>

                                <span className='text-[#3F3F3F]  text-xs sm:text-sm md:text-sm max-w-xs'>Get expert guidance and claim your free consultation today!
                                </span>




                            </div>




                            <form className='bg-white rounded-bl-3xl rounded-br-3xl px-4 py-3 sm:py-5 space-y-3 sm:space-y-4' onSubmit={handleSubmit}>

                                <div className="relative ">


                                    <img src={user_img} alt="" className='absolute left-3 top-1/2 transform -translate-y-1/2 w-5 sm:w-7' />
                                    <input
                                        type="text"
                                        name="fullName"
                                        placeholder="Full Name"
                                        value={formData.fullName}
                                        onChange={handleChange}
                                        required
                                        className="w-full pl-12 pr-3 py-2 sm:py-3 rounded-xl border border-[#DBDBDB]  focus:outline-none focus:ring-2 focus:ring-green-500"
                                    />
                                </div>

                                {/* <div className="relative ">
                                <div className="absolute left-3 top-1/2 transform -translate-y-1/2 flex gap-2 items-center pr-3 border-r border-gray-400">
                                    <img src={indian_flag} alt="" className="w-7" />
                                    <span>+91</span>
                                </div>
                                <input
                                    type="tel"
                                    name='phone'
                                    value={formData.phone}
                                    // onChange={handleChange}
                                    onChange={(e) => {
                                        const value = e.target.value.replace(/\D/g, ""); 
                                        if (value.length <= 10) {
                                            setFormData({ ...formData, phone: value }); 
                                        }
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.key === "e" || e.key === "E" || e.key === "+" || e.key === "-" || e.key === ".") {
                                            e.preventDefault(); 
                                        }
                                    }}
                                    required
                                    // required
                                    placeholder="+91989898989"
                                    className="w-full pl-24 pr-3 py-2 sm:py-3 rounded-xl border border-[#DBDBDB] focus:outline-none focus:ring-2 focus:ring-green-500"
                                />
                            </div> */}

                                <div className="relative">
                                    {/* <label htmlFor="phone-number" className="block text-base text-[#636363] mb-2 font-medium">
                                    Phone Number
                                </label> */}
                                    <div className="flex items-center border border-[#DBDBDB] rounded-xl">

                                        <CountryFlag
                                            countryCode={selectedCountryCode}
                                            svg
                                            style={{ width: "1.5em", height: "1.5em", marginLeft: "8px", borderRadius: "7px" }}
                                        />

                                        <select
                                            value={selectedCountry}
                                            onChange={handleCountryChange}
                                            className="bg-transparent outline-none text-sm font-medium pr-0  py-3 rounded-l-xl focus:outline-none text-[#636363]"
                                        >
                                            {countryList.map(({ key, code, name }) => (
                                                <option key={key} value={code}>
                                                    {code}
                                                </option>
                                            ))}
                                        </select>




                                        <div className="h-6 w-[2px] bg-[#8888884F] ml-1"></div>


                                        <input
                                            type="tel"
                                            id="phone-number"
                                            value={formData.phone}
                                            onChange={(e) => {
                                                const value = e.target.value.replace(/\D/g, "");
                                                if (value.length <= 10) {
                                                    setFormData({ ...formData, phone: value });
                                                }
                                            }}
                                            onKeyDown={(e) => {
                                                if (e.key === "e" || e.key === "E" || e.key === "+" || e.key === "-" || e.key === ".") {
                                                    e.preventDefault();
                                                }
                                            }}
                                            required
                                            placeholder="Enter phone number"
                                            className="w-full   px-2 py-3 rounded-r-xl focus:outline-none "


                                        />
                                    </div>
                                </div>





                                <div className="relative ">
                                    <img src={email_img} alt="" className='absolute left-3 top-1/2 transform -translate-y-1/2 w-5 sm:w-7' />
                                    <input
                                        name='email'
                                        value={formData.email}
                                        onChange={handleChange}
                                        type="email"
                                        placeholder="Email"
                                        className="w-full pl-12 pr-3 py-2 sm:py-3 rounded-xl border border-[#DBDBDB] focus:outline-none focus:ring-2 focus:ring-green-500"
                                    />


                                </div>

                                {emailError && <p className="text-red-500 text-sm text-start">{emailError}</p>}
                                <div className="relative">
                                    <select onChange={handleChange}
                                        required
                                        name="service" className="w-full appearance-none pl-4 pr-10 py-2 sm:py-3 rounded-xl text-[#636363]  sm:text-base text-sm border border-[#DBDBDB] focus:outline-none focus:ring-2 focus:ring-green-500 bg-white">
                                        <option value="" disabled selected>
                                            Pick Your Preferred Service...
                                        </option>

                                        <option value="ai-development">AI-Enabled Solutions</option>
                                        <option value="product-development">SaaS Product Development</option>

                                        <option value="mobile-app-development">Mobile App Development</option>
                                        <option value="web-app-development">Web App Development</option>
                                        <option value="cms-development">CMS Development</option>
                                        <option value="ui-ux-design">UI/UX Design</option>
                                        <option value="quality-assurance-testing">Quality Assurance Testing</option>
                                    </select>
                                    <img src={dropdown} alt="Dropdown" className="absolute right-4 top-1/2 transform -translate-y-1/2 w-3 sm:w-5" />
                                </div>

                                <div className='flex gap-2 items-center'>

                                    <div className='flex items-center gap-1'>

                                        <img src={verify_icon} alt="" className='w-3 sm:w-4' />

                                        <span className='text-xs sm:text-sm text-[#4B4B57] font-medium'>100% Confidential</span>

                                    </div>

                                    <div className='flex items-center gap-1 '>

                                        <img src={verify_icon} alt="" className='w-3 sm:w-4' />


                                        <span className='text-xs sm:text-sm text-[#4B4B57] font-medium'>We sign NDA</span>

                                    </div>


                                </div>

                                <button
                                    type='submit'
                                    className='consultation-btn-pop w-full text-white py-3 sm:py-3 text-sm sm:text-base md:text-lg font-semibold rounded-lg'
                                    disabled={loading} 
                                >
                                    {loading ? "Submitting..." : "Let’s Build Your Project"}
                                </button>

                            </form>
                        </div>
                    </motion.div>

                )}
            </div>
        </>
    )
}

export default ConsultationPopup
