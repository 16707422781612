import React from "react";
import { useState } from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer/Footer";
import AllBlog from "../../components/AllBlog/AllBlog";
import FAQsWork from "../../components/FAQsWork/FAQsWork";
import BreadCrumbBlog from "../../components/BreadCrumbsBlog/BreadCrumbBlog";
import FooterSecondary from "../../components/FooterSecondary/FooterSecondary";
import CTMSection from "../../components/CTMSection/CTMSection";
import TechStack from "../../components/TechStackSection/TechStack";
import TestimonialsSection from "../../components/Testimonials/Testimonials";

import banner_img from "../../assets/images/about/about-main.svg"
import Banner from "../../components/Services/CustomProductDev/Banner";
import TechStackAlt from "../../components/TechStackAlt/TechStackAlt";
import CustomSection from "../../components/Services/CustomProductDev/CustomSection";
import InfoSection from "../../components/Services/CustomProductDev/InfoSection";
import OtherServices from "../../components/Services/CustomProductDev/OtherServices";
import { useEffect } from "react";
import CustomCTM from "../../components/Services/CustomProductDev/CustomCTM";
import WhoCanUse from "../../components/Services/CustomProductDev/WhoCanUse";
import BreadCrumbService from "../../components/BreadCrumbService/BreadCrumbService";
import product_dev from"../../assets/images/Services/CustomProduct/product_dev.svg"
import { techstackproduct } from "../../data/techStackProduct";
import verify_tag from "../../assets/images/Services/verify_icon.svg"
// import pr_Dev from "../../assets/images/Services/CustomProduct/pr_dev.svg"
import pr_Dev from "../../assets/images/Services/product_Dev.png"

import Loader from "../../components/Loader1/Loader";

function CustomProducts() {

    const data = [
        {
          title: "SaaS Product Development",
          description: "Turn your ideas into reality with our expert product development services. From concept to launch, we craft innovative, high-quality solutions that meet market demands and drive success!",
          img: pr_Dev, 
        },

    ]




    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const [isOpen, setIsOpen] = useState(false)

    const [loading, setLoading] = useState(true); 


    useEffect(() => {
       const handleLoad = () => {
         setTimeout(() => setLoading(false), 1500); 
       };
     
       if (document.readyState === "complete") {
         setTimeout(() => setLoading(false), 1500);
       } else {
         window.addEventListener("load", handleLoad);
       }
     
       return () => window.removeEventListener("load", handleLoad);
     }, []);
     
 
     
     if (loading) {
       return <Loader />;
     }

    return (



        <div className="relative flex min-h-screen py-10 flex-col bg-white">

            <main className="flex-1 relative " style={{ zIndex: "999" }}>

                <div className="w-full relative">
                    <Navbar isOpen={isOpen} setIsOpen={setIsOpen} />




                </div>



                {/* <Banner /> */}
                <BreadCrumbService data={data} />
                <InfoSection />

                <CustomSection />




                <TechStackAlt technologies={techstackproduct} />
                <CustomCTM/>

           
                <WhoCanUse/>
                <OtherServices currentService="SaaS Product Development" />
       
                <TestimonialsSection />

                <CTMSection />
             











                <Footer />


            </main>
        </div>


    );
}

export default CustomProducts;
