import { LocationOn, Phone, Email, AccessTime, Facebook, Twitter, YouTube } from "@mui/icons-material"
import "./ContactSection.css"
import location_img from "../../assets/images/contact/location.png"
import phone_img from "../../assets/images/contact/call.png"
import email_img from "../../assets/images/contact/mail.png"
import time_img from "../../assets/images/contact/time.png"
import DropdwonArrow from "../../assets/images/contact/DropdownArrow.png"
import { useState } from "react"
import fb from "../../assets/images/contact/fb.svg"
import twitter from "../../assets/images/contact/twitter.svg"
import youtube from "../../assets/images/contact/yt.svg"
import overlay_blog from "../../assets/images/blog-overlay.svg"
import emailjs from "@emailjs/browser";
import { motion } from "framer-motion"
import verify_icon from "../../assets/images/Services/verify_icon.svg"
import CountryFlag from "react-country-flag";
import countries from "i18n-iso-countries";
import enLocale from "i18n-iso-countries/langs/en.json";
import { getCountryCallingCode } from "libphonenumber-js";

import thank_you_webm2 from "../../assets/webm/thank-you-2.webm"
export default function ContactSection() {
    const [selectedCountry, setSelectedCountry] = useState("+91");
    const [selectedCountryCode, setSelectedCountryCode] = useState("IN");
    const [emailError, setEmailError] = useState("");
    const [showPopup, setShowPopup] = useState(false); // State to control popup  


    const [formData, setFormData] = useState({
        fullName: "",
        email: "",
        phone: "",
        company: "",
        service: "",
        message: "",
        countrycode: selectedCountry,
    });

    const handleCountryChange = (event) => {
        const selectedCode = event.target.value; // This will be "+91", "+1", etc.
        const country = countryList.find((c) => c.code === selectedCode);

        if (country) {
            setSelectedCountry(country.code); // Update displayed phone code (e.g., "+91")
            setSelectedCountryCode(country.countryCode); // Update flag (e.g., "IN")
        }
    };



    const countryList = Object.entries(countries.getNames("en"))
        .map(([countryCode, name]) => {
            try {
                const callingCode = `+${getCountryCallingCode(countryCode)}`;
                return {
                    key: `${callingCode}-${countryCode}`, // Unique key format
                    code: callingCode,
                    name: `${name} (${callingCode})`,
                    countryCode
                };
            } catch (error) {
                // console.warn(`Skipping unknown country: ${countryCode}`);
                return null; // Skip invalid countries
            }
        })
        .filter(Boolean); // Remove null values


    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });

        if (e.target.name === "email") {
            const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            if (!emailPattern.test(e.target.value)) {
                setEmailError("Please enter a valid email address");
            } else {
                setEmailError("");
            }
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!formData.email.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)) {
            setEmailError("Please enter a valid email address");
            return;
        }

        emailjs
            .send(
                "service_yd1la42",
                "template_8o8umdc",
                formData,
                "V3AyLL9jbdM8ZN2kf"
            )
            .then((response) => {
                console.log("Success!", response.status, response.text);

                setFormData({
                    fullName: "",
                    email: "",
                    phone: "",
                    company: "",
                    service: "",
                    message: "",
                    countrycode: selectedCountry,
                });

                // alert("Message sent successfully!");

                setShowPopup(true);

                // Clear form fields

                // Hide popup after 3 seconds
                setTimeout(() => {
                    setShowPopup(false);
                }, 3000);
            })
            .catch((err) => {
                console.error("Failed...", err);
                alert("Message failed to send.");
            });
    };

    return (
        <section className="pt-16 px-4 contact">
            <div className="max-w-7xl mx-auto">

                {showPopup && (
                    <div
                        className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 p-4"
                        style={{ pointerEvents: "auto" }}
                    >
                        <div className="bg-white px-6 py-8 sm:px-10 sm:py-12 rounded-3xl text-center border-2 border-[#EBEDEF] max-w-sm w-full">
                            {/* Thank You Video */}
                            <video autoPlay loop muted className="w-32 sm:w-40 mx-auto rounded-lg">
                                <source src={thank_you_webm2} type="video/webm" />
                                Your browser does not support the video tag.
                            </video>

                            <h2 className="text-2xl sm:text-3xl font-semibold mt-4">Thank You!</h2>
                            <p className="text-gray-600 mt-6 sm:mt-8 text-lg sm:text-xl">
                                We appreciate you reaching out to us! We will review your request and get back to you as soon as possible.
                            </p>
                        </div>
                    </div>
                )}

                <motion.div className="text-center mb-16" initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true, amount: 0.2 }}
                    transition={{ duration: 0.5 }}>
                    <h2 className="text-3xl md:text-5xl font-medium mb-4 lg-text max-w-3xl mx-auto text-[#0C0C0C]">Get in Touch with Us</h2>
                    <p className="text-[#666666] max-w-4xl mx-auto text-xl">
                        We’d love to hear from you! Whether you have a question, feedback, or just want to say hello, feel free to reach out. Our team is here to assist you with anything you need.
                    </p>
                </motion.div>


                <div className="grid grid-cols-1 lg:grid-cols-[2fr_1.4fr] gap-8">

                    <motion.div className="bg-white rounded-3xl p-8 md:p-11 border border-[#00000026]" initial={{ opacity: 0, y: 50 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true, amount: 0.2 }}
                        transition={{ duration: 0.5 }}>
                        <h3 className="text-xl  md:text-3xl  font-semibold mb-6 text-[#1F1F1F]">Contact Us</h3>

                        <form className="space-y-6" onSubmit={handleSubmit}>

                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                                <div>
                                    <label className="block text-base text-[#636363] mb-2 font-medium">Full Name</label>
                                    <input
                                        type="text"
                                        name="fullName"
                                        value={formData.fullName}
                                        placeholder="Jane Smith"
                                        className="w-full px-4 py-3 rounded-xl border border-[#8888884F] focus:outline-none focus:ring-2 focus:ring-emerald-500"
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div>
                                    <label className="block text-base text-[#636363] mb-2 font-medium">Email Address</label>
                                    <input
                                        type="email"
                                        name="email"
                                        value={formData.email}

                                        placeholder="yourcompanyname@example.com" className="w-full px-4 py-3 rounded-xl border border-[#8888884F] focus:outline-none focus:ring-2 focus:ring-emerald-500"
                                        onChange={handleChange}
                                        required
                                    />

                                    {emailError && <p className="text-red-500 text-sm mt-1">{emailError}</p>}
                                </div>
                            </div>


                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                                {/* <div>
                                    <label htmlFor="phone-number" className="block text-base text-[#636363] mb-2 font-medium">
                                        Phone Number
                                    </label>
                                    <div className="flex items-center border border-[#8888884F] rounded-xl ">
                                        <select

                                            id="country-code"
                                            className="w-20 px-4 py-3 rounded-l-xl focus:outline-none text-[#636363] "
                                            defaultValue={formData.countrycode}
                                            onChange={handleChange}


                                        >
                                            <option value="+91">+91</option>
                                            <option value="+1">+1</option>
                                            <option value="+44">+44</option>

                                            <option value="+33">+33</option>

                                        </select>

                                        <input
                                            type="tel"
                                            id="phone-number"
                                            name="phone"
                                            placeholder="Enter phone number"
                                            className="w-full px-4 py-3 rounded-r-xl focus:outline-none "
                                            value={formData.phone} // Ensure controlled component
                                            onChange={(e) => {
                                                const value = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
                                                if (value.length <= 10) {
                                                    setFormData({ ...formData, phone: value }); // Update state only if <= 10 digits
                                                }
                                            }}
                                            onKeyDown={(e) => {
                                                if (e.key === "e" || e.key === "E" || e.key === "+" || e.key === "-" || e.key === ".") {
                                                    e.preventDefault(); // Prevent non-numeric characters
                                                }
                                            }}
                                            required

                                        />
                                    </div>
                                </div> */}


                                <div className="">
                                    <label htmlFor="phone-number" className="block text-base text-[#636363] mb-2 font-medium">
                                        Phone Number
                                    </label>
                                    <div className="flex items-center border border-[#8888884F] rounded-xl">

                                        <CountryFlag
                                            countryCode={selectedCountryCode}
                                            svg
                                            style={{ width: "1.7em", height: "1.7em", marginLeft: "8px", borderRadius: "7px" }}
                                        />

                                        <select
                                            value={selectedCountry}
                                            onChange={handleCountryChange}
                                            className="bg-transparent outline-none  font-medium pr-0  py-3 rounded-l-xl focus:outline-none text-[#636363]"
                                        >
                                            {countryList.map(({ key, code, name }) => (
                                                <option key={key} value={code}>
                                                    {code}
                                                </option>
                                            ))}
                                        </select>




                                        <div className="h-6 w-[2px] bg-[#8888884F] ml-1"></div>


                                        <input
                                            type="tel"
                                            id="phone-number"
                                            value={formData.phone}
                                            onChange={(e) => {
                                                const value = e.target.value.replace(/\D/g, "");
                                                if (value.length <= 10) {
                                                    setFormData({ ...formData, phone: value });
                                                }
                                            }}
                                            onKeyDown={(e) => {
                                                if (e.key === "e" || e.key === "E" || e.key === "+" || e.key === "-" || e.key === ".") {
                                                    e.preventDefault();
                                                }
                                            }}
                                            required
                                            placeholder="Enter phone number"
                                            className="w-full   px-2 py-3 rounded-r-xl focus:outline-none "


                                        />
                                    </div>
                                </div>


                                <div>
                                    <label className="block text-base text-[#636363] mb-2 font-medium">Company Name</label>
                                    <input
                                        type="text"
                                        name="company"
                                        placeholder="Your company or organization"
                                        className="w-full px-4 py-3 rounded-xl border border-[#8888884F] focus:outline-none focus:ring-2 focus:ring-emerald-500"
                                        value={formData.company}
                                        onChange={handleChange}
                                        required

                                    />
                                </div>
                            </div>

                            <div className="relative">
                                <label className="block text-base text-[#636363] mb-2 font-medium">
                                    Choose Service
                                </label>
                                <select
                                    className="w-full px-4 py-3 rounded-xl border border-[#8888884F] focus:outline-none focus:ring-2 focus:ring-emerald-500 bg-white text-[#636363] cursor-pointer appearance-none"
                                    style={{
                                        backgroundImage: `url(${DropdwonArrow})`,
                                        backgroundRepeat: "no-repeat",
                                        backgroundPosition: "right 12px center",
                                        backgroundSize: "16px",
                                    }}
                                    defaultValue=""
                                    onChange={handleChange}
                                    required
                                    name="service"
                                >
                                    <option value="" disabled hidden>Pick Your Preferred Service...</option>
                                    <option value="ai-development">AI-Enabled Solutions</option>
                                    <option value="product-development">SaaS Product Development</option>

                                    <option value="mobile-app-development">Mobile App Development</option>
                                    <option value="web-app-development">Web App Development</option>
                                    <option value="cms-development">CMS Development</option>
                                    <option value="ui-ux-design">UI/UX Design</option>
                                    <option value="quality-assurance-testing">Quality Assurance Testing</option>
                                </select>
                            </div>




                            <div>
                                <label className="block text-base text-[#636363] mb-2 font-medium">Brief About Your Project</label>
                                <textarea
                                    rows={1}
                                    name="message"
                                    placeholder="Describe your project goals, features, and scope"
                                    className="w-full px-4 py-3 rounded-xl border border-[#8888884F] focus:outline-none focus:ring-2 focus:ring-emerald-500"
                                    value={formData.message}
                                    onChange={handleChange}
                                    required
                                />
                            </div>

                            <div className='flex gap-2 items-center mb-2'>

                                <div className='flex items-center gap-1'>

                                    <img src={verify_icon} alt="" className='w-4' />

                                    <span className='text-sm text-[#4B4B57] font-medium'>100% Confidential</span>

                                </div>

                                <div className='flex items-center gap-1 '>

                                    <img src={verify_icon} alt="" className='w-4' />


                                    <span className='text-sm text-[#4B4B57] font-medium'>We sign NDA</span>

                                </div>


                            </div>

                            <button
                                type="submit"
                                className="w-full py-3 px-6 text-white font-medium rounded-2xl bg-gradient-to-r from-emerald-500 to-cyan-500 hover:from-emerald-600 hover:to-cyan-600 transition-colors"
                            >
                                Submit
                            </button>
                        </form>
                    </motion.div>

                    {/* Contact Info */}
                    <motion.div className="bg-gradient-to-b from-emerald-500 to-cyan-500 rounded-3xl p-8 md:p-11 text-white h-fit relative" initial={{ opacity: 0, y: 50 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true, amount: 0.2 }}
                        transition={{ duration: 0.5 }}>
                        {/* <div className="bg-gradient-to-b from-emerald-500 to-cyan-500 rounded-3xl p-8 md:p-11 text-white h-fit animated-diagonal-gradient"> */}



                        <h3 className="text-2xl md:text-4xl font-medium mb-4">Contact Info</h3>
                        <p className="mb-8  text-lg">Explore our services today and see how we can help you succeed.</p>

                        <div className="space-y-6">
                            {/* Location */}
                            <div className="flex items-start gap-4">
                                <img src={location_img} alt="Location" className="w-6 h-6 flex-shrink-0" />
                                <p className="text-lg">939/940, Sun Gravitas, Nr.Shyamal Cross Road, Ahmedabad - 380015</p>
                            </div>

                            <div className="flex items-start gap-4">
                                <img src={location_img} alt="Location" className="w-6 h-6 flex-shrink-0" />
                                <p className="text-lg">40, Treeline blvd, Brampton, Ontario L6P1E4</p>
                            </div>

                            {/* Phone */}
                            <div className="flex items-center gap-4">
                                <img src={phone_img} alt="Phone" className="w-6 h-6" />
                                <p className="text-lg">+91 7600213313 / +91 9173964000</p>
                            </div>

                            {/* <div className="flex items-center gap-4">
                                <img src={phone_img} alt="Phone" className="w-6 h-6" />
                                <p className="text-lg">+9173964000</p>
                            </div> */}

                            {/* Email */}
                            <div className="flex items-center gap-4">
                                <img src={email_img} alt="Email" className="w-6 h-6" />
                                <p className="text-lg">hello@techrabbit.io</p>
                            </div>

                            {/* Working hours */}
                            <div className="flex items-center gap-4">
                                <img src={time_img} alt="Working hours" className="w-6 h-6" />
                                <p className="text-lg">Mon-Fri: 10 AM - 7 PM</p>
                            </div>

                            {/* Social Links */}
                            <div className="flex gap-4 pt-4">
                                <a href="#" className="bg-white p-2 rounded-full hover:bg-white/20 transition-colors">
                                    <img src={fb} alt="Facebook" className="w-5 h-5" />
                                </a>
                                <a href="#" className="bg-white p-2 rounded-full hover:bg-white/20 transition-colors">
                                    <img src={twitter} alt="Twitter" className="w-5 h-5" />
                                </a>
                                <a href="#" className="bg-white p-2 rounded-full hover:bg-white/20 transition-colors">
                                    <img src={youtube} alt="YouTube" className="w-5 h-5" />
                                </a>
                            </div>
                        </div>

                        <img src={overlay_blog} alt="Blog Overlay" className="absolute inset-0 w-full h-full object-cover opacity-100 overlay-animation-breadcrumb" />
                    </motion.div>
                </div>




                <div className="grid sm:grid-cols-2 mt-16 gap-6" >



                    <motion.div className="w-full h-[300px] md:h-[400px] rounded-3xl overflow-hidden  border border-[#8888884F]" initial={{ opacity: 0, y: 50 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true, amount: 0.2 }}
                        transition={{ duration: 0.5 }}>
                        <iframe
                            title="Google Map"
                            className="w-full h-full"
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3672.3528683264813!2d72.53043749999999!3d23.0108125!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e85e4a491f589%3A0xa3889ac966e8071e!2sTech%20Rabbit%20-%20Mobile%20App%20Development%20Company!5e0!3m2!1sen!2sin!4v1739883579787!5m2!1sen!2sin"
                            allowFullScreen
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                        ></iframe>
                    </motion.div>

                    <motion.div className="w-full h-[300px] md:h-[400px] rounded-3xl overflow-hidden  border border-[#8888884F]" initial={{ opacity: 0, y: 50 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true, amount: 0.2 }}
                        transition={{ duration: 0.5 }}>
                        <iframe
                            title="Google Map"
                            className="w-full h-full"
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2880.525908654357!2d-79.7425909!3d43.782699199999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b17fe63c80e51%3A0x5be461f062bafe76!2s40%20Treeline%20Blvd%2C%20Brampton%2C%20ON%20L6P%201C5%2C%20Canada!5e0!3m2!1sen!2sin!4v1740814990207!5m2!1sen!2sin"
                            allowFullScreen
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                        ></iframe>
                    </motion.div>
                </div>

                {/* <div className="mt-16" >



                    <motion.div className="w-full h-[300px] md:h-[400px] rounded-3xl overflow-hidden  border border-[#8888884F]" initial={{ opacity: 0, y: 50 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true, amount: 0.2 }}
                        transition={{ duration: 0.5 }}>
                        <iframe
                            title="Google Map"
                            className="w-full h-full"
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2880.525908654357!2d-79.7425909!3d43.782699199999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b17fe63c80e51%3A0x5be461f062bafe76!2s40%20Treeline%20Blvd%2C%20Brampton%2C%20ON%20L6P%201C5%2C%20Canada!5e0!3m2!1sen!2sin!4v1740814990207!5m2!1sen!2sin"
                            allowFullScreen
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                        ></iframe>
                    </motion.div>
                </div> */}

                {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2880.525908654357!2d-79.7425909!3d43.782699199999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b17fe63c80e51%3A0x5be461f062bafe76!2s40%20Treeline%20Blvd%2C%20Brampton%2C%20ON%20L6P%201C5%2C%20Canada!5e0!3m2!1sen!2sin!4v1740814990207!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}




            </div>
        </section>
    )
}

