import { SmartToy, PhoneAndroid, Computer, Brush, WebAsset, BugReport, ArrowForward } from "@mui/icons-material"
// import ai_dev from "../../assets/images/Services/ai_dev.svg"
import web_app from "../../assets/images/Services/web_app.svg"
import mobile_app from "../../assets/images/Services/mobile_app.svg"
// import ui_ux from "../../assets/images/Services/ui-ux.svg"
// import quality from "../../assets/images/Services/quality.svg"
import cms from "../../assets/images/Services/sms.svg"
import { motion } from 'framer-motion';
import right_arrow from "../../assets/images/Services/CustomProduct/right_arrow.svg"
import product_dev from "../../assets/images/Services/product_dev.svg"
import web_Dev from "../../assets/images/Services/svc_web.svg"
import ai_dev from "../../assets/images/Services/svc_ai.svg"
import mobile_dev from "../../assets/images/Services/svc_mobile.svg"
import ui_ux from "../../assets/images/Services/svc_ui.svg"
import cms_dev from "../../assets/images/Services/svc_cms.svg"
import quality from "../../assets/images/Services/svc_quality.svg"
import business_success from "../../assets/images/Services/buisness_success.svg"
import { Link } from "react-router-dom";
import "./OurServices.css"
export default function OurServices() {
    const services = [

        {
            title: "AI-Enabled Solutions",
            description: "Leverage artificial intelligence to automate processes, enhance decision-making, and create smarter, data-driven solutions.",
            image: ai_dev,
            url: "/ai-enabled-solutions",
        },
        {
            title: "SaaS Product Development",
            description: "Transform your ideas into high-quality products with our expert development services, ensuring efficiency and market success.",
            image: product_dev,
            url: "/saas-product-development",
        },

     

        
        {
            title: "Mobile App Development",
            description: "Develop intuitive, high-performance mobile apps for iOS and Android, ensuring seamless functionality and user engagement.",
            image: mobile_app,
            url: "/mobile-app-development",
        },
        {
            title: "Web App Development",
            description: "Build scalable and high-performing web applications tailored to your business needs, delivering seamless user experiences.",
            image: web_Dev,
            url: "/web-app-development",
        },

        {
            title: "CMS Development",
            description: "Manage your content effortlessly with a custom-built CMS, designed for flexibility, scalability, and seamless content updates.",
            image: cms_dev,
            url: "/cms-development",
        },
  
        {
            title: "UI/UX Design",
            description: "Craft visually stunning and user-friendly interfaces with our UI/UX design expertise, enhancing digital experiences for users.",
            image: ui_ux,
            url: "/ui-ux-design",
        },
    
        {
            title: "Quality Assurance & Testing",
            description: "Ensure flawless performance and reliability with our comprehensive QA testing services, optimizing user experience and security.",
            image: quality,
            url: "/quality-assurance",
        },
    ];
    

    return (
        <section className="pb-0 sm:pb-16 px-4 our-service">
            <div className="max-w-7xl mx-auto">

                <motion.div className="text-center mb-12" initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true, amount: 0.2 }}
                    transition={{ duration: 0.5 }}
                >

                    {/* bg-[#F8F9FA] text-[#333333] text-xs sm:text-sm font-medium px-3 sm:px-4 py-1.5 sm:py-2 rounded-full border border-[#EDEFF3] */}
                    <span className="font-medium text-[#333333]  px-4 py-2   rounded-full border border-[#EDEFF3] bg-[#F8F9FA] ">Our Services</span>
                    <h2 className="text-3xl md:text-3xl font-semibold text-[#0C0C0C] mb-4 mt-8 poppins lg-text max-w-2xl mx-auto"> Offering Top-Notch IT Solutions To Your Needs</h2>
                    <p className="text-[#666666] max-w-2xl text-lg mx-auto">
                        Offering the most recent modern technology to grow your business exponentially equipped with top-quality technology that meets your desires.

                    </p>
                </motion.div>




                <motion.div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mt-4" >
                    {services.map((service, index) => (
                        <motion.div key={index} className="group p-5 border border-[#E8EAF1] rounded-xl " initial={{ opacity: 0, y: 50 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            viewport={{ once: true, amount: 0.2 }}
                            transition={{ duration: 0.5 }}>

                            {/* <div className="mb-4 text-gray-600"><img src={ai_dev} alt="" /></div> */}
                            <div className="flex items-center justify-between mb-4">
                                <div className="mb-4 bg-[#FAFAFA] border border-[#EDEFF3] rounded-xl p-2">
                                    <img src={service?.image} alt="" className="w-10 h-10" />
                                </div>



                            </div>


                            <h3 className="text-lg font-medium mb-2">{service.title}</h3>
                            <p className="text-gray-600 mb-4">{service.description}</p>
                            <Link to={service.url} className="bg-[#F8F9FA] text-[#333333] text-xs sm:text-sm font-medium px-3 sm:px-4 py-1.5 sm:py-2 rounded-full border border-[#EDEFF3] flex items-center gap-2 " style={{ display: "inline-flex" }}>
                             Know More
                                <img src={right_arrow} alt="Arrow" className="w-3 h-3" />
                            </Link>



                        </motion.div>
                    ))}

                    <motion.div className="group p-5 border border-[#E8EAF1] rounded-xl " initial={{ opacity: 0, y: 50 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true, amount: 0.2 }}
                        transition={{ duration: 0.5 }}>



                        <h3 className="text-xl font-medium mb-2">Boost Your Business Success! </h3>

                        <Link to={"/consult-us"} className="bg-gradient-to-r from-emerald-500 to-cyan-500 text-[white] font-medium px-4 py-2 rounded-lg border border-[#EDEFF3] flex items-start gap-2 text-lg  " style={{display:"inline-flex"}} >
                           Get Started

                        </Link>

                        <div className="mt-4">
                            <img
                                src={business_success}
                                alt="Business Success"
                                className="w-full h-auto rounded-lg"
                            />

                        </div>



                    </motion.div>


                </motion.div>
            </div>
        </section>
    )
}

