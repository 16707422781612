import { CheckCircle, AccessTime, Settings, Chat, ArrowForward } from "@mui/icons-material"
import small1 from "../../assets/images/CareerPage/small1.png"
import small2 from "../../assets/images/CareerPage/small2.png"
import small3 from "../../assets/images/CareerPage/small3.png"
import small4 from "../../assets/images/CareerPage/small4.png"
import small5 from "../../assets/images/CareerPage/small5.png"
import small6 from "../../assets/images/CareerPage/small6.png"
import small7 from "../../assets/images/CareerPage/small7.png"
import "./CareerHero.css"
import { Link } from "react-router-dom"
import clients from "../../assets/images/clients1.png"
import clients2 from "../../assets/images/clients2.png"
import clients3 from "../../assets/images/clients3.png"
import place from "../../assets/images/CareerPage/place.svg"
const images = [clients, clients2, clients3];
export default function CareerHero() {
    const benefits = [
        {
            icon: <CheckCircle className="w-6 h-6 text-emerald-500" />,
            title: "Work from anywhere",
            description:
                "Excepteur sint occaecat cupidat non proident sunt in culpam qui the officia mollit aliquip ex ea comm.",
        },
        {
            icon: <AccessTime className="w-6 h-6 text-emerald-500" />,
            title: "Flexible hours",
            description:
                "Excepteur sint occaecat cupidat non proident sunt in culpam qui the officia mollit aliquip ex ea comm.",
        },
        {
            icon: <Settings className="w-6 h-6 text-emerald-500" />,
            title: "Robust system",
            description:
                "Excepteur sint occaecat cupidat non proident sunt in culpam qui the officia mollit aliquip ex ea comm.",
        },
        {
            icon: <Chat className="w-6 h-6 text-emerald-500" />,
            title: "Safe environment",
            description:
                "Excepteur sint occaecat cupidat non proident sunt in culpam qui the officia mollit aliquip ex ea comm.",
        },
    ]

    return (
        <section className="py-16 px-4 careerhero">
            <div className="max-w-7xl mx-auto">
                {/* Top Section */}
                <div className="grid grid-cols-1 lg:grid-cols-[2fr_1.5fr] gap-12 items-center">
                    {/* Left Content */}
                    <div className="space-y-8">
                        {/* <div className="flex items-center gap-1">
                            <div className="flex -space-x-2">
                                {[1, 2, 3].map((_, index) => (
                                    <div key={index} className="w-8 h-8 rounded-full bg-gray-200 border-2 border-white" />
                                ))}
                            </div>
                            <span className="ml-2 text-gray-600">3,500 + Happy Clients</span>
                        </div> */}

                        <div
                            className={`flex justify-center zoom-in bg-white rounded-full shadow-[0px_2px_2px_0px_#00000005,0px_10px_41px_0px_#0000000A] 
                }`}
                            style={{
                                alignItems: "center",
                                display: "inline-flex",
                                padding: "10px",
                            }}
                        >

                            <div className="flex -space-x-2">
                                {images.map((clientImage, index) => (
                                    <div
                                        key={index}
                                        className="relative w-6 h-6  sm:w-8 sm:h-8 md:w-8 md:h-8 rounded-full border-2 border-white overflow-hidden"
                                    >
                                        <img src={clientImage} alt={`Client ${index + 1}`} className="w-full h-full object-cover" />
                                    </div>
                                ))}
                            </div>
                            <span className="ml-2 font-medium text-sm sm:text-base" style={{ color: "#333333" }}>3,500+ Happy Clients</span>
                        </div>

                        <h1 className="text-3xl md:text-5xl font-semibold leading-tight lg-text text-[#414141]">
                            Choose the Right Pricing Model for Your Business
                        </h1>

                        <p className="text-lg md:text-xl text-[#4D4D4D] font-medium">
                            Flexible engagement models designed to fit your project's scope, budget, and timeline.
                        </p>

                        {/* <button className="inline-flex items-center gap-2 px-6 py-3 text-white rounded-full bg-gradient-to-r from-emerald-500 to-cyan-500 hover:from-emerald-600 hover:to-cyan-600 transition-colors">
                            Get a Custom Quote
                            <ArrowForward className="w-5 h-5" />
                        </button> */}



                        <Link to={"/consult-us"}>

                        <button
                            // className=" text-white px-6 py-2 rounded-full text-sm font-semibold consultation-btn mt-8"
                            className={`text-white px-4 py-3 sm:px-6 sm:py-3 rounded-3xl text-base sm:text-lg font-medium consultation-btn mt-8 border-2 border-[#D0D0D0] `}




                        >
                            Get a Custom Quote
                        </button>
                        </Link>
                    </div>


                    <div className="flex flex-col gap-4 hidden lg:block">
                        {/* Row 1: Two images aligned to the right */}
                        <div className="flex justify-end gap-4 items-center">
                            <div className=" overflow-hidden w-1/5">
                                <img src={small1} alt="Team member 1" className="w-32 h-32 object-contain" />
                            </div>
                            <div className=" overflow-hidden w-1/5">
                                <img src={small2} alt="Team member 2" className="w-32 h-32 object-contain" />
                            </div>
                        </div>

                        <div className="flex gap-4 justify-end">
                            <div className="relative flex items-center justify-center w-1/5 object-contain">
                                {/* "?" Span */}
                                <span className="text-4xl text-[#646464] bg-[#E0E2DE] px-7 py-5 rounded-3xl relative">
                                    ?

                                    <img
                                        src={place}
                                        alt="Place Icon"
                                        className="absolute top-2 right-1 w-6 h-6 transform translate-x-1/2 -translate-y-1/2"
                                    />
                                </span>

                                {/* Positioned Image */}

                            </div>

                            <div className=" overflow-hidden w-1/5">
                                <img src={small3} alt="Team member 3" className="w-32 h-32 object-contain" />
                            </div>
                            <div className=" overflow-hidden w-1/5">
                                <img src={small4} alt="Team member 4" className="w-32 h-32 object-contain" />
                            </div>
                        </div>


                        <div className="flex gap-4 justify-end">
                            <div className="overflow-hidden w-1/5">
                                <img src={small5} alt="Team member 5" className="w-32 h-32 object-contain" />
                            </div>
                            <div className=" overflow-hidden w-1/5">
                                <img src={small6} alt="Team member 6" className="w-32 h-32 object-contain" />
                            </div>
                            <div className="overflow-hidden w-1/5">
                                <img src={small7} alt="Team member 7" className="w-32 h-32 object-contain" />
                            </div>
                        </div>
                    </div>

                    <div className="relative grid grid-cols-3 gap-4 hidden lg:hidden">
                        {/* Question Mark Box */}
                        <div className="aspect-square bg-gray-100 rounded-2xl flex items-center justify-center">
                            <span className="text-4xl text-emerald-500">?</span>
                        </div>
                        {/* Image Placeholders */}
                        {[...Array(8)].map((_, index) => (
                            <div key={index} className="aspect-square bg-gray-200 rounded-2xl overflow-hidden">
                                <img
                                    src={small1}
                                    alt={`Team member ${index + 1}`}
                                    className="w-full h-full object-cover"
                                />
                            </div>
                        ))}
                    </div>

                </div>


                {/* <div className="text-center mb-16">
          <span className="text-sm font-medium text-emerald-500">Benefits</span>
          <h2 className="text-3xl md:text-4xl font-bold mt-2 mb-4">Working at Clonify</h2>
          <p className="text-gray-600 max-w-2xl mx-auto">
            Designing has never been so fast. Browse hundreds of beautifully designed layouts and have your project
            ready in minutes.
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {benefits.map((benefit, index) => (
            <div key={index} className="flex gap-4">
              <div className="flex-shrink-0">{benefit.icon}</div>
              <div>
                <h3 className="text-xl font-semibold mb-2">{benefit.title}</h3>
                <p className="text-gray-600">{benefit.description}</p>
              </div>
            </div>
          ))}
        </div> */}
            </div>
        </section>
    )
}

