import React, { useEffect, useState } from 'react';
// import "./Animatedcard.css"
const ScrollComponent = () => {
  const [activeSection, setActiveSection] = useState(null);

  useEffect(() => {
    const handleScroll = () => {
      const scroll = window.scrollY + window.innerHeight / 3;
      const sections = document.querySelectorAll('.section');

      let foundActiveSection = null;
      sections.forEach((section) => {
        const sectionTop = section.offsetTop;
        const sectionBottom = sectionTop + section.offsetHeight;

        if (sectionTop <= scroll && sectionBottom > scroll) {
          foundActiveSection = section;
        }
      });

      setActiveSection(foundActiveSection);
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll();  // Run once on mount to check initial scroll position.

    return () => {
      window.removeEventListener('scroll', handleScroll);  // Clean up on component unmount.
    };
  }, []);

  return (

    <>
      <div>
        <div className="background"></div>

        <div className={`section ${activeSection === document.querySelector('.section') ? 'active' : ''}`}>
          <div className="section__img">
            <div
              className="section__img__inner"
              style={{ backgroundImage: 'url(http://media.idownloadblog.com/wp-content/uploads/2014/10/iPad-mini-3-silver-side-by-side.png)' }}
            ></div>
          </div>
          <div className="section__body">
            <div className="section__text">
              <h2>Section title</h2>
            </div>
          </div>
        </div>

        <div className={`section ${activeSection === document.querySelectorAll('.section')[1] ? 'active' : ''}`}>
          <div className="section__img">
            <div
              className="section__img__inner"
              style={{ backgroundImage: 'url(http://wpuploads.appadvice.com/wp-content/uploads/2014/12/ipad-c4a63aad52bb1d69495bc2100e5f2026.png)' }}
            ></div>
          </div>
          <div className="section__body">
            <div className="section__text">
              <h2>Section title</h2>
            </div>
          </div>
        </div>
      </div>



    </>
  );
};

export default ScrollComponent;
