import { LocationOn, Phone, Email, AccessTime, Facebook, Twitter, YouTube } from "@mui/icons-material"
import { useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
// import "./ContactSection.css"
import location_img from "../../assets/images/contact/location.png"
import phone_img from "../../assets/images/contact/call.png"
import email_img from "../../assets/images/contact/mail.png"
import time_img from "../../assets/images/contact/time.png"
import DropdwonArrow from "../../assets/images/contact/DropdownArrow.png"
import { useState, useEffect } from "react"
import fb from "../../assets/images/contact/fb.svg"
import twitter from "../../assets/images/contact/twitter.svg"
import youtube from "../../assets/images/contact/yt.svg"
import overlay_blog from "../../assets/images/blog-overlay.svg"
import emailjs from "@emailjs/browser";
import { motion } from "framer-motion"
import verify_icon from "../../assets/images/Services/verify_icon.svg"
import close_icon from "../../assets/images/MobileDevPage/close.svg"
import { closeConsultation } from "../../redux/authModalSlice"
import CountryFlag from "react-country-flag";
import countries from "i18n-iso-countries";
import enLocale from "i18n-iso-countries/langs/en.json";
import { getCountryCallingCode } from "libphonenumber-js";
import thank_you_webm2 from "../../assets/webm/thank-you-2.webm"
import { useNavigate } from "react-router-dom"
countries.registerLocale(enLocale);
export default function ContactPopup() {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate()
    const modalRef = useRef(null);
    const [selectedCountry, setSelectedCountry] = useState("+91");
    const [selectedCountryCode, setSelectedCountryCode] = useState("IN");
    const { showConsultation } = useSelector((state) => state.authModal);
    const [emailError, setEmailError] = useState("");
    const [showPopup, setShowPopup] = useState(false); // State to control popup  
    const [selectedBudget, setSelectedBudget] = useState(null)
    const budgets = ["Under ₹2 Lakh", "₹2–5 Lakh", "₹5–10 Lakh", "Over ₹10 Lakh"]
    const [showContactPopUp, setShowContactPopup] = useState(false);
    const dispatch = useDispatch()


    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                dispatch(closeConsultation());

                // dis

                // setShowContactPopup(!showConsultation)
            }
        };

        if (showConsultation) {
            document.addEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [showConsultation]);


    const handleCountryChange = (event) => {
        const selectedCode = event.target.value; // This will be "+91", "+1", etc.
        const country = countryList.find((c) => c.code === selectedCode);

        if (country) {
            setSelectedCountry(country.code); // Update displayed phone code (e.g., "+91")
            setSelectedCountryCode(country.countryCode); // Update flag (e.g., "IN")
        }
    };



    const countryList = Object.entries(countries.getNames("en"))
        .map(([countryCode, name]) => {
            try {
                const callingCode = `+${getCountryCallingCode(countryCode)}`;
                return {
                    key: `${callingCode}-${countryCode}`, // Unique key format
                    code: callingCode,
                    name: `${name} (${callingCode})`,
                    countryCode
                };
            } catch (error) {
                // console.warn(`Skipping unknown country: ${countryCode}`);
                return null; // Skip invalid countries
            }
        })
        .filter(Boolean); // Remove null values

    // useEffect(() => {
    //     const timer = setTimeout(() => {
    //         setShowContactPopup(true);
    //     }, 1000);

    //     return () => clearTimeout(timer);
    // }, []);

    const [formData, setFormData] = useState({
        fullName: "",
        email: "",
        phone: "",
        company: "",
        service: "",
        message: "",
        countrycode: selectedCountry,
        budget:selectedBudget
    });
    console.log("Budget",selectedBudget)
    console.log(formData)

    useEffect(() => {
        setFormData((prevData) => ({
            ...prevData,
            budget: selectedBudget,
        }));
    }, [selectedBudget]);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });

        if (e.target.name === "email") {
            const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            if (!emailPattern.test(e.target.value)) {
                setEmailError("Please enter a valid email address");
            } else {
                setEmailError("");
            }
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!formData.email.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)) {
            setEmailError("Please enter a valid email address");
            return;
        }

        setLoading(true);

        emailjs
            .send(
                "service_sgsvjoy",
                "template_i18q89n",
                formData,
                "WRHR_yi9dUTXMXsPb"
            )
            .then((response) => {
                console.log("Success!", response.status, response.text);

                setFormData({
                    fullName: "",
                    email: "",
                    phone: "",
                    company: "",
                    service: "",
                    message: "",
                    budget:selectedBudget,
                    countrycode: selectedCountry,
                });

                console.log(formData)

                navigate("/thank-you")





                // alert("Message sent successfully!");

                // setShowPopup(true);

                // Clear form fields

                // Hide popup after 3 seconds
                // setTimeout(() => {
                //     setShowPopup(false);
                // }, 3000);
            })
            .catch((err) => {
                console.error("Failed...", err);
                alert("Message failed to send.");
            })

            .finally(() => {
                setLoading(false); 
            });
    };

    return (

        <>





            <motion.div
                className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 p-4"
                style={{ pointerEvents: "auto" }}

                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3, ease: "easeInOut" }}

            >
                <motion.div className="bg-white rounded-3xl px-8 py-8 md:px-8 md:py-8 border border-[#00000026] relative" style={{ maxHeight: "95vh", overflow: "auto" }} ref={modalRef}
                    initial={{ scale: 0.8, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    exit={{ scale: 0.8, opacity: 0 }}
                    transition={{ duration: 0.3, ease: "easeOut" }}

                // ref={modalRef}
                >

                    <img src={close_icon} className="absolute w-5 top-4 right-4 cursor-pointer" alt="" onClick={() => dispatch(closeConsultation())} />
                    <h3 className="text-xl  md:text-3xl  font-semibold mb-2 text-[#1F1F1F]">Get in Touch with TR!</h3>

                    <h2 className="mb-6 text-[#4B4B57]">Claim your free consultation and let’s discuss your project.</h2>

                    <form className="space-y-6" onSubmit={handleSubmit}>

                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                            <div>
                                <label className="block text-base text-[#636363] mb-2 font-medium">Full Name</label>
                                <input
                                    type="text"
                                    name="fullName"
                                    value={formData.fullName}
                                    placeholder="Jane Smith"
                                    className="w-full px-4 py-3 rounded-xl border border-[#8888884F] focus:outline-none focus:ring-2 focus:ring-emerald-500"
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div>
                                <label className="block text-base text-[#636363] mb-2 font-medium">Email Address</label>
                                <input
                                    type="email"
                                    name="email"
                                    value={formData.email}

                                    placeholder="yourcompanyname@example.com" className="w-full px-4 py-3 rounded-xl border border-[#8888884F] focus:outline-none focus:ring-2 focus:ring-emerald-500"
                                    onChange={handleChange}
                                    required
                                />

                                {emailError && <p className="text-red-500 text-sm mt-1">{emailError}</p>}
                            </div>
                        </div>


                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                            {/* <div>
                                    <label htmlFor="phone-number" className="block text-base text-[#636363] mb-2 font-medium">
                                        Phone Number
                                    </label>
                                    <div className="flex items-center border border-[#8888884F] rounded-xl ">
                                        <select

                                            id="country-code"
                                            className="w-20 px-4 py-3 rounded-l-xl focus:outline-none text-[#636363] "
                                            defaultValue={formData.countrycode}
                                            onChange={handleChange}


                                        >
                                            <option value="+91">+91</option>
                                            <option value="+1">+1</option>
                                            <option value="+44">+44</option>

                                            <option value="+33">+33</option>

                                        </select>

                                        <input
                                            type="tel"
                                            id="phone-number"
                                            name="phone"
                                            placeholder="Enter phone number"
                                            className="w-full px-4 py-3 rounded-r-xl focus:outline-none "
                                            value={formData.phone} 
                                            onChange={(e) => {
                                                const value = e.target.value.replace(/\D/g, ""); 
                                                if (value.length <= 10) {
                                                    setFormData({ ...formData, phone: value }); 
                                                }
                                            }}
                                            onKeyDown={(e) => {
                                                if (e.key === "e" || e.key === "E" || e.key === "+" || e.key === "-" || e.key === ".") {
                                                    e.preventDefault();
                                                }
                                            }}
                                            required

                                        />
                                    </div>
                                </div> */}

                            <div className="">
                                <label htmlFor="phone-number" className="block text-base text-[#636363] mb-2 font-medium">
                                    Phone Number
                                </label>
                                <div className="flex items-center border border-[#8888884F] rounded-xl">

                                    <CountryFlag
                                        countryCode={selectedCountryCode}
                                        svg
                                        style={{ width: "1.7em", height: "1.7em", marginLeft: "8px", borderRadius: "7px" }}
                                    />

                                    <select
                                        value={selectedCountry}
                                        onChange={handleCountryChange}
                                        className="bg-transparent outline-none  font-medium pr-0  py-3 rounded-l-xl focus:outline-none text-[#636363]"
                                    >
                                        {countryList.map(({ key, code, name }) => (
                                            <option key={key} value={code}>
                                                {code}
                                            </option>
                                        ))}
                                    </select>




                                    <div className="h-6 w-[2px] bg-[#8888884F] ml-1"></div>


                                    <input
                                        type="tel"
                                        id="phone-number"
                                        value={formData.phone}
                                        onChange={(e) => {
                                            const value = e.target.value.replace(/\D/g, "");
                                            if (value.length <= 10) {
                                                setFormData({ ...formData, phone: value });
                                            }
                                        }}
                                        onKeyDown={(e) => {
                                            if (e.key === "e" || e.key === "E" || e.key === "+" || e.key === "-" || e.key === ".") {
                                                e.preventDefault();
                                            }
                                        }}
                                        required
                                        placeholder="Enter phone number"
                                        className="w-full   px-2 py-3 rounded-r-xl focus:outline-none "


                                    />
                                </div>
                            </div>


                            <div>
                                <label className="block text-base text-[#636363] mb-2 font-medium">Company Name</label>
                                <input
                                    type="text"
                                    name="company"
                                    placeholder="Your company or organization"
                                    className="w-full px-4 py-3 rounded-xl border border-[#8888884F] focus:outline-none focus:ring-2 focus:ring-emerald-500"
                                    value={formData.company}
                                    onChange={handleChange}
                                    required

                                />
                            </div>
                        </div>

                        <div className="relative">
                            <label className="block text-base text-[#636363] mb-2 font-medium">
                                Estimated Budget?
                            </label>

                            <div className="flex flex-row flex-wrap gap-3">
                                {budgets.map((exp) => (
                                    <button
                                        key={exp}
                                        type="button"
                                        onClick={() => setSelectedBudget(exp)}
                                        className={`px-4 py-3  rounded-lg text-sm transition-colors font-300
      ${selectedBudget === exp
                                                ? "bg-[#E9F7EC] text-[#28A745] border border-[#28A745]" // Green when selected
                                                : "bg-[#FFFFFF] text-[#6A6C70] hover:bg-gray-100 border border-[#E1E1E1]" // Default state
                                            }
    `}
                                    >
                                        {exp}
                                    </button>
                                ))}
                            </div>

                        </div>

                        <div className="relative">
                            <label className="block text-base text-[#636363] mb-2 font-medium">
                                Choose Service
                            </label>
                            <select
                                className="w-full px-4 py-3 rounded-xl border border-[#8888884F] focus:outline-none focus:ring-2 focus:ring-emerald-500 bg-white text-[#636363] cursor-pointer appearance-none"
                                style={{
                                    backgroundImage: `url(${DropdwonArrow})`,
                                    backgroundRepeat: "no-repeat",
                                    backgroundPosition: "right 12px center",
                                    backgroundSize: "16px",
                                }}
                                defaultValue=""
                                onChange={handleChange}
                                required
                                name="service"
                            >
                                <option value="" disabled hidden>Pick Your Preferred Service...</option>
                                <option value="ai-development">AI-Enabled Solutions</option>
                                <option value="product-development">SaaS Product Development</option>

                                <option value="mobile-app-development">Mobile App Development</option>
                                <option value="web-app-development">Web App Development</option>
                                <option value="cms-development">CMS Development</option>
                                <option value="ui-ux-design">UI/UX Design</option>
                                <option value="quality-assurance-testing">Quality Assurance Testing</option>
                            </select>
                        </div>




                        {/* <div>
                                <label className="block text-base text-[#636363] mb-2 font-medium">Brief About Your Project</label>
                                <textarea
                                    rows={4}
                                    name="message"
                                    placeholder="Describe your project goals, features, and scope"
                                    className="w-full px-4 py-3 rounded-xl border border-[#8888884F] focus:outline-none focus:ring-2 focus:ring-emerald-500"
                                    value={formData.message}
                                    onChange={handleChange}
                                    required
                                />
                            </div> */}

                        <div className='flex gap-2 items-center mb-2'>

                            <div className='flex items-center gap-1'>

                                <img src={verify_icon} alt="" className='w-4' />

                                <span className='text-sm text-[#4B4B57] font-medium'>100% Confidential</span>

                            </div>

                            <div className='flex items-center gap-1 '>

                                <img src={verify_icon} alt="" className='w-4' />


                                <span className='text-sm text-[#4B4B57] font-medium'>We sign NDA</span>

                            </div>


                        </div>

                        <button
                            type="submit"
                            className="w-full py-3 px-6 text-white font-medium rounded-2xl bg-gradient-to-r from-emerald-500 to-cyan-500 hover:from-emerald-600 hover:to-cyan-600 transition-colors"
                        >
                                 {loading ? "Submitting..." : "Submit"}
                        </button>
                    </form>
                </motion.div>

                {/* Contact Info */}

                {/* </div> */}


                {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2880.525908654357!2d-79.7425909!3d43.782699199999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b17fe63c80e51%3A0x5be461f062bafe76!2s40%20Treeline%20Blvd%2C%20Brampton%2C%20ON%20L6P%201C5%2C%20Canada!5e0!3m2!1sen!2sin!4v1740814990207!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}




            </motion.div>

        </>
    )
}

