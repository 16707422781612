

import { useState } from "react"
import "./WorkProcess.css"
import {
    Assignment, // Gathering
    Palette, // UI/UX
    Code, // Prototype
    DeveloperMode, // Development
    VerifiedUser, // Quality Assurance
    Storage, // MongoDB
    DataObject, // Postgre SQL
} from "@mui/icons-material"
import l1 from "../../assets/images/landing_1.webp"
import l2 from "../../assets/images/l2.webp"

import l3 from "../../assets/images/l3.webp"
import l4 from "../../assets/images/l4.webp"
import l5 from "../../assets/images/l5.webp"
import l6 from "../../assets/images/l6.webp"
import l7 from "../../assets/images/l7.webp"
import { motion } from "framer-motion"


import gather_grad from "../../assets/images/Services/gather_grad.svg"
import ux_grad from "../../assets/images/Services/ux_grad.svg"
import proto_grad from "../../assets/images/Services/prototype_grad.svg"
import dev_grad from "../../assets/images/Services/development_grad.svg"
import quality_grad from "../../assets/images/Services/quality_grad.svg"
import mongo_grad from "../../assets/images/Services/mongo_grad.svg"
import postgress_grad from "../../assets/images/Services/postgress_grad.svg"






import gathering from "../../assets/images/Services/gathering.svg"
import non_gathering from "../../assets/images/Services/non-gather.svg"

import ui_ux from "../../assets/images/Services/ux.svg"
import non_ui_ux from "../../assets/images/Services/non-ux.svg"

import prototype from "../../assets/images/Services/prototype.svg"
import non_prototype from "../../assets/images/Services/non-proto.svg"

import dev from "../../assets/images/Services/development.svg"
import non_dev from "../../assets/images/Services/non-dev.svg"

import quality from "../../assets/images/Services/quality.svg"
import non_quality from "../../assets/images/Services/non-quality.svg"

import mongo from "../../assets/images/Services/mongo.svg"
import non_mongo from "../../assets/images/Services/non-mongo.svg"

import post from "../../assets/images/Services/postgress.svg"
import non_post from "../../assets/images/Services/non-post.svg"








export default function WorkProcess() {
    const [activeStep, setActiveStep] = useState(0)

    const steps = [
        {
            NonActive: <img src={gathering} alt="Logistics Icon" className="sm:w-8 sm:h-8 w-6 h-6" />,

            Activeicon:
                <img src={non_gathering} alt="Logistics Icon" className="sm:w-8 sm:h-8 w-6 h-6"/>,
            ActiveGrad: <img src={gather_grad} alt="Logistics Icon" />,

            label: "Gathering",
            title: "Requirement Gathering",
            description: `Boost your productivity with our Focus Time feature. 
        Effortlessly block out distractions and dedicate uninterrupted periods to your most important tasks. 
        Whether it's completing a report, brainstorming, or deep work, our Focus Time tool helps you achieve more in less time.`,
            image: l1
        },
        {
            NonActive: <img src={ui_ux} alt="Logistics Icon" className="sm:w-8 sm:h-8 w-6 h-6"/>,
            ActiveGrad: <img src={ux_grad} alt="Logistics Icon" />,

            Activeicon: <img src={non_ui_ux} alt="Logistics Icon" className="sm:w-8 sm:h-8 w-6 h-6"/>,
            label: "UI/UX",
            title: "UI/UX Design",
            description: `Boost your productivity with our Focus Time feature. 
            Effortlessly block out distractions and dedicate uninterrupted periods to your most important tasks. 
            Whether it's completing a report, brainstorming, or deep work, our Focus Time tool helps you achieve more in less time.`,
            image: l2
        },
        {
            NonActive: <img src={prototype} alt="Logistics Icon" className="sm:w-8 sm:h-8 w-6 h-6" />,
            ActiveGrad: <img src={proto_grad} alt="Logistics Icon" />,

            Activeicon: <img src={non_prototype} alt="Logistics Icon" className="sm:w-8 sm:h-8 w-6 h-6"/>,
            label: "Prototype",
            title: "Prototype Development",
            description: `Boost your productivity with our Focus Time feature. 
            Effortlessly block out distractions and dedicate uninterrupted periods to your most important tasks. 
            Whether it's completing a report, brainstorming, or deep work, our Focus Time tool helps you achieve more in less time.`,
            image: l3
        },
        {
            NonActive: <img src={dev} alt="Logistics Icon" className="sm:w-8 sm:h-8 w-6 h-6"/>,
            ActiveGrad: <img src={dev_grad} alt="Logistics Icon" />,

            Activeicon: <img src={non_dev} alt="Logistics Icon" className="sm:w-8 sm:h-8 w-6 h-6"/>,
            label: "Development",
            title: "Development Phase",
            description: `Boost your productivity with our Focus Time feature. 
            Effortlessly block out distractions and dedicate uninterrupted periods to your most important tasks. 
            Whether it's completing a report, brainstorming, or deep work, our Focus Time tool helps you achieve more in less time.`,
            image: l4
        },
        {
            NonActive: <img src={quality} alt="Logistics Icon" className="sm:w-8 sm:h-8 w-6 h-6"/>,
            ActiveGrad: <img src={quality_grad} alt="Logistics Icon" />,

            Activeicon: <img src={non_quality} alt="Logistics Icon" className="sm:w-8 sm:h-8 w-6 h-6"/>,
            label: "Quality Assurance",
            title: "Quality Testing",
            description: `Boost your productivity with our Focus Time feature. 
            Effortlessly block out distractions and dedicate uninterrupted periods to your most important tasks. 
            Whether it's completing a report, brainstorming, or deep work, our Focus Time tool helps you achieve more in less time.`,
            image: l5
        },
        {
            NonActive: <img src={mongo} alt="Logistics Icon" className="sm:w-8 sm:h-8 w-6 h-6"/>,
            ActiveGrad: <img src={mongo_grad} alt="Logistics Icon" />,

            Activeicon: <img src={non_mongo} alt="Logistics Icon" className="sm:w-8 sm:h-8 w-6 h-6"/>,
            label: "Deployment",
            title: "Deployment Phase",
            description: `Boost your productivity with our Focus Time feature. 
            Effortlessly block out distractions and dedicate uninterrupted periods to your most important tasks. 
            Whether it's completing a report, brainstorming, or deep work, our Focus Time tool helps you achieve more in less time.`,
            image: l6
        },
        {
            NonActive: <img src={post} alt="Logistics Icon" className="sm:w-8 sm:h-8 w-6 h-6"/>,
            ActiveGrad: <img src={postgress_grad} alt="Logistics Icon" />,

            Activeicon: <img src={non_post} alt="Logistics Icon" className="sm:w-8 sm:h-8 w-6 h-6"/>,
            label: "Support & Maintanance",
            title: "Support & Maintanance ",
            description: `Boost your productivity with our Focus Time feature. 
            Effortlessly block out distractions and dedicate uninterrupted periods to your most important tasks. 
            Whether it's completing a report, brainstorming, or deep work, our Focus Time tool helps you achieve more in less time.`,
            image: l7
        },
    ]

    return (
        <section className="py-16 px-4 workprocess">
            <div className="max-w-7xl mx-auto">

                <motion.div className="text-center mb-12" initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true, amount: 0.2 }}
                    transition={{ duration: 0.5 }}
                >

                    {/* bg-[#F8F9FA] text-[#333333] text-xs sm:text-sm font-medium px-3 sm:px-4 py-1.5 sm:py-2 rounded-full border border-[#EDEFF3] */}
                    {/* <span className="font-medium text-[#333333]  px-4 py-2   rounded-full border border-[#EDEFF3] bg-[#F8F9FA] ">Our Services</span> */}
                    <h2 className="text-3xl md:text-3xl font-medium text-[#0C0C0C] mb-4 poppins lg-text max-w-2xl mx-auto">Lorem ipsum dolor</h2>
                    <p className="text-[#666666] max-w-3xl text-lg mx-auto">
                        Offering the most recent modern technology to grow your business exponentially equipped with top-quality technology that meets your desires.

                    </p>
                </motion.div>

                <div className="flex sm:flex-wrap sm:justify-center gap-4 mb-8 lg:mb-0" style={{overflowX:"auto"}} initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true, amount: 0.2 }}
                    transition={{ duration: 0.5 }}>
                    {steps.map((step, index) => (
                        <button
                            key={index}
                            onClick={() => setActiveStep(index)}
                            className={`flex flex-col items-center p-4 transition-colors ${activeStep === index
                                ? "border-b-2 border-emerald-500"
                                : "text-gray-500"
                                }`}
                        >
                            <div
                                className={`w-10 h-10 sm:w-16 sm:h-16 flex items-center justify-center mb-2 ${activeStep === index ? "bg-[#1DBB91] rounded-lg" : "bg-[#F6F7F9] rounded-lg"
                                    }`}
                            >
                                {activeStep === index ? step.Activeicon : step.NonActive}
                            </div>

                            <span className="text-xs sm:text-sm md:text-base text-[#666666]">{step.label}</span>
                        </button>

                    ))}


                </div>

                <div className="max-w-5xl border-t mx-auto mb-8 hidden lg:block " style={{ width: "100%" }}></div>



                <motion.div className="bg-gradient-to-br from-emerald-500 to-cyan-500 rounded-3xl overflow-hidden" initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true, amount: 0.2 }}
                    transition={{ duration: 0.5 }}>
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 p-8 items-center">

                        <div className="text-white space-y-6">
                            <div className="w-16 h-16 bg-white rounded-xl flex items-center justify-center">
                                <div className="text-emerald-500">{steps[activeStep].ActiveGrad}</div>
                            </div>
                            <h2 className="text-2xl font-medium  sm-text">{steps[activeStep].title}</h2>
                            <p className="text-white leading-relaxed">{steps[activeStep].description}</p>
                        </div>


                        <div className="relative">
                            <div className="bg-white rounded-3xl p-4 shadow-lg">
                                <img
                                    src={steps[activeStep].image || "/placeholder.svg"}
                                    alt={steps[activeStep].title}
                                    className="w-full h-auto rounded-2xl"
                                />
                            </div>
                        </div>
                    </div>
                </motion.div>
            </div>
        </section>
    )
}

