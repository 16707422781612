// Card.js
import React from 'react';
import "./GallerComp.css"
import { useEffect, useRef } from 'react';

const GalleryCard = ({ title, description, image, icon, index, totalCards }) => {

    const cardRef = useRef(null);
    const cardInnerRef = useRef(null);

    useEffect(() => {
        // Ensure that aat.js is loaded globally
        if (window.aat) {
            const { ScrollObserver, valueAtPercentage } = window.aat;
            const card = cardRef.current;
            const cardInner = cardInnerRef.current;
            const offsetTop = 20 + index * 20;
            card.style.paddingTop = `${offsetTop}px`;

            if (index === totalCards - 1) return;

            const toScale = 1 - (totalCards - 1 - index) * 0.1;
            const nextCard = document.querySelectorAll('.card')[index + 1];

            ScrollObserver.Element(nextCard, {
                offsetTop,
                offsetBottom: window.innerHeight - card.clientHeight
            }).onScroll(({ percentageY }) => {
                cardInner.style.scale = valueAtPercentage({
                    from: 1,
                    to: toScale,
                    percentage: percentageY
                });
                cardInner.style.filter = `brightness(${valueAtPercentage({
                    from: 1,
                    to: 0.6,
                    percentage: percentageY
                })})`;
            });
        }
    }, [index, totalCards]);




    return (

        // overflow-hidden rounded-3xl bg-white p-6 shadow-lg md:p-8 


        // <div className="card  overflow-hidden rounded-3xl bg-white p-6 shadow-lg md:p-8  " ref={cardRef} data-index={index}>
        // <div className="flex flex-col items-start gap-6 md:flex-row md:items-start card__inner" ref={cardInnerRef}> 


        <div className="card   " ref={cardRef} data-index={index}>



            <div className="flex flex-col items-start gap-6 md:flex-row md:items-center card__inner rounded-3xl bg-white p-6  md:p-8 border-4 border-[#E1E4EB]" ref={cardInnerRef} style={{minHeight:"500px"}}>
                <div className="flex-1 space-y-4 text-left">
                    {/* <div className="flex items-center gap-2 cursor-pointer">
                        <img src={icon} alt="Subscribe" />
                        <span className="text-sm" style={{ fontWeight: "500", color: "#717073" }}>Subscribe</span>
                    </div> */}
                    {/* <h2 className="text-2xl  text-[#131314] md:text-4xl fn-size-md " style={{ fontWeight: "500" }}>{title}</h2> */}
                    <p style={{ color: "#656565", fontWeight: "500" }} className='font-family-description text-lg'>{description}</p>
                </div>
                <div className="w-full md:w-1/2 ">
                    <img
                        src={image}
                        alt={title}
                       className="w-full h-48 md:h-64 lg:h-80 object-cover "
                    />
                </div>
            </div>
        </div>

    )
};

export default GalleryCard;
