import React from 'react'
import { useEffect, useRef } from 'react'
import img1 from "../../assets/images/ImagesCards/img1.png"
import img2 from "../../assets/images/ImagesCards/img2.png"
import img3 from "../../assets/images/ImagesCards/img3.png"


// import "./TeamImages.css"


const TeamImages = () => {

    const stackRef = useRef(null);

    useEffect(() => {
        let stack = stackRef.current;
        if (!stack) return;

        [...stack.children].reverse().forEach((i) => stack.append(i));

        function swap(e) {
            let card = stack.querySelector(".team-card:last-child");
            if (e.target !== card) return;
            card.style.animation = "swap 700ms forwards";

            setTimeout(() => {
                card.style.animation = "";
                stack.prepend(card);
            }, 700);
        }

        stack.addEventListener("click", swap);


        return () => {
            stack.removeEventListener("click", swap);
        };
    }, []);
    return (
        <section className="pb-24 px-4 mission">
            <div className="max-w-7xl mx-auto space-y-12 stack my-20 px-4" ref={stackRef}>


                <div className="grid grid-cols-1 md:grid-cols-2 gap-8 bg-img-grad  rounded-2xl overflow-hidden p-10 team-card" style={{ minHeight: "600px" }}>
                    <div>
                        <img
                            src={img1}
                            alt="Mission"
                            className="w-full h-full object-cover"
                        />
                    </div>
                    <div className="flex flex-col justify-center p-6">

                        <p className="text-lg text-white">
                            Our mission is to create a better future by leveraging technology,
                            innovation, and sustainable solutions. We believe in empowering
                            communities and making a positive impact on the world.
                        </p>
                    </div>
                </div>


                <div
                    className="grid grid-cols-1 gap-4 relative bg-img-grad rounded-2xl overflow-hidden p-10 team-card items-center"
                    style={{ minHeight: "600px" }}
                >
                    {/* First Row - Image and Text */}
                    <div className="grid grid-cols-[auto,1fr] items-center gap-3 absolute top-10 left-10">
                        <img src={img2} className="object-cover w-96 " />
                        <p className="text-lg font-medium manrope text-white">
                            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Placeat reprehenderit debitis nemo neque. Velit optio impedit amet qui, dolorum hic nihil asperiores, eos cumque obcaecati reiciendis. Nobis maxime eius temporibus!
                        </p>
                    </div>

                    {/* Second Row - Positioned Image and Text */}
                    <div className="grid grid-cols-[auto,1fr] items-center gap-3 absolute left-20 top-64 bottom-10">
                        <img src={img3} className="object-cover w-96 " />
                        <p className="text-lg font-medium manrope text-white">
                            Another description goes here. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cumque, voluptas?
                        </p>
                    </div>
                </div>

                {/* 
                <div className="flex flex-col space-y-4 w-[1000px] flex-shrink-0  relative">
                        <div className="flex items-center space-x-3">
                            <motion.img src={col1} className="w-[400px] object-cover" initial={{ opacity: 0, x: -100 }}
                                whileInView={{ opacity: 1, x: 0 }}
                                viewport={{ once: true, amount: 0.2 }}
                                transition={{ duration: 1 }} />
                            <p className="text-lg font-medium manrope text-white whitespace-wrap">
                                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Placeat reprehenderit debitis nemo neque. Velit optio impedit amet qui, dolorum hic nihil asperiores, eos cumque obcaecati reiciendis. Nobis maxime eius temporibus!
                            </p>
                        </div>
                        <div className="flex items-center space-x-3 relative absolute  -top-10 left-20 z-10">
                            <motion.img src={col2} className="w-[400px] object-cover" initial={{ opacity: 0, x: 100 }}
                                whileInView={{ opacity: 1, x: 0 }}
                                viewport={{ once: true, amount: 0.2 }}
                                transition={{ duration: 1 }} />
                            <p className="text-lg font-medium manrope text-white whitespace-wrap">
                                Another description goes here. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cumque, voluptas?
                            </p>
                        </div>
                    </div> */}


                {/* <div className="grid grid-cols-1 md:grid-cols-2 gap-8 bg-img-grad rounded-2xl overflow-hidden p-10 card" style={{minHeight:"600px"}}>
                    <div className='flex flex-col'>
                        <img
                            src={img9}
                            alt="Mission"
                            className="w-full h-full object-cover"
                        />
                    </div>
                    <div className="flex flex-col justify-center p-6">

                        <p className="text-lg text-white">
                            Our mission is to create a better future by leveraging technology,
                            innovation, and sustainable solutions. We believe in empowering
                            communities and making a positive impact on the world.
                        </p>
                    </div>
                </div> */}


            </div>
        </section>

    )
}

export default TeamImages
