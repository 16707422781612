import mobile_img from "../../assets/images/WorkDetails/SindhiSoulmate/iPhone.png"
import bg_grad from "../../assets/images/WorkDetails/SindhiSoulmate/bg_grad.png"
import iphone_2 from "../../assets/images/WorkDetails/SindhiSoulmate/iphone_2.png"
import iphone_3 from "../../assets/images/WorkDetails/SindhiSoulmate/iphone_3.png"
import { motion } from "framer-motion";


export default function AccountSteps() {
    const steps = [
        {
            step: "01",
            title: "Registration",
            image: mobile_img,
            description:
                "Lorem ipsum,Lorem ipsum dolor, sit Commodi iure numquam facilis itaque,Lorem ipsum,Lorem ipsum dolor, sit Commodi iure numquam facilis itaque??",
        },
        {
            step: "02",
            title: "Login Account",
            image: iphone_2,
            description:
                "Lorem ipsum,Lorem ipsum dolor, sit Commodi iure numquam facilis itaque,Lorem ipsum,Lorem ipsum dolor, sit Commodi iure numquam facilis itaque??",
        },
        {
            step: "03",
            title: "OTP verification",
            image: iphone_3,
            description:
                "Lorem ipsum,Lorem ipsum dolor, sit Commodi iure numquam facilis itaque,Lorem ipsum,Lorem ipsum dolor, sit Commodi iure numquam facilis itaque??",
        },
    ]

    return (
        <motion.section className="py-16 px-4 "   >
            <div className="max-w-7xl mx-auto" >
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
                    {steps.map((step, index) => (
                        <motion.div key={index} className="bg-white rounded-3xl text-center border-[1.1px]" initial={{ opacity: 0, y: 50 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true, amount: 0.2 }}
                        transition={{ duration: 0.5 }}>
                            {/* Upper part with background image */}
                            <div
                                className="relative mx-auto  p-7 rounded-t-3xl"
                                style={{
                                    backgroundImage: `url(${bg_grad})`,
                                    backgroundSize: "cover",
                                    backgroundPosition: "center",
                                }}
                            >
                                <div className="relative z-10 overflow-hidden -mb-7">
                                    <img
                                        src={step.image || "/placeholder.svg"}
                                        alt={`Step ${step.step} screenshot`}
                                        className="w-full rounded-t-3xl scale-110"
                                    />
                                </div>
                            </div>

                            <div className="bg-white p-7 rounded-b-3xl inst-sans">
                                <div className="mb-4 border-[1.1px] border-[#E6E9EE] rounded-full px-4 py-1 inline-block">
                                    <span className="text-base text-[#3D3D3D]">Step {step.step}</span>
                                </div>


                                <h3 className="text-xl font-medium mb-4 fightree text-[#191919]">Create an Account</h3>

                                <p className="text-[#606060] text-lg font-medium ">{step.description}</p>
                            </div>
                        </motion.div>
                    ))}
                </div>
            </div>
        </motion.section>
    )
}

