// Card.js
import React from 'react';
import "./DevelopmentSection.css"
import arrow_icon from "../../assets/images/right_arrow.svg"
import { useEffect, useRef } from 'react';
import cursor_img from "../../assets/images/Services/WebApp/cursor.svg"
import { Link } from 'react-router-dom';
const Card = ({ title, description, image, icon, index, totalCards }) => {

    const cardRef = useRef(null);
    const cardInnerRef = useRef(null);

    useEffect(() => {
        // Ensure that aat.js is loaded globally
        if (window.aat) {
            const { ScrollObserver, valueAtPercentage } = window.aat;
            const card = cardRef.current;
            const cardInner = cardInnerRef.current;
            const offsetTop = 20 + index * 20;
            card.style.paddingTop = `${offsetTop}px`;

            if (index === totalCards - 1) return;

            const toScale = 1 - (totalCards - 1 - index) * 0.1;
            const nextCard = document.querySelectorAll('.card')[index + 1];

            ScrollObserver.Element(nextCard, {
                offsetTop,
                offsetBottom: window.innerHeight - card.clientHeight
            }).onScroll(({ percentageY }) => {
                cardInner.style.scale = valueAtPercentage({
                    from: 1,
                    to: toScale,
                    percentage: percentageY
                });
                cardInner.style.filter = `brightness(${valueAtPercentage({
                    from: 1,
                    to: 0.6,
                    percentage: percentageY
                })})`;
            });
        }
    }, [index, totalCards]);




    return (

        // overflow-hidden rounded-3xl bg-white p-6 shadow-lg md:p-8 


        // <div className="card  overflow-hidden rounded-3xl bg-white p-6 shadow-lg md:p-8  " ref={cardRef} data-index={index}>
        // <div className="flex flex-col items-start gap-6 md:flex-row md:items-start card__inner" ref={cardInnerRef}> 


        <div className="card   " ref={cardRef} data-index={index}>



            <div className="flex flex-col items-start gap-6 md:flex-row md:items-start card__inner rounded-3xl bg-white p-6  md:p-8 " ref={cardInnerRef}>
                <div className="flex-1 space-y-4 text-left"> {/* Added text-left to align text to the left */}
                    <div className="bg-[#F8F9FA] rounded-lg border border-[#EDEFF3] p-2 inline-block">
                        <img src={icon} alt="service" className='w-6 h-6' />
                    </div>

                    <h2 className="text-2xl  text-[#131314] md:text-4xl fn-size-md " style={{ fontWeight: "500" }}>{title}</h2>
                    <p style={{ color: "#656565", fontWeight: "500" }} className='font-family-description'>{description}</p>

                    <Link to={"/consult-us"} className="font-medium text-[#333333]   px-3 py-2  gap-1  rounded-full border border-[#EDEFF3] bg-[#F8F9FA] text-sm flex " style={{ display: "inline-flex" }}>

                    

                        <span className='' >Get Started</span>
                        <img src={arrow_icon} alt="" className="w-3 sm:w-4 h-5 " />
                    </Link>
                </div>
                <div className="w-full md:w-1/2">
                    <img
                        src={image}
                        alt={title}
                        className="h-auto w-full object-contain"
                    />
                </div>
            </div>
        </div>

    )
};

export default Card;
