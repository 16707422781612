// import { useState } from "react";
// import "./expand.css"; 
import test1 from "../../assets/images/test1.webp"
import test2 from "../../assets/images/test2.webp"
import test3 from "../../assets/images/test3.webp"
import test4 from "../../assets/images/test4.webp"



import { motion } from "framer-motion";

// const cardData = [
//   {
//     name: "Monstera",
//     author: "Aditya Aiyarasddddddddddddddddddddddddddddddddddddddddd",
//     image: test1,
//     link: "https://www.pexels.com/photo/close-up-photo-of-green-leafed-plant-1407305/"
//   },
//   {
//     name: "Dracaena",
//     author: "Teona Swift",
//     image: test2,
//     link: "https://www.pexels.com/photo/snake-plant-with-ornamental-leaves-in-house-6913569/"
//   },
//   {
//     name: "Alocacia",
//     author: "Luiz de Oliveira",
//     image: test1,
//     link: "https://www.pexels.com/photo/green-leaves-of-a-plant-7388195/"
//   },
//   {
//     name: "Begonia",
//     author: "Eva Bronzini",
//     image: test2,
//     link: "https://www.pexels.com/photo/green-leaves-with-dots-6072161/"
//   },
//   {
//     name: "Calathea",
//     author: "Karolina Grabowska",
//     image: test1,
//     link: "https://www.pexels.com/photo/green-and-white-leaf-plant-4593961/"
//   }
// ];

// export default function ExpandningCards() {
//   const [activeIndex, setActiveIndex] = useState(0);

//   return (
//     <div className="cards">
//       {cardData.map((card, index) => (
//         <div
//           key={index}
//           className={`card ${activeIndex === index ? "active" : ""}`}
//           onClick={() => setActiveIndex(index)}
//         >
//           <img src={card.image} alt={card.name} className="card__image" />
//           <div className="card__infos">
//             <h3 className="card__name">{card.name}</h3>
//             <a href={card.link} className="card__author">{card.author}</a>
//           </div>
//         </div>
//       ))}
//     </div>
//   );
// }



import { useState } from "react";
// import { FaWalking, FaSnowflake, FaTree, FaTint, FaSun } from "react-icons/fa";
import "./expand.css";

const optionsData = [
  {
    id: 1,
    image: test4,
    // icon: <FaWalking />,
    title: "Discovery & Consultation",
    subtitle: "Understanding your business goals and CMS needs your business goals and CMS needsUnderstanding your business goals and CMS needs your business ",
  },
  {
    id: 2,
    image: test2,
    // icon: <FaSnowflake />,
    title: "Platform Selection",
    subtitle: "Choosing the right CMS based on your business type (WordPress, Webflow, Framer, or Shopify).",
  },
  {
    id: 3,
    image: test3,
    // icon: <FaTree />,
    title: "Design & Development",
    subtitle: "Custom design and development using Elementor, Webflow, Framer, and Shopify.",
  },
  // {
  //   id: 4,
  //   image: test2,
  //   // icon: <FaTint />,
  //   title: "Idiefe",
  //   subtitle: "Omuke trughte a otufta",
  // },

  {
    id: 5,
    image: test1,
    // icon: <FaTint />,
    title: "Ongoing Support & Maintenance",
    subtitle: "Providing continuous support and updates for your CMS.",
  },


];

export default function ExpandningCards() {
  const [activeOption, setActiveOption] = useState(optionsData[0].id);

  return (

    <section className="py-16 px-4 relative expand">
      <div className="max-w-7xl mx-auto">

        <motion.div className="text-center mb-12 max-w-5xl mx-auto" initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true, amount: 0.2 }}
          transition={{ duration: 0.5 }}
        >
          <h2 className="text-3xl md:text-4xl font-semibold text-[#020710] mb-4  lg-text  mx-auto" >
            Lorem Ipusm dolor sit amet consectetur. </h2>
          <p className="text-[#485E79] max-w-2xl text-base md:text-lg mx-auto">
            Lorem ipsum dolor sit amet consectetur, adipisicing elit A, ab nemo! Expedita!Lorem ipsum dolor sit amet consectetur
          </p>
        </motion.div>

        <div className="main-container ">


          <motion.div className="options" initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true, amount: 0.2 }}


                    transition={{ duration: 0.5 }}>
            {optionsData.map((option) => (
              <div
                key={option.id}
                className={`option ${activeOption === option.id ? "active" : ""}`}
                style={{ "--optionBackground": `url(${option.image})` }}
                onClick={() => setActiveOption(option.id)}
              >


                <div className="shadow"></div>
                <div className="label">

                  <div className="info">
                    <div className="main text-sm sm:text-xl md:text-3xl font-semibold ">{option.title}</div>
                    <div className="sub text-xs sm:text-base">{option.subtitle}</div>
                  </div>
                </div>
              </div>
            ))}

          </motion.div>

        </div>

      </div>

    </section>
  );
}
