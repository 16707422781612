import main_img from "../../assets/images/WorkDetails/ParenticianPage/banner_img.webp"
// import ProjectDescription from "./ProjectDescription"
// import "./ProjectDetails.css"

import logo from "../../assets/images/WorkDetails/ParenticianPage/logo.svg"
import { motion } from "framer-motion";

import ParenticianDescription from "./ParenticianDescription";

export default function ParenticianPageDetails() {

    const projectDetails = {
        categories: ["Mobile App", "UI/UX Design", "Development"],
        date: "2023-2024",
        team: ["UX Designer", "UI Designer", "Full Stack Dev"],
    }

    return (
        <section className="relative py-0 sm:py-12 px-4 project -z-10">


            <div className="max-w-5xl mx-auto py-8">


                <motion.div className="mb-7 sm:mb-10" initial={{ opacity: 0, y: 50 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true, amount: 0.2 }}
                        transition={{ duration: 0.5 }}>
                    <h1 className="text-3xl md:text-5xl font-medium text-[#292929] lg-text fightree text-center" 
                    >Designed & Developed by Tech Rabbit: Meet Parentician</h1>
                </motion.div>






                <motion.div className="" initial={{ opacity: 0, y: 50 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true, amount: 0.2 }}
                        transition={{ duration: 0.5 }} >
                    <img
                        src={main_img}
                        alt="Featured story"
                        className="w-full "
                    />
                </motion.div>


            </div>


            <motion.section className="relative   project ">


                <div className="max-w-7xl mx-auto border-b-[1.44px] py-5  border-[#E6EBF5]">
                    <img
                        src={logo}
                        alt="Sindhi Soulmate"
                        className="h-14 w-auto"
                    />
                </div>

            </motion.section>

            {/* <ProjectDescription /> */}
            <ParenticianDescription/>


        </section>


    )
}



