import React from 'react'
import "./JobReq.css"
import location from "../../assets/images/CareerPage/location_svg.svg"
import experience from "../../assets/images/CareerPage/exp.svg"
import full_time from "../../assets/images/CareerPage/full_time.svg"


const JobReq = () => {
    return (
        <section className='py-16 px-4 jobreq'>

            <div className='max-w-5xl mx-auto'>

                <div className=" bg-white rounded-2xl p-1 max-w-6xl mx-auto " initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true, amount: 0.2 }}
                    transition={{ duration: 0.5 }}
                    style={{ boxShadow: "0px 0px 4px 0px #00000040" }}
                >
                    <div className="bg-gradient-to-b from-[#EBFFFA] to-[#FFFFFF] rounded-2xl  p-6 ">
                        {/* <h3 className="text-lg md:text-xl font-semibold text-[#111111] flex items-center gap-2">
                                    {job.title}
                                    <span className={`px-2 py-1 rounded-full text-xs font-semibold ${job.categoryColor}`}>
                                        {job.category}
                                    </span>
                                </h3> */}


                        <h1 className="text-xl sm:text-2xl md:text-4xl font-semibold text-[#111111] mb-4 inter lg-text flex items-center gap-2">
                       <span> QA Engineer</span>

                            <span className='text-[#C48C00] text-sm sm:text-base md:text-lg px-3 py-1 rounded-full bg-[#FFF5D1]'>Quality Assurance</span>
                        </h1>
                        <span className='text-[#3E3E59] text-lg sm:text-xl md:text-3xl lg:text-4xl mb-4'>Collaborate with our development team to ensure top-notch software quality</span>

                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-4 items-center">

                            <div className="text-[#5F6980] text-base sm:text-lg md:text-xl lg:text-2xl flex items-center gap-2 ">
                                <img src={location} alt="Location" className="w-4 h-4 md:w-5 md:h-5 lg:w-6 lg:h-6" />
                                Ahmedabad
                            </div>
                            <div className="flex flex-col sm:flex-row sm:justify-end gap-2 md:gap-6">
                
                                <div className="flex items-center gap-2 text-[#5F6980] text-base sm:text-lg md:text-xl lg:text-2xl">
                                    <img src={experience} alt="Experience" className="w-5 h-5 md:w-6 md:h-6  lg:w-7 lg:h-7" />
                                    2-4 years
                                </div>

                         
                                <div className="flex items-center gap-2 text-[#5F6980] text-base sm:text-lg md:text-xl lg:text-2xl">
                                    <img src={full_time} alt="Full Time" className="w-5 h-5 md:w-6 md:h-6 lg:w-7 lg:h-7" />
                                    Full time
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>


        </section>
    )
}

export default JobReq
