
import { technologies } from "../../data/techStackData"
import { useState, useRef, useEffect } from "react";
import "./TechStack.css";
import { motion } from "framer-motion";
import hoverscikit from "../../assets/images/TechStack/scikit-hover.png"
import { technologies2nd } from "../../data/techStackData";
import hoverazure from "../../assets/images/TechStack/azure-hover.png"
import hoverelemetor from "../../assets/images/TechStack/element_hover_2.png"
import { useMediaQuery } from "@mui/material";
import { techCms } from "../../data/techStackCMS";
import { techWeb } from "../../data/techStackWeb";
import { techstackai } from "../../data/techStackAI";
export default function TechStack() {

    const [isHovered, setIsHovered] = useState(false);
    const [isHoveredpt, setIsHoveredpt] = useState(false);
    const [hoveredIndex, setHoveredIndex] = useState(null);

    const isDesktop = useMediaQuery('(min-width:1024px)');
    const isMobile = useMediaQuery('(min-width:640px)');

    const trackRef = useRef(null);
    const trackRef2 = useRef(null);


    useEffect(() => {
        const track = trackRef.current;
        if (track && isDesktop) {
            // Duplicate items for smooth infinite scrolling
            track.innerHTML += track.innerHTML;
        }
    }, []);

    useEffect(() => {
        const track = trackRef2.current;
        if (track && isDesktop) {
            // Duplicate items for smooth infinite scrolling
            track.innerHTML += track.innerHTML;
        }
    }, []);
    return (
        <section className="py-16 px-4 max-w-7xl mx-auto tech-stack" initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true, amount: 0.2 }}
            transition={{ duration: 0.5 }} >
            <motion.div className="text-center mb-12" initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true, amount: 0.2 }}
                transition={{ duration: 0.5 }} >
                <h2 className="text-3xl md:text-5xl font-medium text-[#212A40] mb-4 poppins lg-text">Tech-Stack we use</h2>
                <p className="text-[#485E79] max-w-2xl text-lg mx-auto">
                    From front-end to back-end, we utilize the latest tools and frameworks to deliver seamless, high-quality digital experiences.

                </p>
            </motion.div>

            {!isMobile ? (<>

                <div className="space-y-8 flex flex-row h-full  px-6 justify-center items-center ">


                    <div className="grid grid-cols-2 sm:grid-cols-3 gap-12 srvc-shadow ">




                        <div className="srvc-card-slider">
                            <div className="space-y-6 srvc-card-track" >
                                {/* {apps.map((app, index) => (
                                    <div key={index}>
                                        <div className="group rounded-2xl overflow-hidden bg-white cursor-pointer relative ct-card">
                                            <div className="relative aspect-[4/3] overflow-hidden">
                                                <img
                                                    src={app.img || "/placeholder.svg"}
                                                    className="w-full h-auto object-cover"
                                                />
                                            </div>

                                            <div className="p-2">
                                                <div className="flex items-start gap-3 mb-3">
                                                    <div className="w-10 h-10 rounded-[10px] overflow-hidden flex-shrink-0 shadow-[0px_0px_1.19px_0px_#00000040] flex items-center justify-center">
                                                        <img
                                                            src={app.logo || "/placeholder.svg"}
                                                            className="max-w-full max-h-full object-contain"
                                                        />
                                                    </div>

                                                    <div className="flex flex-col">
                                                        <h3 className="font-semibold text-base text-[#434343] fightree">{app.title}</h3>
                                                        <p className="text-[#8A8A8A] text-sm fightree">{app.description}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))} */}

                                {techWeb?.map((tech, index) => (
                                    <div
                                        key={index}
                                        className="flex flex-col cursor-pointer items-center justify-center group bg-[#F9FAFB]  rounded-3xl p-4 transition-colors duration-300 hover:bg-[#22989F] ct-card border border-[#E5E7EB]"
                                    >
                                        <div className="w-20 h-20 mb-3 flex items-center justify-center transition-transform duration-300 hover:-translate-y-2  ">
                                            <img
                                                src={tech.logo || "/placeholder.svg"}
                                                alt={`${tech.name} logo`}
                                                // className="object-contain group-hover:filter group-hover:brightness-0 group-hover:invert"
                                                className={`object-contain ${["Webflow", "Node Js"].includes(tech.name)
                                                    ? "" // No hover effect for these
                                                    : "w-14"
                                                    } 
                            
                            
                            
                            ${["Sanity", "Angular", "PostgreSQL"].includes(tech.name)
                                                        ? "" // No hover effect for these
                                                        : "group-hover:filter group-hover:brightness-0 group-hover:invert"
                                                    }`}
                                            />
                                        </div>
                                        <span className="text-xl text-[#3C4858] text-center font-600 group-hover:text-[#FFFFFFCC] transition-colors duration-300">{tech.name}</span>
                                    </div>
                                ))}
                            </div>
                        </div>




                        <div className='srvc-card-slider-2'>

                            <div className="space-y-6 srvc-card-track-2" >
                                {/* {apps2.map((app, index) => (
                                    <div key={index}>
                                        <div className="group rounded-2xl overflow-hidden bg-white cursor-pointer relative ct-card-2">
                                            <div className="relative aspect-[4/3] overflow-hidden">
                                                <img
                                                    src={app.img || "/placeholder.svg"}
                                                    className="w-full h-auto object-cover"
                                                />
                                            </div>

                                            <div className="p-2">
                                                <div className="flex items-start gap-3 mb-3">
                                                    <div className="w-10 h-10 rounded-[10px] overflow-hidden flex-shrink-0 shadow-[0px_0px_1.19px_0px_#00000040] flex items-center justify-center">
                                                        <img
                                                            src={app.logo || "/placeholder.svg"}
                                                            className="max-w-full max-h-full object-contain"
                                                        />
                                                    </div>

                                                    <div className="flex flex-col">
                                                        <h3 className="font-semibold text-base text-[#434343] fightree">{app.title}</h3>
                                                        <p className="text-[#8A8A8A] text-sm fightree">{app.description}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))} */}

                                {techstackai?.map((tech, index) => (
                                    <div
                                        key={index}
                                        className="flex flex-col cursor-pointer items-center justify-center group bg-[#F9FAFB]  rounded-3xl p-4 transition-colors duration-300 hover:bg-[#22989F] ct-card-2 border border-[#E5E7EB]"
                                    >
                                        <div className="w-20 h-20 mb-3 flex items-center justify-center transition-transform duration-300 hover:-translate-y-2  ">
                                            <img
                                                src={tech.logo || "/placeholder.svg"}
                                                alt={`${tech.name} logo`}
                                                // className="object-contain group-hover:filter group-hover:brightness-0 group-hover:invert"
                                                className={`object-contain ${["Webflow", "Node Js"].includes(tech.name)
                                                    ? "" // No hover effect for these
                                                    : "w-14"
                                                    } 
                            
                            
                            
                            ${["Sanity", "Angular", "PostgreSQL"].includes(tech.name)
                                                        ? "" // No hover effect for these
                                                        : "group-hover:filter group-hover:brightness-0 group-hover:invert"
                                                    }`}
                                            />
                                        </div>
                                        <span className="text-xl text-[#3C4858] text-center font-600 group-hover:text-[#FFFFFFCC] transition-colors duration-300">{tech.name}</span>
                                    </div>
                                ))}
                            </div>



                        </div>

                        <div className="srvc-card-slider hidden sm:block">
                            <div className="space-y-6 srvc-card-track" >
                                {/* {apps.map((app, index) => (
                                    <div key={index}>
                                        <div className="group rounded-2xl overflow-hidden bg-white cursor-pointer relative ct-card">
                                            <div className="relative aspect-[4/3] overflow-hidden">
                                                <img
                                                    src={app.img || "/placeholder.svg"}
                                                    className="w-full h-auto object-cover"
                                                />
                                            </div>

                                            <div className="p-2">
                                                <div className="flex items-start gap-3 mb-3">
                                                    <div className="w-10 h-10 rounded-[10px] overflow-hidden flex-shrink-0 shadow-[0px_0px_1.19px_0px_#00000040] flex items-center justify-center">
                                                        <img
                                                            src={app.logo || "/placeholder.svg"}
                                                            className="max-w-full max-h-full object-contain"
                                                        />
                                                    </div>

                                                    <div className="flex flex-col">
                                                        <h3 className="font-semibold text-base text-[#434343] fightree">{app.title}</h3>
                                                        <p className="text-[#8A8A8A] text-sm fightree">{app.description}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))} */}

                                {techCms?.map((tech, index) => (
                                    <div
                                        key={index}
                                        className="flex flex-col cursor-pointer items-center justify-center group bg-[#F9FAFB]  rounded-3xl p-4 transition-colors duration-300 hover:bg-[#22989F] ct-card border border-[#E5E7EB]"
                                    >
                                        <div className="w-20 h-20 mb-3 flex items-center justify-center transition-transform duration-300 hover:-translate-y-2  ">
                                            <img
                                                src={tech.logo || "/placeholder.svg"}
                                                alt={`${tech.name} logo`}
                                                // className="object-contain group-hover:filter group-hover:brightness-0 group-hover:invert"
                                                className={`object-contain ${["Webflow", "Node Js"].includes(tech.name)
                                                    ? "" // No hover effect for these
                                                    : "w-14"
                                                    } 
                            
                            
                            
                            ${["Sanity", "Angular", "PostgreSQL"].includes(tech.name)
                                                        ? "" // No hover effect for these
                                                        : "group-hover:filter group-hover:brightness-0 group-hover:invert"
                                                    }`}
                                            />
                                        </div>
                                        <span className="text-xl text-[#3C4858] text-center font-600 group-hover:text-[#FFFFFFCC] transition-colors duration-300">{tech.name}</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>



            </>













            ) : (


                <>
                    <div className="space-y-5" initial={{ opacity: 0, y: 50 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true, amount: 0.2 }}
                        transition={{ duration: 0.5 }} >

                        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-8 justify-items-center " initial={{ opacity: 0, y: 50 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            viewport={{ once: true, amount: 0.2 }}
                            transition={{ duration: 0.5 }} >


                            {technologies.map((tech, index) => (
                                <div
                                    key={index}
                                    className={`flex flex-col  cursor-pointer items-center justify-center group bg-white shadow-[0px_0px_3px_rgba(60,72,88,0.15)] rounded-lg p-4 transition-colors duration-300 hover:bg-[#22989F] 
                                 `}
                                    onMouseEnter={() => setHoveredIndex(index)}
                                    onMouseLeave={() => setHoveredIndex(null)}
                                >
                                    <div className="w-32 h-20 mb-3 flex items-center justify-center transition-transform duration-300 hover:-translate-y-2">
                                        <img
                                            src={hoveredIndex === index && tech.hover ? tech.hover : tech.logo || "/placeholder.svg"}

                                            alt={`${tech.name} logo`}
                                            className={`object-contain transition-all duration-300
          ${["Webflow", "Node Js", "Scikit-Learn"].includes(tech.name) ? "" : "w-14"} 
          ${["Sanity", "Angular", "PostgreSQL", "Scikit-Learn"].includes(tech.name) ? "" : "group-hover:filter group-hover:brightness-0 group-hover:invert"}
        `}
                                        />
                                    </div>
                                    <span className="text-xl text-[#3C4858] text-center font-600 group-hover:text-[#FFFFFFCC] transition-colors duration-300">{tech.name}</span>
                                </div>
                            ))}


                        </div>

                        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-4 gap-8 justify-items-center max-w-full lg:max-w-4xl mx-auto " initial={{ opacity: 0, y: 50 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            viewport={{ once: true, amount: 0.2 }}
                            transition={{ duration: 0.5 }} >
                            {technologies2nd.map((tech, index) => (
                                <div
                                    key={index}
                                    className={`flex flex-col cursor-pointer items-center justify-center group bg-white shadow-[0px_0px_3px_rgba(60,72,88,0.15)] rounded-lg p-4 transition-colors duration-300 hover:bg-[#22989F]
                             `} onMouseEnter={() => setHoveredIndex(index)}
                                    onMouseLeave={() => setHoveredIndex(null)}
                                >
                                    <div className="w-32 h-20 mb-3 flex items-center justify-center transition-transform duration-300 hover:-translate-y-2"
                                    >
                                        <img
                                            src={hoveredIndex === index && tech.hover ? tech.hover : tech.logo || "/placeholder.svg"}
                                            alt={`${tech.name} logo`}
                                            className={`object-contain transition-all duration-300
    ${["Google Cloud AI"]?.includes(tech.name) ? "" : "w-14"} 
    ${["Azure", "Elementor"]?.includes(tech.name) ? "" : "group-hover:filter group-hover:brightness-0 group-hover:invert"}
  `}
                                        />
                                    </div>
                                    <span className="text-xl text-[#3C4858] text-center font-600 group-hover:text-[#FFFFFFCC] transition-colors duration-300">{tech.name}</span>
                                </div>
                            ))}


                        </div>

                    </div>


                </>















            )}






        </section>
    )
}

