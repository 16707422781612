import React from 'react'
import { motion } from 'framer-motion'
// import "./AIServices.css"
import begin_img from "../../../assets/images/Services/MobileApp/first_img.png"
import "./MobileAppDev.css"
import next_gen from "../../../assets/images/Services/MobileApp/next-gen.svg"
import transfom from "../../../assets/images/Services/MobileApp/transform.svg"
import empower from "../../../assets/images/Services/MobileApp//empowering.svg"
import ai_powered from "../../../assets/images/Services/MobileApp/ai_powered.svg"

const grid_data = [


    {
        img: next_gen,
        text: "Next-Gen Performance & Speed"
    },


    {
        img: transfom,
        text: "Transform Industries with Mobile AI"
    },


    {
        img: empower,
        text: "Empowering Developers & Businesses"
    },


    {
        img: ai_powered,
        text: "AI-Powered Mobile Experiences"
    },
]
const MobileBegin = () => {
    return (

        <section className="relative  py-0 md:py-16 px-4 ">


            <div className="max-w-7xl mx-auto">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-start p-0 lg:p-4 mobilebegin">

                    <motion.div
                        className="space-y-8 flex flex-col h-full relative group justify-center"
                        initial={{ opacity: 0, y: 50 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true, amount: 0.2 }}
                        transition={{ duration: 0.5 }}
                    >

                        <img src={begin_img} alt="" className='rounded-xl' />



                    </motion.div>


                    <motion.div className="flex flex-col h-full justify-center" initial={{ opacity: 0, y: 50 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true, amount: 0.2 }}
                        transition={{ duration: 0.5 }}>
                        <div className="space-y-10">

                            <span className="font-medium text-[#333333]  px-4 py-2   rounded-full border border-[#EDEFF3] bg-[#F8F9FA] ">Mobile App</span>
                            <h3 className="text-2xl md:text-4xl font-medium text-[#0C0C0C] lg-text">
                                Build the <span className='text-[#25B57E]'>Future of Mobile</span> <br />with Us
                            </h3>

                            <div>
                                <p className=" text-[#606060]" style={{ fontSize: "17px" }}>
                                    Briefly introduce your firm and the value you bring. Emphasize your expertise in mobile app development and the impact it can have on clients' businesses.
                                </p>

                                <p className=" text-[#606060] mt-2" style={{ fontSize: "17px" }}>

                                    At Tech Rabbit, we specialize in creating mobile experiences that are intuitive, scalable, and customized for your business. From concept to deployment, we craft mobile apps that help you reach your audience wherever they are.
                                </p>


                            </div>

                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                {/* <div className="bg-white rounded-2xl p-4 border border-[#E6E9EE] flex items-center gap-3">
                             
                                    <img src={next_gen} alt="Next Gen" className="" />

                         
                                    <p className="text-[#4C4C4C] text-lg font-medium">
                                        Our team of developers ensures seamless,
                                    </p>
                                </div>
                                <div className="bg-white  rounded-2xl p-4 border border-[#E6E9EE]">

                                    <p className="text-[#4C4C4C] text-lg font-medium ">
                                        We build apps that grow with your business,
                                    </p>
                                </div>

                                <div className="bg-white  rounded-2xl p-4 border border-[#E6E9EE]">

                                    <p className="text-[#4C4C4C] text-lg font-medium">
                                        Our team of developers ensures seamless,
                                    </p>
                                </div>
                                <div className="bg-white  rounded-2xl p-4 border border-[#E6E9EE]">

                                    <p className="text-[#4C4C4C] text-lg font-medium ">
                                        We build apps that grow with your business,
                                    </p>
                                </div> */}

                                {grid_data.map((item, index) => {
                                    return (
                                        <div className="bg-white rounded-2xl px-2 py-3  border border-[#E6E9EE] flex items-center gap-3" key={index}>

                                            <img src={item?.img} alt="Next Gen" className="" />


                                            <p className="text-[#4C4C4C] text-lg font-medium">
                                                {item?.text}
                                            </p>
                                        </div>
                                    );
                                })}



                            </div>






                        </div>
                    </motion.div>

                </div>
            </div>
        </section>
    )
}

export default MobileBegin
