import React from "react";

import { HeroSection } from "../components/HeroSection";
import Navbar from "../components/Navbar";
import Partners from "../components/Partners";

import left from "../assets/images/left-overlay.webp"
import right from "../assets/images/right-overlay.webp"
import DevelopmentSection from "../components/DevelopmentSection/DevelopmentSection";
import SplitLanding from "../components/SplitLanding/SplitLanding";
import WorkflowSection from "../components/Workflow/Workflow";


import { motion } from "framer-motion";
import { useState } from "react";

import { useEffect } from "react";
import TechStack from "../components/TechStackSection/TechStack";
import PortfolioSection from "../components/BestWork/BestWork";
import IndustriesSection from "../components/Industries/IndustriesSection";
import TestimonialsSection from "../components/Testimonials/Testimonials";

import BlogSection from "../components/OurBlogs/BlogSection";
import Footer from "../components/Footer/Footer";
import FAQsWork from "../components/FAQsWork/FAQsWork";
import cookies_img from "../assets/images/cookies.svg"
import Loader from "../components/Loader1/Loader";
import CookieConsent from "./CookiesConstent";
import ConsultationPopup from "./MobileDevPage/ConsultationPopup";


function LandingPage() {



  const [cardStack, setCardStack] = useState(false)

  const [scrollPosition, setScrollPosition] = useState(0);

  const [scrollPositionCards, setScrollPositionCards] = useState(0);

  // Detect scroll event
  useEffect(() => {
    const handleScrollCards = () => {
      setScrollPositionCards(window.scrollY); // Set scroll position on scroll
    };

    window.addEventListener("scroll", handleScrollCards); // Add scroll event listener
    return () => {
      window.removeEventListener("scroll", handleScrollCards); // Clean up event listener
    };
  }, []);

  // Zoom effect based on scroll position
  const calculateZoom = (scrollPositionCards) => {
    const zoomFactor = Math.max(1 - scrollPositionCards / 1000, 0.7); // Adjust zoom sensitivity here
    return zoomFactor;
  };


  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const [animate, setAnimate] = useState(false);
  useEffect(() => {
    // Trigger animation on mount
    setAnimate(true);

    // Optional: Remove animation class after it's done
    const timer = setTimeout(() => setAnimate(false), 1000); // Animation duration is 1s
    return () => clearTimeout(timer); // Clean up the timer on unmount
  }, []);

  const [isOpen, setIsOpen] = useState(false)
  const [loading, setLoading] = useState(true);

  // useEffect(() => {

  //   const handleLoad = () => {
  //     setLoading(false); 
  //   };

  //   window.addEventListener("load", handleLoad);

  //   return () => window.removeEventListener("load", handleLoad);
  // }, []);

  // useEffect(() => {
  //   if (!loading) {

  //     setTimeout(() => setAnimate(true), 100);
  //   }
  // }, [loading]);

  // if (loading) {
  //   return <Loader />;
  // }
  useEffect(() => {
    const handleLoad = () => {
      setTimeout(() => setLoading(false), 1500);
    };

    if (document.readyState === "complete") {
      setTimeout(() => setLoading(false), 1500);
    } else {
      window.addEventListener("load", handleLoad);
    }

    return () => window.removeEventListener("load", handleLoad);
  }, []);

  useEffect(() => {
    if (!loading) {
      setTimeout(() => setAnimate(true), 100);
    }
  }, [loading]);

  if (loading) {
    return <Loader />;
  }


  return (



    <motion.div className="relative flex min-h-screen py-10 flex-col bg-white"  >

      <main className="flex-1 ">

        <div className="w-full relative" style={{ zIndex: "999" }}>
          <Navbar isOpen={isOpen} setIsOpen={setIsOpen} />
          {/* <div
            className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 p-4"
            style={{ pointerEvents: "auto" }}
          >
            <div className="bg-white px-6 py-8 sm:px-10 sm:py-12 rounded-3xl text-center border-2 border-[#EBEDEF] max-w-xl w-full">
              <img src={cookies_img} alt="" className="mx-auto w-16 sm:w-28" />

              <h2 className="text-xl sm:text-3xl font-semibold mt-8">We Value Your Privacy</h2>
              <p className="text-[#555555] mt-6 sm:mt-8 text-base sm:text-lg">
                We use cookies to enhance your experience, analyze site traffic, and serve personalized content.
                By clicking 'Allow Cookies,' you consent to our use of cookies. You can manage your preferences
                or reject non-essential cookies anytime.
              </p>

              <div className="mt-6 sm:mt-8 flex justify-center space-x-4">
                <button className="bg-blue-600 text-white px-6 py-3 rounded-lg text-base sm:text-lg font-medium hover:bg-blue-700 transition consultation-btn">
                  Allow Cookies
                </button>
                <button className="text-[#3A3A3A] px-6 py-3 rounded-lg text-base  sm:text-lg font-medium transition border border-[#E1E4EB] hover:bg-[#F5F5F5]">
                  Reject Cookies
                </button>

              </div>
            </div>
          </div> */}

          <CookieConsent/>
{/* <ConsultationPopup/> */}


          <div className="relative -z-10">
            <HeroSection isOpen={isOpen} setIsOpen={setIsOpen} />




            <img
              src={left}
              alt="Left decoration"
              className={`absolute top-0 left-0 h-auto max-h-[70%] pointer-events-none left-joint ${animate ? "animate__animated animate__bounceInLeft" : ""
                }`}
              style={{
                width: "auto",
                // display:"none"
                transform: `scale(${calculateZoom(scrollPositionCards)})`, // Apply zoom
                transition: "transform 0.2s ease-out", // Smooth transition
              }}

              role="presentation"

              loading="lazy"
            />

            {/* Right Image */}
            <img
              src={right}
              alt="Right decoration"
              className={`absolute top-0 right-0 h-auto max-h-[70%] pointer-events-none right-joint ${animate ? "animate__animated animate__bounceInRight" : ""
                }`}
              style={{
                width: "auto",
                // display:"none"
                transform: `scale(${calculateZoom(scrollPositionCards)})`, // Apply zoom
                transition: "transform 0.2s ease-out", // Smooth transition
              }}

              role="presentation"

              loading="lazy"
            />


          </div>



          {/* animate__bounceInLeft */}


          {/* <div
            className={`absolute left-0 top-0 h-full w-1/3 bg-no-repeat  bg-left pointer-events-none left-joint ${
              animate ? "animate__animated   animate__bounceInLeft" : ""
             } `}
            style={{
              backgroundImage: `url(${left})`,
              height: "70%",
     
            }}
          ></div>


          <div
             className={`absolute right-0 top-0 h-full w-1/3 bg-no-repeat  bg-right pointer-events-none right-joint ${
              animate ? "animate__animated   animate__bounceInRight" : ""
             } `}
            style={{
              backgroundImage: `url(${right})`,
              height: "70%",
            
            }}
          ></div> */}



        </div>







        <Partners />

        <DevelopmentSection />
        <SplitLanding />
        {/* <Workflow/> */}
        <WorkflowSection />

        <TechStack />
        <PortfolioSection />
        <IndustriesSection />
        <TestimonialsSection />
        {/* <FAQSection /> */}
        <FAQsWork />
        <BlogSection />
        <Footer />







      </main>
    </motion.div>


  );
}

export default LandingPage;
