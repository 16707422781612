import React from "react";

// import Navbar from "../components/Navbar";
import Navbar from "../../components/Navbar";
import { ServiceHero } from "../../components/ServiceHero/ServiceHero";

import left from "../../assets/images/Services/left_cont.svg"
import right from "../../assets/images/Services/right_cont.svg"

import { useState } from "react";

import { useEffect } from "react";
import IndustriesWeServe from "../../components/Services/AIDev/IndustriesWeServe";
import CustomCTM from "../../components/Services/CustomProductDev/CustomCTM";
import Footer from "../../components/Footer/Footer";
import ServiceSlider from "../../components/ServiceSlider/ServiceSlider";
import OurServices from "../../components/OurServices/OurServices";
import TechServices from "../../components/TechServices/TechServices";
import WorkProcess from "../../components/WorkProcess/WorkProcess";
import "./Service.css"
import ServiceGraph from "../../components/ServiceGraph/ServiceGraph";
import Loader from "../../components/Loader1/Loader";



function Services() {

  const [cardStack, setCardStack] = useState(false)

  const [scrollPosition, setScrollPosition] = useState(0);

  const [scrollPositionCards, setScrollPositionCards] = useState(0);

  // Detect scroll event
  useEffect(() => {
    const handleScrollCards = () => {
      setScrollPositionCards(window.scrollY); // Set scroll position on scroll
    };

    window.addEventListener("scroll", handleScrollCards); // Add scroll event listener
    return () => {
      window.removeEventListener("scroll", handleScrollCards); // Clean up event listener
    };
  }, []);

  // Zoom effect based on scroll position
  const calculateZoom = (scrollPositionCards) => {
    const zoomFactor = Math.max(1 - scrollPositionCards / 1000, 0.7); // Adjust zoom sensitivity here
    return zoomFactor;
  };


  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const [animate, setAnimate] = useState(false);
  useEffect(() => {
    // Trigger animation on mount
    setAnimate(true);

    // Optional: Remove animation class after it's done
    const timer = setTimeout(() => setAnimate(false), 1000); // Animation duration is 1s
    return () => clearTimeout(timer); // Clean up the timer on unmount
  }, []);

  const [isOpen, setIsOpen] = useState(false)
       useEffect(() => {
            window.scrollTo(0, 0); 
        }, []);


        const [loading, setLoading] = useState(true); 


        useEffect(() => {
           const handleLoad = () => {
             setTimeout(() => setLoading(false), 1500); 
           };
         
           if (document.readyState === "complete") {
             setTimeout(() => setLoading(false), 1500);
           } else {
             window.addEventListener("load", handleLoad);
           }
         
           return () => window.removeEventListener("load", handleLoad);
         }, []);
         
         useEffect(() => {
           if (!loading) {
             setTimeout(() => setAnimate(true), 100);
           }
         }, [loading]);
         
         if (loading) {
           return <Loader />;
         }
  return (



    <div className="relative flex min-h-screen py-10 flex-col bg-white">

      <main className="flex-1">

        <div className="w-full relative" style={{ zIndex: "999" }}>
          <Navbar isOpen={isOpen} setIsOpen={setIsOpen} />



          <img
            src={left}
            alt="Left decoration"
            className={`absolute top-15 left-10 h-auto  pointer-events-none left-img -z-10 hidden lg:block ${animate ? "animate__animated animate__jackInTheBox" : ""
              }`}
            style={{
           
              transform: `scale(${calculateZoom(scrollPositionCards)})`,
              transition: "transform 0.2s ease-out",
            }}
          />


          <img
            src={right}
            alt="Right decoration"
            className={`absolute top-15 right-10 h-auto pointer-events-none right-img -z-10 hidden lg:block ${animate ? "animate__animated animate__jackInTheBox" : ""
              }`}
            style={{
           

              transform: `scale(${calculateZoom(scrollPositionCards)})`,
              transition: "transform 0.2s ease-out",
            }}
          />
          <div className="w-full hidden justify-center -mt-20 md:flex">
            <svg
              className=" h-auto"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 489 429"
              style={{width:"clamp(25px,80%,335px)"}}
            >
              <path
                d="M 472.903 83.8 
           C 417.302 35.747 321.102 -11.368 240.895 35.459 
           C 111.796 110.834 194.484 225.891 258.778 246.414 
           C 314.373 264.161 343.363 199.518 243.126 162.942 
           C 177.606 139.035 86.553 147.243 51.352 198.732 
           C 3.224 269.132 72.078 316.981 104.714 324.385 
           C 158.609 336.612 163.716 281.692 101.748 273.198 
           C 38.974 264.593 0.97 303.747 21.932 412.542"
                stroke="url(#gradient1)"
                strokeWidth="32"
                strokeLinecap="round"
                fill="transparent"
                opacity="1"
                pathLength="1"
                strokeDashoffset="0"
                strokeDasharray="1 1"
              >
                <animate
                  attributeName="stroke-dashoffset"
                  from="1"
                  to="0"
                  dur="3s"
                  fill="freeze"
                />
              </path>
              <defs>
                <linearGradient id="gradient1" x1="0%" y1="0%" x2="0%" y2="100%">
                  <stop offset="0%" stopColor="#2AACD1" />
                  <stop offset="100%" stopColor="#24B574" />
                </linearGradient>

              </defs>
            </svg>
          </div>




          <div className="relative -z-10">


            <ServiceHero isOpen={isOpen} setIsOpen={setIsOpen} />




            {/* <ServiceSlider /> */}


          </div>

          <OurServices />
          <WorkProcess/>
          <ServiceGraph/>

          <TechServices/>


          <IndustriesWeServe />
          <CustomCTM />
          <Footer />







        </div>









        {/* <Footer /> */}







      </main>
    </div>


  );
}

export default Services;
