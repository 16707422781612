import React from 'react'
import { motion } from 'framer-motion' 


const BlogBanner = ({blogData}) => {


  // console.log("sahndbhabdhbas", blogData?.blogData)
  return (
    <motion.div className="mb-8 bg-white rounded-2xl p-1 max-w-5xl mx-auto" initial={{ opacity: 0, y: 50 }}
    whileInView={{ opacity: 1, y: 0 }}
    viewport={{ once: true, amount: 0.2 }}
    transition={{ duration: 0.5 }}
    style={{ boxShadow: "0px 0px 4px 0px #00000040" }}
  >
    <div className="bg-gradient-to-b from-[#EBFFFA] to-[#FFFFFF] rounded-2xl  p-6 min-h-[210px]">



      <div className="flex items-center mb-4 gap-2">
        <span className="text-xs sm:text-sm font-medium text-[#3D3D3D] bg-[#FFFFFF] px-2 sm:px-3 py-1 rounded-full border border-[#E1E4EB]">
        {blogData?.topic?.tag}
        </span>
        <span className="text-xs sm:text-sm font-medium text-[#3D3D3D] bg-[#FFFFFF] px-2 sm:px-3 py-1 rounded-full border border-[#E1E4EB]">
        {blogData?.topic?.date}
        </span>
      </div>
      <h1 className="text-3xl md:text-4xl font-medium text-[#191919] mb-4 inter lg-text">
      {blogData?.topic?.text}
      </h1>



    </div>
  </motion.div>
  )
}

export default BlogBanner
