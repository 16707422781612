import NotFoundImg from "../../assets/images/404NotFound.png";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen text-center px-4">
      <img src={NotFoundImg} alt="404 Not Found" className="w-56 sm:w-72 md:w-80 mb-6" />
      <h1 className="text-2xl sm:text-3xl font-extrabold mb-6 text-[#414141]">Oops! Page Not Found</h1>
<p className="text-base sm:text-lg text-[#18181B] mb-6 max-w-xl">
  The page you are looking for might have been removed, had its name changed, or is temporarily unavailable. 
  Please check the URL or return to the homepage.
</p>




      <Link to={"/"}>
      <button
       
        className={`text-white px-12 py-3 rounded-xl text-sm sm:text-base font-semibold consultation-btn  `}



      >
        Go to Home
      </button>
      </Link>
    </div>
  );
};

export default NotFound;
