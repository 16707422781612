import logo from "../../assets/images/WorkDetails/SindhiSoulmate/logo.svg"
import CommonDiv from "./CommonDiv"
import { motion } from "framer-motion";

export default function ProjectDescription() {
    const projectInfo = {
        deliverables: ["Desktop Web app", "Mobile App", "Landing Page"],
        timeline: "2022-2023",
        services: ["Research", "UI/UX Design", "Prototype"],
    }

    return (

        <>


            <motion.div className="max-w-5xl mx-auto pt-8" initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true, amount: 0.2 }}
                transition={{ duration: 0.5 }}>



                <div className="grid grid-cols-2 md:grid-cols-3 gap-8 mb-12">

                    <div>
                        <h3 className="font-normal text-black mb-4 text-lg">Deliverables</h3>
                        <ul className="space-y-2">
                            {projectInfo.deliverables.map((item, index) => (
                                <li key={index} className="text-[#4D4D4D] text-lg font-normal">
                                    {item}
                                </li>
                            ))}
                        </ul>
                    </div>

                    {/* Project Timeline */}
                    <div>
                        <h3 className="font-normal text-black mb-4 text-lg">Project timeline</h3>
                        <p className="text-[#4D4D4D] text-lg font-normal">{projectInfo.timeline}</p>
                    </div>

                    {/* What We Do */}
                    <div>
                        <h3 className="font-normal text-black mb-4 text-lg">What we do</h3>
                        <ul className="space-y-2">
                            {projectInfo.services.map((service, index) => (
                                <li key={index} className="text-[#4D4D4D] text-lg font-normal">
                                    {service}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>


                {/* <div className="max-w-6xl mx-auto">
                <div className="mb-12">
                    <p className="text-[#292929] text-2xl md:text-3xl leading-relaxed">
                        Lorem ipsum dolor sit amet consectetur ,adipl ipsum dolor sit ametr sit a Lorem. Lorem ipsum dolor sit amet
                        consectetur ,adipl ipsum dolor sit ametr sit a Lorem. Lorem ipsum dolor sit amet consectetur ,adipl ipsum
                        dolor Lorem.
                    </p>
                </div>

         
                <div>
                    <h2 className="text-xl font-semibold text-gray-900 mb-4">The Challenge</h2>
                    <div className="space-y-4">
                        <p className="text-gray-600">
                            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Provident in doloremque officia dignissimos
                            deleniti inventore porro possimus, suscipit natus. Commodi iure voluptate voluptatum quo ducimus eaque error
                            numquam facilis itaque?
                        </p>
                        <p className="text-gray-600">
                            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Provident in doloremque officia dignissimos
                            deleniti inventore porro possimus, suscipit natus. Commodi iure voluptate voluptatum quo ducimus eaque error
                            adipisicing elit. Provident in doloremque i iure voluptate voluptatum quo ducimus eaque error numquam
                            facilis itaque?
                        </p>
                    </div>
                </div>
            </div> */}
            </motion.div>
            <div className="max-w-6xl mx-auto">
                <motion.div className="mb-12"   initial={{ opacity: 0, y: 50 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true, amount: 0.2 }}
                        transition={{ duration: 0.5 }}>
                    <p className="text-[#292929] text-xl md:text-2xl leading-relaxed font-medium">
                        Lorem ipsum dolor sit amet consectetur ,adipl ipsum dolor sit ametr sit a Lorem. Lorem ipsum dolor sit amet
                        consectetur ,adipl ipsum dolor sit ametr sit a Lorem. Lorem ipsum dolor sit amet consectetur ,adipl ipsum
                        dolor Lorem. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Similique, veritatis.
                    </p>
                </motion.div>

                <div className="space-y-10">
                    <motion.div className="grid grid-cols-1 md:grid-cols-[0.5fr_2.5fr] gap-4 items-start"   initial={{ opacity: 0, y: 50 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true, amount: 0.2 }}
                        transition={{ duration: 0.5 }}>
                        {/* Left Side - Heading */}
                        <h2 className="text-2xl  text-[#000000] font-normal">The Challenge</h2>

                        {/* Right Side - Description */}
                        <div className="space-y-4">
                            <p className="text-[#676767] text-lg">
                                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Provident in doloremque officia dignissimos
                                deleniti inventore porro possimus, suscipit natus. Commodi iure voluptate voluptatum quo ducimus eaque error
                                numquam facilis itaque?
                            </p>
                            <p className="text-[#676767] text-lg">
                                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Provident in doloremque officia dignissimos
                                deleniti inventore porro possimus, suscipit natus. Commodi iure voluptate voluptatum quo ducimus eaque error
                                adipisicing elit. Provident in doloremque i iure voluptate voluptatum quo ducimus eaque error numquam
                                facilis itaque?
                            </p>
                        </div>
                    </motion.div>


                    <motion.div className="grid grid-cols-1 md:grid-cols-[0.5fr_2.5fr] gap-4 items-start"   initial={{ opacity: 0, y: 50 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true, amount: 0.2 }}
                        transition={{ duration: 0.5 }}>

                        <h2 className="text-2xl  text-[#000000] font-normal">The Solution</h2>

                        {/* Right Side - Description */}
                        <div className="space-y-4">
                            <p className="text-[#676767] text-lg">
                                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Provident in doloremque officia dignissimos
                                deleniti inventore porro possimus, suscipit natus. Commodi iure voluptate voluptatum quo ducimus eaque error
                                numquam facilis itaque?
                            </p>
                            <p className="text-[#676767] text-lg">
                                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Provident in doloremque officia dignissimos
                                deleniti inventore porro possimus, suscipit natus. Commodi iure voluptate voluptatum quo ducimus eaque error
                                adipisicing elit. Provident in doloremque i iure voluptate voluptatum quo ducimus eaque error numquam
                                facilis itaque?
                            </p>
                        </div>
                    </motion.div>
                </div>




            </div>

            <CommonDiv />


        </>




    )
}

