import React from "react";
import { useState, useEffect } from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer/Footer";
import AllBlog from "../../components/AllBlog/AllBlog";
import FAQsWork from "../../components/FAQsWork/FAQsWork";
import BreadCrumbBlog from "../../components/BreadCrumbsBlog/BreadCrumbBlog";
import BlogPersonal from "../../components/BlogDetails/BlogPersonal";
import OtherBlogs from "../../components/BlogDetails/OtherBlogs";
import blogData from "../../data/blogWeb.json"
import { useParams } from "react-router-dom";
import Loader from "../../components/Loader1/Loader";



function BlogDetails() {
    const { slug } = useParams();
    console.log(slug)


    const [isOpen, setIsOpen] = useState(false)

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [loading, setLoading] = useState(true);


    useEffect(() => {
        const handleLoad = () => {
            setTimeout(() => setLoading(false), 1500);
        };

        if (document.readyState === "complete") {
            setTimeout(() => setLoading(false), 1500);
        } else {
            window.addEventListener("load", handleLoad);
        }

        return () => window.removeEventListener("load", handleLoad);
    }, []);


    if (loading) {
        return <Loader />;
    }

    return (



        <div className="relative flex min-h-screen py-10 flex-col bg-white">

            <main className="flex-1 relative" style={{ zIndex: "999" }}>

                <div className="w-full relative">
                    <Navbar isOpen={isOpen} setIsOpen={setIsOpen} />




                </div>

                <div className="relative -z-10">
                    <BreadCrumbBlog />
                    <div className="relative " style={{ marginTop: "-17rem" }}>  {/* Increased overlap further */}
                        <BlogPersonal />
                    </div>
                </div>

                {/* <BreadCrumbBlog /> */}

                <OtherBlogs />




                {/* <FAQsWork /> */}










                <Footer />


            </main>
        </div>


    );
}

export default BlogDetails;
