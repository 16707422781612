import { Star } from "@mui/icons-material"
import mobile_img from "../../assets/images/MobileDevPage/HeroSection/mobile_img.png"
import star_img from "../../assets/images/MobileDevPage/HeroSection/star.svg"
import active1 from "../../assets/images/MobileDevPage/HeroSection/active1.png"
import active2 from "../../assets/images/MobileDevPage/HeroSection/active2.png"
import active3 from "../../assets/images/MobileDevPage/HeroSection/active3.png"
import { motion } from "framer-motion"
export default function GoalBasedMobile() {

    const images = [active1, active2, active3];
    return (
        <section className="relative py-0 overflow-hidden">
            {/* Background Gradients */}


            <div className="max-w-7xl mx-auto px-4 relative">
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-12 items-center">

                    <div className="space-y-8 bg-white p-6 rounded-2xl">
                        <div >
                            <h2 className="text-2xl font-bold mb-4">Lorem ipsum dolor sit amet</h2>
                            <p className="text-[#4B4B57] text-base">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusantium, sint? Lorem ipsum dolor sit amet consectetur adipisicing.
                            </p>
                        </div>


                        <div className="flex items-center gap-4">
                            <div className="flex -space-x-3">
                                {/* {[1, 2, 3].map((_, index) => (
                                    <div key={index} className="w-10 h-10 rounded-full border-2 border-white bg-gray-200" />
                                ))} */}

                                {images.map((img, index) => (
                                    <img
                                        key={index}
                                        src={img}
                                        alt={`Active ${index + 1}`}
                                        className="w-10 h-10 rounded-full border-2 border-white object-cover"
                                    />
                                ))}
                            </div>
                            <div>
                                <div className="text-2xl font-bold text-[#0F0F0F]">100+</div>
                                <div className="text-base text-[#4B4B57]">Active Clients</div>
                            </div>
                        </div>
                    </div>
                    <motion.div
                        initial={{ y: 100, opacity: 0 }}
                        whileInView={{ y: 0, opacity: 1 }}
                        transition={{ duration: 2, ease: "easeOut" }}
                        viewport={{ once: true, amount: 0.2 }} // Ensures animation plays only once
                        className="relative mx-auto w-[300px] h-[550px] sm:h-[600px]"
                    >
                        <img src={mobile_img} alt="" />
                    </motion.div>

                    {/* <div className="relative mx-auto w-[300px] h-[550px] sm:h-[600px]">
                        <img src={mobile_img} alt="" />

                    </div> */}


                    <div className="bg-gradient-to-b from-[#E6F9FC] to-[#FAFAFA] backdrop-blur-sm rounded-2xl p-6 ">

                        <div className="flex text-emerald-500 mb-2">
                            {[1, 2, 3, 4, 5].map((star) => (
                                // <Star key={star} className="w-5 h-5" />
                                <img src={star_img} alt="" />
                            ))}
                        </div>
                        <h3 className="text-xl font-bold mb-4">Lorem ipsum dolor sit amet.</h3>
                        <p className="text-[#4B4B57] mb-4">Lorem ipsum dolor sit amet consectetur adipisicing elit. Rem!</p>
                        <div className="font-medium text-[#0F0F0F]">Lorem, ipsum </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

