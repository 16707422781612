import React from "react";
import { useState, useEffect } from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer/Footer";
import AllBlog from "../../components/AllBlog/AllBlog";
import FAQsWork from "../../components/FAQsWork/FAQsWork";
import BreadCrumbBlog from "../../components/BreadCrumbsBlog/BreadCrumbBlog";
import FooterSecondary from "../../components/FooterSecondary/FooterSecondary";
import CTMSection from "../../components/CTMSection/CTMSection";






function BlogPage() {

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top when component mounts
      }, []);
    
    const [isOpen, setIsOpen] = useState(false)

    return (



        <div className="relative flex min-h-screen py-10 flex-col bg-white">

            <main className="flex-1 relative " style={{ zIndex: "999" }}>

                <div className="w-full relative">
                    <Navbar isOpen={isOpen} setIsOpen={setIsOpen} />




                </div>
                <div className="relative -z-10" >
                    <BreadCrumbBlog />
                    {/* <div className="relative " style={{ marginTop: "-20rem" }}>  */}
                    <div className="relative " style={{ marginTop: "-17rem" }}>

                        <AllBlog />
                    </div>
                </div>
                <CTMSection />










                {/* <Footer /> */}
                <Footer />


            </main>
        </div>


    );
}

export default BlogPage;
