// import { technologies } from "../../data/techStackData"

import { useState } from "react";
import { motion } from "framer-motion";

import { useMediaQuery } from "@mui/material";
export default function TechStackAlt({ technologies, name }) {

    const [hoveredIndex, setHoveredIndex] = useState(null);
    const isDesktop = useMediaQuery('(min-width:1024px)');
    // console.log("Name", name)
    const chunkArray = (arr, size) => {
        return arr.reduce((acc, _, i) =>
            (i % size ? acc : [...acc, arr.slice(i, i + size)]), []);
    };

    const rows = chunkArray(technologies, 5);

    return (
        isDesktop ? (
            <>
                <section className="py-0 sm:py-16 px-4 max-w-7xl mx-auto tech-stack space-y-8" >
                    <motion.div className="text-center mb-12" initial={{ opacity: 0, y: 50 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true, amount: 0.2 }}
                        transition={{ duration: 0.5 }} >
                        <h2 className="text-3xl md:text-5xl font-medium text-[#212A40] mb-4 poppins lg-text">Tech-<span className="text-[#24B574]">Stack</span> we use</h2>
                        <p className="text-[#485E79] max-w-2xl text-lg mx-auto">
                            Lorem ipsum dolor sit amet consectetur, adipisicing elit. A, ab nemo! Expedita! Lorem ipsum dolor sit amet
                        </p>
                    </motion.div>

                    {rows.map((row, index) => (
                        

                        <div className={`grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-${row.length} gap-8 justify-items-center  ${index === rows.length - 1 && row.length === 4 ? "max-w-5xl" : "max-w-7xl"} 
 mx-auto `}>


                            {row?.map((tech, index) => (
                                <motion.div
                                    onMouseEnter={() => setHoveredIndex(index)}
                                    onMouseLeave={() => setHoveredIndex(null)}
                                    initial={{ opacity: 0, y: 50 }}
                                    whileInView={{ opacity: 1, y: 0 }}
                                    viewport={{ once: true, amount: 0.2 }}
                                    transition={{ duration: 0.5 }}
                                    key={index}
                                    className="flex flex-col cursor-pointer items-center justify-center group bg-white shadow-[0px_0px_3px_rgba(60,72,88,0.15)] rounded-lg p-4 transition-colors duration-300 hover:bg-[#22989F]"
                                >
                                    <div className="w-36 h-20 mb-3 flex items-center justify-center transition-transform duration-300 hover:-translate-y-2">
                                        <img
                                            src={hoveredIndex === index && tech.hover ? tech.hover : tech.logo || "/placeholder.svg"}

                                            alt={`${tech.name} logo`}
                                            // className="object-contain group-hover:filter group-hover:brightness-0 group-hover:invert"
                                            className={`object-contain ${["Webflow", "Node Js", "Google Cloud AI"].includes(tech.name)
                                                ? ""
                                                : "w-14"
                                                } 
                                
                                
                                
                                ${["Sanity", "Angular", "PostgreSQL", "Azure", "Scikit-Learn", "Elementor"].includes(tech.name)
                                                    ? ""
                                                    : "group-hover:filter group-hover:brightness-0 group-hover:invert"
                                                }`}
                                        />
                                    </div>
                                    <span className="text-xl text-[#3C4858] text-center font-600 group-hover:text-[#FFFFFFCC] transition-colors duration-300">{tech.name}</span>
                                </motion.div>
                            ))}


                        </div>
                    ))}
                </section>
            </>
        ) :
            (
                <>
                    <section className="py-0 sm:py-16 px-4 max-w-7xl mx-auto tech-stack">
                        <motion.div
                            className="text-center mb-12"
                            initial={{ opacity: 0, y: 50 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            viewport={{ once: true, amount: 0.2 }}
                            transition={{ duration: 0.5 }}
                        >
                            <h2 className="text-3xl md:text-5xl font-medium text-[#212A40] mb-4 poppins lg-text">
                                Tech-<span className="text-[#24B574]">Stack</span> we use
                            </h2>
                            <p className="text-[#485E79] max-w-2xl text-lg mx-auto">
                                Lorem ipsum dolor sit amet consectetur, adipisicing elit. A, ab nemo! Expedita! Lorem ipsum dolor sit amet
                            </p>
                        </motion.div>

                        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-8 justify-items-center max-w-7xl mx-auto">
                            {technologies?.map((tech, index) => (
                                <motion.div
                                    onMouseEnter={() => setHoveredIndex(index)}
                                    onMouseLeave={() => setHoveredIndex(null)}
                                    initial={{ opacity: 0, y: 50 }}
                                    whileInView={{ opacity: 1, y: 0 }}
                                    viewport={{ once: true, amount: 0.2 }}
                                    transition={{ duration: 0.5 }}
                                    key={index}
                                    className="flex flex-col cursor-pointer items-center justify-center group bg-white shadow-[0px_0px_3px_rgba(60,72,88,0.15)] rounded-lg p-4 transition-colors duration-300 hover:bg-[#22989F]"
                                >
                                    <div className="w-36 h-20 mb-3 flex items-center justify-center transition-transform duration-300 hover:-translate-y-2">
                                        <img
                                            src={
                                                hoveredIndex === index && tech.hover
                                                    ? tech.hover
                                                    : tech.logo || "/placeholder.svg"
                                            }
                                            alt={`${tech.name} logo`}
                                            className={`object-contain ${["Webflow", "Node Js", "Google Cloud AI"].includes(tech.name) ? "" : "w-14"
                                                } ${["Sanity", "Angular", "PostgreSQL", "Azure", "Scikit-Learn", "Elementor"].includes(tech.name)
                                                    ? ""
                                                    : "group-hover:filter group-hover:brightness-0 group-hover:invert"
                                                }`}
                                        />
                                    </div>
                                    <span className="text-xl text-[#3C4858] text-center font-600 group-hover:text-[#FFFFFFCC] transition-colors duration-300">
                                        {tech.name}
                                    </span>
                                </motion.div>
                            ))}
                        </div>
                    </section>

                </>
            )
    );

}

